
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';


import useAxios from 'library/axios'; 
import { useState } from 'react';
import { currentUserSessionAtom } from 'library/store';
import {IPerson, IPersonSearch } from "../models/Person";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';



import { FireExtinguisher } from '@mui/icons-material';
import { isFalsy } from 'utility-types';


const _ = () => {

    const axios = useAxios(); 

    const createPerson = async (person: IPerson)  =>       
        await (await axios.post('/api/production/person/create', person)).data;  
    
    const createBasicImportPerson = async (product: IPerson[])  =>       
      await (await axios.post('/api/production/person/create-basic-import', product)).data;  
        
    const updatePerson = async (person: IPerson)  =>       
        await (await axios.post('/api/production/person/update', person)).data; 
    
    const getPerson = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/person/get-person/${id}`));
      return await data;
    }
   

    const getPersons = async (criteria: IPersonSearch, pagination?: IPagination) : Promise<IPerson[]> => {

      const { name, portable, email} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/person/get-persons?name=${name}&portable=${portable}&email=${email}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getPersonsSearchCount = async (firstName: string, lastName: string, particularSign: string) : Promise<number> => {
      
      const {data} = (await axios.get(`/api/production/person/get-persons-search-count?firstName=${firstName}&lastName=${lastName}&particularSign=${particularSign}`));
      return await data;
    }

    const getPersonsSearch = async (firstName: string, lastName: string, particularSign: string, criteria: IPersonSearch, pagination?: IPagination) : Promise<IPerson[]> => {

      const { name, portable, email} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/person/get-persons-search?firstName=${firstName}&lastName=${lastName}&particularSign=${particularSign}`+
                  `&portable=${portable}&email=${email}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getParentPortables = async (personId: number) : Promise<IPerson[]> => {

      const {data} = (await axios.get(`/api/production/person/get-parent-portables?personId=${personId}`));
      return await data;
    }

    const getParentEmails = async (personId: number) : Promise<IPerson[]> => {

      const {data} = (await axios.get(`/api/production/person/get-parent-emails?personId=${personId}`));
      return await data;
    }

    const hasName = (person  : IPerson ) : boolean  => {      
      const {firstName, lastName, corporationName} = person;
      return !(isFalsy(firstName) && isFalsy(lastName) && isFalsy(corporationName));
    }

    const getName = (person : IPerson, language: string) : string => {
      const {firstName, lastName, corporationName, type} = person;
      return type === 'moral' ? corporationName : 
              language.includes('en')? `${firstName} ${lastName}`.trim() : `${lastName} ${firstName}`.trim();
      
    }

    const getPhysicalPersonNameLg = (firstName: string, lastName: string, language: string) : string => {
      
      return (language||'').includes('en')? `${firstName||''} ${lastName}||''`.trim() : `${lastName||''} ${firstName||''}`.trim();
      
    }
    
      
    return {    
      createPerson,
      createBasicImportPerson,
      updatePerson,
      getPerson,

      getPersons,
      getPersonsSearchCount,
      getPersonsSearch,

      getParentPortables,
      getParentEmails,

      hasName,
      getName,
      getPhysicalPersonNameLg
    } 
}

export default _;

export interface IFilterPersonOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IPerson[], React.Dispatch<React.SetStateAction<IPerson[]>>],
}

const defaultFilterPersonOption: IFilterPersonOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterPerson = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IPerson) => void,
                                            filterOption?: IFilterPersonOption  ) => {

  const { getPersons } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg,schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterPersonOption;

  const [headPersonCells, setHeadPersonCells]  = useState<HeadCell<IPerson>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },    

    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 20 },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 20},

    {id:'birthDate', label : t('Birth date'),  display: true, type: 'date', },
    {id:'portable1', label : t('Portable'),  display: true, type: 'string', },
    {id:'portable2', label : t('Portable'),  display: true, type: 'string', },
    {id:'email1', label : t('Email'),  display: true, type: 'string', },
    {id:'email2', label : t('Email'),  display: true, type: 'string', },
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
      {name: 'name', text: t('Name'), value: ''},
      {name: 'portable', text: t('Portable'), value: ''},
      {name: 'email', text: t('Email'), value: ''},
      
    ]);    

  const [filteredPersons, ] = useState<IPerson[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IPerson[]> => {    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const portable = filterElements.find( elt => elt.name === 'portable')?.value || '';       
    const email = filterElements.find( elt => elt.name === 'email')?.value || '';     

    const arr = await getPersons( { name, portable, email}, pagination );
   
    return arr;
  }

  const objKey: keyof IPerson = 'id';

  return {
    title: t('Person'), headCells: headPersonCells, objKey,
    filterElements, rows: filteredPersons, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterPersonSearch = ( getSearch: () => {firstName: string, lastName: string, particularSign: string}, 
                                            onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IPerson) => void,
                                            filterOption?: IFilterPersonOption  ) => {

  const { getPersonsSearch } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg,schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterPersonOption;

  const [headPersonCells, setHeadPersonCells]  = useState<HeadCell<IPerson>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },    
    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string' },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string' },

    {id:'birthDate', label : t('Birth date'),  display: true, type: 'date', },
    {id:'portable1', label : t('Portable'),  display: true, type: 'string', },
    {id:'portable2', label : t('Portable'),  display: true, type: 'string', },
    {id:'email1', label : t('Email'),  display: true, type: 'string', },
    {id:'email2', label : t('Email'),  display: true, type: 'string', },
  ]); 

  const {firstName, lastName, particularSign} = getSearch();

  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
    {name: 'portable', text: t('Portable'), value: ''},
    {name: 'email', text: t('Email'), value: ''},
      
  ]);    

  const [filteredPersons, ] = useState<IPerson[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IPerson[]> => {    
        
    const portable = filterElements.find( elt => elt.name === 'portable')?.value || '';       
    const email = filterElements.find( elt => elt.name === 'email')?.value || '';     

    const arr = await getPersonsSearch(firstName, lastName, particularSign, { name: '', portable, email}, pagination );
   
    return arr;
  }

  const objKey: keyof IPerson = 'id';

  return {
    title: `${t('Person')} : ${firstName} | ${lastName} | ${particularSign}`, headCells: headPersonCells, objKey,
    filterElements, rows: filteredPersons, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered,
    autoFilter: true
  }
}



