

export interface IClassroom {
    id: number,
    name: string,
    isActive: boolean,
    localisation: string,
    capacity: number,
    maxCapacity: number,
    base64Picture: string,
    area: number,
    canBeShared: boolean,


    fileName: string,
  }


  export const defaultClassroom : IClassroom = {
    id: 0,
    name: '',
    isActive: true,
    localisation: '',
    capacity: 0,
    maxCapacity: 0,
    base64Picture: '',
    area: 0,
    canBeShared: false,


    fileName: ''
  }

  export interface IClassroomSearch {
   
    name: string,
    localisation: string
  }