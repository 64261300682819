
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IBillingType, IBillingTypeSearch } from "../models/BillingType";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createBillingType = async (billingType: IBillingType)  =>       
        await (await axios.post('/api/setup/billingType/create', billingType)).data;       
        
    const updateBillingType = async (billingType: IBillingType)  =>       
        await (await axios.post('/api/setup/billingType/update', billingType)).data; 
    
    const getBillingType = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/billingType/get-billingType/${id}`));
      return await data;
    }
   

    const getBillingTypes = async (criteria: IBillingTypeSearch) : Promise<IBillingType[]> => {

      const {code, name, description} = criteria;
      const {data} = (await axios.get(`/api/setup/billingType/get-billingTypes?code=${code}&name=${name}&description=${description}`));
      return await data;
    }

    const getTransportBillingTypes = async (criteria: IBillingTypeSearch) : Promise<IBillingType[]> => {

      const {code, name, description} = criteria;
      const {data} = (await axios.get(`/api/setup/billingType/get-transport-billingTypes?code=${code}&name=${name}&description=${description}`));
      return await data;
    }

    const getArticleBillingTypes = async (criteria: IBillingTypeSearch) : Promise<IBillingType[]> => {

      const {code, name, description} = criteria;
      const {data} = (await axios.get(`/api/setup/billingType/get-article-billingTypes?code=${code}&name=${name}&description=${description}`));
      return await data;
    }
      
    const getAvailableArticleBillingTypes = async (criteria: IBillingTypeSearch) : Promise<IBillingType[]> => {

      const {code, name, description} = criteria;
      const {data} = (await axios.get(`/api/setup/billingType/get-available-article-billingTypes?code=${code}&name=${name}&description=${description}`));
      return await data;
    }

    
    return {    
      createBillingType,
      updateBillingType,
      getBillingType,
      getBillingTypes,

      getTransportBillingTypes,
      getArticleBillingTypes,

      getAvailableArticleBillingTypes     
    } 
}

export default _;

export interface IFilterBillingTypeOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IBillingType[], React.Dispatch<React.SetStateAction<IBillingType[]>>],
}

const defaultFilterProductOption: IFilterBillingTypeOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterBillingType = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBillingType) => void,
                                            filterOption?: IFilterBillingTypeOption  ) => {

  const { getBillingTypes } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headBillingTypeCells, setHeadBillingTypeCells]  = useState<HeadCell<IBillingType>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'code', label : t('Code'),  display: true, type: 'string', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'code', text: t('Code'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredBillingTypes, ] = useState<IBillingType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IBillingType[]> => {
    
    
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getBillingTypes( {code, name,description} );
    
    return arr;
  }

  const objKey: keyof IBillingType = 'id';

  return {
    title: t('Billing type'), headCells: headBillingTypeCells, objKey,
    filterElements, rows: filteredBillingTypes, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}



export const useBasicFilterArticleBillingType = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBillingType) => void,
                                            filterOption?: IFilterBillingTypeOption  ) => {

  const { getAvailableArticleBillingTypes } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headBillingTypeCells, setHeadBillingTypeCells]  = useState<HeadCell<IBillingType>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    //{id:'code', label : t('Code'),  display: true, type: 'string', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },

    {id:'articleOptionName', label : t('Option'),  display: true, type: 'string', },
    {id:'availableQuantity', label : t('Quantity'),  display: true, type: 'numeric', },

    //{id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'deliveryDescription', label : t('Description'),  display: true, type: 'string', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'code', text: t('Code'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredBillingTypes, ] = useState<IBillingType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IBillingType[]> => {
    
    
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getAvailableArticleBillingTypes( {code, name,description} );
    
    return arr;
  }

  const objKey: keyof IBillingType = 'deliveryArticleId';

  return {
    title: t('Billing type'), headCells: headBillingTypeCells, objKey,
    filterElements, rows: filteredBillingTypes, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterTransportBillingType = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBillingType) => void,
                                            filterOption?: IFilterBillingTypeOption  ) => {

  const { getTransportBillingTypes } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headBillingTypeCells, setHeadBillingTypeCells]  = useState<HeadCell<IBillingType>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'code', label : t('Code'),  display: true, type: 'string', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },

    {id:'description', label : t('Description'),  display: true, type: 'string', },    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'code', text: t('Code'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredBillingTypes, ] = useState<IBillingType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IBillingType[]> => {    
    
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getTransportBillingTypes( {code, name,description} );
    
    return arr;
  }

  const objKey: keyof IBillingType = 'id';

  return {
    title: t('Billing type'), headCells: headBillingTypeCells, objKey,
    filterElements, rows: filteredBillingTypes, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
