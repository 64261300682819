
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useSchoolYearService, { useBasicFilterSchoolYear } from './services/SchoolYear';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ISchoolYear, ISchoolYearBillingType, ISchoolYearClass, ISchoolYearClassTemplate, ISchoolYearMaterial, ISchoolYearPeriod,
    ISchoolYearPeriodExam, ISchoolYearTarification, defaultSchoolYear, defaultSchoolYearPeriod } from './models/SchoolYear';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_STUDY_TYPE, Enum_STUDY_LEVEL, 
    Enum_STUDY_LANGUAGE, Enum_SCHOOL_MATERIAL } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import { DatePicker } from '@mui/x-date-pickers';
import ArrayFieldTableEx, {ActionIconTableRow, HeadCell} from 'components/ui/ArrayFieldTableEx';
import { IClassTemplate } from './models/ClassTemplate';
import { useBasicFilterClassroom } from './services/Classroom';
import { IClassroom } from './models/Classroom';
import { IBillingType } from './models/BillingType';
import { useBasicFilterBillingType } from './services/BillingType';
import { ITeacher } from './models/Teacher';
import { useBasicFilterTeacher } from './services/Teacher';
import { debounce, sum } from 'lodash';

export const BillingSchoolYearForm: FC<ISchoolYear> = (props: ISchoolYear = defaultSchoolYear) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { createSchoolYear, updateBillingSchoolYear } = useSchoolYearService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSchoolYear = useBasicFilterSchoolYear( 
    (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  const [selectedFilterClassTemplates, setSelectedFilterClassTemplates] = useState<number[]>([]);
  const [filteredClassTemplates, setFilteredClassTemplates] = useState<IClassTemplate[]>([]);
  
  type ClassTemplateUsage = 'class' | 'tarification';

  const [currentClassTemplateUsage, setCurrentClassTemplateUsage] = useState<ClassTemplateUsage>('class');
  const [openClassTemplateFilter, setOpenClassTemplateFilter] = useState(false);
  const basicFilterClassTemplate = useBasicFilterClassTemplate( 
      (event: React.MouseEvent<unknown>, row: IClassTemplate) => {
          const {name, description, id, studyLevel, studyLanguage} = row;

          
        if(currentClassTemplateUsage === 'tarification') {
          const schoolYearBillingType = getValues().schoolYearBillingTypes.at(schoolYearBillingTypeIndex);
          if(isFalsy(schoolYearBillingType)) return;
          
          const studyTypes = enumItems?.filter( e => e.enumerationCode === Enum_STUDY_TYPE && 
                !schoolYearBillingType.schoolYearTarifications.some( t => t.classTemplateId === id && t.studyType === e.code) ) || [];

          (refAppendSchoolYearTarifications.current??emptyFunc)( studyTypes.map( ({code}) => 
            ({id:0, schoolYearBillingTypeId:0, studyType: code, classTemplateId: id, 
              amountExpression: schoolYearBillingType.defaultAmountExpression,  
              billingTypeName: schoolYearBillingType.billingTypeName , classTemplateName: name }) ) );

        }
                           
         setOpenClassTemplateFilter(false);
      }, {
        rowCheckedMode: 'multiple', 
        stateSelected: [selectedFilterClassTemplates, setSelectedFilterClassTemplates],
        stateFiltered: [filteredClassTemplates, setFilteredClassTemplates],
    }
  );

  const [selectedFilterBillingTypes, setSelectedFilterBillingTypes] = useState<number[]>([]);
  const [filteredBillingTypes, setFilteredBillingTypes] = useState<IBillingType[]>([]);
  
  const [openBillingTypeFilter, setOpenBillingTypeFilter] = useState(false);
  const basicFilterBillingType = useBasicFilterBillingType( 
      (event: React.MouseEvent<unknown>, row: IBillingType) => {
          const {name, description, id} = row;

        if(getValues().schoolYearBillingTypes.some( bt => bt.billingTypeId === id))
          return;

        (refAppendSchoolYearBillingTypes.current??emptyFunc)(
          {id:0, schoolYearId:0, billingTypeId: id, billingTypeName: name, dueDate: new Date(), 
            obligatoryExpression: '',  defaultAmountExpression: '', schoolYearTarifications: [] });
                           
          setOpenBillingTypeFilter(false);
      }, {
        rowCheckedMode: 'multiple', 
        stateSelected: [selectedFilterBillingTypes, setSelectedFilterBillingTypes],
        stateFiltered: [filteredBillingTypes, setFilteredBillingTypes],
    }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  // const basicFilterEnumeration = useBasicFilterEnumeration( 
  //     (event: React.MouseEvent<unknown>, row: IEnumeration) => {
  //         const {id, name, description} = row;


  //         // setValue('enumerationId', id);
  //         // setValue('enumerationName', name);
                           
  //         setOpenEnumerationFilter(false);
  //     }
  // );
  

  
  const [openExtensionTypeFilter, setOpenExtensionTypeFilter] = useState(false);
  const basicFilterExtensionType = useBasicFilterExtensionType( 
      (event: React.MouseEvent<unknown>, row: IExtensionType) => {
          const {id, name, description, type, baseType} = row;
          
        setValue('currentExtensionTypeId', id);
        setValue('currentExtensionTypeName', name);  
        //setValue('currentExtensionTypeX', type);   
        //setValue('currentExtensionTypeBaseType', baseType);                    
                          
        setOpenExtensionTypeFilter(false);
      }
  );

  const emptyFunc = (obj: any) => {}

  const methods = useForm<ISchoolYear>({defaultValues:defaultSchoolYear});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields : schoolYearRegistrationExtensions, 
      append: appendSchoolYearRegistrationExtensions, 
      update: updateSchoolYearRegistrationExtension ,
      remove: removeSchoolYearRegistrationExtension,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
    name: `schoolYearRegistrationExtensions`,
    control,            
  });

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYear>,Error,ISchoolYear>(
      _id>0?updateBillingSchoolYear:createSchoolYear, {   
        onSuccess: (data: IResult<ISchoolYear>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SchoolYear',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ISchoolYear>(['SchoolYear', _id], () => retrieveEntity('SchoolYear',_id, 'Billing'), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const {data: enumItems} = useQuery<IEnumerationItem[]>(
      ['EnumerationItems', 'SchoolYear'], () => 
        getEnumerationItemsByEnumerationCodes( [Enum_STUDY_TYPE, Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_SCHOOL_MATERIAL ] ));

      const handleOkBasicTextFilterClassTemplateForm = () => {

        if(selectedFilterClassTemplates.length === 0) {
          // enque
          setOpenClassTemplateFilter(false);
          return;
        } 
    
      const classes2Append = filteredClassTemplates.filter(ct => selectedFilterClassTemplates.includes(ct.id || 0) );
    
      if(currentClassTemplateUsage === 'tarification') {
    
        const schoolYearBillingType = getValues().schoolYearBillingTypes.at(schoolYearBillingTypeIndex);
        if(isFalsy(schoolYearBillingType)) return;
        
        const studyTypes = enumItems?.filter( e => e.enumerationCode === Enum_STUDY_TYPE ) || [];
    
        const arrBillingTypeStudyType = classes2Append.map( ({name, id}) => ({name, id, studyTypes }) ).flatMap( 
          ({name, id, studyTypes }) => studyTypes.map( ({code}) => ({ name, id, studyType: code }) ) )
          .filter( x => !schoolYearBillingType.schoolYearTarifications.some( t => t.classTemplateId === x.id && t.studyType === x.studyType) );
          
        (refAppendSchoolYearTarifications.current??emptyFunc)( arrBillingTypeStudyType.map( ({name, id,studyType}) => 
          ({id:0, schoolYearBillingTypeId:0, studyType, classTemplateId: id, 
            amountExpression: schoolYearBillingType.defaultAmountExpression,  
            billingTypeName: schoolYearBillingType.billingTypeName , classTemplateName: name }) ) );
        //console.log(arrBillingTypeStudyType);
      }
    
        setOpenClassTemplateFilter(false);
    }
      
      const [openBillingTypeDetails,setOpenBillingTypeDetails] = useState<boolean>(false); 
          
    const handleOkBasicTextFilterBillingTypeForm = () => {
    
      if(selectedFilterBillingTypes.length === 0) {
        // enque
        setOpenBillingTypeFilter(false);
        return;
      } 
    
    const billings2Append = filteredBillingTypes.filter(ct => selectedFilterBillingTypes.includes(ct.id || 0) );
    
    (refAppendSchoolYearBillingTypes.current??emptyFunc)( billings2Append.map( ({name, description, id}) => 
                  ({id:0, schoolYearId:0, billingTypeId: id, billingTypeName: name, dueDate: new Date(), 
                    obligatoryExpression: '',  defaultAmountExpression: '', maxNumberPerRegistration: 0, schoolYearTarifications: [] 
                })));
    
      setOpenBillingTypeFilter(false);
    }
    
    
    const getInputAdornmentDefaultAmountExpression = (row: ISchoolYearBillingType, cellId: keyof ISchoolYearBillingType)  => ({
     
      toolTip: 'Expression',
      icon: DeveloperModeIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearBillingType ) => {    
        const {defaultAmountExpression, obligatoryExpression} = row;
        
        setSchoolYearBillingTypeIndex(index);
        handleClickOpenExpression(event, 'billing-defaultAmount', 'Billing', 'numeric', defaultAmountExpression);
      }  
    })
    
    const getInputAdornmentObligatoryExpression = (row: ISchoolYearBillingType, cellId: keyof ISchoolYearBillingType)  => ({
     
      toolTip: 'Expression',
      icon: DeveloperModeIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearBillingType ) => {    
        const {defaultAmountExpression, obligatoryExpression} = row;
        
        setSchoolYearBillingTypeIndex(index);
        handleClickOpenExpression(event, 'billing-obligatory', 'Registration', 'boolean', obligatoryExpression);
      }  
    })
    
    const getInputAdornmentBillingTypeDetails = (row: ISchoolYearBillingType, cellId: keyof ISchoolYearBillingType)  => ({
     
      toolTip: 'Details',
      icon: MoreIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearBillingType ) => {    
        const {defaultAmountExpression, obligatoryExpression} = row;
        
        setSchoolYearBillingTypeIndex(index);
        setOpenBillingTypeDetails(true);
      }  
    })
    
    const cellEditableBillingType = (row: ISchoolYearBillingType, cellId: keyof ISchoolYearBillingType) => {
      return true;
    }
    const [headSchoolYearBillingTypeCells, setHeadSchoolYearBillingTypeCells]  = useState<HeadCell<ISchoolYearBillingType>[]>([]);
      useEffect(() => {
        setHeadSchoolYearBillingTypeCells([            
          {id:'id', label : t('Id'),  display: false, type: 'numeric', },
          {id:'billingTypeName', label : t('Name'),  display: true, type: 'string', width: 25,  
            getInputAdornment: getInputAdornmentBillingTypeDetails},
          {id:'dueDate', label : t('Due date'),  display: true, type: 'date', width: 25, isEditable: cellEditableBillingType, },
          {id:'maxNumberPerRegistration', label : t('Max nber'),  display: true, type: 'numeric', width: 10, isEditable: cellEditableBillingType, },
          {id:'obligatoryExpression', label : `${t('Expression')} : ${t('Obligatory')}`,  display: true, type: 'string', width: 20, 
            getInputAdornment: getInputAdornmentObligatoryExpression },
          {id:'defaultAmountExpression', label : `${t('Default expression')} : ${t('Amount')}`,  display: true, type: 'string', width: 20, 
            getInputAdornment: getInputAdornmentDefaultAmountExpression },
        ]  )
      }, [t,i18n])
    
      const refAppendSchoolYearBillingTypes = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateSchoolYearBillingType= useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
      const refRemoveSchoolYearBillingType= useRef<(index: number ) => void>(null);
    
      const handleAddSchoolYearBillingTypes = (event: any) => {
    
        setOpenBillingTypeFilter(true);
        // (refAppendApplicationQueryParameters.current??emptyFunc)(
        //   {id:0, businessApplicationQueryId:0, jobScheduleParameters: [],
        //     parameterName: '',  parameterDataType: 'text' });
      }
    
      const [schoolYearBillingTypeIndex, setSchoolYearBillingTypeIndex] = useState<number>(-1);
      const schoolYearBillingTypeRowActionIcon = ( schoolYearBillingType: ISchoolYearBillingType) : ActionIconTableRow<ISchoolYear,ISchoolYearBillingType> => {
      
        const res: ActionIconTableRow<ISchoolYear,ISchoolYearBillingType> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ISchoolYearBillingType) => {
            
            (refRemoveSchoolYearBillingType.current??emptyFunc)(index);            
          }
        }
        return res;
    }
     
    const getStudyTypeTarificationList = (row: ISchoolYearTarification, cellId: keyof ISchoolYearTarification, 
      opts: {value: string, name: string}[]) => {        
      
      return getAsOptions(refEnumItems.current ?? [],Enum_STUDY_TYPE);
    }
    
    const getInputAdornmentTarificationAmountExpression = (row: ISchoolYearTarification, cellId: keyof ISchoolYearTarification)  => ({
     
      toolTip: 'Expression',
      icon: DeveloperModeIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearTarification ) => {    
        const {amountExpression} = row;
        
        setSchoolYearTarificationIndex(index);
        handleClickOpenExpression(event, 'billing-amount', 'Billing', 'numeric', amountExpression);
      }  
    })
    
    const cellEditableTarification = (row: ISchoolYearTarification, cellId: keyof ISchoolYearTarification) => {
      return true;
    }
    
    const [headSchoolYearTarificationCells, setHeadSchoolYearTarificationCells]  = useState<HeadCell<ISchoolYearTarification>[]>([]);
      useEffect(() => {
        setHeadSchoolYearTarificationCells([            
          {id:'id', label : t('Id'),  display: false, type: 'numeric', },
          {id:'classTemplateName', label : t('Name'),  display: true, type: 'string', width: 25,  },
          
          {id:'studyType', label : `${t('Study type')}`,  display: true, type: 'string', width: 25, 
            getOptions: getStudyTypeTarificationList },
          {id:'amountExpression', label : `${t('Expression')} : ${t('Amount')}`,  display: true, type: 'string', width: 50, 
            getInputAdornment: getInputAdornmentTarificationAmountExpression },
        ]  )
      }, [t,i18n])
    
      const refAppendSchoolYearTarifications = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateSchoolYearTarification= useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
      const refRemoveSchoolYearTarification= useRef<(index: number ) => void>(null);
    
      const handleAddSchoolYearTarifications = (event: any) => {
    
        setCurrentClassTemplateUsage('tarification');
        setOpenClassTemplateFilter(true);
        // (refAppendApplicationQueryParameters.current??emptyFunc)(
        //   {id:0, businessApplicationQueryId:0, jobScheduleParameters: [],
        //     parameterName: '',  parameterDataType: 'text' });
      }
    
      const [schoolYearTarificationIndex, setSchoolYearTarificationIndex] = useState<number>(-1);
      const schoolYearTarificationRowActionIcon = ( schoolYearTarification: ISchoolYearTarification) : ActionIconTableRow<ISchoolYear,ISchoolYearTarification> => {
      
        const res: ActionIconTableRow<ISchoolYear,ISchoolYearTarification> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ISchoolYearTarification) => {
            
            (refRemoveSchoolYearTarification.current??emptyFunc)(index);            
          }
        }
        return res;
    }
      

  


////
const getMaterialList = (row: ISchoolYearMaterial, cellId: keyof ISchoolYearMaterial, 
  opts: {value: string, name: string}[]) => {        
  
  return getAsOptions(refEnumItems.current ?? [],Enum_SCHOOL_MATERIAL);
}

const getInputAdornmentMaterialConditionExpression = (row: ISchoolYearMaterial, cellId: keyof ISchoolYearMaterial)  => ({
 
  toolTip: 'Expression',
  icon: DeveloperModeIcon,
  onClickIcon: (event: any, index: number, row: ISchoolYearMaterial ) => {    
    const {conditionExpression} = row;
    
    setSchoolYearMaterialIndex(index);
    handleClickOpenExpression(event, 'material', 'Registration', 'boolean', conditionExpression);
  }  
})
const cellEditableMaterial = (row: ISchoolYearMaterial, cellId: keyof ISchoolYearMaterial) => {
  return true;
}

const [headSchoolYearMaterialCells, setHeadSchoolYearMaterialCells]  = useState<HeadCell<ISchoolYearMaterial>[]>([]);
  useEffect(() => {
    setHeadSchoolYearMaterialCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'schoolMaterialCode', label : t('Name'),  display: true, type: 'string', width: 40,  
        getOptions: getMaterialList, isEditable: cellEditableMaterial  },
      
      {id:'isGivenByStudent', label : `${t('Given by student ?')}`,  display: true, type: 'boolean', width: 15, 
        isEditable: cellEditableMaterial },
      {id:'conditionExpression', label : `${t('Expression')} : ${t('Condition')}`,  display: true, type: 'string', width: 55, 
        getInputAdornment: getInputAdornmentMaterialConditionExpression },
    ]  )
  }, [t,i18n])

  const refAppendSchoolYearMaterials = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateSchoolYearMaterial = useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
  const refRemoveSchoolYearMaterial = useRef<(index: number ) => void>(null);

  const handleAddSchoolYearMaterials = (event: any) => {
    if(getValues().schoolYearMaterials.some(m => isFalsy(m.schoolMaterialCode))) return;

    (refAppendSchoolYearMaterials.current??emptyFunc)({});
  }

  const [schoolYearMaterialIndex, setSchoolYearMaterialIndex] = useState<number>(-1);
  const schoolYearMaterialRowActionIcon = ( schoolYearMaterial: ISchoolYearMaterial) : ActionIconTableRow<ISchoolYear,ISchoolYearMaterial> => {
  
    const res: ActionIconTableRow<ISchoolYear,ISchoolYearMaterial> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ISchoolYearMaterial) => {
        
        (refRemoveSchoolYearMaterial.current??emptyFunc)(index);            
      }
    }
    return res;
  }

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'billing-obligatory' | 'billing-defaultAmount' | 'billing-amount' 
              | 'registration-sequence' | 'registration-code' | 'student-sequence' | 'student-code' | 'material' ;
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('billing-amount');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      const handleClickOkExpression = async () => {
        const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
       
        if(returnType !== currentReturnType) {
          enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                return;
        }

      const schoolYearBillingType = getValues().schoolYearBillingTypes.at(schoolYearBillingTypeIndex);  

      if( ['billing-defaultAmount', 'billing-obligatory'].includes(currentExpressionType)  ) {
          if(isFalsy(schoolYearBillingType)) return;

          const amountExpression = currentExpressionType === 'billing-defaultAmount' ? currentExpression: schoolYearBillingType.defaultAmountExpression;
          const obligatoryExpression = currentExpressionType === 'billing-obligatory' ? currentExpression: schoolYearBillingType.obligatoryExpression;

          (refUpdateSchoolYearBillingType.current??emptyFunc)( schoolYearBillingTypeIndex, 
            {...schoolYearBillingType, defaultAmountExpression: amountExpression, obligatoryExpression: obligatoryExpression });
      } else if(['billing-amount'].includes(currentExpressionType) ) {
        if(isFalsy(schoolYearBillingType)) return;

        const schoolYearTarification = schoolYearBillingType.schoolYearTarifications.at(schoolYearTarificationIndex);
        if(isFalsy(schoolYearTarification)) return;

        (refUpdateSchoolYearTarification.current??emptyFunc)( schoolYearTarificationIndex, 
          {...schoolYearTarification, amountExpression: currentExpression });

      } else if(['student-sequence'].includes(currentExpressionType) ) {
            setValue('studentSequenceExpression', currentExpression);
        } else if(['student-code'].includes(currentExpressionType) ) {
            setValue('studentCodeExpression', currentExpression);
        } else if(['registration-sequence'].includes(currentExpressionType) ) {
          setValue('registrationSequenceExpression', currentExpression);
      } else if(['registration-code'].includes(currentExpressionType) ) {
          setValue('registrationCodeExpression', currentExpression);
      } else if(['material'].includes(currentExpressionType) ) {
        const schoolYearMaterial = getValues().schoolYearMaterials.at(schoolYearMaterialIndex);
        if(isFalsy(schoolYearMaterial)) return;
        
        (refUpdateSchoolYearMaterial.current??emptyFunc)( schoolYearMaterialIndex, 
          {...schoolYearMaterial, conditionExpression: currentExpression });
      }

        setOpenEntityExpression(false);
      }
    

      const [extensionIndex, setExtensionIndex] = useState<number>(-1);
      const [openExtension, setOpenExtension] = useState(false);
      const handleClickAddExtension = (event: any) => {
        setExtensionIndex(-1);

        //setValue('currentExtensionAlias','');        
        //setValue('currentExtensionTypeId',0);
        //setValue('currentExtensionTypeName','');
        //setValue('currentExtensionDescription','');
        
        //setOpenExtension(true);
      }



      const handleClickEditExtension = (index: number) => {
        const schoolYearRegistrationExtension = getValues().schoolYearRegistrationExtensions.at(index);
        if(isFalsy(schoolYearRegistrationExtension)) return;

        setExtensionIndex(index);
        const {alias, description, extensionTypeId, extensionTypeName} = schoolYearRegistrationExtension!;

        //setValue('currentExtensionAlias',alias);        
        //setValue('currentExtensionTypeId',extensionTypeId);
        //setValue('currentExtensionTypeName',extensionTypeName);
        //setValue('currentExtensionDescription',description);
        
        //setOpenExtension(true);
      }
     
      const handleOkExtension =  () => { 

        const { currentExtensionAlias, currentExtensionTypeId,
          currentExtensionTypeName, currentExtensionDescription } = getValues();

        if(isFalsy(currentExtensionAlias) || isFalsy(currentExtensionTypeId)) return;

        if(extensionIndex<0 && getValues().schoolYearRegistrationExtensions.some( x => x.alias.trim() === currentExtensionAlias.trim())) 
          return;

        if(extensionIndex>=0 && getValues().schoolYearRegistrationExtensions.findIndex( o => o.alias === currentExtensionAlias) !== extensionIndex)
          return;

        if(extensionIndex<0)
          appendSchoolYearRegistrationExtensions( {
            id: 0, schoolYearId: _id,
            extensionTypeId: currentExtensionTypeId, extensionTypeName: currentExtensionTypeName,
            alias: currentExtensionAlias, description: currentExtensionDescription, });
        else {
          const schoolYearRegistrationExtension = getValues().schoolYearRegistrationExtensions.at(extensionIndex)!;
          updateSchoolYearRegistrationExtension(extensionIndex,{...schoolYearRegistrationExtension,
            extensionTypeId: currentExtensionTypeId, extensionTypeName: currentExtensionTypeName,
            alias: currentExtensionAlias, description: currentExtensionDescription });
        }

        setOpenExtension(false);
      }

      
    const handleClickSearchExtensionType = (event: any) => {
      setOpenExtensionTypeFilter(true);
    }

    const refEnumItems = useRef<IEnumerationItem[]>();
    
    useEffect( () => {   
        refEnumItems.current = enumItems;
     
    }, [enumItems])

      useEffect( () => {        
        setCurrentFormNameAtom(t('School year'));
        setCurrentBasicTextFilterProps(basicFilterSchoolYear);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('SchoolYear',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultSchoolYear);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('SchoolYear', _id)) {
          setIsSaveLoading(false);
             return;
        }
        

          const data = getValues(); 
          if(data.name.trim() === '' || data.year <= 0) {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
            
            if(data.schoolYearMaterials.some(m => isFalsy(m.conditionExpression) || isFalsy(m.schoolMaterialCode) )) {
              enqueueSnackbar( t('The type of material and the condition have to be specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
            }

          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('SchoolYear', _id);
      }

      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('SchoolYear', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'  >  
                        <Box sx={{ mt: 1, width: '100%' }} >
                            <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                            <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                            <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                            <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                            <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                            <TextField sx={{width:'calc(20% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                            <TextField sx={{width:'calc(20% - 8px)'}} id="year" label={t('Year')} {...register('year')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                            
                                 
                            <Controller                              
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('Start Date')}
                                    
                                  //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}         
                                  onChange={onChange}                        
                                  value={new Date(value)}
                                  slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}}  />}
                                /> )}
                              name='startDate'                                  
                              control={control}
                            />            
                            <Controller                              
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('End Date')}
                                  
                                  //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}         
                                  onChange={onChange}                        
                                  value={new Date(value)}
                                  slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                /> )}
                              name='endDate'                                  
                              control={control}
                            />                                       
                        </Box> 
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(70% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                          <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                              label={t('Active ?')}
                              control={
                              <Controller
                                  name='isActive'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                        </Box>  
                        { openClassTemplateFilter && <FormDialog open={openClassTemplateFilter} maxWidth='md'
                            okText={t('OK')} cancelText={t('Cancel')} title={t('Study level filter')} onCancel={()=> {}} 
                            onClose={()=> {setOpenClassTemplateFilter(false);}} onOk={handleOkBasicTextFilterClassTemplateForm}  >
                                <BasicTextFilterForm<IClassTemplate> {...basicFilterClassTemplate } />
                        </FormDialog> }
                        { openBillingTypeFilter && <FormDialog open={openBillingTypeFilter} maxWidth='md'
                            okText={t('OK')} cancelText={t('Cancel')} title={t('Billing type filter')} onCancel={()=> {setOpenBillingTypeFilter(false);}} 
                            onClose={()=> {setOpenBillingTypeFilter(false);}} onOk={handleOkBasicTextFilterBillingTypeForm}  >
                                <BasicTextFilterForm<IBillingType> {...basicFilterBillingType } />
                        </FormDialog> }
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'> 
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`student-sequence`}
                            render={({ field }) => <TextField label={t('Student sequence')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'student-sequence', 'Registration', 'numeric', getValues().studentSequenceExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`studentSequenceExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`student-code`}
                            render={({ field }) => <TextField label={t('Student matricule')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'student-code', 'Registration', 'string', getValues().studentCodeExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`studentCodeExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`code-expression`}
                            render={({ field }) => <TextField label={t('Registration sequence')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'registration-sequence', 'Registration', 'numeric', getValues().registrationSequenceExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`registrationSequenceExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`code-expression`}
                            render={({ field }) => <TextField label={t('Registration matricule')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'registration-code', 'Registration', 'string', getValues().registrationCodeExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`registrationCodeExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>  
                        
                        <Box sx={{ mt: 4, width: '100%' }} key={`${getValues().schoolYearPeriods.length} - $${getValues().schoolYearPeriods.map(p => p.name).join('-')}`} >
                          <ArrayFieldTableEx<ISchoolYear,ISchoolYearBillingType,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headSchoolYearBillingTypeCells} rowsPathName={`schoolYearBillingTypes` }
                                title={t('Billings')} rowActionIcon={schoolYearBillingTypeRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                                    
                                refAppend={refAppendSchoolYearBillingTypes as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateSchoolYearBillingType as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                refRemove={refRemoveSchoolYearBillingType as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearBillingTypes ,icon: AddCircleIcon,  },
                                
                                ]}
                              />
                              { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Expression')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }
                              { openBillingTypeDetails && <FormDialog open={openBillingTypeDetails} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Details')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenBillingTypeDetails(false);}} onOk={()=> {setOpenBillingTypeDetails(false);}}  >
                                    <Stack flexDirection='column'  >
                                      <Box sx={{ mt: 1, width: '100%' }} key={`reportFields-${'reportTable.name'}-${'reportTableIndex'} ${'reportTable.reportTableDefaultParameters.length'}`}>
                                        <Controller                              
                                          render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <DatePicker label={t('Start Date')}
                                                
                                              //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}         
                                              onChange={onChange}                        
                                              value={new Date(value)}
                                              slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                              //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}}  />}
                                            /> )}
                                          name={`schoolYearBillingTypes.${schoolYearBillingTypeIndex}.startDate`}                                  
                                          control={control}
                                        />            
                                        <Controller                              
                                          render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <DatePicker label={t('End Date')}
                                              
                                              //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}         
                                              onChange={onChange}                        
                                              value={new Date(value)}
                                              slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                              //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                            /> )}
                                          name={`schoolYearBillingTypes.${schoolYearBillingTypeIndex}.endDate`}
                                          control={control}
                                        />   
                                      </Box>
                                      <Box sx={{ mt: 1, width: '100%' }} key={`reportFields-${'reportTable.name'}-${'reportTableIndex'} ${'reportTable.reportTableDefaultParameters.length'}`}>
                                          <ArrayFieldTableEx<ISchoolYear,ISchoolYearTarification,'id'> 
                                                key={`Report field - IReportTableDefaultParameter ${'reportTable.name'}`}
                                                mainObject={getValues()} fieldKey='id' 
                                                headCells={headSchoolYearTarificationCells} 
                                                rowsPathName={`schoolYearBillingTypes.${schoolYearBillingTypeIndex}.schoolYearTarifications`}  
                                                title={`${t('Tarification')}`} rowActionIcon={schoolYearTarificationRowActionIcon}  
                                                
                                                //onRowSelected={handleRoleEntitySelected}                                                    
                                                refAppend={refAppendSchoolYearTarifications as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                                refUpdate={refUpdateSchoolYearTarification as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                                refRemove={refRemoveSchoolYearTarification as MutableRefObject<(index: number) => void>}
                                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                                toolbarActions={[
                                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearTarifications ,icon: AddCircleIcon,  },
                                              ]}
                                            />                                                                 
                                      </Box>
                                    </Stack>
                                  </FormDialog> }
                        </Box> 
                        
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>                        
                        <Box sx={{ mt: 1, width: '100%' }} >
                            
                              { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Expression')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }
                              
                          </Box>
                          
                          <Box sx={{ mt: 3, width: '100%' }} >
                            <ArrayFieldTableEx<ISchoolYear,ISchoolYearMaterial,'id'> 
                                key={`Material -  ${'reportTable.name'}`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headSchoolYearMaterialCells} 
                                rowsPathName={`schoolYearMaterials`}  
                                title={`${t('School material')}`} rowActionIcon={schoolYearMaterialRowActionIcon}  
                                
                                //onRowSelected={handleRoleEntitySelected}                                                    
                                refAppend={refAppendSchoolYearMaterials as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateSchoolYearMaterial as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                refRemove={refRemoveSchoolYearMaterial as MutableRefObject<(index: number) => void>}
                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearMaterials ,icon: AddCircleIcon,  },
                              ]}
                            />
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                          {                              
                              getValues().schoolYearRegistrationExtensions.map( ( {alias, description} ,idx) => 
                                ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${alias}`} label={`${alias} : ${description} `} color="primary" variant="outlined"
                                    onDelete={() => {removeSchoolYearRegistrationExtension(idx)}} 
                                    onClick={() => handleClickEditExtension(idx)}/>
                                    ))
                            }
                          </Box>
                          <Box sx={{ mt: 4, width: '100%' }} >
                            <Button>
                              {`${t('Add Extension')}`}
                              <Box sx={{ ...justifyCenter, ml: 1 }}>
                                <MdOutlineAdd size={24} onClick={handleClickAddExtension} />
                              </Box>
                            </Button>
                            { openExtension && <FormDialog open={openExtension} maxWidth='sm' height='75vh'
                                  okText={t('OK')} cancelText='' title={t('Extension')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtension(false);}} onOk={handleOkExtension}  >
                                    <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionAlias" label={t('Alias')} 
                                            {...register('currentExtensionAlias')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                      </Box>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionDescription" label={t('Description')} 
                                          {...register('currentExtensionDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                      </Box>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionTypeName" label={t('Extension type')} 
                                            {...register('currentExtensionTypeName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                            InputProps={{
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={handleClickSearchExtensionType}>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                              </InputAdornment>
                                            )
                                          }} />                                                                           
                                    </Box>                                    
                                  </Stack>
                            </FormDialog> } 
                            { openExtensionTypeFilter && <FormDialog open={openExtensionTypeFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Extension type')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionTypeFilter(false);}} onOk={()=> {setOpenExtensionTypeFilter(false);}}  >
                                      <BasicTextFilterForm<IExtensionType> {...basicFilterExtensionType } />
                              </FormDialog> }
                          </Box>  
                      </Stack>
                    </Grid>     
                                          
                </Grid>
            </Box>
        </FormProvider> 
  )
}

