
import React, { MouseEvent, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { Breakpoint, useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EnhancedTable from 'components/ui/EnhancedTable';

import { DatePicker } from '@mui/x-date-pickers';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import useTellerOperationService, { useBasicFilterTellerOperation } from './services/TellerOperation';
import {IDashboardSchoolYear, ISchoolYear, ISchoolYearClass, defaultDashboardSchoolYear} from 'features/setup/models/SchoolYear';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import useEntityService from 'features/services/Entity';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import WidgetSummary from 'features/widgets/WidgetSummary';
import WidgetBarChart from 'features/widgets/WidgetBarChart';
import WidgetPieChart from 'features/widgets/WidgetPieChart';

import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';
import { defaultTellerOperationDashboard, ITellerOperationDashboard, IOperationDasbordItem } from './models/TellerOperation';
import { isFalsy } from 'utility-types';


export const TellerOperationDashboardForm = () => {

  const navigate = useNavigate();
  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');

  const {openEntityPrintDrawer, openEntityPrintDrawerEx } = useEntityService();

  const { getTellerOperationDashboard } = useTellerOperationService();

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const [dashboard, setDashboard] = useState<ITellerOperationDashboard>(defaultTellerOperationDashboard);

  const [schoolYearId, setSchoolYearId] = useState(0);
  const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  const basicFilterSchoolYear = useBasicFilterSchoolYear( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
          const {id, name } = row;

          setSchoolYearId(id);
          setSchoolYearName(name);
          
          setOpenSchoolYearFilter(false);
      }
  );


  const handleClickSelectSchoolYear = (event: any) => {
    setOpenSchoolYearFilter(true);
  }

  const handleSchoolYearClassDoubleClick = (event: React.MouseEvent<unknown>, row: IOperationDasbordItem) => {
    const roleEntitySchoolYearClass = roleEntities.find(re => re.entityName === 'SchoolYearClass');
    if(isFalsy(roleEntitySchoolYearClass)) return;
    
    navigate(`/schoolYearClass/${row.id}`);
  }

  const [dateFrom, setDateFrom] = React.useState<Date>(new Date());
  const [dateTo, setDateTo] = React.useState<Date>(new Date());

  useEffect(() => {
    async function _() {
            
      const dash = await getTellerOperationDashboard(schoolYearId,dateFrom, dateTo);            
      setDashboard(dash);     
    }
    _();
  }, [schoolYearId, dateFrom, dateTo, getTellerOperationDashboard]);


  useEffect( () => {        
    setCurrentFormName(t('Teller operation'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
    setIsSaveLoading(false);
  }, []);    

  useEffect(() => {
    async function _() {
             
      const dash = await getTellerOperationDashboard(applicationSetup.currentSchoolYearId, new Date(), new Date());

      const schoolYear = schoolYears.find(s => s.id === applicationSetup.currentSchoolYearId);
      setSchoolYearName(schoolYear?.name || '');
      
      setDashboard(dash);     
    }
    _();
  }, []);

  const printData = async (event: MouseEvent<HTMLButtonElement>) => {   
        
    openEntityPrintDrawerEx('TellerOperationDashboard' , [
        {name: 'DateFrom', value: dateFrom}, {name: 'DateTo', value: dateTo}]);
  }
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${schoolYearName} :::::`}
                </Typography>
                <Box sx={{ ...justifyCenter, ml: 1 }} >
                  {`${t('Click to change school year')}`}                  
                  <MdOutlineChangeCircle size={24} onClick={handleClickSelectSchoolYear} />
                </Box>  
              </Button>

              <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />
            </Box>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The day from')} : `}
                </Typography>                
              </Button>              
              <DatePicker                      
                //renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(30% - 8px)':'calc(10% - 8px)'}} />}
                onChange={(newValue) => setDateFrom(newValue||new Date())}                    
                value={dateFrom}   
                slotProps={{ textField: { sx: {width:isSm?'calc(30% - 8px)':'calc(10% - 8px)'}  }} }                      
              /> 
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('to')} `}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                slotProps={{ textField: { sx: {width:isSm?'calc(30% - 8px)':'calc(10% - 8px)'}  }} }     
                onChange={(newValue) => setDateTo(newValue||new Date())}                    
                value={dateTo}                      
              />
            </Box>
            { openSchoolYearFilter && <FormDialog open={openSchoolYearFilter} maxWidth='md'
                  okText='' cancelText='' title={t('School year')} onCancel={()=> {}} 
                  onClose={()=> {setOpenSchoolYearFilter(false);}} onOk={()=> {setOpenSchoolYearFilter(false);}}  >
                      <BasicTextFilterForm<ISchoolYear> {...basicFilterSchoolYear } />
              </FormDialog> }
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummary title={`${t('Raw amount')} / ${t('# Count')}`} paletteColor={theme.palette['info']} 
                iconName='IoSendSharp' total={dashboard.amount} total2={dashboard.count}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummary title={`${t('Net amount')} / ${t('# Count')}`} paletteColor={theme.palette['success']} 
                iconName='IoSendOutline' total={dashboard.netAmountAtDayClosing} total2={dashboard.countNetAtDayClosing}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummary title={`${t('Net amount at closing')} / ${t('# Count')}`} paletteColor={theme.palette['success']}  
                iconName='MdCallReceived' total={dashboard.netAmount} total2={dashboard.countNet}   />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummary title={`${t('Cancelled')} / ${t('# Count')}`} paletteColor={theme.palette['error']} 
                iconName='MdContacts' total={dashboard.cancelledAmount} total2={dashboard.countCancelled}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummary title={`${t('Cancelled at closing')} / ${t('# Count')}`} paletteColor={theme.palette['warning']} 
                iconName='IoSendSharp' total={dashboard.cancelledAmountAtDayClosing} total2={dashboard.countCancelledAtDayClosing}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummary title={`${t('Cancelled after closing')} / ${t('# Count')}`} paletteColor={theme.palette['warning']} 
                iconName='IoSendSharp' total={dashboard.cancelledAmountAfterClosing} total2={dashboard.countCancelledAfterClosing}  />
        </Grid>
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IOperationDasbordItem> rows={dashboard.operationsByClass} 
                  headCells={[            
                    
                    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
                    {id:'count', label : '#',  display: true, type: 'string', width: 8 },
                    {id:'totalAmount', label : t('Total amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmountAtClosingDay', label : `${t('Net amount')}(cl.)`,  display: true, type: 'numeric', width: 18 },
                    {id:'cancelledAmount', label : t('Cancelled amount'),  display: true, type: 'numeric', width: 18 },                    
                    
                  ]} 
                  title={t(`Operations per class`)} objKey='name' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                  onRowDoubleClick={handleSchoolYearClassDoubleClick} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>

            <Box sx={{ mt: 3, width: '100%' }} >
              <EnhancedTable<IOperationDasbordItem> rows={dashboard.operationsByMode} 
                  headCells={[            
                    
                    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
                    {id:'count', label : '#',  display: true, type: 'string', width: 8 },
                    {id:'totalAmount', label : t('Total amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmountAtClosingDay', label : `${t('Net amount')}(cl.)`,  display: true, type: 'numeric', width: 18 },
                    {id:'cancelledAmount', label : t('Cancelled amount'),  display: true, type: 'numeric', width: 18 },                    
                    
                  ]} 
                  title={t(`Operations per mode`)} objKey='name' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>   
        </Grid>  
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IOperationDasbordItem> rows={dashboard.operationsByTeller} 
                  headCells={[            
                    
                    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
                    {id:'count', label : '#',  display: true, type: 'string', width: 8 },
                    {id:'totalAmount', label : t('Total amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmountAtClosingDay', label : `${t('Net amount')}(cl.)`,  display: true, type: 'numeric', width: 18 },
                    {id:'cancelledAmount', label : t('Cancelled amount'),  display: true, type: 'numeric', width: 18 },                    
                    
                  ]} 
                  title={t(`Operations per teller`)} objKey='name' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 3, width: '100%' }} >
              <EnhancedTable<IOperationDasbordItem> rows={dashboard.operationsByBillingCategory} 
                  headCells={[            
                    
                    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
                    {id:'count', label : '#',  display: true, type: 'string', width: 8 },
                    {id:'totalAmount', label : t('Total amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmountAtClosingDay', label : `${t('Net amount')}(cl.)`,  display: true, type: 'numeric', width: 18 },
                    {id:'cancelledAmount', label : t('Cancelled amount'),  display: true, type: 'numeric', width: 18 },                    
                    
                  ]} 
                  title={t(`Operations per billing category`)} objKey='name' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 3, width: '100%' }} >
              <EnhancedTable<IOperationDasbordItem> rows={dashboard.operationsByBillingType} 
                  headCells={[            
                    
                    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
                    {id:'count', label : '#',  display: true, type: 'string', width: 8 },
                    {id:'totalAmount', label : t('Total amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 18},
                    {id:'netAmountAtClosingDay', label : `${t('Net amount')}(cl.)`,  display: true, type: 'numeric', width: 18 },
                    {id:'cancelledAmount', label : t('Cancelled amount'),  display: true, type: 'numeric', width: 18 },                    
                    
                  ]} 
                  title={t(`Operations per billing type`)} objKey='name' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>
        </Grid> 
      </Grid>
    </Box>
  )
}
