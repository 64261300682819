import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// react icons
import { TbFileCertificate } from 'react-icons/tb';

import { useRecoilValue, useRecoilState } from 'recoil';

import { flexCenter, IAppLogo } from 'themes/commonStyles';
import { pink } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { useQuery } from 'react-query';
import { isFalsy } from 'utility-types';

import useApplicationSetupService from 'features/setup/services/ApplicationSetup';


import { colorsAtom,currentUserSessionAtom } from 'library/store';

export const Logo = () => {

  const navigate = useNavigate();

  const [Colors, setColors] = useRecoilState(colorsAtom);

  const {schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const { createApplicationSetup, updateApplicationSetup, getApplicationTheme, getApplicationLogo,
    defineApplicationTheme, defineApplicationLogo } = useApplicationSetupService();

  const {data: appLogo} = useQuery<IAppLogo>( ['AppLogo'], () => getApplicationLogo());
 
  return (
    <Box sx={flexCenter}>
      { (isFalsy(appLogo) || isFalsy(appLogo.base64Logo))? 
        <TbFileCertificate size={24} color={Colors.secondary} /> :
        <img src={`data:image/png;base64,${appLogo.base64Logo}`} height={24} width={24} alt='Logo' />        
      }
      <Button key="key-logo-button" sx={{pl:0}}>
        <Typography onClick={() => {navigate(`home`);}}
          sx={{
            ml: 1,
            color: (theme) => theme.palette.secondary.main,
            fontSize: '20px',
            fontWeight: 'bold',
          }}
          component="h3"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          Kampux - {applicationSetup.companyName}
        </Typography>
      </Button>
    </Box>
  );
};

