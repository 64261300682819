

export interface IBusDestination {
    id: number,
    name: string,
    description: string,
    destinationOption: string,
    isActive: boolean,    
  }


  export const defaultBusDestination : IBusDestination = {
    id: 0,
    name: '',
    description: '',
    destinationOption: '',
    isActive: true,   
  }

  export interface IBusDestinationSearch {   
    name: string,
    description: string,
    destinationOption: string,
  }
  