import { string } from "yup"

export interface IPayment {
    id: number,
    
    ownerId: number,
    bearerId: number,
    description: string,
    issueDate: Date,
    totalAmount: number,
    accessories: number,
    amount: number,
    isCancelled: boolean,
    cancellationDate: Date,
    cancellationPurpose: string,
    
    type: string,

    tellerOperationId: number,

    ownerName: string,
    bearerName: string,
  }
  

  export const defaultPayment : IPayment = {
    id: 0,
    
    ownerId: 0,
    bearerId: 0,
    description: '',
    issueDate: new Date(),
    totalAmount: 0,
    accessories: 0,
    amount: 0,
    isCancelled: false,
    cancellationDate: new Date(),
    cancellationPurpose: '',
    
    type: '',

    tellerOperationId: 0,

    ownerName: '',
    bearerName: '',
  }

  export interface IPaymentSearch {
    schoolYearId: number,
    
    description: string,
    
    ownerName: string,
    bearerName: string,
  }