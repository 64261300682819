import { IAppTheme, defaultAppTheme } from "themes/commonStyles"

export interface IApplicationSetup {
    id: number,
    companyName: string,
    description: string,

    currentSchoolYearId: number,
    schoolYearStartDate: Date,
    schoolYearEndDate: Date,

    

    isPasswordComplexityAllowed: boolean,
    passwordDuration: number,
    passwordRotation: number,
    maxAccessFailedCount: number,
    accessLockoutDuration: number,

    applicationApiToken: string,
    websiteApplicationApiToken: string,
    
    whatsAppBaseUrl: string,
    whatsAppIdentityToken: string,
    whatsAppLogin: string,
    whatsAppPasswordMd5: string,

    whatsAppPassword: string,
    isPasswordChanged: boolean,

    webhookToken: string,
    webhookUrl: string,

    smtpServer: string,
    smtpPort: number,
    smtpUsername: string,
    smtpPassword: string,
    smtpSenderEmail: string,
    smtpUseTls: boolean,
    smtpServerType: string,

    certificateSmsExpression: string,

    certificateDocumentExpression: string,
    certificateImageExpression: string,
    certificateQrCodeExpression: string,

    
    
    isSmsOn: boolean,
    isWhatsAppOn: boolean,
    isReportScheduleOn: boolean,
    isFeatureScheduleOn: boolean,
    notificationWhatsAppNumber1: string,
    notificationWhatsAppNumber2: string,

    applicationTheme: IAppTheme
  }


  export const defaultApplicationSetup : IApplicationSetup = {
    id: 0,
    companyName: '',
    description: '',
    
    currentSchoolYearId: 0,
    schoolYearStartDate: new Date(),
    schoolYearEndDate: new Date(),

    isPasswordComplexityAllowed: false,
    passwordDuration: 0,
    passwordRotation: 0,
    maxAccessFailedCount: 0,
    accessLockoutDuration: 0,

    applicationApiToken: '',
    websiteApplicationApiToken: '',
    
    whatsAppBaseUrl: '',
    whatsAppIdentityToken: '',
    whatsAppLogin: '',
    whatsAppPasswordMd5: '',

    whatsAppPassword: '',
    isPasswordChanged: false,

    webhookToken: '',
    webhookUrl: '',

    smtpServer: '',
    smtpPort: 0,
    smtpUsername: '',
    smtpPassword: '',
    smtpSenderEmail: '',
    smtpUseTls: false,
    smtpServerType: '',

    certificateSmsExpression: '',

    certificateDocumentExpression: '',
    certificateImageExpression: '',
    certificateQrCodeExpression: '',

    isSmsOn: true,
    isWhatsAppOn: true,
    isReportScheduleOn: true,
    isFeatureScheduleOn: true,
    notificationWhatsAppNumber1: '',
    notificationWhatsAppNumber2: '',

    applicationTheme: defaultAppTheme
  }


  export interface IApplicationSetupSearch {
    companyName: string
  }