

export interface ISchoolYearBus {
  id: number,
  
  schoolYearId: number,
  name: string,
  description: string,

  registrationNumber: string,
  licensePlate: string,
  mark: string,
  energyType: string,
  fiscalPotency: number,
  isActive: boolean,
  capacity: number,
  maxCapacity: number,
  base64Picture: string,
  mainDriverId: number,
  firstSubstituteDriverId: number,
  secondSubstituteDriverId: number,

  mainDriverFirstName: string,
  mainDriverLastName: string,

  mainDriverName: string,
  firstSubstituteDriverName: string,
  secondSubstituteDriverName: string,
  
  schoolYearBusDestinations: ISchoolYearBusDestination[],

  startDate: Date,
  endDate: Date,
}

export interface ISchoolYearBusDestination {
  id: number,
  
  schoolYearBusId: number,
  busDestinationId: number,

  isDeparture: boolean,
  isArrival: boolean,

  busDestinationName: string,
  busDestinationOption: string,
}

export const defaultSchoolYearBus : ISchoolYearBus = {
  id: 0,
 
  schoolYearId: 0,
  name: '',
  description: '',
  registrationNumber: '',
  licensePlate: '',
  mark: '',
  energyType: '',
  fiscalPotency: 0,
  isActive: true,
  capacity: 0,
  maxCapacity: 0,
  base64Picture: '',
  mainDriverId: 0,
  firstSubstituteDriverId: 0,
  secondSubstituteDriverId: 0,

  mainDriverFirstName: '',
  mainDriverLastName: '',

  mainDriverName: '',
  firstSubstituteDriverName: '',
  secondSubstituteDriverName: '',
  
  schoolYearBusDestinations: [],

  startDate: new Date(),
  endDate: new Date()
}

export interface ISchoolYearBusSearch {
  
  schoolYearId: number,
  name: string,
  description: string,

  licensePlate: string,
  
}