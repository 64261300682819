
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import {IDashboardSchoolYear, ISchoolYear, defaultDashboardSchoolYear} from 'features/setup/models/SchoolYear';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import WidgetSummary from './widgets/WidgetSummary';
import WidgetBarChart from './widgets/WidgetBarChart';
import WidgetPieChart from './widgets/WidgetPieChart';
import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


export const Home = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();

  const {getSchoolYearDashboard} = useSchoolYearService();
  const [dashboard, setDashboard] = useState<IDashboardSchoolYear>(defaultDashboardSchoolYear);

  const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  const basicFilterSchoolYear = useBasicFilterSchoolYear( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
          const {id, name } = row;

          const dash = await getSchoolYearDashboard(id);
          setSchoolYearName(name);

          setDashboard(dash);
          setOpenSchoolYearFilter(false);
      }
  );


  const handleClickSelectSchoolYear = (event: any) => {
    setOpenSchoolYearFilter(true);
  }


  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
    setIsSaveLoading(false);
  }, []);    

  useEffect(() => {
    async function _() {
             
      const dash = await getSchoolYearDashboard(applicationSetup.currentSchoolYearId);
      const schoolYear = schoolYears.find(s => s.id === applicationSetup.currentSchoolYearId);
      setSchoolYearName(schoolYear?.name || '');
      
      setDashboard(dash);     
    }
    _();
  }, []);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${schoolYearName} :::::`}
                </Typography>
                <Box sx={{ ...justifyCenter, ml: 1 }} >
                  {`${t('Click to change school year')}`}                  
                  <MdOutlineChangeCircle size={24} onClick={handleClickSelectSchoolYear} />
                </Box>  
              </Button>
            </Box>
            { openSchoolYearFilter && <FormDialog open={openSchoolYearFilter} maxWidth='md'
                  okText='' cancelText='' title={t('School year')} onCancel={()=> {}} 
                  onClose={()=> {setOpenSchoolYearFilter(false);}} onOk={()=> {setOpenSchoolYearFilter(false);}}  >
                      <BasicTextFilterForm<ISchoolYear> {...basicFilterSchoolYear } />
              </FormDialog> }
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`- ${t('Classes')} / ${t('Students')}`} paletteColor={theme.palette['primary']} 
                iconName='IoSendSharp' total={dashboard.countClasses} total2={dashboard.countStudents}  />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={` - ${t('Due amount')} / ${t('# Count')}`} paletteColor={theme.palette['info']} 
                iconName='IoSendOutline' total={dashboard.dueAmount} total2={dashboard.countDueStudents}  />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={` - ${t('Unpaid amount')} / ${t('# Count')}`} paletteColor={theme.palette['warning']}  
                iconName='MdCallReceived' total={dashboard.unpaidAmount} total2={dashboard.countUnpaidStudents}   />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <WidgetSummary title={`${t('Up to date')} / ${t('Total')}`} paletteColor={theme.palette['success']} 
                iconName='MdContacts' total={dashboard.countUpToDateStudents} total2={dashboard.countStudents}  />
        </Grid>
        <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
             
        </Grid>  
        <Grid item xs={12} md={6} lg={8} key={` - `}>
        <WidgetBarChart title={`${t('Classes distribution')}`}
              subheader={`${t('Total')}/${t('Up to date')}`}
              formatterText={`${t('Student')}(s) `}
              chartLabels={dashboard.classChatLabels}
              //chartLabels={['SIL-A','CP-A','CE1-A','CE2-A','CM1-A','CM2-A','CL1-A','CL2-A','CL3-A','CL4-A','CL5-A','CL6-A']}
              chartData={[
                {
                  name: t('Total'),
                  type: 'column',
                  fill: 'solid',
                  data: dashboard.countClassStudents, // [32,41,25,41,35,28,29,27,35,24,41,33],
                },
                {
                  name: t('Up to date'),
                  type: 'area',
                  fill: 'gradient',
                  data: dashboard.countClassUpToDateStudents,
                },
                {
                  name: t('Due amount'),
                  type: 'line',
                  fill: 'gradient',
                  data: dashboard.countClassDueStudents,
                },
                {
                  name: t('Completed'),
                  type: 'line',
                  fill: 'gradient',
                  data: dashboard.countClassCompletedStudents,
                },
                // {
                //   name: 'Team C',
                //   type: 'line',
                //   fill: 'solid',
                //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                // },
              ]}   />
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
          <WidgetPieChart title={`${t('Gender')}`} subheader=''
              // chartData={[
              //   { label: 'America', value: 4344 },
              //   { label: 'Asia', value: 5435 },
              //   { label: 'Europe', value: 1443 },
              //   { label: 'Africa', value: 4443 },
              // ]}
              //chartData={ dashboard.monthMessagesByType.map( x => ({...x, label: _mt.has(x.label)? _mt.get(x.label)??x.label :x.label }) )}
              chartData={ dashboard.countStudentsPerGender }
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}/>
        </Grid>      
      </Grid>
      
    </Box>
  )
}
