

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {IAdmission, IAdmissionSearch } from "../models/Admission";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';


const _ = () => {

    const axios = useAxios(); 

    const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createAdmission = async (admission: IAdmission)  =>       
        await (await axios.post('/api/production/admission/create', admission)).data;       
        
    const updateAdmission = async (admission: IAdmission)  =>       
        await (await axios.post('/api/production/admission/update', admission)).data; 
    
    const getAdmission = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/admission/get-admission/${id}`));
      return await data;
    }
   
    const getAdmissions = async (criteria: IAdmissionSearch, pagination?: IPagination) : Promise<IAdmission[]> => {

      const {schoolYearId, studyLanguage, schoolYearClassTemplateName, firstName, lastName } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/production/admission/get-admissions?schoolYearId=${schoolYearId}` + 
          `&studyLanguage=${studyLanguage}&schoolYearClassTemplateName=${schoolYearClassTemplateName}&firstName=${firstName}&lastName=${lastName}` +
          `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
          
      return await data;
    }

          
    return {    
      createAdmission,
      updateAdmission,
      getAdmission,
      getAdmissions,
      
    } 
}

export default _;

export interface IFilterAdmissionOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IAdmission[], React.Dispatch<React.SetStateAction<IAdmission[]>>],
}

const defaultFilterAdmissionOption: IFilterAdmissionOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterAdmission = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IAdmission) => void,
                    filterOption?: IFilterAdmissionOption  ) => {

  const { getAdmissions } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg,schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterAdmissionOption;

  //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

  const [headAdmissionCells, setHeadAdmissionCells]  = useState<HeadCell<IAdmission>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    
    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 25 },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 25},

    //{id:'admissionCode', label : t('Code'),  display: true, type: 'string', width: 10},

    {id:'status', label : t('Status'),  display: true, type: 'string', width: 10},
    {id:'schoolYearClassTemplateName', label : t('Class'),  display: true, type: 'string', width: 20},
    {id:'schoolYearName', label : t('School year'),  display: true, type: 'string', width: 20},
    //{id:'studyType', label : t('Study type'),  display: true, type: 'string', width: 15},
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
    
    {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
      options: [ ...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },

    {name: 'studyType', text: t('Study type'), value: ''},
    {name: 'studyLanguage', text: t('Study language'), value: ''},
  
    {name: 'schoolYearClassTemplateName', text: t('Class'), value: ''},

    {name: lg.includes('en')?'firstName':'lastName', text: lg.includes('en')?t('First name'):t('Last name'), value: ''},
    {name: lg.includes('en')?'lastName':'firstName', text: lg.includes('en')?t('Last name'):t('First name'), value: ''},            
  ]);
  
  const [filteredAdmissions, ] = useState<IAdmission[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IAdmission[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const schoolYearId = toNumber(temp);
    
    const studyLanguage = filterElements.find( elt => elt.name === 'studyLanguage')?.value || '';

    const schoolYearClassTemplateName = filterElements.find( elt => elt.name === 'schoolYearClassTemplateName')?.value || '';

    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';  
    
    const arr = await getAdmissions( {schoolYearId, studyLanguage, schoolYearClassTemplateName, firstName,lastName}, pagination );
   
    return arr;
  }

  const objKey: keyof IAdmission = 'id';

  return {
    title: `${t('Admission')}(s)`, headCells: headAdmissionCells, objKey,
    filterElements, rows: filteredAdmissions, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
