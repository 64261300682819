
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import {format} from 'date-fns';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IBilling, IBillingSearch } from "../models/Billing";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createBilling = async (billing: IBilling)  =>       
        await (await axios.post('/api/finance/billing/create', billing)).data;       
        
    const updateBilling = async (billing: IBilling)  =>       
        await (await axios.post('/api/finance/billing/update', billing)).data; 
    
    const getBilling = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/billing/get-billing/${id}`));
      return await data;
    }
   

    const getBillings = async (criteria: IBillingSearch, pagination?: IPagination) : Promise<IBilling[]> => {
      
      const {schoolYearId, schoolYearClassName, firstName, lastName} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/billing/get-billings?schoolYearId=${schoolYearId}` +
        `&schoolYearClassName=${schoolYearClassName}&firstName=${firstName}&lastName=${lastName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getBillingsByRegistration = async (registrationId: number) : Promise<IBilling[]> => {
            
      const {data} = (await axios.get(`/api/finance/billing/get-billings-by-registration?registrationId=${registrationId}`));
      return await data;
    }

    const getBillingsBySchoolYearBus = async (schoolYearBusId: number, startDate: Date, endDate: Date) : Promise<IBilling[]> => {

      const sStartDate = format(startDate, 'yyyy-MM-dd'); // encodeURIComponent(startDate.toISOString().split('T')[0]);
      const sEndDate = format(endDate, 'yyyy-MM-dd'); //encodeURIComponent(endDate.toISOString().split('T')[0]);


      const {data} = (await axios.get(`/api/finance/billing/get-billings-by-schoolYearBus?schoolYearBusId=${schoolYearBusId}` + 
            `&sStartDate=${sStartDate}&sEndDate=${sEndDate}`));
          
      return await data;
    }
      
    return {    
      createBilling,
      updateBilling,
      getBilling,
      getBillings,

      getBillingsBySchoolYearBus,
      
      getBillingsByRegistration
     
    } 
}

export default _;

export interface IFilterBillingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IBilling[], React.Dispatch<React.SetStateAction<IBilling[]>>],
}

const defaultFilterProductOption: IFilterBillingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterBilling = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBilling) => void,
                                            filterOption?: IFilterBillingOption  ) => {

  const { getBillings } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg,schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headBillingCells, setHeadBillingCells]  = useState<HeadCell<IBilling>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'billingTypeName', label : t('Billing type'),  display: true, type: 'string', },

    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 20 },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 20},

    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },
        
    {id:'schoolYearClassName', label : t('Class'),  display: true, type: 'string', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
    {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
      options: [ ...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },

    {name: 'schoolYearClassName', text: t('Class'), value: ''},

    {name: lg.includes('en')?'firstName':'lastName', text: lg.includes('en')?t('First name'):t('Last name'), value: ''},
    {name: lg.includes('en')?'lastName':'firstName', text: lg.includes('en')?t('Last name'):t('First name'), value: ''},  

      
    ]);    

  const [filteredBillings, ] = useState<IBilling[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IBilling[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const schoolYearId = toNumber(temp);

    const schoolYearClassName = filterElements.find( elt => elt.name === 'schoolYearClassName')?.value || '';
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';
       
    const arr = await getBillings( {schoolYearId, schoolYearClassName,firstName,lastName}, pagination );
    
    return arr;
  }

  const objKey: keyof IBilling = 'id';

  return {
    title: t('Teller operation'), headCells: headBillingCells, objKey,
    filterElements, rows: filteredBillings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
