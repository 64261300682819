
export interface IDelivery {
    id: number,
    reference: string,
    description: string,
    issueDate: Date,

    status: string,
    statusDate: Date,
    
    deliveryArticles: IDeliveryArticle[],

  }

  export interface IDeliveryArticle {
    id: number, 
    deliveryId: number,
    billingTypeId: number,
    option: string,

    quantityDemand: number, 
    quantity: number, 
    quantityUsed: number, 

    billingTypeName: string
  }
  

export const defaultDelivery : IDelivery = {
  id: 0,
  reference: '',
  description: '',
  issueDate: new Date(),

  status: '95',
  statusDate: new Date(),
  
  deliveryArticles: [],
  }

  export interface IDeliverySearch {    
    reference: string,
    description: string
  }
