
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useResourceTypeService, { useBasicFilterResourceType } from './services/ResourceType';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IResourceType, _ResourceType, defaultResourceType } from './models/ResourceType';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_RESOURCE_CATEGORY, Enum_RESOURCE_OPTION_CLASS } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const ResourceTypeForm: FC<IResourceType> = (props: IResourceType = defaultResourceType) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  const { createResourceType, updateResourceType } = useResourceTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterResourceType = useBasicFilterResourceType( 0,
    (event: React.MouseEvent<unknown>, row: IResourceType) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  

  
  const methods = useForm<IResourceType>({defaultValues:defaultResourceType});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchResourceType = watch('type');
  const watchTemporaryFilterOption = watch('temporaryFilterOption');
  const watchCategoryCode = watch('categoryCode');
  // const watchFileName = watch('fileName');
  // const watchBase64Picture = watch('base64Picture');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IResourceType>,Error,IResourceType>(
      _id>0?updateResourceType:createResourceType, {   
        onSuccess: (data: IResult<IResourceType>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ResourceType',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IResourceType>(['ResourceType', _id], () => retrieveEntity('ResourceType',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'ResourceType'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_RESOURCE_CATEGORY, Enum_RESOURCE_OPTION_CLASS ] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    const handleResourceType = ( event: React.MouseEvent<HTMLElement>, _newResourceType: _ResourceType ) => {

      if(_newResourceType === null) return;
      setValue('type', _newResourceType);

    };

   

    

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])


      useEffect( () => {        
        setCurrentFormNameAtom(t('ResourceType'));
        setCurrentBasicTextFilterProps(basicFilterResourceType);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('ResourceType',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultResourceType);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('ResourceType', _id)) {
          setIsSaveLoading(false);
             return;
        }
        const data = getValues(); 

        if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }
        
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ResourceType', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(70% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Active ?')}`}
                                    control={
                                    <Controller
                                        name='isActive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup value={watchResourceType} exclusive key={watchResourceType} size="small" onChange={handleResourceType} aria-label="text alignment" fullWidth >
                                <ToggleButton value="temporary" aria-label="centered">
                                  {t('Temporary')}
                                </ToggleButton>
                                <ToggleButton value="permanent" aria-label="centered">
                                  {t('Permanent')}
                                </ToggleButton>       
                                <ToggleButton value="exploitation" aria-label="centered">
                                  {t('Exploitation')}
                                </ToggleButton>               
                              </ToggleButtonGroup>                                                             
                            </Box>   
                                                                               
                            <Box sx={{ mt: 1, width: '100%' }} >

                              <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                
                              {watchResourceType === 'permanent' && <Controller name='categoryCode' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="categoryCode"
                                        label={t('Category')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_RESOURCE_CATEGORY, 
                                                                  `${t('Resource')} - ${t('Category')}`, `${t('Resource')} - ${t('Category')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchCategoryCode) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_RESOURCE_CATEGORY, `${t('Resource')} - ${t('Category')}`, `${t('Resource')} - ${t('Category')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_RESOURCE_CATEGORY && x.code === watchCategoryCode)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_RESOURCE_CATEGORY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />}
                                {watchResourceType === 'temporary' && <Controller name='temporaryFilterOption' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="temporaryFilterOption"
                                        label={t('Option')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_RESOURCE_OPTION_CLASS, 
                                                                  `${t('Class')} - ${t('Option')}`, `${t('Class')} - ${t('Option')}`)}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchTemporaryFilterOption) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_RESOURCE_OPTION_CLASS, `${t('Class')} - ${t('Option')}`, `${t('Class')} - ${t('Option')}`,
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_RESOURCE_OPTION_CLASS && x.code === watchTemporaryFilterOption)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_RESOURCE_OPTION_CLASS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />}
                            </Box>
                            
                            { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={` - key`} sx={{ mt: 1, width: '100%' }} >  
                          
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

