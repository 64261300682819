
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IPaymentOperation, IPaymentOperationSearch } from "../models/PaymentOperation";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createPaymentOperation = async (paymentOperation: IPaymentOperation)  =>       
        await (await axios.post('/api/finance/paymentOperation/create', paymentOperation)).data;       
        
    const updatePaymentOperation = async (paymentOperation: IPaymentOperation)  =>       
        await (await axios.post('/api/finance/paymentOperation/update', paymentOperation)).data; 
    
    const getPaymentOperation = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/paymentOperation/get-paymentOperation/${id}`));
      return await data;
    }
   

    const getPaymentOperations = async (criteria: IPaymentOperationSearch, pagination?: IPagination) : Promise<IPaymentOperation[]> => {
      
      const {schoolYearId, paymentDescription, ownerName, bearerName, reference} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/paymentOperation/get-paymentOperations?schoolYearId=${schoolYearId}` +
        `&reference=${reference}&paymentDescription=${paymentDescription}&ownerName=${ownerName}&bearerName=${bearerName}`+
        `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getPaymentOperationsByRegistration = async (registrationId: number) : Promise<IPaymentOperation[]> => {
            
      const {data} = (await axios.get(`/api/finance/paymentOperation/get-paymentOperations-by-registration?registrationId=${registrationId}`));
      return await data;
    }
      
    return {    
      createPaymentOperation,
      updatePaymentOperation,
      getPaymentOperation,
      getPaymentOperations,
      
      getPaymentOperationsByRegistration
     
    } 
}

export default _;

export interface IFilterPaymentOperationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IPaymentOperation[], React.Dispatch<React.SetStateAction<IPaymentOperation[]>>],
}

const defaultFilterProductOption: IFilterPaymentOperationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterPaymentOperation = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IPaymentOperation) => void,
                                            filterOption?: IFilterPaymentOperationOption  ) => {

  const { getPaymentOperations } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headPaymentOperationCells, setHeadPaymentOperationCells]  = useState<HeadCell<IPaymentOperation>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'reference', label : t('Reference'),  display: true, type: 'string', },

    {id:'paymentDescription', label : t('Description'),  display: true, type: 'string', },
    {id:'ownerName', label : t('Owner'),  display: true, type: 'string', },
   
    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },
    
    {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', },
  ]); 

  const [filterElements,] = useState<ITextFilterElement[]>( [
         
    {name: 'schoolYearId', text: t('School year'), value: '0', dataType: 'enumeration', 
      options: [{name: t('All'), value: '0'}, ...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
    },

    {name: 'description', text: t('Description'), value: ''},

    {name: 'ownerName', text: t('Beneficiary'), value: ''},
    {name: 'bearerName', text: t('Bearer'), value: ''},      
      
  ]);    

  const [filteredPaymentOperations, ] = useState<IPaymentOperation[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IPaymentOperation[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '';
    const schoolYearId = isNumber(temp) ? toNumber(temp) : 0;

    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';

    const paymentDescription = filterElements.find( elt => elt.name === 'paymentDescription')?.value || '';
    const ownerName = filterElements.find( elt => elt.name === 'ownerName')?.value || '';
    const bearerName = filterElements.find( elt => elt.name === 'bearerName')?.value || '';
       
    const arr = await getPaymentOperations( {schoolYearId, reference, paymentDescription, ownerName, bearerName} );
    
    return arr;
  }

  const objKey: keyof IPaymentOperation = 'id';

  return {
    title: t('Payment operation'), headCells: headPaymentOperationCells, objKey,
    filterElements, rows: filteredPaymentOperations, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
