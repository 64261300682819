import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import MoreIcon from '@mui/icons-material/More';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { useTheme } from '@mui/material/styles';

import { IEntity, IResult } from 'library/interface';

import useRegistrationMarkService from './services/RegistrationMark';

import { IRegistrationMark, ISchoolYearClassSubjectMark, ISchoolYearClassReportCard, defaultSchoolYearClassSubjectMark, defaultSchoolYearClassReportCard, ReportCardType } from './models/RegistrationMark';

import useEntityService from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Typography, ToggleButtonGroup, ToggleButton, Avatar } from '@mui/material';
import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE, Enum_DAY_WEEK,
  Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_TEACHER_STATUS, Enum_SUBJECT,
  Enum_REGISTRATION_MARK_PURPOSE } from 'features/configuration/models/Enumeration';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import usePersonService from 'features/production/services/Person';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { ISchoolYearClass, ISchoolYearClassSubject, ISchoolYearClassSubjectExamSchedule, ISchoolYearClassTemplate, ISchoolYearPeriod, ISchoolYearPeriodExam, ISchoolYearTemplateSubjectExamSchedule } from 'features/setup/models/SchoolYear';
import useSchoolYearService, { useBasicFilterSchoolYearClass } from 'features/setup/services/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { typographyGroupBoxStyling } from 'themes/commonStyles';

import { debounce } from 'lodash';
import { defaultRegistration, IRegistration } from './models/Registration';
import EnhancedTable from 'components/ui/EnhancedTable';
import { RegistrationMarkForm } from './RegistrationMarkForm';
import WidgetPieChart from 'features/widgets/WidgetPieChart';
//import { useBasicFilterApplicationQuery } from 'features/setup/services/ApplicationQuery';





export const SchoolYearClassReportCardForm: FC<ISchoolYearClassReportCard> = (props: ISchoolYearClassReportCard = defaultSchoolYearClassReportCard) => {

  const theme = useTheme();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();

  const {language: lg, schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {openEntityPrintDrawer, openEntityPrintDrawerEx } = useEntityService();

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {saveSchoolYearClassReportCard, getMarks } = useRegistrationMarkService();
  const {getPhysicalPersonNameLg } = usePersonService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { getSchoolYearClassTemplates, getSchoolYearClassRegistrationMarks, getSchoolYearClassSubjects,
    getSchoolYearPeriods, getSchoolYearPeriodExams, getSchoolYearTemplateSubjectExamSchedules, 
    getSchoolYearClasses } = useSchoolYearService();
  
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  // const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
    
  //   async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {
  //     const {id, name } = row;
      
  //     setIsSearchBoxShow(false);
  //     _setId(row.id);
  //   }
  // );

  const [openClassFilter, setOpenClassFilter] = useState(false);
  const basicFilterSchoolYearClass = useBasicFilterSchoolYearClass( 
    async (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {
           
        //const schoolYearClass = await retrieveEntity('SchoolYearClass',row.id) as ISchoolYearClass;
        const {id, studyType, name, schoolYearClassTemplateId,
          classTemplateName, studyLanguage,
          fullTeacherName, substituteTeacherName,
          schoolYearId
          } = row;  
          
        const data = getValues();
        // reset({...data, schoolYearClassId: id, 
        //       schoolYearId, schoolYearClassTemplateId,
          
        //       schoolYearClassName: name, studyType, 
        //       fullTeacherName, substituteTeacherName, classTemplateName, studyLanguage,
        //      })

        setValue('schoolYearId', schoolYearId); 

        const arr = await getSchoolYearClassSubjects(id); // schoolYearClassId

        setSchoolYearClassSubjects([...arr]);

        setOpenClassFilter(false);     
      }
  );


  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          
      }
  );

  

  
    
  const emptyFunc = (obj: any) => {}

  const methods = useForm<ISchoolYearClassReportCard>({defaultValues:defaultSchoolYearClassReportCard});
  const { register, setValue ,getValues, watch, reset ,handleSubmit, setFocus ,control , formState: { errors } } = methods;

  const watchSchoolYearId = watch('schoolYearId');
  const watchSchoolYearPeriodId = watch('schoolYearPeriodId');
  const watchSchoolYearPeriodExamId = watch('schoolYearPeriodExamId');

  const watchStudyType = watch('studyType');
  const watchStudyLanguage = watch('studyLanguage');

  const watchSchoolYearClassTemplateId = watch('schoolYearClassTemplateId');
  const watchSchoolYearClassId = watch('schoolYearClassId');

  //const watchSchoolYearClassSubjectId = watch('schoolYearClassSubjectId');
 

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYearClassReportCard>,Error,ISchoolYearClassReportCard>(
    saveSchoolYearClassReportCard, {   
        onSuccess: (data: IResult<ISchoolYearClassReportCard>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SchoolYearClassReportCard',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<ISchoolYearClassSubjectMark>(['SchoolYearClassReportCard', _id], () => retrieveEntity('SchoolYearClassReportCard',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'SchoolYearClassTemplate'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_TEACHER_STATUS, Enum_DAY_WEEK,
            Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_SUBJECT, Enum_REGISTRATION_MARK_PURPOSE ] ));

    const [schoolYearPeriods, setSchoolYearPeriods] = useState<ISchoolYearPeriod[]>([]);
    const [schoolYearPeriodExams, setSchoolYearPeriodExams] = useState<ISchoolYearPeriodExam[]>([]);

    const [schoolYearClassTemplates, setSchoolYearClassTemplates] = useState<ISchoolYearClassTemplate[]>([]);
    const [classes, setClasses] = useState<ISchoolYearClass[]>([]);

    const [schoolYearClassCountStudents, setSchoolYearClassCountStudents] = useState<number>(0);

    const [registrations, setRegistrations] = useState<IRegistration[]>([]);
    const [selectedRegistration, setSelectedRegistration] = useState<IRegistration>(defaultRegistration);

    const [schoolYearClassSubjects, setSchoolYearClassSubjects] = useState<ISchoolYearClassSubject[]>([]);

    
    const [schoolYearTemplateSubjectExamSchedules, setSchoolYearTemplateSubjectExamSchedules] = useState<ISchoolYearTemplateSubjectExamSchedule[]>([]);

    // const [currentLibrary, setCurrentLibrary] = useState<IExternalLibrary>(defaultExternalLibrary);
    // const [currentLibraryClass, setCurrentLibraryClass] = useState<IExternalLibraryClass>(defaultExternalLibraryClass);

    // const [dbmsType, setDbmsType] = useState<string>('');

    // const [applicationQueryIndex, setApplicationQueryIndex] = useState<number>(-1);
    // const [applicationQueryParameterIndex, setRegistrationMarkIndex] = useState<number>(-1);

    // const [openAppQueryParamDialog, setOpenAppQueryParamDialog] = useState<boolean>(false);
 
    const getInputAdornmentQueryParameter = (row: IRegistrationMark, cellId: keyof IRegistrationMark)  => ({
 
      toolTip: `${t('Description')} ...`,
      icon: MoreVertIcon,
      onClickIcon: (event: any, index: number, row: IRegistrationMark ) => {                
        //setRegistrationMarkIndex(index);
       
        //const {parameterName, parameterDataType } = row;
       

        //setOpenAppQueryParamDialog(true);
      }  
   })

     

  useEffect(() => {
    
    const subscription = watch( async (value, { name, type }) => {
      
      if( name?.startsWith('registrationMarks') && name?.endsWith('markExist') ) {    
        debouncedMark();                          
      } 
    });  

    return () => subscription.unsubscribe();
  }, [watch]);

  const debouncedMark = useRef(
    debounce( () => {
     

      // const marks = getValues().registrationMarks.map( x => x.mark).sort((a,b)=>b-a) ; // desc.
      // const markSet = new Set(marks);
      // const len = getValues().registrationMarks.length;

      // markSet.forEach(function(mark) {

      //   for(var i=0; i< len; i++) {
      //     const registrationMark = getValues().registrationMarks[i];

      //     if(registrationMark.mark !== mark) continue;

      //     const calcRank = getValues().registrationMarks.filter( x => x.markExist && x.mark > mark).length;
            
      //     (refUpdateRegistrationMark.current??emptyFunc)(i, {
      //       ...registrationMark, 
      //       mark: registrationMark.markExist? mark : 0,
      //       rank: registrationMark.markExist? calcRank+1 : 0
      //     });   
      //   }

      // });

      
    }, 300)
  ).current;

 const getDataTypesList = (row: IRegistrationMark, cellId: keyof IRegistrationMark, 
  opts: {value: string, name: string}[]) => {        

  //const {requestClassEnumerationCode} = row;
  
  return [ 
    {value: 'string', name: t('Text')}, {value: 'integer', name: t('Integer')}, {value: 'decimal', name: t('Decimal')},
    {value: 'float', name: t('Float')}, {value: 'date', name: t('Date')}, {value: 'boolean', name: t('Boolean')},
    {value: 'time', name: t('Time')}, 
  ]; // getAsOptions(refEnumItems.current ?? [],Enum_REPORTFIELD_DATA_FORMAT);
}

  const [headRegistrationMarkCells, setHeadRegistrationMarkCells]  = useState<HeadCell<IRegistrationMark>[]>([]);
  useEffect(() => {
    setHeadRegistrationMarkCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'firstName', label : t('First name'),  display: true, type: 'string', width: 35, },
      {id:'lastName', label : t('Last name'),  display: true, type: 'string', width: 35, },
      // {id:'mark', label : t('Mark'),  display: true, type: 'numeric', width: 10, 
      //   isEditable: cellEditableRegistrationMark, handleKeyDown: cellKeyDownMark, isAllowed: cellAllowedMark },
      {id:'markExist', label : t('Exist ?'),  display: true, type: 'boolean', width: 10, },
      {id:'rank', label : t('Rank'),  display: true, type: 'numeric', width: 10 },
    ]  )
  }, [t,i18n, watchSchoolYearPeriodId, watchSchoolYearPeriodExamId])

  const refAppendRegistrationMarks = useRef<(value: Partial<FieldArray<ISchoolYearClassSubjectMark>> | Partial<FieldArray<ISchoolYearClassSubjectMark>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateRegistrationMark = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClassSubjectMark>> ) => void>(null);
  const refRemoveRegistrationMark = useRef<(index: number ) => void>(null);

  const handleAddQueryParameter = (event: any) => {
    
  }

  const [openRegistrationMarkForm,setOpenRegistrationMarkForm] = useState<boolean>(false);

  const registrationRowActionIcon = ( registration: IRegistration) : ActionIconTableRow<any,IRegistration> => {
  
    const res: ActionIconTableRow<any,IRegistration> = {
      toolTip: 'more',
      icon: MoreIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IRegistration) => {     
        
        const registration = registrations.find(r => r.id === row.id);
        if(isFalsy(registration)) return;

        setOpenRegistrationMarkForm(true);     
        setSelectedRegistration(registration);    

        //console.log(registrations[index].subjects);
      }
    }
    return res;
}

const handleClickOpenSchoolYearClass = (event: any) => {      
  setOpenClassFilter(true);      
}


const [currentReportCardType, setCurrentReportCardType] = useState<ReportCardType>('period');

const handleChangeReportCardType = ( event: React.MouseEvent<HTMLElement>, type: ReportCardType ) => {  
  if(isFalsy(type) ) 
    return;

  setCurrentReportCardType(type);
}


useEffect( () => {   
    async function _() {
      
      if(watchSchoolYearId <= 0) return;

      const schoolYear = schoolYears.find(x => x.id === watchSchoolYearId);
      if(isFalsy(schoolYear)) return;

      setValue('schoolYearStartDate', schoolYear.startDate);
      setValue('schoolYearEndDate', schoolYear.endDate);

      const periods = await getSchoolYearPeriods(watchSchoolYearId);    
      setSchoolYearPeriods([...periods]);

      const exams = await getSchoolYearPeriodExams(watchSchoolYearId);    
      setSchoolYearPeriodExams([...exams]);          
    }          

   _();

  }, [watchSchoolYearId]);


  useEffect( () => {   
    async function _() {
     
      if(watchSchoolYearId <= 0) return;
      
      const arr = await getSchoolYearClassTemplates(watchSchoolYearId, false, '', '');
      setSchoolYearClassTemplates( arr.filter( x => x.studyLanguage === watchStudyLanguage && x.isActive) );      
    }          

   _();

  }, [watchSchoolYearId, watchStudyLanguage]);

  
  useEffect( () => {              
    async function _() {
      // const schoolYear = (schoolYears || []).find(sy => sy.id === watchSchoolYearId);
      // const year = isFalsy(schoolYear) ? 0 : schoolYear.year;
      if(watchSchoolYearId > 0 && watchSchoolYearClassTemplateId > 0) {
        const arr = await getSchoolYearClasses({
            schoolYearId: watchSchoolYearId, 
            name: '' ,
            studyType: watchStudyType, 
            studyLanguage: watchStudyLanguage,
            studyLevel: '' });
        
        setClasses(arr.filter(x => x.schoolYearClassTemplateId === watchSchoolYearClassTemplateId && x.isActive) );  
      }    
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchSchoolYearClassTemplateId  ]); 


  const [defaultSuccessMark, setDefaultSuccessMark] = useState<number>(10);
  const [firstMarkStudentName, setFirstMarkStudentName] = useState<string>('');
  const [lastMarkStudentName, setLastMarkStudentName] = useState<string>('');
  const [firstMark, setFirstMark] = useState<number>(19.9);
  const [lastMark, setLastMark] = useState<number>(0.1);
  const [avgMark, setAvgMark] = useState<number>(10);

  useEffect( () => {              
    async function _() {
      
      if(watchSchoolYearClassId<=0 || watchSchoolYearClassTemplateId<=0) {
        setRegistrations([]);
        return;
      }
      
      const schoolYearClassTemplate = schoolYearClassTemplates.find(x => x.id === watchSchoolYearClassTemplateId);
      if(isFalsy(schoolYearClassTemplate)) {
        setRegistrations([]);
        return;
      }

      if(currentReportCardType === 'exam' && watchSchoolYearPeriodExamId <= 0) {
        setRegistrations([]);
        return;
      }

      if(currentReportCardType === 'period' && watchSchoolYearPeriodId <= 0) {
        setRegistrations([]);
        return;
      }

      setDefaultSuccessMark(schoolYearClassTemplate.defaultSuccessMark);

      const schoolYearClass = classes.find(c => c.id === watchSchoolYearClassId);      
      setSchoolYearClassCountStudents( isFalsy(schoolYearClass)? 0 : schoolYearClass.countStudents);

      const registrationWithMarks = await getMarks(0, watchSchoolYearClassId, watchSchoolYearId, 
                            (currentReportCardType === 'period')? watchSchoolYearPeriodId : 0, 
                            (currentReportCardType === 'exam')? watchSchoolYearPeriodExamId : 0);

      const marks = registrationWithMarks.map( x => x.mark).sort((a,b)=>b-a) ; // desc.
      const markSet = new Set(marks);
      const len = registrationWithMarks.length;

      markSet.forEach(function(mark) {
        for(var i=0; i< len; i++) {
          const registration = registrationWithMarks[i];

          if(registration.mark !== mark) continue;

          const calcRank = registrationWithMarks.filter( x => x.mark > mark).length;
          registration.rank = calcRank + 1; 
        }
      });

      setRegistrations(registrationWithMarks);

      const tmpMarks = registrationWithMarks.map(x => x.mark);

      const max = (tmpMarks.length>0) ? Math.max(...tmpMarks) : 0;
      const min = (tmpMarks.length>0) ? Math.min(...tmpMarks) : 0;
      const sum = (tmpMarks.length>0) ? tmpMarks.reduce((acc, curr) => acc + curr, 0) : 0;

      const avg = tmpMarks.length>0 ? sum/tmpMarks.length : 0;

      setFirstMark(max);
      setLastMark(min);
      setAvgMark(avg);

      const firstStudent = registrationWithMarks.find(s => s.mark === max);
      const lastStudent = registrationWithMarks.find(s => s.mark === min);

      setFirstMarkStudentName(isFalsy(firstStudent)? '' : 
            getPhysicalPersonNameLg(firstStudent.firstName, firstStudent.lastName, lg) );

      setLastMarkStudentName(isFalsy(lastStudent)? '' : 
            getPhysicalPersonNameLg(lastStudent.firstName, lastStudent.lastName, lg) );

      //const registrationMarks = await getSchoolYearClassRegistrationMarks(watchSchoolYearPeriodExamId, watchSchoolYearClassSubjectId );
    
      //reset({...data, registrationMarks});
        
        // reset({...data, studyType, schoolYearClassId: id, schoolYearClassName: name,
        //   classTemplateName, studyLanguage, fullTeacherName, substituteTeacherName,
        //   schoolYearId: row.schoolYearId,
        //   registrationMarks: (registrations || []).map( x => ({
        //         ...x, 
        //         schoolYearTemplateSubjectExamScheduleId: 0, mark: 0}) )
        //   });
    }          
  
     _();

    
  }, [watchSchoolYearClassId, watchSchoolYearId ,watchSchoolYearPeriodId, watchSchoolYearPeriodExamId, classes, currentReportCardType]);

  
  // useEffect( () => {   

  //     setRegistrations([]);

  //   }, [watchSchoolYearPeriodExamId, watchSchoolYearId, watchStudyType, 
  //         watchSchoolYearClassTemplateId, watchStudyLanguage]);


    const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
        const studyTypes = (enumItems || []).filter(x => x.enumerationCode === Enum_STUDY_TYPE);
        if(studyTypes.length === 1)
          setValue('studyType', studyTypes[0].code);
     
    }, [enumItems])

  useEffect( () => {              
    setCurrentFormNameAtom(t('Mark'));  
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);

  useEffect( () => {        
    if(getValues().schoolYearId <= 0)
      setValue('schoolYearId', applicationSetup.currentSchoolYearId);
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    
    // if(_id > 0)
    //   retrieveData('ApplicationQuery',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
  // if(_data && _data.id > 0) {
  //   reset(_data);
  // }
}, [_data]);


const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultSchoolYearClassSubjectMark);
  setRegistrations([]);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
  if(!checkEntitySaveAuthorization('SchoolYearClassSubjectMark', _id)){
    setIsSaveLoading(false);
    return;
  }   

  const data = getValues(); 

  // if(data.registrationMarks.length <= 0){
  //   enqueueSnackbar( t('There is no mark to save'), { variant: 'warning',
  //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
  //     setIsSaveLoading(false);
  //     return;
  // }

  // const schoolYearTemplateSubjectExamScheduleIds = 
  //   Array.from( new Set( data.registrationMarks.map( s => s.schoolYearTemplateSubjectExamScheduleId ) || []) );
  // if(schoolYearTemplateSubjectExamScheduleIds.length !== 1) {
  //   enqueueSnackbar( t('All marks did not belong to the same exam'), { variant: 'warning',
  //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
  //     setIsSaveLoading(false);
  //     return;
  // } 

  // if(data.registrationMarks.some(x => x.schoolYearTemplateSubjectExamScheduleId <= 0)){
  //   enqueueSnackbar( t('There is an error in the selection of exam'), { variant: 'warning',
  //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
  //     setIsSaveLoading(false);
  //     return;
  // }

  // const schoolYearTemplateSubjectExamScheduleId = schoolYearTemplateSubjectExamScheduleIds[0];
  // if(data.name.trim() === '' || data.description.trim() === '') {
  //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
  //           anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
  //     return;
  //   }


  mutate( {...data, type: currentReportCardType });
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('ApplicationQuery', _id);
}

const printData = async (event: MouseEvent<HTMLButtonElement>) => {   
  const entityName =    (currentReportCardType === 'year' ) ?  'AnnualReportCard':
     (currentReportCardType === 'period' ) ?  'PeriodReportCard':
     (currentReportCardType === 'exam' ) ?  'ExamReportCard': '';  
  
  openEntityPrintDrawerEx(entityName , [{name: 'RegistrationId', value: 0},
      {name: 'SchoolYearId', value: watchSchoolYearId}, {name: 'SchoolYearClassId', value: watchSchoolYearClassId},
      {name: 'SchoolYearPeriodId', value: watchSchoolYearPeriodId}, {name: 'SchoolYearPeriodExamId', value: watchSchoolYearPeriodExamId},
      {name: 'SchoolYearClassTemplateId', value: watchSchoolYearClassTemplateId},
      {name: 'StudyLanguage', value: watchStudyLanguage}, {name: 'StudyType', value: watchStudyType}
  ]);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1.5} columnSpacing={1.5}>
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Class')} ...`}
                              </Typography>                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >

                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                { openClassFilter && <FormDialog open={openClassFilter} maxWidth='md'
                                    okText={''} cancelText={t('Cancel')} title={t('Classes')} onCancel={()=> {setOpenClassFilter(false);}} 
                                    onClose={()=> {setOpenClassFilter(false);}} onOk={()=> {}}  >
                                        <BasicTextFilterForm<ISchoolYearClass> {...basicFilterSchoolYearClass } />
                                </FormDialog> }
                                
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Exam')} ...`}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup size="small"
                                color="primary"
                                value={currentReportCardType}
                                exclusive
                                onChange={ (event, newType) => handleChangeReportCardType(event, newType)}
                                aria-label="Small sizes" fullWidth
                              >
                                <ToggleButton value='exam'>{t('Exam')}</ToggleButton>
                                <ToggleButton value='period'>{t('Period')}</ToggleButton>
                                <ToggleButton value='year'>{t('Year')}</ToggleButton>
                              </ToggleButtonGroup> 
                            </Box>                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='schoolYearId' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller control={control}
                                  name='schoolYearStartDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Start date')} 
                                      onChange={onChange} disableOpenPicker readOnly                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                
                                <Controller control={control}
                                  name='schoolYearEndDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('End date')} 
                                      onChange={onChange} disableOpenPicker readOnly               
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            {currentReportCardType !== 'year' && <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='schoolYearPeriodId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Period')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriods && schoolYearPeriods.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                {currentReportCardType === 'exam' && <Controller name='schoolYearPeriodExamId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Exam')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearPeriodExams && schoolYearPeriodExams.filter(x => x.schoolYearPeriodId === watchSchoolYearPeriodId).map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> }
                            </Box> }
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Exam')} ...`}
                              </Typography>
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='studyType' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearId"
                                        label={t('Study type')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller name='studyLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="studyLanguage"
                                        label={t('Study language')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='schoolYearClassTemplateId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="classTemplateId"
                                        label={t('Study level')} inputProps={ {readOnly: false}} focused >
                                        {schoolYearClassTemplates && schoolYearClassTemplates.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.classTemplateName}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller name='schoolYearClassId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearClassId"
                                        label={t('Class')} inputProps={ {readOnly: false}} focused
                                        helperText={`# ${t('Student')}(s) : ${schoolYearClassCountStudents} `}
                                        >
                                        {classes && classes.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                            </Box> 
                             
                            { (registrations || []).length > 0 && <Box sx={{ mt: 4, width: '100%' }} >
                              <WidgetPieChart title={`${t('Statistical analysis of result')}`} subheader=''
                                // chartData={[
                                //   { label: 'America', value: 4344 },
                                //   { label: 'Asia', value: 5435 },
                                //   { label: 'Europe', value: 1443 },
                                //   { label: 'Africa', value: 4443 },
                                // ]}
                                //chartData={ dashboard.monthMessagesByType.map( x => ({...x, label: _mt.has(x.label)? _mt.get(x.label)??x.label :x.label }) )}
                                chartData={ [{label: t('Success'), 
                                              value: (registrations || []).filter(r => r.mark >= defaultSuccessMark).length }, 
                                             {label: t('Failled'), 
                                              value: (registrations || []).filter(r => r.mark < defaultSuccessMark).length}]}
                                chartColors={[
                                  theme.palette.success.main,
                                  theme.palette.warning.main,
                                  
                                ]}/>
                            </Box> }
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={7} component={Paper} >
                        <Stack flexDirection='column'>            
                            {(registrations || []).length > 0 && <Box sx={{ mt: 1, width: '100%' }} > 
                              <Stack flexDirection='row'>
                                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', padding: 2,  width: '50%' }} >
                                  <Avatar sx= {{backgroundColor: theme.palette.success.main}}>{t('F_')}</Avatar>
                                  <Stack flexDirection='column'>
                                    <Typography variant="h6" id="tableTitle" color={theme.palette.success.main} noWrap>
                                      {t('Best in the class')}
                                    </Typography>
                                    <Typography variant="body2" id="tableTitle" color="primary" noWrap sx={{marginLeft: 'auto'}}>
                                      {firstMarkStudentName}
                                    </Typography>
                                  </Stack>
                                  <Typography variant="h6" id="tableTitle" color={theme.palette.success.main} noWrap >
                                    {firstMark.toFixed(2)}
                                  </Typography>
                                </Box>
                                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', padding: 2,  width: '50%' }} >
                                  <Avatar sx= {{backgroundColor: theme.palette.warning.main}}>{t('L_')}</Avatar>
                                  <Stack flexDirection='column'>
                                    <Typography variant="h6" id="tableTitle" color={theme.palette.warning.main} noWrap>
                                      {t('Last in the class')}
                                    </Typography>
                                    <Typography variant="body2" id="tableTitle" color="primary" noWrap sx={{marginLeft: 'auto'}}>
                                      {lastMarkStudentName}
                                    </Typography>
                                  </Stack>
                                  <Typography variant="h6" id="tableTitle" color={theme.palette.warning.main} noWrap >
                                    {lastMark.toFixed(2)}
                                  </Typography>
                                </Box>
                              </Stack>

                            </Box>}
                            { (watchSchoolYearClassId > 0) &&
                             <Box sx={{ mt: 1, width: '100%' }} key={` ${watchSchoolYearPeriodExamId} `} >
                              <EnhancedTable<IRegistration> rows={registrations || []} 
                                headCells={[   
                                  
                                  {id:'currentMarkStatus', label : t('Status'),  display: true, type: 'string', width: 10, },

                                  {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 25 },
                                  {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 25},

                                  {id:'currentAverageMark', label :`${t('Mark')}-${t('Cur.')}`,  display: true, type: 'numeric', width: 15, decimalScale: 2 },
                                  {id:'currentRank', label :`${t('Rank')}-${t('Cur.')}`,  display: true, type: 'numeric', width: 15, },

                                  {id:'mark', label :`${t('Mark')} - Calc`,  display: true, type: 'numeric', width: 15, decimalScale: 2 },
                                  {id:'rank', label :`${t('Rank')} - Calc`,  display: true, type: 'numeric', width: 15, },
                                
                                  // {id:'status', label : t('Status'),  display: true, type: 'string', getOptions: getRegistrationStatusList },
                                  // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', },
                                  // {id:'schoolFeesDueAmount', label : `${t('Due amount')} (SC)`,  display: true, type: 'numeric', },
                                  // {id:'unpaidAmouregistrationIdnt', label : t('Unpaid amount'),  display: true, type: 'numeric', },
                                  // {id:'mark', label : t('Mark'),  display: true, type: 'numeric', decimalScale: 2},
                                  // {id:'maxMark', label : t('Max mark'),  display: true, type: 'numeric', decimalScale: 2, },

                                  // {id:'reportCardMark', label : `${t('Mark')} - ${t('Report card')}`,  display: true, type: 'numeric', decimalScale: 2},
                                  
                                  // {id:'coefficient', label : t('Coefficient'),  display: true, type: 'numeric', decimalScale: 2, },
                                  
                                ]} 
                                title={`${t('Mark')}s ====> ${t('General average')} : ${avgMark.toFixed(2)}`} objKey='id' 
                                stateSelected={undefined} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='asc' orderBy='rank'
                                onRowDoubleClick={undefined} 
                                rowActionIcon={registrationRowActionIcon} // subjectRowActionIcon
                                toolbarActions={[
                                  // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                ]}
                              />   
                              { openRegistrationMarkForm && <FormDialog open={openRegistrationMarkForm} maxWidth='md'
                                okText='' cancelText='' title={` :: ${t('Mark')}`} onCancel={()=> {}} 
                                onClose={()=> {setOpenRegistrationMarkForm(false);}} onOk={()=> {setOpenRegistrationMarkForm(false);}}  >
                                  <RegistrationMarkForm {...selectedRegistration}  />
                                </FormDialog>              
                               }
                            </Box> }                           
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
