
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useEntityActionReportService, { useBasicFilterEntityActionReport } from './services/EntityActionReport';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IEntityActionState, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IEntityActionReport,  defaultEntityActionReport } from './models/EntityActionReport';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_JOB_RETENTION_MODE, Enum_DURATION_UNIT } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { useBasicFilterReport } from 'features/configuration/services/Report';
import { IReport } from 'features/configuration/models/Report';
import { DatePicker } from '@mui/x-date-pickers';
import EnhancedTable from 'components/ui/EnhancedTable';

export const EntityActionReportForm: FC<IEntityActionReport> = (props: IEntityActionReport = defaultEntityActionReport) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization, getEntityActionStates } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  const { createEntityActionReport, updateEntityActionReport } = useEntityActionReportService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterEntityActionReport = useBasicFilterEntityActionReport( 
    (event: React.MouseEvent<unknown>, row: IEntityActionReport) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          setValue('entityName', name || '');
          setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      },
      undefined, undefined, true
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        async (event: React.MouseEvent<unknown>, row: IReport) => {
            if( isFalsy(row)) return;
            const {id, name, entityName ,description} = row;

            if(entityName !== watchEntityName) {
              enqueueSnackbar( t('The report selected is not compatible with the entity of this schedule'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              return;
            }

            setValue('reportId', id);
            setValue('reportName', name);
                           
            setOpenReportFilter(false);
        }
    );
  

  
  const methods = useForm<IEntityActionReport>({defaultValues:defaultEntityActionReport});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchEntityName = watch('entityName');
  const watchRetentionMode = watch('retentionMode');
  const watchTransmissionMode = watch('transmissionMode');
  const watchHasEndDate = watch('hasEndDate');
  const watchHasTransmissionExpiration = watch('hasTransmissionExpiration');
  // const watchBase64Picture = watch('base64Picture');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IEntityActionReport>,Error,IEntityActionReport>(
      _id>0?updateEntityActionReport:createEntityActionReport, {   
        onSuccess: (data: IResult<IEntityActionReport>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Entity Action Report')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['EntityActionReport',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IEntityActionReport>(['EntityActionReport', _id], () => retrieveEntity('EntityActionReport',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'EntityActionReport'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_JOB_RETENTION_MODE, Enum_DURATION_UNIT ] ));

    const {data: actionStates} = useQuery<IEntityActionState[]>( ['EntityActionStates', 'EntityActionReport', watchEntityName], () => getEntityActionStates
        (watchEntityName), {refetchOnWindowFocus: false ,enabled: !isFalsy(watchEntityName) } );

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    const handleClickSearchEntity = (event: any) => {
      
      setOpenEntityFilter(true);
    }

    const handleClickSearchReport = (event: any) => {   
      if(isFalsy(watchEntityName)) {
        enqueueSnackbar( t('Entity is not defined'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }
      setOpenReportFilter(true);
    }

    const [transmissionModes, setTransmissionModes]  = useState<{name: string, value: string}[]>([]);

    const [openEntityExpression, setOpenEntityExpression] = useState(false);
    const [currentExpression, setCurrentExpression] = useState(''); 
    const [currentEntityName, setCurrentEntityName] = useState(''); 
    const [currentReturnType, setCurrentReturnType] = useState(''); 

    type ExpressionType = 'condition' | 'recipient' | 'recipient-cc' | 'recipient-bcc' | 'language';
    const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('condition');

    const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
        entityName: string, returnType: string, expression: string) => {

      setCurrentExpressionType(expressionType);
      setCurrentEntityName(entityName);
      setCurrentReturnType(returnType);
      setCurrentExpression(expression);
      setOpenEntityExpression(true);
    }

    const handleClickOkExpression = async () => {

      const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
      if(!checkExpression){
        enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      const {syntaxOk, syntaxError, returnType} = checkExpression;
      if(!syntaxOk) {
        enqueueSnackbar( syntaxError , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }
      
      if(returnType !== currentReturnType) {
        enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
              return;
      }    
      
      if( ['condition', 'recipient', 'recipient-cc', 'recipient-bcc', 'language'].includes(currentExpressionType)  ) {

        const transmissionConditionExpression = currentExpressionType === 'condition' ? currentExpression: getValues().transmissionConditionExpression;
        
        const recipientExpression = currentExpressionType === 'recipient' ? currentExpression: getValues().recipientExpression;
        const recipientExpressionCC = currentExpressionType === 'recipient-cc' ? currentExpression: getValues().recipientExpressionCC;
        const recipientExpressionBcc = currentExpressionType === 'recipient-bcc' ? currentExpression: getValues().recipientExpressionBcc;

        const reportLanguageExpression = currentExpressionType === 'language' ? currentExpression: getValues().reportLanguageExpression;
       
        setValue('transmissionConditionExpression', transmissionConditionExpression);
        setValue('recipientExpression', recipientExpression);
        setValue('recipientExpressionCC', recipientExpressionCC);
        setValue('recipientExpressionBcc', recipientExpressionBcc);      
        setValue('reportLanguageExpression', reportLanguageExpression);               
      } 

      setOpenEntityExpression(false);
    }
    
    

    const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
      
    }, [enumItems])

    useEffect(() => {
          
      setTransmissionModes([ {name: t('Sms'), value: 'sms'}, {name: t('WhatsApp'), value: 'whatsApp'}, {name: t('Email'), value: 'email'}
      ]);

    }, [t,i18n]);


      useEffect( () => {        
        setCurrentFormNameAtom(t('Entity Action Report'));
        setCurrentBasicTextFilterProps(basicFilterEntityActionReport);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Entity Action Report')} - # ${_id} # -`: t('Entity Action Report') );
            if(_id > 0)
              retrieveData('EntityActionReport',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultEntityActionReport);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('EntityActionReport', _id)) {
          setIsSaveLoading(false);
             return;
        }
        const data = getValues(); 

        if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }
        
              
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('EntityActionReport', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />                                
                                <TextField sx={{width:'calc(75% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                  label={`${t('Active ?')}`}
                                  control={
                                  <Controller
                                      name='isActive'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                            </Box>                                             
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                                              
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="entityDescription" label={t('Entity')} 
                                  {...register('entityDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) => handleClickSearchEntity(event)}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />
                                <Controller 
                                  name={`entityStatus`}
                                  control={control} 
                                  defaultValue='default'
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="entityStatus"
                                      label={t('Status')} inputProps={ {readOnly: false}} >
                                      { (actionStates||[]).map(({name, label}, idx) => (<MenuItem key={name} value={name}>{label}</MenuItem>))}                                        
                                    </TextField>
                                  )}
                              />
                                { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={()=> {setOpenEntityFilter(false);}}  >
                                    <BasicTextFilterForm<IEntity> {...basicFilterEntity} />
                                  </FormDialog>  }
                            </Box>

                            <Box sx={{ mt: 1, width: '100%' }} >   
                              <Controller                                
                                render={({ field }) => <TextField label={`${t('Report')} - `} {...field} 
                                      inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                      sx={{width:`calc(60% - 8px)`}}
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">                                            
                                            <IconButton color="primary" onClick={(event) => handleClickSearchReport(event)}>
                                              <ArrowDropDownCircleIcon />
                                            </IconButton>                                                                                               
                                        </InputAdornment>
                                      )
                                    }} />}
                                name={`reportName`}                      
                                control={control}
                              /> 
                              <Controller 
                                  name={`transmissionMode`}
                                  control={control} 
                                  defaultValue='default'
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="transmissionMode"
                                      label={t('Transmission mode')} inputProps={ {readOnly: false}} >
                                      { transmissionModes.map(({name, value}, idx) => (<MenuItem key={value} value={value}>{name}</MenuItem>))}                                        
                                    </TextField>
                                  )}
                              />  
                              { openReportFilter && <FormDialog open={openReportFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                        <BasicTextFilterForm<IReport> {...basicFilterReport } />
                                </FormDialog> }  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="transmissionConditionExpression" label={`${t('Expression')} - ${t('Transmission condition')}`}                                   
                                    {...register('transmissionConditionExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'condition', getValues().entityName,
                                                          'boolean',getValues().transmissionConditionExpression)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />                                                                                                                                  
                            </Box> 
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name={`retentionMode`} 
                                    control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="retentionMode"
                                        label={t('Retention')} inputProps={ {readOnly: false}} >
                                        {enumItems && enumItems.filter(x => x.enumerationCode === Enum_JOB_RETENTION_MODE).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />   
                                { watchRetentionMode === 'PERSO' &&
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={'#'} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`retentionValue`} 
                                control={control}
                              /> }
                              { watchRetentionMode === 'PERSO' && <Controller 
                                  name={`retentionUnit`} 
                                  control={control} 
                                  //defaultValue={ (businessApplications || []).length === 1 ? (businessApplications || [])[0].id : 0 }
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="retentionUnit"
                                      label={t('Dur. unit')} inputProps={ {readOnly: false}} >
                                      {enumItems && enumItems.filter(x => x.enumerationCode === Enum_DURATION_UNIT).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              /> }       
                            </Box>     
                            <Box sx={{ mt: 1, width: '100%' }} >                                   
                              <Controller
                                key={`query-startDate-x`}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Start date')}        
                                    onChange={onChange}                        
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:`calc(40% - 8px)`}}  />}
                                  /> )}
                                  name={`startDate`}          
                                control={control}
                              />  
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                label={`${t('End date')} ?`}
                                control={
                                <Controller
                                    name='hasEndDate'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />  
                              { watchHasEndDate && <Controller
                                  key={`query-startDate-x`}
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('End date')}        
                                      onChange={onChange}                        
                                      value={new Date(value?? new Date())}
                                      slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:`calc(40% - 8px)`}}  />}
                                    /> )}
                                    name={`endDate`}          
                                  control={control}
                                />   }                
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} >      
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={`${t('Transmission delay')} (Min.)`} sx={{width:'calc(30% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`transmissionDelayInMinute`} 
                                control={control}
                              />
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                
                                label={`${t('Transmission expiration')} ?`}
                                control={
                                <Controller
                                    name='hasTransmissionExpiration'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                              {watchHasTransmissionExpiration && <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={`${t('Transmission expiration')} (Min.)`} sx={{width:'calc(30% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`transmissionExpirationInMinute`} 
                                control={control}
                              /> }
                            </Box> 

                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="recipientExpression" label={`${t('Expression')} - ${t('Recipient')}`}
                                  {...register('recipientExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={(event) =>
                                            handleClickOpenExpression(event, 'recipient', getValues().entityName,
                                                        'string',getValues().recipientExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              />
                              { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                okText={t('OK')} cancelText='' title={`${t('Expression')} ...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                <EntityExpression entityName={currentEntityName} properties={[]} 
                                  expression={currentExpression} setExpression={setCurrentExpression} />
                              </FormDialog>  }                                                                                                    
                            </Box>
                            { watchTransmissionMode === 'email' && <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="recipientExpressionCC" label={`${t('Expression')} - ${t('Recipient')} CC`}
                                    {...register('recipientExpressionCC')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'recipient-cc', getValues().entityName,
                                                          'string',getValues().recipientExpressionCC)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />                                                                                                                                  
                            </Box> } 
                            { watchTransmissionMode === 'email' && <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="recipientExpressionBcc" label={`${t('Expression')} - ${t('Recipient')} Bcc/Cci`}
                                    {...register('recipientExpressionBcc')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'recipient-bcc', getValues().entityName,
                                                          'string',getValues().recipientExpressionBcc)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />                                                                                                                                  
                            </Box> }      
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <TextField sx={{width:'calc(100% - 8px)'}} id="reportLanguageExpression" label={`${t('Expression')} - ${t('Language')}`}
                                    {...register('reportLanguageExpression')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">                                            
                                          <IconButton color="primary" onClick={(event) =>
                                              handleClickOpenExpression(event, 'language', getValues().entityName,
                                                          'string',getValues().recipientExpressionBcc)}>
                                            <DeveloperModeIcon />
                                          </IconButton>                                                                                               
                                      </InputAdornment>
                                    ) 
                                  }}
                                />                                                                                                                                  
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <EnhancedTable<IEntityActionReport> rows={[]} 
                              headCells={[            
                                //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                {id:'name', label : t('Name'),  display: true, type: 'string', width: 30},
                                
                                {id:'description', label : t('Description'),  display: true, type: 'string', width: 40},
                                {id:'reportName', label : t('Report'),  display: true, type: 'string', width: 30},
                                                                
                              ]} 
                              title={t(`Others scheduled reports for action`)} objKey='name' 
                              stateSelected={undefined} 
                              onRowSelected={undefined} rowCheckedMode='single'
                              onRowCheckedSelectChange={undefined} order='desc' orderBy='name'
                              onRowDoubleClick={undefined} 
                              rowActionIcon={undefined}
                              toolbarActions={[
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                              ]}
                            />
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

