
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useSchoolYearService, { useBasicFilterSchoolYear } from './services/SchoolYear';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ISchoolYear, ISchoolYearBillingType, ISchoolYearClass, ISchoolYearClassTemplate, ISchoolYearMaterial, ISchoolYearPeriod,
    ISchoolYearPeriodExam, ISchoolYearTarification, defaultSchoolYear, defaultSchoolYearPeriod } from './models/SchoolYear';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_STUDY_TYPE, Enum_STUDY_LEVEL, 
    Enum_STUDY_LANGUAGE, Enum_SCHOOL_MATERIAL, 
    Enum_APPRECIATION_SYSTEM} from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import { DatePicker } from '@mui/x-date-pickers';
import ArrayFieldTableEx, {ActionIconTableRow, HeadCell} from 'components/ui/ArrayFieldTableEx';
import { IClassTemplate } from './models/ClassTemplate';
import { useBasicFilterClassroom } from './services/Classroom';
import { IClassroom } from './models/Classroom';
import { IBillingType } from './models/BillingType';
import { useBasicFilterBillingType } from './services/BillingType';
import { ITeacher } from './models/Teacher';
import { useBasicFilterTeacher } from './services/Teacher';
import { debounce, sum } from 'lodash';

export const ClassSchoolYearForm: FC<ISchoolYear> = (props: ISchoolYear = defaultSchoolYear) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { createSchoolYear, updateClassSchoolYear } = useSchoolYearService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSchoolYear = useBasicFilterSchoolYear( 
    (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [selectedFilterClassTemplates, setSelectedFilterClassTemplates] = useState<number[]>([]);
  const [filteredClassTemplates, setFilteredClassTemplates] = useState<IClassTemplate[]>([]);
  
  type ClassTemplateUsage = 'class' | 'tarification';

  const [currentClassTemplateUsage, setCurrentClassTemplateUsage] = useState<ClassTemplateUsage>('class');
  const [openClassTemplateFilter, setOpenClassTemplateFilter] = useState(false);
  const basicFilterClassTemplate = useBasicFilterClassTemplate( 
      (event: React.MouseEvent<unknown>, row: IClassTemplate) => {
          const {name, description, id, studyLevel, studyLanguage} = row;

          
        if(currentClassTemplateUsage === 'class') {

          if(getValues().schoolYearClassTemplates.some( c => c.classTemplateId === id))  {
            setOpenClassTemplateFilter(false);
            return;
          }            
            
          (refAppendSchoolYearClassTemplates.current??emptyFunc)({id:0, schoolYearId:_id, studyLanguage , classTemplateId: id, 
            studyLevel, classTemplateName: name , schoolYearClasses: [], schoolYearTemplateSubjects: []});          
         
        } 

         setOpenClassTemplateFilter(false);
      }, {
        rowCheckedMode: 'multiple', 
        stateSelected: [selectedFilterClassTemplates, setSelectedFilterClassTemplates],
        stateFiltered: [filteredClassTemplates, setFilteredClassTemplates],
    }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  // const basicFilterEnumeration = useBasicFilterEnumeration( 
  //     (event: React.MouseEvent<unknown>, row: IEnumeration) => {
  //         const {id, name, description} = row;


  //         // setValue('enumerationId', id);
  //         // setValue('enumerationName', name);
                           
  //         setOpenEnumerationFilter(false);
  //     }
  // );


  const [openClassroomFilter, setOpenClassroomFilter] = useState(false);
  const basicFilterClassroom = useBasicFilterClassroom( 
      (event: React.MouseEvent<unknown>, row: IClassroom) => {
          const {name, id} = row;

        const schoolYearClassTemplate = getValues().schoolYearClassTemplates.at(schoolYearClassTemplateIndex);
        if(isFalsy(schoolYearClassTemplate)) return;

        const schoolYearClass = schoolYearClassTemplate.schoolYearClasses.at(schoolYearClassIndex);
        if(isFalsy(schoolYearClass)) return;

        (refUpdateSchoolYearClass.current??emptyFunc)( schoolYearClassIndex, 
          {...schoolYearClass, classroomId: id, classroomName: name });
                           
         setOpenClassroomFilter(false);
      }
  );
  

  type TeacherUsage = 'full' | 'substitute';
  const [currentTeacherUsage, setCurrentTeacherUsage] = useState<TeacherUsage>('full');

  const [openTeacherFilter, setOpenTeacherFilter] = useState(false);
  const basicFilterTeacher = useBasicFilterTeacher( 
      (event: React.MouseEvent<unknown>, row: ITeacher) => {
          const {id, firstName, lastName} = row;

          const schoolYearClassTemplate = getValues().schoolYearClassTemplates.at(schoolYearClassTemplateIndex);
          if(isFalsy(schoolYearClassTemplate)) return;

          const schoolYearClass = schoolYearClassTemplate.schoolYearClasses.at(schoolYearClassIndex);
          if(isFalsy(schoolYearClass)) return;

          const fullTeacherId = (currentTeacherUsage === 'full') ? id : schoolYearClass.fullTeacherId;
          const substituteTeacherId = (currentTeacherUsage === 'substitute') ? id : schoolYearClass.substituteTeacherId;

          const fullTeacherName = (currentTeacherUsage === 'full') ? `${lastName} ${firstName}` : schoolYearClass.fullTeacherName;
          const substituteTeacherName = (currentTeacherUsage === 'substitute') ? `${lastName} ${firstName}` : schoolYearClass.substituteTeacherName;

          (refUpdateSchoolYearClass.current??emptyFunc)( schoolYearClassIndex,
               {...schoolYearClass, fullTeacherId, substituteTeacherId, fullTeacherName, substituteTeacherName });  

          if(currentTeacherUsage === 'full') {

          }
          // setValue('parentSchoolYearName', name);
          // setValue('parentSchoolYearId', id);
                           
          setOpenTeacherFilter(false);
      }
  );
  
  const [openExtensionTypeFilter, setOpenExtensionTypeFilter] = useState(false);
  const basicFilterExtensionType = useBasicFilterExtensionType( 
      (event: React.MouseEvent<unknown>, row: IExtensionType) => {
          const {id, name, description, type, baseType} = row;
          
        setValue('currentExtensionTypeId', id);
        setValue('currentExtensionTypeName', name);  
        //setValue('currentExtensionTypeX', type);   
        //setValue('currentExtensionTypeBaseType', baseType);                    
                          
        setOpenExtensionTypeFilter(false);
      }
  );

  const emptyFunc = (obj: any) => {}

  const methods = useForm<ISchoolYear>({defaultValues:defaultSchoolYear});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields : schoolYearRegistrationExtensions, 
      append: appendSchoolYearRegistrationExtensions, 
      update: updateSchoolYearRegistrationExtension ,
      remove: removeSchoolYearRegistrationExtension,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
    name: `schoolYearRegistrationExtensions`,
    control,            
  });

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYear>,Error,ISchoolYear>(
      _id>0?updateClassSchoolYear:createSchoolYear, {   
        onSuccess: (data: IResult<ISchoolYear>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SchoolYear',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ISchoolYear>(['SchoolYear', _id], () => retrieveEntity('SchoolYear',_id, 'Class'), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const {data: enumItems} = useQuery<IEnumerationItem[]>(
      ['EnumerationItems', 'SchoolYear'], () => 
        getEnumerationItemsByEnumerationCodes( [Enum_STUDY_TYPE, Enum_STUDY_LEVEL, 
          Enum_STUDY_LANGUAGE, Enum_SCHOOL_MATERIAL, Enum_APPRECIATION_SYSTEM ] ));

      
      const [openBillingTypeDetails,setOpenBillingTypeDetails] = useState<boolean>(false); 
          
    
      const handleOkBasicTextFilterClassTemplateForm = () => {

        if(selectedFilterClassTemplates.length === 0) {
          // enque
          setOpenClassTemplateFilter(false);
          return;
        } 
    
      const classes2Append = filteredClassTemplates.filter(ct => selectedFilterClassTemplates.includes(ct.id || 0) );
    
      if(currentClassTemplateUsage === 'class') {
    
        // const studyTypes = enumItems?.filter( e => e.enumerationCode === Enum_STUDY_TYPE ) || [];
        
        // const arrBillingTypeStudyType = classes2Append.map( ({name, id}) => ({name, id, studyTypes }) ).flatMap( 
        //   ({name, id, studyTypes }) => studyTypes.map( ({code}) => ({ name, id, studyType: code }) ) )
        //   .filter( x => !getValues().schoolYearClasses.some( t => t.classTemplateId === x.id && t.studyType === x.studyType) );
    
        const newClasses = classes2Append.filter( cl => !getValues().schoolYearClassTemplates.some(x => x.classTemplateId === cl.id) );
    
        (refAppendSchoolYearClassTemplates.current??emptyFunc)( newClasses.map( ({name, id, studyLanguage, studyLevel}) => 
                      ({id:0, schoolYearId:_id, studyLanguage , classTemplateId: id, 
                        studyLevel, classTemplateName: name, schoolYearClasses: [], schoolYearTemplateSubjects: [] })));
      }
    
        setOpenClassTemplateFilter(false);
      }
    
    
      const getInputAdornmentSchoolYearClassTemplateRemove = (row: ISchoolYearClassTemplate, cellId: keyof ISchoolYearClassTemplate)  => ({
    
        toolTip: 'Remove class template',
        icon: RemoveCircleIcon,
        onClickIcon: (event: any, index: number, row: ISchoolYearClassTemplate ) => {
                  
          (refRemoveSchoolYearClassTemplate.current??emptyFunc)(index);   
        }  
      })
    
      const getStudyLevelList = (row: ISchoolYearClassTemplate, cellId: keyof ISchoolYearClassTemplate, 
        opts: {value: string, name: string}[]) => {        
        
        return getAsOptions(refEnumItems.current ?? [],Enum_STUDY_LEVEL);
      }
    
      const getStudyLanguageList = (row: ISchoolYearClassTemplate, cellId: keyof ISchoolYearClassTemplate, 
        opts: {value: string, name: string}[]) => {        
        
        return getAsOptions(refEnumItems.current ?? [],Enum_STUDY_LANGUAGE);
      }
    
      const [headSchoolYearClassTemplateCells, setHeadSchoolYearClassTemplateCells]  = useState<HeadCell<ISchoolYearClassTemplate>[]>([]);
      useEffect(() => {
        setHeadSchoolYearClassTemplateCells([            
          {id:'id', label : t('Id'),  display: false, type: 'numeric', },
          {id:'classTemplateName', label : t('Template'),  display: true, type: 'string', width: 25, getInputAdornment: getInputAdornmentSchoolYearClassTemplateRemove  },
          {id:'studyLevel', label : t('Study level'),  display: true, type: 'string', width: 30, getOptions: getStudyLevelList},
          {id:'studyLanguage', label : t('Study language'),  display: true, type: 'string', width: 45, getOptions: getStudyLanguageList},
        ]  )
      }, [t,i18n])
    
      const refAppendSchoolYearClassTemplates = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateSchoolYearClassTemplate = useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
      const refRemoveSchoolYearClassTemplate = useRef<(index: number ) => void>(null);
      const handleAddSchoolYearClassTemplates = (event: any) => {
        setCurrentClassTemplateUsage('class')
        setOpenClassTemplateFilter(true);
       
      }
      
      const [openSchoolYearClasses,setOpenSchoolYearClasses] = useState<boolean>(false); 
    
      const [schoolYearClassTemplateIndex, setSchoolYearClassTemplateIndex] = useState<number>(-1);
      const schoolYearClassTemplateRowActionIcon = ( schoolYearClassTemplate: ISchoolYearClassTemplate) : ActionIconTableRow<ISchoolYear,ISchoolYearClassTemplate> => {
      
        const res: ActionIconTableRow<ISchoolYear,ISchoolYearClassTemplate> = {
          toolTip: 'More',
          icon: MoreIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ISchoolYearClassTemplate) => {
            
            setSchoolYearClassTemplateIndex(index);     
            setOpenSchoolYearClasses(true);       
          }
        }
        return res;
      }
              

      const getInputAdornmentClassroom = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass)  => ({

        toolTip: 'Classroom',
        icon: ArrowDropDownCircleIcon,
        onClickIcon: (event: any, index: number, row: ISchoolYearClass ) => {
                  
          setSchoolYearClassIndex(index);
          setOpenClassroomFilter(true);
        }  
      })
    
      const getInputAdornmentFullTeacher = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass)  => ({
    
        toolTip: 'Teacher',
        icon: ArrowDropDownCircleIcon,
        onClickIcon: (event: any, index: number, row: ISchoolYearClass ) => {
                  
          setCurrentTeacherUsage('full');
          setSchoolYearClassIndex(index);
          setOpenTeacherFilter(true);
        }  
      })
    
      const getInputAdornmentSubstituteTeacher = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass)  => ({
    
        toolTip: 'Teacher',
        icon: ArrowDropDownCircleIcon,
        onClickIcon: (event: any, index: number, row: ISchoolYearClass ) => {
                  
          setCurrentTeacherUsage('substitute');
          setSchoolYearClassIndex(index);
          setOpenTeacherFilter(true);
        }  
      })
    
      const getStudyTypeList = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass, 
        opts: {value: string, name: string}[]) => {        
        
        return getAsOptions(refEnumItems.current ?? [],Enum_STUDY_TYPE);
      }
    
      const cellEditableClass = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass) => {
        return true;
      }
    
      const [headSchoolYearClassCells, setHeadSchoolYearClassCells]  = useState<HeadCell<ISchoolYearClass>[]>([]);
      useEffect(() => {
        setHeadSchoolYearClassCells([            
          {id:'id', label : t('Id'),  display: false, type: 'numeric', },
          {id:'name', label : t('Name'),  display: true, type: 'string', width: 15, isEditable: cellEditableClass, },
          {id:'studyType', label : t('Study type'),  display: true, type: 'string', width: 15, isEditable: cellEditableClass,
            getOptions: getStudyTypeList},
          {id:'classroomName', label : t('Room'),  display: true, type: 'string', width: 20,  
              getInputAdornment: getInputAdornmentClassroom},
          {id:'fullTeacherName', label : t('Full teacher'),  display: true, type: 'string', width: 25, 
              getInputAdornment: getInputAdornmentFullTeacher},
          {id:'substituteTeacherName', label : t('Substitute teacher'),  display: true, type: 'string', width: 25, 
              getInputAdornment: getInputAdornmentSubstituteTeacher},
        ]  )
      }, [t,i18n])
    
      const refAppendSchoolYearClasses = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
      const refUpdateSchoolYearClass = useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
      const refRemoveSchoolYearClass = useRef<(index: number ) => void>(null);
    
      const handleAddSchoolYearClasses = (event: any) => {
        (refAppendSchoolYearClasses.current??emptyFunc)( {
          id: 0, schoolYearId: _id, name: '', classTemplateId: 0, classTemplateName: '', classroomId: 0, classroomName: '',
          schoolYearClassSubjects: [] 
        });
      }
        
    
      const [schoolYearClassIndex, setSchoolYearClassIndex] = useState<number>(-1);
      const schoolYearClassRowActionIcon = ( schoolYearClass: ISchoolYearClass) : ActionIconTableRow<ISchoolYear,ISchoolYearClass> => {
      
        const res: ActionIconTableRow<ISchoolYear,ISchoolYearClass> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, row: ISchoolYearClass) => {
            
            (refRemoveSchoolYearClass.current??emptyFunc)(index);            
          }
        }
        return res;
    }


////
const getMaterialList = (row: ISchoolYearMaterial, cellId: keyof ISchoolYearMaterial, 
  opts: {value: string, name: string}[]) => {        
  
  return getAsOptions(refEnumItems.current ?? [],Enum_SCHOOL_MATERIAL);
}

const getInputAdornmentMaterialConditionExpression = (row: ISchoolYearMaterial, cellId: keyof ISchoolYearMaterial)  => ({
 
  toolTip: 'Expression',
  icon: DeveloperModeIcon,
  onClickIcon: (event: any, index: number, row: ISchoolYearMaterial ) => {    
    const {conditionExpression} = row;
    
    setSchoolYearMaterialIndex(index);
    handleClickOpenExpression(event, 'material', 'Registration', 'boolean', conditionExpression);
  }  
})
const cellEditableMaterial = (row: ISchoolYearMaterial, cellId: keyof ISchoolYearMaterial) => {
  return true;
}

const [headSchoolYearMaterialCells, setHeadSchoolYearMaterialCells]  = useState<HeadCell<ISchoolYearMaterial>[]>([]);
  useEffect(() => {
    setHeadSchoolYearMaterialCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'schoolMaterialCode', label : t('Name'),  display: true, type: 'string', width: 40,  
        getOptions: getMaterialList, isEditable: cellEditableMaterial  },
      
      {id:'isGivenByStudent', label : `${t('Given by student ?')}`,  display: true, type: 'boolean', width: 15, 
        isEditable: cellEditableMaterial },
      {id:'conditionExpression', label : `${t('Expression')} : ${t('Condition')}`,  display: true, type: 'string', width: 55, 
        getInputAdornment: getInputAdornmentMaterialConditionExpression },
    ]  )
  }, [t,i18n])

  const refAppendSchoolYearMaterials = useRef<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateSchoolYearMaterial = useRef<(index: number,value: Partial<FieldArray<ISchoolYear>> ) => void>(null);
  const refRemoveSchoolYearMaterial = useRef<(index: number ) => void>(null);

  const handleAddSchoolYearMaterials = (event: any) => {
    if(getValues().schoolYearMaterials.some(m => isFalsy(m.schoolMaterialCode))) return;

    (refAppendSchoolYearMaterials.current??emptyFunc)({});
  }

  const [schoolYearMaterialIndex, setSchoolYearMaterialIndex] = useState<number>(-1);
  const schoolYearMaterialRowActionIcon = ( schoolYearMaterial: ISchoolYearMaterial) : ActionIconTableRow<ISchoolYear,ISchoolYearMaterial> => {
  
    const res: ActionIconTableRow<ISchoolYear,ISchoolYearMaterial> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ISchoolYearMaterial) => {
        
        (refRemoveSchoolYearMaterial.current??emptyFunc)(index);            
      }
    }
    return res;
  }

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'billing-obligatory' | 'billing-defaultAmount' | 'billing-amount' 
              | 'registration-sequence' | 'registration-code' | 'student-sequence' | 'student-code' | 'material' ;
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('billing-amount');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      const handleClickOkExpression = async () => {
        const checkExpression = await checkEntityExpressionSyntax(currentEntityName, currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }
       
        if(returnType !== currentReturnType) {
          enqueueSnackbar( `${t('The result of expression must be')} ${currentReturnType}` , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                return;
        }

       if(['student-sequence'].includes(currentExpressionType) ) {
            setValue('studentSequenceExpression', currentExpression);
        } else if(['student-code'].includes(currentExpressionType) ) {
            setValue('studentCodeExpression', currentExpression);
        } else if(['registration-sequence'].includes(currentExpressionType) ) {
          setValue('registrationSequenceExpression', currentExpression);
      } else if(['registration-code'].includes(currentExpressionType) ) {
          setValue('registrationCodeExpression', currentExpression);
      } else if(['material'].includes(currentExpressionType) ) {
        const schoolYearMaterial = getValues().schoolYearMaterials.at(schoolYearMaterialIndex);
        if(isFalsy(schoolYearMaterial)) return;
        
        (refUpdateSchoolYearMaterial.current??emptyFunc)( schoolYearMaterialIndex, 
          {...schoolYearMaterial, conditionExpression: currentExpression });
      }

        setOpenEntityExpression(false);
      }
    

      const [extensionIndex, setExtensionIndex] = useState<number>(-1);
      const [openExtension, setOpenExtension] = useState(false);
      const handleClickAddExtension = (event: any) => {
        setExtensionIndex(-1);

        //setValue('currentExtensionAlias','');        
        //setValue('currentExtensionTypeId',0);
        //setValue('currentExtensionTypeName','');
        //setValue('currentExtensionDescription','');
        
        //setOpenExtension(true);
      }



      const handleClickEditExtension = (index: number) => {
        const schoolYearRegistrationExtension = getValues().schoolYearRegistrationExtensions.at(index);
        if(isFalsy(schoolYearRegistrationExtension)) return;

        setExtensionIndex(index);
        const {alias, description, extensionTypeId, extensionTypeName} = schoolYearRegistrationExtension!;

        //setValue('currentExtensionAlias',alias);        
        //setValue('currentExtensionTypeId',extensionTypeId);
        //setValue('currentExtensionTypeName',extensionTypeName);
        //setValue('currentExtensionDescription',description);
        
        //setOpenExtension(true);
      }
     
      const handleOkExtension =  () => { 

        const { currentExtensionAlias, currentExtensionTypeId,
          currentExtensionTypeName, currentExtensionDescription } = getValues();

        if(isFalsy(currentExtensionAlias) || isFalsy(currentExtensionTypeId)) return;

        if(extensionIndex<0 && getValues().schoolYearRegistrationExtensions.some( x => x.alias.trim() === currentExtensionAlias.trim())) 
          return;

        if(extensionIndex>=0 && getValues().schoolYearRegistrationExtensions.findIndex( o => o.alias === currentExtensionAlias) !== extensionIndex)
          return;

        if(extensionIndex<0)
          appendSchoolYearRegistrationExtensions( {
            id: 0, schoolYearId: _id,
            extensionTypeId: currentExtensionTypeId, extensionTypeName: currentExtensionTypeName,
            alias: currentExtensionAlias, description: currentExtensionDescription, });
        else {
          const schoolYearRegistrationExtension = getValues().schoolYearRegistrationExtensions.at(extensionIndex)!;
          updateSchoolYearRegistrationExtension(extensionIndex,{...schoolYearRegistrationExtension,
            extensionTypeId: currentExtensionTypeId, extensionTypeName: currentExtensionTypeName,
            alias: currentExtensionAlias, description: currentExtensionDescription });
        }

        setOpenExtension(false);
      }

      
    const handleClickSearchExtensionType = (event: any) => {
      setOpenExtensionTypeFilter(true);
    }

    const refEnumItems = useRef<IEnumerationItem[]>();
    
    useEffect( () => {   
        refEnumItems.current = enumItems;
     
    }, [enumItems])

      useEffect( () => {        
        setCurrentFormNameAtom(t('School year'));
        setCurrentBasicTextFilterProps(basicFilterSchoolYear);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('SchoolYear',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultSchoolYear);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('SchoolYear', _id)) {
          setIsSaveLoading(false);
             return;
        }
        

          const data = getValues(); 
          if(data.name.trim() === '' || data.year <= 0) {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
            
            if(data.schoolYearMaterials.some(m => isFalsy(m.conditionExpression) || isFalsy(m.schoolMaterialCode) )) {
              enqueueSnackbar( t('The type of material and the condition have to be specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
            }

            if(data.schoolYearClassTemplates.some(c => isFalsy(c.appreciationSystemCode) )) {
              enqueueSnackbar( t('The system of appreciation have to be specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
            }

          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('SchoolYear', _id);
      }

      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('SchoolYear', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'  >  
                        <Box sx={{ mt: 1, width: '100%' }} >
                            <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                            <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                            <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                            <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                            <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                            <TextField sx={{width:'calc(20% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                            <TextField sx={{width:'calc(20% - 8px)'}} id="year" label={t('Year')} {...register('year')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                            
                                 
                            <Controller                              
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('Start Date')}
                                    
                                  //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}         
                                  onChange={onChange}                        
                                  value={new Date(value)}
                                  slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}}  />}
                                /> )}
                              name='startDate'                                  
                              control={control}
                            />            
                            <Controller                              
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('End Date')}
                                  
                                  //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}         
                                  onChange={onChange}                        
                                  value={new Date(value)}
                                  slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                /> )}
                              name='endDate'                                  
                              control={control}
                            />                                       
                        </Box> 
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(70% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                          <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                              label={t('Active ?')}
                              control={
                              <Controller
                                  name='isActive'
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} />
                        </Box>  
                        { openClassTemplateFilter && <FormDialog open={openClassTemplateFilter} maxWidth='md'
                            okText={t('OK')} cancelText={t('Cancel')} title={t('Study level filter')} onCancel={()=> {}} 
                            onClose={()=> {setOpenClassTemplateFilter(false);}} onOk={handleOkBasicTextFilterClassTemplateForm}  >
                                <BasicTextFilterForm<IClassTemplate> {...basicFilterClassTemplate } />
                        </FormDialog> }
                        { openClassroomFilter && <FormDialog open={openClassroomFilter} maxWidth='md'
                            okText={t('OK')} cancelText={t('Cancel')} title={t('Classroom filter')} onCancel={()=> {setOpenClassroomFilter(false);}} 
                            onClose={()=> {setOpenClassroomFilter(false);}} onOk={()=> {setOpenClassroomFilter(false);}}  >
                                <BasicTextFilterForm<IClassroom> {...basicFilterClassroom } />
                        </FormDialog> }
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'> 
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`student-sequence`}
                            render={({ field }) => <TextField label={t('Student sequence')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'student-sequence', 'Registration', 'numeric', getValues().studentSequenceExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`studentSequenceExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`student-code`}
                            render={({ field }) => <TextField label={t('Student matricule')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'student-code', 'Registration', 'string', getValues().studentCodeExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`studentCodeExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`code-expression`}
                            render={({ field }) => <TextField label={t('Registration sequence')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'registration-sequence', 'Registration', 'numeric', getValues().registrationSequenceExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`registrationSequenceExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={2} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller
                            key={`code-expression`}
                            render={({ field }) => <TextField label={t('Registration matricule')} multiline rows={3}
                                {...field} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={(event) => handleClickOpenExpression(event, 'registration-code', 'Registration', 'string', getValues().registrationCodeExpression)}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                              
                                    </InputAdornment>
                                )
                              }}
                              sx={{width:`calc(100% - 8px)`, textTransform: 'none'}}  />} 
                              name={`registrationCodeExpression`}                      
                            control={control}
                          />
                        </Box>
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>  
                        
                        <Box sx={{ mt: 4, width: '100%' }} key={`${getValues().schoolYearPeriods.length} - $${getValues().schoolYearPeriods.map(p => p.name).join('-')}`} >
                                                        
                          <ArrayFieldTableEx<ISchoolYear,ISchoolYearClassTemplate,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headSchoolYearClassTemplateCells} rowsPathName={`schoolYearClassTemplates` }
                              title={t('Classes')} rowActionIcon={schoolYearClassTemplateRowActionIcon}  
                              //onRowSelected={handleQuerySelected}
                                                  
                              refAppend={refAppendSchoolYearClassTemplates as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateSchoolYearClassTemplate as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                              refRemove={refRemoveSchoolYearClassTemplate as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearClassTemplates ,icon: AddCircleIcon,  },
                              
                              ]}
                            />
                        </Box> 
                        { openSchoolYearClasses && <FormDialog open={openSchoolYearClasses} maxWidth='md'
                            okText={t('OK')} cancelText='' title={t('Details')} onCancel={()=> {}} 
                            onClose={()=> {setOpenSchoolYearClasses(false);}} onOk={()=> {setOpenSchoolYearClasses(false);}}  >
                            <Stack flexDirection='column'  >
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <Controller name={`schoolYearClassTemplates.${schoolYearClassTemplateIndex}.countReportCard`} control={control} 
                                      
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="countReportCard"
                                        label={t('Count report card')}  inputProps={ {readOnly: false}} focused >
                                        <MenuItem key={1} value={1}>{1}</MenuItem>
                                        <MenuItem key={1} value={2}>{2}</MenuItem>
                                      </TextField>
                                    )}
                                />
                                <Controller                                                   
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Mark')} - ${t('Report card')}`} sx={{width:'calc(25% - 8px)'}} 
                                          allowEmptyFormatting={false} 
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`schoolYearClassTemplates.${schoolYearClassTemplateIndex}.reportCardMark`}
                                    control={control}
                                />
                                <Controller                                                   
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Default success mark')}`} sx={{width:'calc(25% - 8px)'}} 
                                          allowEmptyFormatting={false} 
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`schoolYearClassTemplates.${schoolYearClassTemplateIndex}.defaultSuccessMark`}
                                    control={control}
                                />
                                <Controller name={`schoolYearClassTemplates.${schoolYearClassTemplateIndex}.appreciationSystemCode`} control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="appreciationSystemCode"
                                        label={t('Appreciation system')} inputProps={ {readOnly: false}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_APPRECIATION_SYSTEM ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} key={`reportFields-${'reportTable.name'}-${'reportTableIndex'} ${'reportTable.reportTableDefaultParameters.length'}`}>
                                <ArrayFieldTableEx<ISchoolYear,ISchoolYearClass,'id'> 
                                      key={`Report field - IReportTableDefaultParameter ${'reportTable.name'}`}
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headSchoolYearClassCells} 
                                      rowsPathName={`schoolYearClassTemplates.${schoolYearClassTemplateIndex}.schoolYearClasses`}  
                                      title={t(`Classes`)} rowActionIcon={schoolYearClassRowActionIcon}  
                                      
                                      //onRowSelected={handleRoleEntitySelected}                                                    
                                      refAppend={refAppendSchoolYearClasses as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateSchoolYearClass as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                      refRemove={refRemoveSchoolYearClass as MutableRefObject<(index: number) => void>}
                                                        //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                      toolbarActions={[
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearClasses ,icon: AddCircleIcon,  },
                                    ]}
                                  />                                                                 
                              </Box>
                            </Stack>
                          </FormDialog> }
                          { openTeacherFilter && <FormDialog open={openTeacherFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Teacher filter')} onCancel={()=> {setOpenTeacherFilter(false);}} 
                                onClose={()=> {setOpenTeacherFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<ITeacher> {...basicFilterTeacher } />
                            </FormDialog> }
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>                        
                        <Box sx={{ mt: 1, width: '100%' }} >
                            
                              { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Expression')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  }
                              
                          </Box>
                          
                          <Box sx={{ mt: 3, width: '100%' }} >
                            <ArrayFieldTableEx<ISchoolYear,ISchoolYearMaterial,'id'> 
                                key={`Material -  ${'reportTable.name'}`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headSchoolYearMaterialCells} 
                                rowsPathName={`schoolYearMaterials`}  
                                title={`${t('School material')}`} rowActionIcon={schoolYearMaterialRowActionIcon}  
                                
                                //onRowSelected={handleRoleEntitySelected}                                                    
                                refAppend={refAppendSchoolYearMaterials as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateSchoolYearMaterial as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                refRemove={refRemoveSchoolYearMaterial as MutableRefObject<(index: number) => void>}
                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearMaterials ,icon: AddCircleIcon,  },
                              ]}
                            />
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                          {                              
                              getValues().schoolYearRegistrationExtensions.map( ( {alias, description} ,idx) => 
                                ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${alias}`} label={`${alias} : ${description} `} color="primary" variant="outlined"
                                    onDelete={() => {removeSchoolYearRegistrationExtension(idx)}} 
                                    onClick={() => handleClickEditExtension(idx)}/>
                                    ))
                            }
                          </Box>
                          <Box sx={{ mt: 4, width: '100%' }} >
                            <Button>
                              {`${t('Add Extension')}`}
                              <Box sx={{ ...justifyCenter, ml: 1 }}>
                                <MdOutlineAdd size={24} onClick={handleClickAddExtension} />
                              </Box>
                            </Button>
                            { openExtension && <FormDialog open={openExtension} maxWidth='sm' height='75vh'
                                  okText={t('OK')} cancelText='' title={t('Extension')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtension(false);}} onOk={handleOkExtension}  >
                                    <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionAlias" label={t('Alias')} 
                                            {...register('currentExtensionAlias')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                      </Box>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionDescription" label={t('Description')} 
                                          {...register('currentExtensionDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                      </Box>
                                      <Box sx={{ mt: 1, width: '100%' }}>
                                        <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionTypeName" label={t('Extension type')} 
                                            {...register('currentExtensionTypeName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                            InputProps={{
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={handleClickSearchExtensionType}>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                              </InputAdornment>
                                            )
                                          }} />                                                                           
                                    </Box>                                    
                                  </Stack>
                            </FormDialog> } 
                            { openExtensionTypeFilter && <FormDialog open={openExtensionTypeFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Extension type')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionTypeFilter(false);}} onOk={()=> {setOpenExtensionTypeFilter(false);}}  >
                                      <BasicTextFilterForm<IExtensionType> {...basicFilterExtensionType } />
                              </FormDialog> }
                          </Box>  
                      </Stack>
                    </Grid>     
                                          
                </Grid>
            </Box>
        </FormProvider> 
  )
}

