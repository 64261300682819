
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IBusDestination, IBusDestinationSearch } from "../models/BusDestination";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createBusDestination = async (busDestination: IBusDestination)  =>       
        await (await axios.post('/api/setup/busDestination/create', busDestination)).data;       
        
    const updateBusDestination = async (busDestination: IBusDestination)  =>       
        await (await axios.post('/api/setup/busDestination/update', busDestination)).data; 
    
    const getBusDestination = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/busDestination/get-busDestination/${id}`));
      return await data;
    }
   

    const getBusDestinations = async (criteria: IBusDestinationSearch) : Promise<IBusDestination[]> => {

      const { name, description, destinationOption} = criteria;

      const {data} = (await axios.get(`/api/setup/busDestination/get-busDestinations?name=${name}&description=${description}&destinationOption=${destinationOption}`));
      return await data;
    }
    
      
    return {    
      createBusDestination,
      updateBusDestination,
      getBusDestination,
      getBusDestinations
      
    } 
}

export default _;

export interface IFilterBusDestinationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IBusDestination[], React.Dispatch<React.SetStateAction<IBusDestination[]>>],
}

const defaultFilterBusDestinationOption: IFilterBusDestinationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterBusDestination = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IBusDestination) => void,
                                            filterOption?: IFilterBusDestinationOption  ) => {

  const { getBusDestinations } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterBusDestinationOption;



  const [headBusDestinationCells, setHeadBusDestinationCells]  = useState<HeadCell<IBusDestination>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'destinationOption', label : t('Option'),  display: true, type: 'string', },      

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'destinationOption', text: t('Option'), value: ''},

    ]);    

  const [filteredBusDestinations, ] = useState<IBusDestination[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IBusDestination[]> => {
    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';     
    const destinationOption = filterElements.find( elt => elt.name === 'destinationOption')?.value || '';       

    const arr = await getBusDestinations( { name,description,destinationOption} );
   
    return arr;
  }

  const objKey: keyof IBusDestination = 'id';

  return {
    title: t('Bus destination'), headCells: headBusDestinationCells, objKey,
    filterElements, rows: filteredBusDestinations, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
