
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BoltIcon from '@mui/icons-material/Bolt';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useExpenseService, { useBasicFilterExpense } from './services/Expense';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ExpenseDetailType, IExpense, IExpenseDetail, IExpenseDetailInfo, IExpenseInfo, defaultExpense, defaultExpenseDetail } from './models/Expense';

import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from 'features/production/services/Person';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_RESOURCE_CATEGORY, Enum_OPERATION_STATUS, 
        Enum_EXPENSE_STATUS_PURPOSE, Enum_RESOURCE_OPTION, 
        Enum_ARTICLE_OPTION} from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker } from '@mui/x-date-pickers';
import { IPerson, PersonType } from './models/Person';
import { useBasicFilterResource } from './services/Resource';
import { IResource } from './models/Resource';
import { useBasicFilterResourceType } from 'features/setup/services/ResourceType';
import { IResourceType } from 'features/setup/models/ResourceType';
import { debounce, sum } from 'lodash';
import { GrSearch, GrClose } from 'react-icons/gr';
import BasicButtonList from 'components/ui/BasicButtonList';
import { useBasicFilterProduct } from 'features/setup/services/Product';
import { defaultProduct, IProduct } from 'features/setup/models/Product';
import EnhancedTable from 'components/ui/EnhancedTable';

export const ExpenseForm: FC<IExpense> = (props: IExpense = defaultExpense) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();

  const { createExpense, updateExpense } = useExpenseService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterExpense = useBasicFilterExpense( 
    (event: React.MouseEvent<unknown>, row: IExpense) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  const expenseDetailTypes : {type: number, value: ExpenseDetailType, name: string, resourceType: 1|2|3}[] = 
                                  [ {type: 1, value: 'production', name: t('Production'), resourceType: 1},
                                    {type: 2, value: 'resource', name: t('Resource'), resourceType: 2},
                                    {type: 3, value: 'maintenance', name: t('Maintenance'), resourceType: 2},
                                    {type: 4, value: 'exploitation', name: t('Exploitation'), resourceType: 3},
                                    {type: 6, value: 'other', name: t('Other'), resourceType: 1},
                                    {type: 7, value: 'article', name: t('Article'), resourceType: 3},
                                  ];

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, particularSign, corporationName, corporationType,
            portable1, portable2, email1, email2} = row;
        
        setValue('supplierId', id);

        setValue('supplierType', type);

        setValue('supplierFirstName', firstName);
        setValue('supplierLastName', lastName); 
        setValue('supplierCorporationName', corporationName);  

        setValue('supplierPortable1', portable1);   
        setValue('supplierPortable2', portable2);                    
        setValue('supplierEmail1', email1); 
        setValue('supplierEmail2', email2); 
                          
        setOpenPersonFilter(false);
      }
  );

  

  const [openResourceFilter, setOpenResourceFilter] = useState(false);
  const basicFilterResource = useBasicFilterResource( 
      (event: React.MouseEvent<unknown>, row: IResource) => {
          const {id, description,permanentResourceId: resourceTypeId, permanentResourceName: resourceTypeName} = row;
        
          const detailType = expenseDetailTypes.find(t => t.type === refExpenseDetailType.current);
          if(isFalsy(detailType)) return;

          (refAppendExpenseDetails.current??emptyFunc)({id: 0, expenseId: _id, resourceId: id, type: detailType.value,
            resourceDescription: description, resourceTypeId , resourceTypeName ,
            expenseReference: '', expenseDescription: '', expenseSupplierFullName: '',
            description: '', amount: 0,  });                          
        setOpenResourceFilter(false);
      }
  );

  const refExpenseDetailType = useRef<number>(1);

  const [openResourceTypeFilter, setOpenResourceTypeFilter] = useState(false);
  const basicFilterResourceType = useBasicFilterResourceType( 
      expenseDetailTypes.find(t => t.type === refExpenseDetailType.current)?.resourceType??0, 
      (event: React.MouseEvent<unknown>, row: IResourceType) => {
        const {id, name, isActive, temporaryFilterOption} = row;
        
        if(!isActive) {
          enqueueSnackbar( t('This resource type is not active'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }
        // const resource = getValues().expenseDetails.at(resourceIndex);
        
        // if(isFalsy(resource)) return;

        // if(resource.resourceId > 0) return;
        const detailType = expenseDetailTypes.find(t => t.type === refExpenseDetailType.current);
        
        if(isFalsy(detailType)) return;
       
        (refAppendExpenseDetails.current??emptyFunc)( {...defaultExpenseDetail, expenseId: _id, type: detailType.value, 
            resourceTypeId: id, resourceTypeName: name, temporaryResourceFilterOption: temporaryFilterOption  });  

        setOpenResourceTypeFilter(false);
      }
  );

  const [openArticleOption, setOpenArticleOption] = useState<boolean>(false);
  const [currentArticle2Add, setCurrentArticle2Add] = useState<IProduct>({...defaultProduct});
  const [articleOptions, setArticleOptions] = useState<IEnumerationItem[]>([]);
  const [openArticleFilter, setOpenArticleFilter] = useState(false);
  // const basicFilterArticle = useBasicFilterProduct( 2,
  //     async (event: React.MouseEvent<unknown>, row: IProduct) => {
  //         const {id,type, description,name, serviceDurationInMinute, filterOption, lineOfBusinessCode} = row;
          
  //         if( type !== 'article') return;

  //         setCurrentArticle2Add(row);

  //         const detailType = expenseDetailTypes.find(t => t.type === refExpenseDetailType.current);
  //         if(isFalsy(detailType)) return;

  //         const options = (refEnumItems.current ?? []).filter(e => e.enumerationCode === Enum_ARTICLE_OPTION &&
  //                                                                 e.parentEnumerationItemCode === filterOption);
  //         setArticleOptions(options);

  //         if(options.length === 1) {
  //           await handleDoubleClickArticleOption(event, options[0], row);
  //         }
  //         else          
  //           setOpenArticleOption(true);

  //         // (refAppendExpenseDetails.current??emptyFunc)( {...defaultExpenseDetail, expenseId: _id, type: detailType.value, 
  //         //   articleId: id, resourceTypeName: name, articleOption: temporaryFilterOption  });  

  //         setOpenArticleFilter(false);
  //     }
  // );
 

  
  const methods = useForm<IExpense>({defaultValues:defaultExpense});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchSupplierType = watch('supplierType');
  const watchSupplierFirstName = watch('supplierFirstName');
  const watchSupplierLastName = watch('supplierLastName');
  const watchSupplierParticularSign = watch('supplierParticularSign');

  const watchSupplierCorporationName = watch('supplierCorporationName');

  const watchNetAmount = watch('netAmount');
  const watchTax = watch('tax');
  const watchAccessories = watch('accessories');
  const watchAccessoriesTax = watch('accessoriesTax');

  const refExpenseDetailIndex = useRef<number>(0);

  // const watchBase64Picture = watch('base64Picture'); , , 
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });

  type SearchPersonUsage = 'supplier';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('supplier');
  
  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const { supplierType, supplierFirstName, supplierLastName, supplierParticularSign, supplierCorporationName } = getValues();
       
        return {firstName: supplierType === 'moral' ? supplierCorporationName : supplierFirstName, 
                lastName: supplierType === 'moral' ? supplierCorporationName : supplierLastName, particularSign: supplierParticularSign}; 

        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, particularSign, corporationName,
            portable1, portable2, email1, email2} = row;
          
        setValue('supplierId', id);
        setValue('supplierType', type);
        setValue('supplierFirstName', firstName);
        setValue('supplierLastName', lastName);  
        setValue('supplierParticularSign', particularSign);  
        setValue('supplierCorporationName', corporationName);  
        setValue('supplierPortable1', portable1);   
        setValue('supplierPortable2', portable2);                    
        setValue('supplierEmail1', email1); 
        setValue('supplierEmail2', email2); 
                          
        setOpenPersonSearchFilter(false);
      }
  );

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IExpense>,Error,IExpense>(
      _id>0?updateExpense:createExpense, {   
        onSuccess: (data: IResult<IExpense>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Expense')} - ${t('Pur. Order')} - # ${data.data.id} # , N° : ${getValues().expenseNumber}` );
          
          //queryClient.invalidateQueries(['Expense',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IExpense>(['Expense', _id], () => retrieveEntity('Expense',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Expense'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_OPERATION_STATUS, Enum_EXPENSE_STATUS_PURPOSE, Enum_RESOURCE_OPTION, Enum_ARTICLE_OPTION ] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    const handleClickOpenPerson = async (event: any) => {
      setOpenPersonFilter(true);
    }

    const handleClickRemovePerson = async (event: any) => {
      setValue('supplierId', 0);
      setValue('supplierFirstName', '');
      setValue('supplierLastName', '');
      setValue('supplierPortable1', '');
      setValue('supplierPortable2', '');
      setValue('supplierEmail1', '');
      setValue('supplierEmail2', '');
    }

    const handleSupplierType = ( event: React.MouseEvent<HTMLElement>, newSupplierType: PersonType ) => {

      if(newSupplierType === null) return;
      setValue('supplierType', newSupplierType);

    };
    // const getServiceTaskCode = (row: IExpenseDetail, cellId: keyof IExpenseDetail, 
    //   opts: {value: string, name: string}[]) => {        
      
    //     return getAsOptions(refEnumItems.current ?? [],Enum_SERVICE_TASK);
    // }

    const [openExpenseDetail, setOpenExpenseDetail] = useState<boolean>(false);

    const [expenseDetailIndex, setExpenseDetailIndex] = useState<number>(-1);
    const getInputAdornmentExpenseDetail = (row: IExpenseDetail, cellId: keyof IExpenseDetail)  => ({
  
      toolTip: `${t('Details')} ...`,
      icon: MoreVertIcon,
      //iconDisable: row.resourceId > 0,
      onClickIcon: (event: any, index: number, row: IExpenseDetail ) => {
        setExpenseDetailIndex(index);
        setOpenExpenseDetail(true);
      }  
    })

    const getExpenseDetailTypeList = (row: IExpenseDetail, cellId: keyof IExpenseDetail, 
      opts: {value: string, name: string}[]) => {        
      
      return expenseDetailTypes;
    }

    const cellEditableExpenseDetail = (row: IExpenseDetail, cellId: keyof IExpenseDetail) => {
      const {resourceId} = row;
      
      if(cellId === 'resourceDescription' && resourceId > 0) 
        return false;

      return true;
    }

    const [headExpenseDetailCells, setHeadExpenseDetailCells]  = useState<HeadCell<IExpenseDetail>[]>([]);
    useEffect(() => {
      setHeadExpenseDetailCells([
            
        {id:'type', label : t('Type'),  display: true, type: 'string', width: 20, getOptions: getExpenseDetailTypeList },
        
        {id:'resourceTypeName', label : t('Resource type'),  display: true, type: 'string', width: 35, },
  
        {id:'description', label : t('Description'),  display: true, type: 'string', width: 35, isEditable: cellEditableExpenseDetail,
          getInputAdornment: getInputAdornmentExpenseDetail},
        {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 10, 
            isEditable: cellEditableExpenseDetail, decimalScale: 2 },           
      ]);
    }, [t,i18n]);
    

    const refAppendExpenseDetails = useRef<(value: Partial<FieldArray<IExpense>> | Partial<FieldArray<IExpense>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateExpenseDetail = useRef<(index: number,value: Partial<FieldArray<IExpense>> ) => void>(null);
    const refRemoveExpenseDetail = useRef<(index: number ) => void>(null);


    const cellEditableExpenseInfo = (row: IExpenseInfo, cellId: keyof IExpenseInfo) => { 
      return true;
  }

  const [headExpenseInfoCells, setHeadExpenseInfoCells]  = useState<HeadCell<IExpenseInfo>[]>([]);
  useEffect(() => {
    setHeadExpenseInfoCells([   
      {id:'description', label : t('Description'),  display: true, type: 'string', width: 60, isEditable: cellEditableExpenseInfo},   
      {id:'designation', label : t('Designation'),  display: true, type: 'string', width: 40, isEditable: cellEditableExpenseInfo}, 
    ]);
  }, [t,i18n]);
      

  const refAppendExpenseInfos = useRef<(value: Partial<FieldArray<IExpense>> | Partial<FieldArray<IExpense>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateExpenseInfo = useRef<(index: number,value: Partial<FieldArray<IExpense>> ) => void>(null);
  const refRemoveExpenseInfo = useRef<(index: number ) => void>(null);
  
  const expenseInfoRowActionIcon = ( row: IExpenseInfo) : ActionIconTableRow<IExpense,IExpenseInfo> => {
      
    
    const res: ActionIconTableRow<IExpense,IExpenseInfo> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: (getValues().status === '95'),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IExpenseInfo) => {
        
        (refRemoveExpenseInfo.current??emptyFunc)(index);                        
      }
    }
    return res;
}


    const cellEditableExpenseDetailInfo = (row: IExpenseDetailInfo, cellId: keyof IExpenseDetailInfo) => { 
      return true;
  }

  const [headExpenseDetailInfoCells, setHeadExpenseDetailInfoCells]  = useState<HeadCell<IExpenseDetailInfo>[]>([]);
  useEffect(() => {
    setHeadExpenseDetailInfoCells([   
      {id:'description', label : t('Description'),  display: true, type: 'string', width: 60, isEditable: cellEditableExpenseDetailInfo},   
      {id:'designation', label : t('Designation'),  display: true, type: 'string', width: 40, isEditable: cellEditableExpenseDetailInfo}, 
    ]);
  }, [t,i18n]);
      

  const refAppendExpenseDetailInfos = useRef<(value: Partial<FieldArray<IExpense>> | Partial<FieldArray<IExpense>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateExpenseDetailInfo = useRef<(index: number,value: Partial<FieldArray<IExpense>> ) => void>(null);
  const refRemoveExpenseDetailInfo = useRef<(index: number ) => void>(null);
  
  const expenseDetailInfoRowActionIcon = ( row: IExpenseDetailInfo) : ActionIconTableRow<IExpense,IExpenseDetailInfo> => {
      
    
    const res: ActionIconTableRow<IExpense,IExpenseDetailInfo> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: (getValues().status === '95'),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IExpenseDetailInfo) => {
        
        (refRemoveExpenseDetailInfo.current??emptyFunc)(index);                        
      }
    }
    return res;
}
      

    const [openExpenseDetailChoice, setOpenExpenseDetailChoice] = useState<boolean>(false);
    const handleAddExpenseDetails = (event: any) => {
      //(refAppendExpenseDetails.current??emptyFunc)({id: 0, resourceTypeId: _id, taskCode: '', part: 0,  });
      setOpenExpenseDetailChoice(true);
    }

    const [openExpenseInfo, setOpenExpenseInfo] = useState<boolean>(false);
    const handleLinkClickExpenseInfo = (event: any) => {
      setOpenExpenseInfo(true);
    }

    const handleAddExpenseInfos = (event: any) => {      
      if(getValues().proxy.expenseInfos.some( x => isFalsy(x.description) && isFalsy(x.designation))) return;

      (refAppendExpenseInfos.current??emptyFunc)({id: 0, expenseId: 0, description: '', designation: '' });   
    }

    const handleAddExpenseDetailInfos = (event: any) => {     
      const expenseDetail = getValues().expenseDetails.at(expenseDetailIndex);
      if(isFalsy(expenseDetail)) return;

      if(expenseDetail.expenseDetailInfos.some( x => isFalsy(x.description) && isFalsy(x.designation))) return;

      (refAppendExpenseDetailInfos.current??emptyFunc)({id: 0, expenseDetailId: expenseDetail.id, description: '', designation: '' });   
    }

    const handleExpenseDetailChoiceClick = async (expenseChoice: {type: number, value: ExpenseDetailType, name: string, resourceType: 1|2|3 }) => {
      const {type, value} = expenseChoice;

      refExpenseDetailType.current = type;

      if([1,2,4].includes(type))
        setOpenResourceTypeFilter(true);
      else if(type === 3)
        setOpenResourceFilter(true);
      else if(type === 7) {
        setOpenArticleFilter(true);
      } else
        (refAppendExpenseDetails.current??emptyFunc)( {...defaultExpenseDetail, expenseId: _id, type: value, 
          resourceTypeId: 0, resourceTypeName: ''  });

      setOpenExpenseDetailChoice(false);
    }


    const handleDoubleClickArticleOption = async (event: React.MouseEvent<unknown>, row: IEnumerationItem, currentArticle?: IProduct) => {
                       
      
      // const articleId = isFalsy(currentArticle)? currentArticle2Add.id : currentArticle.id;
      // const articleName = isFalsy(currentArticle)? currentArticle2Add.name : currentArticle.name;
      // const articleFilterOption = isFalsy(currentArticle)? currentArticle2Add.filterOption : currentArticle.filterOption;
      // const articleDescription = isFalsy(currentArticle)? currentArticle2Add.description : currentArticle.description;

      // if(getValues().expenseDetails.some(x => x.type === 'article' 
      //                   && x.articleId === articleId && x.articleOption === row.code)) {
      //   setOpenArticleOption(false);
      //   return;
      // }

      // (refAppendExpenseDetails.current??emptyFunc)({id: 0, expenseId: _id, articleId, type: 'article',
      //   articleOption: row.code, resourceTypeName: `${articleName} - ${row.code}`,
      //   expenseReference: '', expenseDescription: '', expenseSupplierFullName: '',
      //   articleFilterOption,
      //   description: articleDescription, amount: 0,  });    

      // setOpenArticleOption(false);
}

    const handleAddNewRessources = (event: any) => {
      (refAppendExpenseDetails.current??emptyFunc)({id: 0, expenseId: _id, resourceId: 0,
        resourceDescription: '', resourceTypeId: 0, resourceTypeName: '',
        expenseReference: '', expenseDescription: '', expenseSupplierFullName: '',
        description: '', amount: 0,  });
    }

    const expenseDetailRowActionIcon = ( tarificationMatrix: IExpenseDetail) : ActionIconTableRow<IExpense,IExpenseDetail> => {
  
      const res: ActionIconTableRow<IExpense,IExpenseDetail> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IExpenseDetail) => {
          
           (refRemoveExpenseDetail.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const debouncedTemporaryResourceOrArticleChange = useRef(
    debounce( async () => {      
        const index = refExpenseDetailIndex.current;
        const expenseDetail = getValues().expenseDetails.at(index);
        if(isFalsy(expenseDetail)) return;

        const {temporaryResourceUnityPrice, temporaryResourceUnityTax, temporaryResourceQuantity,
          articleUnityPrice, articleUnityTax, articleQuantity, type} = expenseDetail!;

        const netAmount = type === 'article' ? articleUnityPrice*articleQuantity : temporaryResourceUnityPrice*temporaryResourceQuantity;
        const tax = type === 'article' ? articleUnityTax*articleQuantity : temporaryResourceUnityTax*temporaryResourceQuantity;
        const amount = netAmount + tax;

        (refUpdateExpenseDetail.current??emptyFunc)(index, {...expenseDetail!, 
          netAmount, tax, amount });        
          
        setTotalAmount();
    }, 1000)
  ).current;

  const debouncedNetAmountChange = useRef(
    debounce( async () => {      
      const index = refExpenseDetailIndex.current;
      const expenseDetail = getValues().expenseDetails.at(index);
      if(isFalsy(expenseDetail)) return;

      const {netAmount, tax} = expenseDetail!;
      

      (refUpdateExpenseDetail.current??emptyFunc)(index, {...expenseDetail!, amount: netAmount + tax }); 
      
      setTotalAmount();
    }, 1000)
  ).current;

  useEffect(() => {
    const subscription = watch( async (value, { name, type }) => {

      if( name?.startsWith('expenseDetails') && 
              (name?.endsWith('temporaryResourceUnityPrice') || name?.endsWith('temporaryResourceUnityTax') || name?.endsWith('temporaryResourceQuantity') ||
              name?.endsWith('articleUnityPrice') || name?.endsWith('articleUnityTax') || name?.endsWith('articleQuantity')) ) {

        
        // const index = refExpenseDetailIndex.current;
        // const expenseDetail = getValues().expenseDetails.at(index);
        // if(isFalsy(expenseDetail)) return;

        // const {temporaryResourceUnityPrice, temporaryResourceQuantity, tax} = expenseDetail!;

        // const tmp = temporaryResourceUnityPrice*temporaryResourceQuantity;
        // (refUpdateExpenseDetail.current??emptyFunc)(index, {...expenseDetail!, 
        //   netAmount: tmp, amount: tmp + tax });        
          
        // setTotalAmount();
        debouncedTemporaryResourceOrArticleChange();
      } else if( name?.startsWith('expenseDetails') && (name?.endsWith('netAmount') || name?.endsWith('tax')) ) {
        // const index = refExpenseDetailIndex.current;
        // const expenseDetail = getValues().expenseDetails.at(index);
        // if(isFalsy(expenseDetail)) return;

        // const {netAmount, tax} = expenseDetail!;

        // (refUpdateExpenseDetail.current??emptyFunc)(index, {...expenseDetail!, amount: netAmount + tax }); 
        
        // setTotalAmount();
        debouncedNetAmountChange();
      }
    });  

    return () => subscription.unsubscribe();
  }, [watch]);

  

  const setTotalAmount = () => {
    const {accessories, accessoriesTax} = getValues();
    const sumNetAmount = sum( getValues().expenseDetails.map( ({netAmount}) => netAmount ) );
    const sumTaxAmount = sum( getValues().expenseDetails.map( ({tax}) => tax ) );
    const sumAmount = sum( getValues().expenseDetails.map( ({amount}) => amount ) );

    setValue('netAmount', sumNetAmount);
    setValue('tax', sumTaxAmount);
    setValue('amount', sumNetAmount + sumTaxAmount + accessories + accessoriesTax);
  }

  const debouncedTotalAmountChange = useRef(
    debounce( async () => {      
      const {netAmount, tax, accessories, accessoriesTax} = getValues();            

      setValue('amount', netAmount + tax + accessories + accessoriesTax);
    }, 1000)
  ).current;

  const debouncedSupplierChange = useRef(
    debounce( async () => {      
      const { supplierType, supplierId, supplierFirstName, supplierLastName, supplierParticularSign, supplierCorporationName } = getValues();

      if(supplierId>0) return;

      if( supplierType === 'physical' &&  
          (isFalsy(supplierFirstName) || supplierFirstName === '') && (isFalsy(supplierLastName) || supplierLastName === '' )) return;

      if( supplierType === 'moral' &&  (isFalsy(supplierCorporationName) || supplierCorporationName === '') ) return;

      const fn = (supplierType === 'moral') ? supplierCorporationName : supplierFirstName;
      const ln = (supplierType === 'moral') ? supplierCorporationName : supplierLastName;

      const count = await getPersonsSearchCount(fn, ln, supplierParticularSign);

      if(!isFalsy(refSupplierSnackbarId.current)) closeSnackbar(refSupplierSnackbarId.current!);
      
      if(count > 0) 
        refSupplierSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch('supplier') } );             

    }, 1500)
  ).current;

  const refSupplierSnackbarId = useRef<SnackbarKey>();
  
  const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                setCurrentSearchPersonUsage(searchPersonUsage);                  
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

useEffect( () => {     
  debouncedTotalAmountChange();
}, [watchAccessories, watchAccessoriesTax, watchNetAmount, watchTax, debouncedTotalAmountChange]);


useEffect( () => {     
  setCurrentSearchPersonUsage('supplier');         
  debouncedSupplierChange();      
}, [watchSupplierFirstName, watchSupplierLastName, watchSupplierParticularSign, watchSupplierCorporationName ,debouncedSupplierChange]);

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])


  useEffect( () => {   
    refExpenseDetailIndex.current = expenseDetailIndex;
  
  }, [expenseDetailIndex]);


      useEffect( () => {        
        setCurrentFormNameAtom(`${t('Expense')} - ${t('Pur. Order')}` );
        setCurrentBasicTextFilterProps(basicFilterExpense);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
          setCurrentFormNameAtom(`${t('Expense')} - ${t('Pur. Order')} ${_id>0? `# ${_id} #`:''}, N° : ${getValues().expenseNumber}` ); 
            
            if(_id > 0)
              retrieveData('Expense',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultExpense);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('Expense', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 

        if(data.reference.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( isFalsy(data.supplierFirstName) && isFalsy(data.supplierLastName) && isFalsy(data.supplierCorporationName) ) {
          enqueueSnackbar( t('The name supplier is required'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          setIsSaveLoading(false);
          return;
        }
              
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Expense', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Expense',_id]);        
      await retrieveData('Expense',_id, refetch);        
      reset(_data);          
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              
                              <TextField sx={{width:'calc(50% - 8px)'}} id="name" label={t('Reference')} {...register('reference')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Purchase order ?')}`}
                                    control={
                                    <Controller
                                        name='isPurchaseOrder'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} /> 
                              <Controller control={control}
                                name='expenseDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Date')} 
                                    onChange={onChange}                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                          </Box>                                                                                                           
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(80% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                                              
                            <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                          </Box>
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Identity of supplier'))} `}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <ToggleButtonGroup value={watchSupplierType} exclusive key={watchSupplierType} size="small" onChange={handleSupplierType} aria-label="text alignment" fullWidth >
                              <ToggleButton value="moral" aria-label="centered">
                                {t('Moral person')}
                              </ToggleButton>
                              <ToggleButton value="physical" aria-label="centered">
                                {t('Physical person')}
                              </ToggleButton>    
                            </ToggleButtonGroup>                                                             
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().supplierId}`}>                              
                            <TextField sx={{width:'calc(20% - 8px)'}} id="personId" label={t('Supplier Id')} 
                              {...register('supplierId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">   
                                    { (isFalsy(getValues().supplierId) ||  getValues().supplierId<=0) ?
                                    <IconButton color="primary" onClick={handleClickOpenPerson}>
                                      <ArrowDropDownCircleIcon />
                                    </IconButton> : 
                                    <IconButton color="primary" onClick={handleClickRemovePerson}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  }                                                                                                
                                </InputAdornment>
                              ) 
                            }} />
                            {watchSupplierType === 'physical' && <TextField sx={{width:'calc(30% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                              {...register(lg.startsWith('fr')?'supplierLastName':'supplierFirstName')}  inputProps={ {readOnly: (getValues().supplierId>0) }} /> }   
                            {watchSupplierType === 'physical' && <TextField sx={{width:'calc(30% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                              {...register(lg.startsWith('fr')?'supplierFirstName':'supplierLastName')} inputProps={ {readOnly: (getValues().supplierId>0) }} /> } 
                            {watchSupplierType === 'physical' && <TextField sx={{width:'calc(20% - 8px)'}} id="ln" label={t('Particular sign')} 
                              {...register('supplierParticularSign')} inputProps={ {readOnly: (getValues().supplierId>0) }} /> }
                            {watchSupplierType === 'moral' && <TextField sx={{width:'calc(80% - 8px)'}} id="cn" label={t('Name')} 
                              {...register('supplierCorporationName')} inputProps={ {readOnly: (getValues().supplierId>0) }} /> }                              
                            { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                    <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                            </FormDialog> }
                          </Box>   
                          <Box sx={{ mt: 1, width: '100%' }} > 
                            <TextField sx={{width:'calc(15% - 8px)'}} id="supplierPortable1" label={`${t('Portable')} 1`} 
                              {...register('supplierPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none', }, readOnly: (getValues().supplierId>0) } }  />
                            <TextField sx={{width:'calc(15% - 8px)'}} id="supplierPortable2" label={`${t('Portable')} 2`} 
                              {...register('supplierPortable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }/>   
                            <TextField sx={{width:'calc(35% - 8px)'}} id="supplierEmail1" label={`${t('Email')} 1`} 
                              {...register('supplierEmail1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }/>  
                            <TextField sx={{width:'calc(35% - 8px)'}} id="supplierEmail2" label={`${t('Email')} 2`} 
                              {...register('supplierEmail2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: (getValues().supplierId>0) } }/>                                                    

                            { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                    <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                            </FormDialog> } 
                        </Box>  
                        <Box sx={{ mt: 0.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t(('Amounts related to expense'))} `}
                          </Typography>                                                       
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller                                
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                              return (
                                <NumberFormat      
                                  decimalScale={3}
                                  label={`${t('Net amount')}`} sx={{width:'calc(20% - 8px)'}}
                                  allowEmptyFormatting={false}
                                  control={control}             
                                                
                                  onValueChange={ (v) => onChange(v.floatValue) }              
                                  //fixedDecimalScale={true} 
                                  thousandSeparator={true}
                                  //{...field}
                                  customInput={TextFieldRight}
                                  defaultValue={value}
                                  value={value}
                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                />
                              );
                            }}
                            name={`netAmount`}
                            //name={`${fieldsName}.${idx}.${cell.id}`}
                            control={control}
                          />
                          <Controller                                
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                              return (
                                <NumberFormat      
                                  decimalScale={3}
                                  label={`${t('Tax')}`} sx={{width:'calc(20% - 8px)'}}
                                  allowEmptyFormatting={false}
                                  control={control}             
                                  onValueChange={ (v) => onChange(v.floatValue) }              
                                  //fixedDecimalScale={true} 
                                  thousandSeparator={true}
                                  //{...field}
                                  customInput={TextFieldRight}
                                  defaultValue={value}
                                  value={value}
                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                />
                              );
                            }}
                            name={`tax`}
                            //name={`${fieldsName}.${idx}.${cell.id}`}
                            control={control}
                          />
                          <Controller                                
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                              return (
                                <NumberFormat      
                                  decimalScale={3}
                                  label={`${t('Accessories')}`} sx={{width:'calc(20% - 8px)'}}
                                  allowEmptyFormatting={false}
                                  control={control}             
                                                
                                  onValueChange={ (v) => onChange(v.floatValue) }              
                                  //fixedDecimalScale={true} 
                                  thousandSeparator={true}
                                  //{...field}
                                  customInput={TextFieldRight}
                                  defaultValue={value}
                                  value={value}
                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                />
                              );
                            }}
                            name={`accessories`}
                            //name={`${fieldsName}.${idx}.${cell.id}`}
                            control={control}
                          />
                          <Controller                                
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                              return (
                                <NumberFormat      
                                  decimalScale={3}
                                  label={`${t('Accessories')} - ${t('Tax')}`} sx={{width:'calc(20% - 8px)'}}
                                  allowEmptyFormatting={false}
                                  control={control}             
                                                
                                  onValueChange={ (v) => onChange(v.floatValue) }              
                                  //fixedDecimalScale={true} 
                                  thousandSeparator={true}
                                  //{...field}
                                  customInput={TextFieldRight}
                                  defaultValue={value}
                                  value={value}
                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                />
                              );
                            }}
                            name={`accessoriesTax`}
                            //name={`${fieldsName}.${idx}.${cell.id}`}
                            control={control}
                          />
                          <Controller                                
                            render={({ field: {onChange, onBlur, name, value, ref} }) => {
                              return (
                                <NumberFormat      
                                  decimalScale={3}
                                  label={`${t('Amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                  allowEmptyFormatting={false}
                                  control={control}             
                                                
                                  onValueChange={ (v) => onChange(v.floatValue) }              
                                  //fixedDecimalScale={true} 
                                  thousandSeparator={true}
                                  //{...field}
                                  customInput={TextFieldRight}
                                  defaultValue={value}
                                  value={value}
                                  //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                />
                              );
                            }}
                            name={`amount`}
                            //name={`${fieldsName}.${idx}.${cell.id}`}
                            control={control}
                          />
                        </Box>
                        <Box sx={{ mt: 0.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t(('Status of expense'))} `}
                          </Typography>                                                       
                        </Box>          
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker readOnly                    
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                          <Controller name='status' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status')} inputProps={ {readOnly: true}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                          <Controller control={control}
                            name='statusDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Status date')} 
                                onChange={onChange} disableOpenPicker readOnly                    
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                              /> )}
                          />
                          <Controller name='statusPurpose' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_EXPENSE_STATUS_PURPOSE ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                        </Box>
                        <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 1.5, width: '100%' }} > 
                          <Button variant="outlined" onClick={handleLinkClickExpenseInfo} sx={{mb:2, ml: 1 }}>
                             {t('More infos')} ...                             
                          </Button>     
                          { openExpenseInfo && <FormDialog open={openExpenseInfo} maxWidth='md'
                              okText='' cancelText='' title={t('Informations on expense')} onCancel={()=> {}} 
                              onClose={()=> {setOpenExpenseInfo(false);}} onOk={()=> {setOpenExpenseInfo(false);}}  >
                              <Stack flexDirection='column'>
                                
                                <Box sx={{ mt: 1, width: '100%' }} key={`details infos  `}  >
                                  <ArrayFieldTableEx<IExpense,IExpenseInfo,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headExpenseInfoCells} rowsPathName={`proxy.expenseInfos`}
                                    title={t('Informations')} rowActionIcon={expenseInfoRowActionIcon}  
                                    //onRowSelected={handleBillingDetailSelected}
                                    //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                        
                                    refAppend={refAppendExpenseInfos as MutableRefObject<(value: Partial<FieldArray<IExpense>> | Partial<FieldArray<IExpense>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateExpenseInfo as MutableRefObject<(index: number,value: Partial<FieldArray<IExpense>>) => void>}
                                    refRemove={refRemoveExpenseInfo as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={                                
                                      [
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddExpenseInfos ,icon: AddCircleIcon }, 
                                      ]
                                    } 
                                    canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                </Box>                                
                              </Stack>
                            </FormDialog>
                          }                                    
                        </Box>
                            
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <ArrayFieldTableEx<IExpense,IExpenseDetail,'id'> 
                               key={`Details - ${getValues().expenseDetails}`}
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headExpenseDetailCells} rowsPathName='expenseDetails' 
                              title={t('Expense details')} rowActionIcon={expenseDetailRowActionIcon}  
                              //onRowSelected={handleRoleEntitySelected}
                                                  
                              refAppend={refAppendExpenseDetails as MutableRefObject<(value: Partial<FieldArray<IExpense>> | Partial<FieldArray<IExpense>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateExpenseDetail as MutableRefObject<(index: number,value: Partial<FieldArray<IExpense>>) => void>}
                              refRemove={refRemoveExpenseDetail as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[

                                //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddNewRessources ,icon: AddCircleIcon,  },
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddExpenseDetails ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          /> 
                          { openExpenseDetailChoice && <FormDialog open={openExpenseDetailChoice} maxWidth='xs' height='50vh'
                                  okText='' cancelText='' title='' onCancel={()=> {}} 
                                  onClose={()=> {setOpenExpenseDetailChoice(false);}} onOk={()=> {setOpenExpenseDetailChoice(false);}}  >
                                      <BasicButtonList<{type: number, value: ExpenseDetailType, name: string, resourceType: 1|2|3}> 
                                          items={expenseDetailTypes} 
                                          icon={BoltIcon} 
                                          onItemClick={handleExpenseDetailChoiceClick} 
                                          valueKey={'value'} displayKey={'name'} title={t('Type of expense')} />
                          </FormDialog> }                         
                          { openResourceFilter && <FormDialog open={openResourceFilter} maxWidth='md' 
                                  okText='' cancelText='' title={t('Resource')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenResourceFilter(false);}} onOk={()=> {setOpenResourceFilter(false);}}  >
                                      <BasicTextFilterForm<IResource> {...basicFilterResource } />
                              </FormDialog> }
                          { openResourceTypeFilter && <FormDialog open={openResourceTypeFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Resource type')} onCancel={()=> {}} 
                              onClose={()=> {setOpenResourceTypeFilter(false);}} onOk={()=> {setOpenResourceTypeFilter(false);}}  >
                                  <BasicTextFilterForm<IResourceType> {...basicFilterResourceType } />
                          </FormDialog> }
                          {/* { openArticleFilter && <FormDialog open={openArticleFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Article')} onCancel={()=> {}} 
                              onClose={()=> {setOpenArticleFilter(false);}} onOk={()=> {setOpenArticleFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterArticle } />
                          </FormDialog> } */}
                          { openArticleOption && <FormDialog open={openArticleOption} maxWidth='md'
                            okText={''} cancelText={''} title={`${t('Article')} : ${t('Option')} `} onCancel={()=> {setOpenArticleOption(false);}} 
                            onClose={()=> {setOpenArticleOption(false);}} onOk={()=> {setOpenArticleOption(false);}}  >
                                <Stack flexDirection='column'>
                                  <Box sx={{ mt: 1, width: '100%' }} >
                                    <EnhancedTable<IEnumerationItem> rows={articleOptions} 
                                      headCells={[            
                                        //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                        {id:'code', label : t('Article'),  display: true, type: 'string', width: 15},
                                        
                                        {id:'name', label : `${t('_Deli')}(+)`,  display: true, type: 'string', width: 40},   
                                        {id:'description', label : `${t('_Mvt')}(+)`,  display: true, type: 'string', width: 45},  
                                        
                                      ]} 
                                      title={`${t(`Options`)} ... ${t('Article')}`} objKey='code' 
                                      stateSelected={undefined} 
                                      onRowSelected={undefined} rowCheckedMode='single'
                                      onRowCheckedSelectChange={undefined} order='asc' orderBy='name'
                                      onRowDoubleClick={handleDoubleClickArticleOption} 
                                      rowActionIcon={undefined}
                                      toolbarActions={[
                                        // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                      ]}
                                    />
                                  </Box>
                                </Stack>
                            </FormDialog> }
                          {expenseDetailIndex >= 0 && openExpenseDetail && <FormDialog open={openExpenseDetail} maxWidth='md' height='80vh'
                                  okText='' cancelText='' 
                                  title={`${getValues().expenseDetails[expenseDetailIndex].resourceTypeName} - ${getValues().expenseDetails[expenseDetailIndex].description}`} 
                                  onCancel={()=> {}} 
                                  onClose={()=> {setOpenExpenseDetail(false);}} onOk={()=> {setOpenExpenseDetail(false);}}  >
                            <Stack flexDirection='column'>
                            
                              { ['production','article'].includes(getValues().expenseDetails[expenseDetailIndex].type)  &&
                              <Box sx={{ mt: 0.25, width: '100%' }} > 
                                <Controller                                   
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(33% - 8px)'}} id="status"
                                        label={t('Option')} 
                                        inputProps={ {readOnly: (getValues().expenseDetails[expenseDetailIndex].type === 'article') }} >
                                        {enumItems && enumItems.filter( e => 
                                          (getValues().expenseDetails[expenseDetailIndex].type === 'production' && 
                                           e.parentEnumerationItemCode === getValues().expenseDetails[expenseDetailIndex].temporaryResourceFilterOption
                                            && e.enumerationCode === Enum_RESOURCE_OPTION) || 
                                            (getValues().expenseDetails[expenseDetailIndex].type === 'article' && 
                                            e.parentEnumerationItemCode === getValues().expenseDetails[expenseDetailIndex].articleFilterOption
                                             && e.enumerationCode === Enum_ARTICLE_OPTION) ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                    name={ getValues().expenseDetails[expenseDetailIndex].type === 'production' ?
                                        `expenseDetails.${expenseDetailIndex}.temporaryResourceOption`:
                                        `expenseDetails.${expenseDetailIndex}.articleOption`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        //decimalScale={3}
                                        label={`${t('Quantity')}`} sx={{width:'calc(13% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                                      
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={ getValues().expenseDetails[expenseDetailIndex].type === 'production' ?
                                    `expenseDetails.${expenseDetailIndex}.temporaryResourceQuantity`:
                                    `expenseDetails.${expenseDetailIndex}.articleQuantity`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                /> 
                                
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Unity price')}`} sx={{width:'calc(27% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                                      
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  //name={`expenseDetails.${expenseDetailIndex}.temporaryResourceUnityPrice`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  name={ getValues().expenseDetails[expenseDetailIndex].type === 'production' ?
                                    `expenseDetails.${expenseDetailIndex}.temporaryResourceUnityPrice`:
                                    `expenseDetails.${expenseDetailIndex}.articleUnityPrice`}
                                  control={control}
                                />
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        //decimalScale={3}
                                        label={`${t('Unity')} - ${t('Tax')}`} sx={{width:'calc(27% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                                      
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  //name={`expenseDetails.${expenseDetailIndex}.temporaryResourceUnityTax`}
                                  name={ getValues().expenseDetails[expenseDetailIndex].type === 'production' ?
                                    `expenseDetails.${expenseDetailIndex}.temporaryResourceUnityTax`:
                                    `expenseDetails.${expenseDetailIndex}.articleUnityTax`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />                                                 
                              </Box> }
                              <Box sx={{ mt: 0.25, width: '100%' }} > 
                                <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                  {`... ${t('Amounts related to expense')}  ...`}
                                </Typography>                                                       
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} key={`box - ${getValues().expenseDetails[expenseDetailIndex].netAmount} - ${getValues().expenseDetails[expenseDetailIndex].amount}`} >
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Net amount')}`} sx={{width:'calc(33% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                                      
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        disabled={['production','article'].includes(getValues().expenseDetails[expenseDetailIndex].type)}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={`expenseDetails.${expenseDetailIndex}.netAmount`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Tax')}`} sx={{width:'calc(33% - 8px)'}}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        disabled={['production','article'].includes(getValues().expenseDetails[expenseDetailIndex].type)}
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={`expenseDetails.${expenseDetailIndex}.tax`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />                                
                                <Controller                                
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat      
                                        decimalScale={3}
                                        label={`${t('Amount')}`} sx={{width:'calc(34% - 8px)'}} disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}             
                                                      
                                        onValueChange={ (v) => onChange(v.floatValue) }              
                                        //fixedDecimalScale={true} 
                                        thousandSeparator={true}
                                        //{...field}
                                        customInput={TextFieldRight}
                                        defaultValue={value}
                                        value={value}
                                        
                                        //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                      />
                                    );
                                  }}
                                  name={`expenseDetails.${expenseDetailIndex}.amount`}
                                  //name={`${fieldsName}.${idx}.${cell.id}`}
                                  control={control}
                                />
                              </Box>
                              <Box sx={{ mt: 0.25, width: '100%' }} > 
                                <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                  {`... ${t('More infos')}  ...`}
                                </Typography>                                                       
                              </Box>
                              <Box sx={{ mt: 1, width: '100%' }} key={`details infos  `}  >
                                <ArrayFieldTableEx<IExpense,IExpenseDetailInfo,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headExpenseDetailInfoCells} rowsPathName={`expenseDetails.${expenseDetailIndex}.expenseDetailInfos`}
                                  title={t('Informations')} rowActionIcon={expenseDetailInfoRowActionIcon}  
                                  //onRowSelected={handleBillingDetailSelected}
                                  //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                      
                                  refAppend={refAppendExpenseDetailInfos as MutableRefObject<(value: Partial<FieldArray<IExpense>> | Partial<FieldArray<IExpense>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateExpenseDetailInfo as MutableRefObject<(index: number,value: Partial<FieldArray<IExpense>>) => void>}
                                  refRemove={refRemoveExpenseDetailInfo as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={                                
                                    [
                                      { toolTip: `${t('Add')}...`, onClickIcon: handleAddExpenseDetailInfos ,icon: AddCircleIcon }, 
                                    ]
                                  } 
                                  canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                />
                              </Box>

                            </Stack>         
                          </FormDialog> }
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

