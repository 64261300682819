
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useAppreciationService, { useBasicFilterAppreciation } from './services/Appreciation';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IAppreciation, defaultAppreciation } from './models/Appreciation';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { Enum_APPRECIATION_SYSTEM, IEnumerationItem } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const AppreciationForm: FC<IAppreciation> = (props: IAppreciation = defaultAppreciation) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();
  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createAppreciation, updateAppreciation } = useAppreciationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterAppreciation = useBasicFilterAppreciation( 
    (event: React.MouseEvent<unknown>, row: IAppreciation) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  

  const methods = useForm<IAppreciation>({defaultValues:defaultAppreciation});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchAppreciationSystemCode = watch('appreciationSystemCode');
  

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IAppreciation>,Error,IAppreciation>(
      _id>0?updateAppreciation:createAppreciation, {   
        onSuccess: (data: IResult<IAppreciation>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          setCurrentFormNameAtom(`${t('Appreciation')} : # ${data.data.id} # ` );
          queryClient.invalidateQueries(['Appreciation',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IAppreciation>(['Appreciation', _id], () => retrieveEntity('Appreciation',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'Appreciation'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_APPRECIATION_SYSTEM ] ));

      
      const refEnumItems = useRef<IEnumerationItem[]>();    
      useEffect( () => {   
          refEnumItems.current = enumItems;
        
      }, [enumItems]);

      useEffect( () => {        
        setCurrentFormNameAtom(t('Appreciation'));
        setCurrentBasicTextFilterProps(basicFilterAppreciation);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Appreciation',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            setCurrentFormNameAtom(`${t('Appreciation')} : # ${_data.id} # ` );
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultAppreciation);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Appreciation', _id)) {
          setIsSaveLoading(false);
             return;
        }
          
          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Appreciation', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                
                                <Controller name='appreciationSystemCode' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="appreciationSystemCode"
                                        label={t('Appreciation system')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_APPRECIATION_SYSTEM, 
                                                                  t('Appreciation system'), t('Appreciation system'))}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchAppreciationSystemCode) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_APPRECIATION_SYSTEM, t('Appreciation system'), t('Appreciation system'),
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_APPRECIATION_SYSTEM && x.code === watchAppreciationSystemCode)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_APPRECIATION_SYSTEM ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <Controller                                                   
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat    
                                        label={`${t('Min mark')}`} sx={{width:'calc(15% - 8px)'}} 
                                        allowEmptyFormatting={false} 
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={4}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}
                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name={`minMark`}
                                  control={control}
                              />
                              <Controller                                                   
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={`${t('Max mark')}`} sx={{width:'calc(15% - 8px)'}} 
                                      allowEmptyFormatting={false} 
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={4}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`maxMark`}
                                control={control}
                              />
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Active ?')}`}
                                    control={
                                    <Controller
                                        name='isActive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                
                                                                                      
                            </Box>   
                                                                               
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(35% - 8px)'}} id="name" 
                                  label={t('Name')} {...register('name')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="shortName" 
                                  label={t('Short name')} {...register('shortName')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>     
                              <TextField sx={{width:'calc(35% - 8px)'}} id="altName" 
                                  label={`Alt : ${t('Name')}`} {...register('altName')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="altShortName" 
                                  label={`Alt : ${t('Short name')}`} {...register('altShortName')} 
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                            
                              
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} /> 
                            </Box>
                            { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          
                          
                            
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          
                        </Box>
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

