

export interface IClassTemplate {
    id: number,
    name: string,
    description: string,

    studyCycle: string,
    studyLevel: string,
    level: number,
    studyLanguage: string,
    
  }


  export const defaultClassTemplate : IClassTemplate = {
    id: 0,
    name: '',    
    description: '',

    studyCycle: '',
    studyLevel: '',
    level: 1,
    studyLanguage: '',
    
  }

  export interface IClassTemplateSearch {
    studyLevel: string,
    studyLanguage: string,

    name: string,
    description: string
  }