
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IDashboardTellerDayClosing, ITellerDayClosing, ITellerDayClosingSearch } from "../models/TellerDayClosing";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';
import { addMonths, addDays } from 'date-fns';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createTellerDayClosing = async (tellerDayClosing: ITellerDayClosing)  =>       
        await (await axios.post('/api/finance/tellerDayClosing/create', tellerDayClosing)).data;       
        
    const updateTellerDayClosing = async (tellerDayClosing: ITellerDayClosing)  =>       
        await (await axios.post('/api/finance/tellerDayClosing/update', tellerDayClosing)).data; 
    
    const getTellerDayClosing = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/tellerDayClosing/get-tellerDayClosing/${id}`));
      return await data;
    }
   

    const getTellerDayClosings = async (criteria: ITellerDayClosingSearch, pagination?: IPagination) : Promise<ITellerDayClosing[]> => {
      
      const {startDate, endDate, closingNote, closingWarning} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/tellerDayClosing/get-tellerDayClosings?closingNote=${closingNote}` +
        `&closingWarning=${closingWarning}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getNewTellerDayClosing = async () : Promise<ITellerDayClosing> => {
      const {data} = (await axios.get(`/api/finance/tellerDayClosing/get-new-tellerDayClosing`));
      return await data;
    }

    const getTellerDayClosingDashboard = async (tellerDayClosingId: number) : Promise<IDashboardTellerDayClosing> => {
      const {data} = (await axios.get(`/api/finance/tellerDayClosing/get-tellerDayClosing-dashboard?tellerDayClosingId=${tellerDayClosingId}`));
      return await data;
    }
      
    return {    
      createTellerDayClosing,
      updateTellerDayClosing,
      getTellerDayClosing,
      getTellerDayClosings,

      getNewTellerDayClosing,

      getTellerDayClosingDashboard
     
    } 
}

export default _;

export interface IFilterTellerDayClosingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [ITellerDayClosing[], React.Dispatch<React.SetStateAction<ITellerDayClosing[]>>],
}

const defaultFilterProductOption: IFilterTellerDayClosingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterTellerDayClosing = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITellerDayClosing) => void,
                                            filterOption?: IFilterTellerDayClosingOption  ) => {

  const { getTellerDayClosings } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headTellerDayClosingCells, setHeadTellerDayClosingCells]  = useState<HeadCell<ITellerDayClosing>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'closingNote', label : t('Note'),  display: true, type: 'string', },
    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', },
    {id:'cancelledAmount', label : t('Cancelled'),  display: true, type: 'numeric', },
    {id:'countNet', label : t('Count net'),  display: true, type: 'numeric', },
    {id:'countCancelled', label : t('Count cancelled'),  display: true, type: 'numeric', },
    {id:'countTotal', label : t('Count total'),  display: true, type: 'numeric', },
    {id:'closingDate', label : t('Date'),  display: true, type: 'date', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
   
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'closingNote', text: t('Note'), value: ''},
    {name: 'closingWarning', text: t('Warning'), value: ''},
  ]);    

  const [filteredTellerDayClosings, ] = useState<ITellerDayClosing[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ITellerDayClosing[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const closingNote = filterElements.find( elt => elt.name === 'closingNote')?.value || '';
    const closingWarning = filterElements.find( elt => elt.name === 'closingWarning')?.value || '';
           
    const arr = await getTellerDayClosings( {startDate, endDate,closingNote,closingWarning }, pagination );
    
    return arr;
  }

  const objKey: keyof ITellerDayClosing = 'id';

  return {
    title: t('Teller operation'), headCells: headTellerDayClosingCells, objKey,
    filterElements, rows: filteredTellerDayClosings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
