
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import { EventInput } from '@fullcalendar/core';

import useAxios from 'library/axios'; 
import { MutableRefObject, useState } from 'react';
import {IDashboardResource, IDashboardSchoolYear, ISchoolYear, ISchoolYearClass, ISchoolYearClassSearch, 
  ISchoolYearClassSubject, ISchoolYearClassSubjectExamSchedule, ISchoolYearClassTemplate, ISchoolYearPeriod, ISchoolYearPeriodExam, ISchoolYearSearch, 
  ISchoolYearTemplateSubject, ISchoolYearTemplateSubjectExamSchedule } from "../models/SchoolYear";

import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { IRegistrationMark } from 'features/production/models/RegistrationMark';

const _ = () => {

    const axios = useAxios(); 

    

    const createSchoolYear = async (schoolYear: ISchoolYear)  =>       
        await (await axios.post('/api/setup/schoolYear/create', schoolYear)).data;       
        
    const updateSchoolYear = async (schoolYear: ISchoolYear)  =>       
        await (await axios.post('/api/setup/schoolYear/update', schoolYear)).data; 

    const updateBasicSchoolYear = async (schoolYear: ISchoolYear)  =>       
      await (await axios.post('/api/setup/schoolYear/update-basic', schoolYear)).data; 

    const updateBillingSchoolYear = async (schoolYear: ISchoolYear)  =>       
      await (await axios.post('/api/setup/schoolYear/update-billing', schoolYear)).data; 

    const updateClassSchoolYear = async (schoolYear: ISchoolYear)  =>       
      await (await axios.post('/api/setup/schoolYear/update-class', schoolYear)).data; 
    
    const getSchoolYear = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/schoolYear/get-schoolYear/${id}`));
      return await data;
    }

    const createSchoolYearClassTemplate = async (schoolYearClassTemplate: ISchoolYearClassTemplate)  =>       
        await (await axios.post('/api/setup/schoolYearClassTemplate/create', schoolYearClassTemplate)).data;  
        
    const updateSchoolYearClassTemplate = async (schoolYearClassTemplate: ISchoolYearClassTemplate)  =>       
        await (await axios.post('/api/setup/schoolYearClassTemplate/update', schoolYearClassTemplate)).data;  

    const createSchoolYearClass = async (schoolYearClass: ISchoolYearClass)  =>       
        await (await axios.post('/api/setup/schoolYearClass/create', schoolYearClass)).data;  
        
    const updateSchoolYearClass = async (schoolYearClass: ISchoolYearClass)  =>       
        await (await axios.post('/api/setup/schoolYearClass/update', schoolYearClass)).data; 
      
    const manageSubjectExamSupervisors = async (subjectExamSupervisors: any): Promise<IResult<boolean>>  =>       
        await (await axios.post('/api/setup/schoolYearClass/manage-subject-exam-supervisors', subjectExamSupervisors)).data; 
        

    const validateSchoolYearClassSubjectExamSchedule = async (subjectExamSchedule: any) : Promise<IResult<boolean>>  =>       
      await (await axios.post('/api/setup/schoolYearClass/validate-subject-exam-schedule', subjectExamSchedule)).data; 

    const unvalidateSchoolYearClassSubjectExamSchedule = async (subjectExamSchedule: any) : Promise<IResult<boolean>>  =>       
      await (await axios.post('/api/setup/schoolYearClass/unvalidate-subject-exam-schedule', subjectExamSchedule)).data; 

    const cancelSchoolYearClassSubjectExamSchedule = async (subjectExamSchedule: any) : Promise<IResult<boolean>>  =>       
      await (await axios.post('/api/setup/schoolYearClass/cancel-subject-exam-schedule', subjectExamSchedule)).data; 

    const cancelPropositionSchoolYearClassSubjectExamSchedule = async (subjectExamSchedule: any) : Promise<IResult<boolean>>  =>       
      await (await axios.post('/api/setup/schoolYearClass/cancel-proposition-subject-exam-schedule', subjectExamSchedule)).data; 

    const cancelCancellationSchoolYearClassSubjectExamSchedule = async (subjectExamSchedule: any) : Promise<IResult<boolean>>  =>       
      await (await axios.post('/api/setup/schoolYearClass/cancel-cancellation-subject-exam-schedule', subjectExamSchedule)).data;


    const getSchoolYears = async (criteria: ISchoolYearSearch) : Promise<ISchoolYear[]> => {

      const {year, name } = criteria;
      const {data} = (await axios.get(`/api/setup/schoolYear/get-schoolYears?year=${year}&name=${name}`));
      return await data;
    }

    const getSchoolYearPeriods = async (schoolYearId: number) : Promise<ISchoolYearPeriod[]> => {

      const {data} = (await axios.get(`/api/setup/schoolYear/get-schoolYear-periods?schoolYearId=${schoolYearId}`));
      return await data;
    }

    const getSchoolYearPeriodExams = async (schoolYearId: number) : Promise<ISchoolYearPeriodExam[]> => {

      const {data} = (await axios.get(`/api/setup/schoolYear/get-schoolYear-period-exams?schoolYearId=${schoolYearId}`));
      return await data;
    }

    const getSchoolYearClasses = async (criteria: ISchoolYearClassSearch) : Promise<ISchoolYearClass[]> => {

      const {schoolYearId,name, studyType, studyLanguage,studyLevel  } = criteria;
      const {data} = (await axios.get(`/api/setup/schoolYearClass/get-schoolYear-classes?schoolYearId=${schoolYearId}&name=${name}&studyType=${studyType}` +
          `&studyLanguage=${studyLanguage}&studyLevel=${studyLevel}`));
      return await data;
    }    

    const getSchoolYearClassSubjects = async (schoolYearClassId: number) : Promise<ISchoolYearClassSubject[]> => {
      
      const {data} = (await axios.get(`/api/setup/schoolYearClass/get-schoolYear-class-subjects?schoolYearClassId=${schoolYearClassId}`));
      return await data;
    }

    const getSchoolYearClassSubjectExamSchedules = async (schoolYearClassId: number, schoolYearPeriodId: number, schoolYearPeriodExamId: number) : Promise<ISchoolYearClassSubjectExamSchedule[]> => {
      
      const {data} = (await axios.get(`/api/setup/schoolYearClass/get-schoolYear-class-subject-exam-schedules?schoolYearClassId=${schoolYearClassId}&schoolYearPeriodId=${schoolYearPeriodId}&schoolYearPeriodExamId=${schoolYearPeriodExamId}`));
      return await data;
    }

    const getSchoolYearClassRegistrationMarks = async (schoolYearPeriodExamId: number, schoolYearClassSubjectId: number) : Promise<IRegistrationMark[]> => {
      
      const {data} = (await axios.get(`/api/setup/schoolYearClass/get-schoolYear-class-registration-marks?schoolYearPeriodExamId=${schoolYearPeriodExamId}` +
          `&schoolYearClassSubjectId=${schoolYearClassSubjectId}`));
      return await data;
    } 

    const getSchoolYearClassTemplates = async (schoolYearId: number, allClassTemplate: boolean ,name: string, description: string ) : Promise<ISchoolYearClassTemplate[]> => {
      
      const {data} = (await axios.get(`/api/setup/schoolYearClassTemplate/get-schoolYear-classTemplates?schoolYearId=${schoolYearId}&allClassTemplate=${allClassTemplate}&name=${name}&description=${description}`));
      return await data;
    }

    const getSchoolYearTemplateSubjects = async (schoolYearClassTemplateId: number) : Promise<ISchoolYearTemplateSubject[]> => {
      
      const {data} = (await axios.get(`/api/setup/schoolYearClassTemplate/get-schoolYear-template-subjects?schoolYearClassTemplateId=${schoolYearClassTemplateId}`));
      return await data;
    }

    const getSchoolYearTemplateSubjectExamSchedules = async (schoolYearPeriodExamId: number, schoolYearClassTemplateId: number) : Promise<ISchoolYearTemplateSubjectExamSchedule[]> => {
      
      const {data} = (await axios.get(`/api/setup/schoolYear/get-schoolYear-template-subject-exam-schedules?schoolYearPeriodExamId=${schoolYearPeriodExamId}&schoolYearClassTemplateId=${schoolYearClassTemplateId}`));
      return await data;
    }

    const getSchoolYearClassTimeTable = async (schoolYearClassId: number) : 
        Promise<{weekStart: Date, weekEnd: Date, timetableEvents: EventInput[]}> => {
      const {data} = (await axios.get(`/api/setup/schoolYearClass/get-schoolYear-class-time-table?schoolYearClassId=${schoolYearClassId}`));
      return await data;
    }


    const getSchoolYearDashboard = async (schoolYearId: number) : Promise<IDashboardSchoolYear> => {
      const {data} = (await axios.get(`/api/setup/schoolYear/get-school-year-dashboard?schoolYearId=${schoolYearId}`));
      return await data;
    }

    const getResourceDashboard = async (schoolYearId: number) : Promise<IDashboardResource> => {
      const {data} = (await axios.get(`/api/setup/schoolYear/get-resource-dashboard?schoolYearId=${schoolYearId}`));
      return await data;
    }
    

          
    return {    
      createSchoolYear,
      updateSchoolYear,
      updateBasicSchoolYear,
      updateBillingSchoolYear,
      updateClassSchoolYear,
      getSchoolYear,
      getSchoolYears,

      getSchoolYearPeriods,
      getSchoolYearPeriodExams,

      createSchoolYearClassTemplate,
      updateSchoolYearClassTemplate,

      createSchoolYearClass,
      updateSchoolYearClass,
      manageSubjectExamSupervisors,

      validateSchoolYearClassSubjectExamSchedule,
      unvalidateSchoolYearClassSubjectExamSchedule,
      cancelSchoolYearClassSubjectExamSchedule,
      cancelPropositionSchoolYearClassSubjectExamSchedule,
      cancelCancellationSchoolYearClassSubjectExamSchedule,
      
      getSchoolYearClassTemplates,

      getSchoolYearTemplateSubjects,

      getSchoolYearTemplateSubjectExamSchedules,

      getSchoolYearClasses,
      getSchoolYearClassSubjects,
      getSchoolYearClassSubjectExamSchedules,
      
      getSchoolYearClassRegistrationMarks,

      getSchoolYearDashboard,

      getResourceDashboard,

      getSchoolYearClassTimeTable     
    } 
}

export default _;

export interface IFilterSchoolYearOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ISchoolYear[], React.Dispatch<React.SetStateAction<ISchoolYear[]>>],
}

const defaultFilterProductOption: IFilterSchoolYearOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterSchoolYear = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISchoolYear) => void,
                                            filterOption?: IFilterSchoolYearOption  ) => {

  const { getSchoolYears } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headSchoolYearCells, setHeadSchoolYearCells]  = useState<HeadCell<ISchoolYear>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'year', label : t('Year'),  display: true, type: 'numeric', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'startDate', label : t('Start date'),  display: true, type: 'date', },
    {id:'endDate', label : t('End date'),  display: true, type: 'date', },
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'year', text: t('Year'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
            
    ]);    

  const [filteredSchoolYears, ] = useState<ISchoolYear[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ISchoolYear[]> => {    
    
    const year = filterElements.find( elt => elt.name === 'year')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
           
    const arr = await getSchoolYears( {year: Number(year) , name} );
    
    return arr;
  }

  const objKey: keyof ISchoolYear = 'id';

  return {
    title: t('School year'), headCells: headSchoolYearCells, objKey,
    filterElements, rows: filteredSchoolYears, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterSchoolYearClassTemplate = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISchoolYearClassTemplate) => void,
                                            refSchoolYearId: MutableRefObject<number>, allClassTemplate: boolean  ) => {

  const { getSchoolYearClassTemplates } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  //const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headSchoolYearClassTemplateCells, setHeadSchoolYearClassTemplateCells]  = useState<HeadCell<ISchoolYearClassTemplate>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },
   
    {id:'classTemplateName', label : t('Name'),  display: true, type: 'string', width: 30},

    {id:'studyLevel', label : t('Study level'),  display: true, type: 'string', width: 15 },
    {id:'studyLanguage', label : t('Study language'),  display: true, type: 'string',width: 15 },

    {id:'classTemplateDescription', label : t('Description'),  display: true, type: 'string', width: 35},
    
  ]); 

  
  const schoolYearId = refSchoolYearId.current;
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>( [   
    
      
      {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
        options: schoolYearId>0 ? [{name: t('Selected year'), value: String(schoolYearId)}]: 
          [...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },

      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
    ]);    

  

  const [filteredSchoolYearClassTemplates, ] = useState<ISchoolYearClassTemplate[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ISchoolYearClassTemplate[]> => {  
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const _schoolYearId = toNumber(temp);

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
           
    const arr = await getSchoolYearClassTemplates( _schoolYearId,allClassTemplate, name, description );
    
    return arr;
  }

  const objKey: keyof ISchoolYear = 'id';

  return {
    title: t('Classes'), headCells: headSchoolYearClassTemplateCells, objKey,
    filterElements, rows: filteredSchoolYearClassTemplates, 
    onFilterButtonClick, onRowDoubleClick, //rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterSchoolYearClass = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => void) => {

  const { getSchoolYearClasses } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  //const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headSchoolYearClassTemplateCells, setHeadSchoolYearClassTemplateCells]  = useState<HeadCell<ISchoolYearClass>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },
   
    {id:'classTemplateName', label : t('Class template'),  display: true, type: 'string', width: 30},

    {id:'studyLevel', label : t('Study level'),  display: true, type: 'string', width: 15 },
    {id:'studyLanguage', label : t('Study language'),  display: true, type: 'string',width: 15 },

    {id:'name', label : t('Name'),  display: true, type: 'string', width: 35},
    
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>( [   
    
      
      {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
        options: //? [{name: t('Selected year'), value: String(schoolYearId)}]: 
          [...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },

      {name: 'studyType', text: t('Study type'), value: ''},
      {name: 'studyLanguage', text: t('Study language'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'studyLevel', text: t('Study level'), value: ''},
    ]);    

  

  const [filteredSchoolYearClassTemplates, ] = useState<ISchoolYearClassTemplate[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ISchoolYearClass[]> => {  
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const _schoolYearId = toNumber(temp);

    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const studyType = filterElements.find( elt => elt.name === 'studyType')?.value || '';
    const studyLanguage = filterElements.find( elt => elt.name === 'studyLanguage')?.value || '';
    const studyLevel = filterElements.find( elt => elt.name === 'studyLevel')?.value || '';
           
    const arr = await getSchoolYearClasses( {schoolYearId: _schoolYearId, name, studyType, studyLanguage, studyLevel} );
    
    return arr;
  }

  const objKey: keyof ISchoolYearClassTemplate = 'id';

  return {
    title: t('Classes'), headCells: headSchoolYearClassTemplateCells, objKey,
    filterElements, rows: filteredSchoolYearClassTemplates, 
    onFilterButtonClick, onRowDoubleClick, //rowCheckedMode, stateSelected, stateFiltered
  }
}
