
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import useBillingService, { useBasicFilterBilling } from './services/Billing';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IBilling, defaultBilling } from './models/Billing';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { Enum_BILLING_CATEGORY, IEnumerationItem } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import { DatePicker } from '@mui/x-date-pickers';
import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';

export const BillingForm: FC<IBilling> = (props: IBilling = defaultBilling) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  const theme = useTheme();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();
  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createBilling, updateBilling } = useBillingService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterBilling = useBasicFilterBilling( 
    (event: React.MouseEvent<unknown>, row: IBilling) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  

  
  const methods = useForm<IBilling>({defaultValues:defaultBilling});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IBilling>,Error,IBilling>(
      _id>0?updateBilling:createBilling, {   
        onSuccess: (data: IResult<IBilling>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Billing',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IBilling>(['Billing', _id], () => retrieveEntity('Billing',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'Billing'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_BILLING_CATEGORY ] ));

      const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

      const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
        
        setValueTabCoverage(newValue);       
      };

      const handleRegistrationNavigationClick = (event: any) => {
        navigate(`/registration/${getValues().registrationId}`);
      };
      

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'coverage-obligatory' | 'coverage-exclusion' | 'coverage-baseAmount' | 
                'coverage-rawAmount' | 'coverage-tax' | 'coverage-defaultCapital' | 
                'accessories' | 'accessories-tax';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('accessories');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      
      

      

      useEffect( () => {        
        setCurrentFormNameAtom(t('Billing'));
        setCurrentBasicTextFilterProps(basicFilterBilling);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Billing',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          setCurrentFormNameAtom(`${t('Billing')} - # ${_data.id} # - ${_data.isCancelled? `(${t('Cancelled')})`: '' }` );
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        // _setId(0);      
        // reset(defaultBilling);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Billing', _id)) {
          setIsSaveLoading(false);
             return;
        }
          
  
          // const data = getValues(); 
          // if(data.billingTypeName.trim() === '' || data.billingCategoryName.trim() === '') {
          //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
          //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          //     setIsSaveLoading(false);
          //     return;
          //   }
      
          // mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Billing', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Billing',_id]);        
       await retrieveData('Billing',_id, refetch);        
       reset(_data);         
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                {/* <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  /> */}
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(45% - 8px)'}} id="code" label={t('Code')} {...register('billingTypeName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <Controller name='billingCategory' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="category"
                                        label={t('Category')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_BILLING_CATEGORY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                                       
                            </Box>                               
                                                     
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="id" label={t('Registration Id')} 
                                {...register('registrationId')} inputProps={ {readOnly: true}} 
                                /> 
                              <TextField sx={{width:'calc(45% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')} inputProps={ {readOnly: true }}
                                helperText={ (getValues().id) > 0 ?
                                  <FormHelperText>                                    
                                    <Link
                                      href="#"
                                      onClick={handleRegistrationNavigationClick}
                                      sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }}
                                    >
                                      {t('View registration details')}
                                    </Link>
                                  </FormHelperText>: null
                                }  />   
                              <TextField sx={{width:'calc(40% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')} inputProps={ {readOnly: true }} />                                  
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Article ?')}`}
                                    control={
                                    <Controller
                                        name='isArticle'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox disabled={true}
                                        {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Transport ?')}`}
                                    control={
                                    <Controller
                                        name='isTransport'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox disabled={true}
                                          {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                {getValues().isTransport && <TextField sx={{width:'calc(60% - 8px)'}} id="ln" label={t('Bus destination')} 
                                  {...register('busDestinationName')} inputProps={ {readOnly: true }} /> } 
                                {getValues().isArticle && 
                                  <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                  label={`${t('Article out confirmed ?')}`}
                                  control={
                                  <Controller
                                      name='isArticleOutConfirmed'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox disabled={true}
                                        {...props} checked={value} onChange={onChange} />}                        
                                  />} /> }    
                                  {getValues().isArticle && getValues().isArticleOutConfirmed &&
                                    <Controller control={control}
                                    name='articleOutConfirmationDate' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Date')} 
                                        onChange={onChange} disableOpenPicker readOnly                    
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      /> )}
                                  />  }                          
                            </Box>
                            {getValues().isTransport &&<Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Arrival ?')}`}
                                    control={
                                    <Controller
                                        name='isArrival'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox disabled={true}
                                        {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Departure ?')}`}
                                    control={
                                    <Controller
                                        name='isDeparture'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox disabled={true}
                                          {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <Controller control={control}
                                    name='startDate' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Start date')} 
                                        onChange={onChange} disableOpenPicker readOnly                    
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      /> )}
                                  />
                                  <Controller control={control}
                                    name='endDate' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Start date')} 
                                        onChange={onChange} disableOpenPicker readOnly                    
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      /> )}
                                  />                           
                            </Box>}
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1} sx={{ mt: 1, width: '100%' }} flexDirection='row' >
                          <WidgetSummaryBasic title={`- ${t('Amount')}`} paletteColor={theme.palette['info']} 
                            iconName='IoSendSharp' total={getValues().amount} />
                          <WidgetSummaryBasic title={`- ${t('Amount paid')}`} paletteColor={theme.palette['success']} 
                            iconName='IoSendSharp' total={getValues().amountPaid}  />
                          <WidgetSummaryBasic title={`- ${t('Due amount')}`} paletteColor={theme.palette['warning']} 
                            iconName='IoSendSharp' total={getValues().dueAmount}  />
                          <WidgetSummaryBasic title={`- ${t('Unpaid amount')}`} paletteColor={theme.palette['error']} 
                            iconName='IoSendSharp' total={getValues().unpaidAmount} />                          
                        </Box>
                       
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

