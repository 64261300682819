
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ICertificateTemplate, ICertificateTemplateSearch } from "../models/CertificateTemplate";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createCertificateTemplate = async (coverage: ICertificateTemplate)  =>       
        await (await axios.post('/api/misc/certificateTemplate/create', coverage)).data;       
        
    const updateCertificateTemplate = async (coverage: ICertificateTemplate)  =>       
        await (await axios.post('/api/misc/certificateTemplate/update', coverage)).data; 
    
    const getCertificateTemplate = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/certificateTemplate/get-certificateTemplate/${id}`));
      return await data;
    }
   

    const getCertificateTemplates = async (criteria: ICertificateTemplateSearch) : Promise<ICertificateTemplate[]> => {

      const { name, description} = criteria;

      const {data} = (await axios.get(`/api/misc/certificateTemplate/get-certificateTemplates?name=${name}&description=${description}`));
      return await data;
    }
    
      
    return {    
      createCertificateTemplate,
      updateCertificateTemplate,
      getCertificateTemplate,
      getCertificateTemplates
      
    } 
}

export default _;

export interface IFilterCertificateTemplateOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ICertificateTemplate[], React.Dispatch<React.SetStateAction<ICertificateTemplate[]>>],
}

const defaultFilterCertificateTemplateOption: IFilterCertificateTemplateOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterCertificateTemplate = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ICertificateTemplate) => void,
                                            filterOption?: IFilterCertificateTemplateOption  ) => {

  const { getCertificateTemplates } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterCertificateTemplateOption;



  const [headCertificateTemplateCells, setHeadCertificateTemplateCells]  = useState<HeadCell<ICertificateTemplate>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredCertificateTemplates, ] = useState<ICertificateTemplate[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ICertificateTemplate[]> => {
    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       

    const arr = await getCertificateTemplates( {name,description} );
   
    return arr;
  }

  const objKey: keyof ICertificateTemplate = 'id';

  return {
    title: t('Certificate Template'), headCells: headCertificateTemplateCells, objKey,
    filterElements, rows: filteredCertificateTemplates, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
