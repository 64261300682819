
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useBillingTypeService, { useBasicFilterBillingType } from './services/BillingType';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IBillingType, defaultBillingType } from './models/BillingType';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { Enum_BILLING_CATEGORY, IEnumerationItem } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const BillingTypeForm: FC<IBillingType> = (props: IBillingType = defaultBillingType) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();
  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createBillingType, updateBillingType } = useBillingTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterBillingType = useBasicFilterBillingType( 
    (event: React.MouseEvent<unknown>, row: IBillingType) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  

  const [openBillingTypeFilter, setOpenBillingTypeFilter] = useState(false);
  const basicFilterBillingTypeParent = useBasicFilterBillingType( 
      (event: React.MouseEvent<unknown>, row: IBillingType) => {
          const {id, name, description} = row;

          setValue('parentBillingTypeName', name);
          setValue('parentBillingTypeId', id);
                           
          setOpenBillingTypeFilter(false);
      }
  );

  const [openExtensionTypeFilter, setOpenExtensionTypeFilter] = useState(false);
  const basicFilterExtensionType = useBasicFilterExtensionType( 
      (event: React.MouseEvent<unknown>, row: IExtensionType) => {
          const {id, name, description, type, baseType} = row;
          
        setValue('currentExtensionTypeId', id);
        setValue('currentExtensionTypeName', name);  
        setValue('currentExtensionTypeType', type);   
        setValue('currentExtensionTypeBaseType', baseType);                    
                          
        setOpenExtensionTypeFilter(false);
      }
  );

  const methods = useForm<IBillingType>({defaultValues:defaultBillingType});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  let { fields : billingTypeExtensions, append: appendBillingTypeExtensions, update: updateBillingTypeExtension ,remove: removeBillingTypeExtension,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
    name: `billingTypeExtensions`,
    control,            
  });

  const watchParentBillingTypeId = watch('parentBillingTypeId');
  const watchIsSchoolFees = watch('isSchoolFees');
  const watchIsTransport = watch('isTransport');
  const watchIsArticle = watch('isArticle');

  const watchCategory = watch('category');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IBillingType>,Error,IBillingType>(
      _id>0?updateBillingType:createBillingType, {   
        onSuccess: (data: IResult<IBillingType>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['BillingType',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IBillingType>(['BillingType', _id], () => retrieveEntity('BillingType',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'BillingType'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_BILLING_CATEGORY ] ));

      const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

      const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
        
        setValueTabCoverage(newValue);       
      };

      

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'coverage-obligatory' | 'coverage-exclusion' | 'coverage-baseAmount' | 
                'coverage-rawAmount' | 'coverage-tax' | 'coverage-defaultCapital' | 
                'accessories' | 'accessories-tax';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('accessories');

      const handleClickOpenExpression = (event: any, expressionType: ExpressionType, 
          entityName: string, returnType: string, expression: string) => {

        setCurrentExpressionType(expressionType);
        setCurrentEntityName(entityName);
        setCurrentReturnType(returnType);
        setCurrentExpression(expression);
        setOpenEntityExpression(true);
      }

      
      const handleClickOpenBillingType = (event: any) => {        
        setOpenBillingTypeFilter(true);
      }

      const handleClickRemoveParentBillingType = (event: any) => {
        setValue('parentBillingTypeId', 0);
        setValue('parentBillingTypeName', '');     
      }

      const [extensionIndex, setExtensionIndex] = useState<number>(-1);
      const [openExtension, setOpenExtension] = useState(false);
      const handleClickAddExtension = (event: any) => {
        setExtensionIndex(-1);

        setValue('currentExtensionAlias','');        
        setValue('currentExtensionTypeId',0);
        setValue('currentExtensionTypeName','');
        setValue('currentExtensionDescription','');
        
        setOpenExtension(true);
      }

      const handleClickEditExtension = (index: number) => {
        const billingTypeExtension = getValues().billingTypeExtensions.at(index);
        if(isFalsy(billingTypeExtension)) return;

        setExtensionIndex(index);
        const {alias, description, extensionTypeId, extensionTypeName} = billingTypeExtension!;

        setValue('currentExtensionAlias',alias);        
        setValue('currentExtensionTypeId',extensionTypeId);
        setValue('currentExtensionTypeName',extensionTypeName);
        setValue('currentExtensionDescription',description);
        
        setOpenExtension(true);
      }

      const handleOkExtension =  () => { 

        const { currentExtensionAlias, currentExtensionTypeId,
          currentExtensionTypeName, currentExtensionDescription } = getValues();

        if(isFalsy(currentExtensionAlias) || isFalsy(currentExtensionTypeId)) return;

        if(extensionIndex<0 && getValues().billingTypeExtensions.some( x => x.alias.trim() === currentExtensionAlias.trim())) 
          return;

        if(extensionIndex>=0 && getValues().billingTypeExtensions.findIndex( o => o.alias === currentExtensionAlias) !== extensionIndex)
          return;

        if(extensionIndex<0)
          appendBillingTypeExtensions( {
            id: 0, billingTypeId: _id,
            extensionTypeId: currentExtensionTypeId, extensionTypeName: currentExtensionTypeName,
            alias: currentExtensionAlias, description: currentExtensionDescription, });
        else {
          const billingTypeExtension = getValues().billingTypeExtensions.at(extensionIndex)!;
          updateBillingTypeExtension(extensionIndex,{...billingTypeExtension,
            extensionTypeId: currentExtensionTypeId, extensionTypeName: currentExtensionTypeName,
            alias: currentExtensionAlias, description: currentExtensionDescription });
        }

        setOpenExtension(false);
      }
      
      const handleClickSearchExtensionType = (event: any) => {
        setOpenExtensionTypeFilter(true);
      }

      const refEnumItems = useRef<IEnumerationItem[]>();    
      useEffect( () => {   
          refEnumItems.current = enumItems;
        
      }, [enumItems]);

      useEffect( () => {
        
        if(watchIsSchoolFees) {
          setValue('isTransport', false);
          setValue('isArticle', false);
        } else  if(watchIsTransport) {
          setValue('isSchoolFees', false);
          setValue('isArticle', false);
        } else if(watchIsArticle) {
          setValue('isSchoolFees', false);
          setValue('isTransport', false);
        }
        
      }, [watchIsSchoolFees, watchIsTransport, watchIsArticle]);

      useEffect( () => {        
        setCurrentFormNameAtom(t('Billing type'));
        setCurrentBasicTextFilterProps(basicFilterBillingType);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('BillingType',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultBillingType);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('BillingType', _id)) {
          setIsSaveLoading(false);
             return;
        }
          
          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('BillingType', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(25% - 8px)'}} id="code" label={t('Code')} {...register('code')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} {...register('name')} />                                                        
                            </Box>   
                            
                            { openBillingTypeFilter && <FormDialog open={openBillingTypeFilter} maxWidth='md'
                                okText={t('OK')} cancelText='' title={t('Tarification filter')} onCancel={()=> {}} 
                                onClose={()=> {setOpenBillingTypeFilter(false);}} onOk={()=> {setOpenBillingTypeFilter(false);}}  >
                                    <BasicTextFilterForm<IBillingType> {...basicFilterBillingTypeParent } />
                            </FormDialog> }                         
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                              <TextField sx={{width:'calc(50% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                
                              <TextField sx={{width:'calc(50% - 8px)'}} id="parentBillingTypeName" label={`${t('Parent')} - ${t('Dependency')}`} 
                                  {...register('parentBillingTypeName')}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">   
                                        { (isFalsy(watchParentBillingTypeId) ||  watchParentBillingTypeId<=0) ? 
                                          <IconButton color="primary" onClick={(event) => handleClickOpenBillingType(event)}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton> :
                                          <IconButton color="primary" onClick={handleClickRemoveParentBillingType}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }                                                                                             
                                    </InputAdornment>
                                  ) 
                                }}
                              />
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='category' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="category"
                                        label={t('Category')} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_BILLING_CATEGORY, 
                                                                  t('Billing category'), t('Billing category'))}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchCategory) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_BILLING_CATEGORY, t('Billing category'), t('Billing category'),
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_BILLING_CATEGORY && x.code === watchCategory)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        } >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_BILLING_CATEGORY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('_School fees ?')}`}
                                    control={
                                    <Controller
                                        name='isSchoolFees'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                              <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Article ?')}`}
                                    control={
                                    <Controller
                                        name='isArticle'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Transport ?')}`}
                                    control={
                                    <Controller
                                        name='isTransport'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                              
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Button>
                            {`${t('Add Extension')}`}
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                              <MdOutlineAdd size={24} onClick={handleClickAddExtension} />
                            </Box>
                          </Button>
                          { openExtension && <FormDialog open={openExtension} maxWidth='sm' height='75vh'
                                okText={t('OK')} cancelText='' title={t('Extension')} onCancel={()=> {}} 
                                onClose={()=> {setOpenExtension(false);}} onOk={handleOkExtension}  >
                                  <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }}>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionAlias" label={t('Alias')} 
                                          {...register('currentExtensionAlias')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionDescription" label={t('Description')} 
                                        {...register('currentExtensionDescription')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }}>
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="currentExtensionTypeName" label={t('Extension type')} 
                                          {...register('currentExtensionTypeName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">                                            
                                                <IconButton color="primary" onClick={handleClickSearchExtensionType}>
                                                  <ArrowDropDownCircleIcon />
                                                </IconButton>                                                                                               
                                            </InputAdornment>
                                          )
                                        }} />                                                                           
                                    </Box>                                    
                                  </Stack>
                            </FormDialog> } 
                            { openExtensionTypeFilter && <FormDialog open={openExtensionTypeFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Extension type')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionTypeFilter(false);}} onOk={()=> {setOpenExtensionTypeFilter(false);}}  >
                                      <BasicTextFilterForm<IExtensionType> {...basicFilterExtensionType } />
                              </FormDialog> }
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          {                              
                              getValues().billingTypeExtensions.map( ( {alias, description} ,idx) => 
                                ( <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${alias}`} label={`${alias} : ${description} `} color="primary" variant="outlined"
                                    onDelete={() => {removeBillingTypeExtension(idx)}} 
                                    onClick={() => handleClickEditExtension(idx)}/>
                                    ))
                            }
                        </Box>

                        { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }      
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

