
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from './services/Person';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, ToggleButtonGroup, ToggleButton, FormHelperText, Link } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IPerson, defaultPerson } from './models/Person';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_TOWN, Enum_COUNTRY,
  Enum_DEFAULT_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, 
    Enum_TEACHER_STATUS, Enum_TEACHER_DIPLOMA, Enum_TEACHER_SPECIALIZATION, Enum_PERSON_IDENTIFICATION, Enum_SPECIFIC_NEED_PURPOSE,
    Enum_CHRONIC_ILLNESS, Enum_HEIGHT_UNITY, Enum_WEIGHT_UNITY, Enum_RELIGION, Enum_ETHNICITY} from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClass } from 'features/setup/models/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBilling, defaultBilling } from 'features/finance/models/Billing';


import { IBillingType } from 'features/setup/models/BillingType';
import { useBasicFilterBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { debounce } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';
import BasicImportForm from 'components/ui/BasicImportForm';


export const PersonForm: FC<IPerson> = (props: IPerson = defaultPerson) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createPerson, createBasicImportPerson, updatePerson, getPersonsSearchCount } = usePersonService();

  const { getSchoolYears, getSchoolYearClasses } = useSchoolYearService();

  const { getClassTemplates } = useClassTemplateService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterPerson = useBasicFilterPerson( 
    (event: React.MouseEvent<unknown>, row: IPerson) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterParent = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, portable1, portable2, email1, email2, country, town, addressLine1, addressLine2 } = row;
        
        if(currentParentType === 'father') {
          setValue('fatherId', id);
          setValue('fatherFirstName', firstName);
          setValue('fatherLastName', lastName);
          setValue('fatherBirthDate', birthDate);
          setValue('fatherPortable1', portable1);
          setValue('fatherPortable2', portable2);
          setValue('fatherEmail1', email1);
          setValue('fatherEmail2', email2);
          setValue('fatherCountry', country);
          setValue('fatherTown', town);
          setValue('fatherAddressLine1', addressLine1);
          setValue('fatherAddressLine2', addressLine2);
        } else if(currentParentType === 'mother') {
          setValue('motherId', id);
          setValue('motherFirstName', firstName);
          setValue('motherLastName', lastName);
          setValue('motherBirthDate', birthDate);
          setValue('motherPortable1', portable1);
          setValue('motherPortable2', portable2);
          setValue('motherEmail1', email1);
          setValue('motherEmail2', email2);
          setValue('motherCountry', country);
          setValue('motherTown', town);
          setValue('motherAddressLine1', addressLine1);
          setValue('motherAddressLine2', addressLine2);
        } else if(currentParentType === 'tutor') {
          setValue('tutorId', id);
          setValue('tutorFirstName', firstName);
          setValue('tutorLastName', lastName);
          setValue('tutorBirthDate', birthDate);
          setValue('tutorPortable1', portable1);
          setValue('tutorPortable2', portable2);
          setValue('tutorEmail1', email1);
          setValue('tutorEmail2', email2);
          setValue('tutorCountry', country);
          setValue('tutorTown', town);
          setValue('tutorAddressLine1', addressLine1);
          setValue('tutorAddressLine2', addressLine2);          
        }

        // setValue('personId', id);
        // setValue('firstName', firstName);
        // setValue('lastName', lastName);  
        // setValue('birthDate', birthDate);   
        // setValue('birthPlace', birthPlace);                    
        // setValue('particularSign', particularSign);  
                          
        setOpenPersonFilter(false);
      }
  );

    
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );


  
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<IPerson>({defaultValues:defaultPerson});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  const watchBase64Picture = watch('base64Picture');

  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchParticularSign = watch('particularSign');

  const watchFatherFirstName = watch('fatherFirstName');
  const watchFatherLastName = watch('fatherLastName');
  const watchFatherParticularSign = watch('fatherParticularSign');

  const watchMotherFirstName = watch('motherFirstName');
  const watchMotherLastName = watch('motherLastName');
  const watchMotherParticularSign = watch('motherParticularSign');

  const watchTutorFirstName = watch('tutorFirstName');
  const watchTutorLastName = watch('tutorLastName');
  const watchTutorParticularSign = watch('tutorParticularSign');

  const watchNationality = watch('nationality');
  const watchCorporationType = watch('corporationType');

  const watchChronicIllnessCode = watch('chronicIllnessCode');
  const watchEthnicityCode = watch('ethnicityCode');
  const watchReligionCode = watch('religionCode');

  const watchSpecificNeedPurposeCode = watch('specificNeedPurposeCode');

  const watchWeightUnity = watch('weightUnity');
  const watchHeightUnity = watch('heightUnity');

  const watchCountry = watch('country');
  const watchTown = watch('town');

  const watchIdentificationType = watch('identificationType');

  type SearchPersonUsage = 'person' | 'father' | 'mother' | 'tutor';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('person');

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const { firstName, lastName, particularSign,
                fatherFirstName, fatherLastName, fatherParticularSign,
                motherFirstName, motherLastName, motherParticularSign,
                tutorFirstName, tutorLastName, tutorParticularSign} = getValues();

        const _firstName = (currentSearchPersonUsage === 'father') ? fatherFirstName :
                    (currentSearchPersonUsage === 'mother') ? motherFirstName :
                      (currentSearchPersonUsage === 'tutor') ? tutorFirstName : firstName;
        const _lastName = (currentSearchPersonUsage === 'father') ? fatherLastName :
                    (currentSearchPersonUsage === 'mother') ? motherLastName :
                      (currentSearchPersonUsage === 'tutor') ? tutorLastName : lastName;
        const _particularSign = (currentSearchPersonUsage === 'father') ? fatherParticularSign :
                      (currentSearchPersonUsage === 'mother') ? motherParticularSign :
                        (currentSearchPersonUsage === 'tutor') ? tutorParticularSign : particularSign;

        return {firstName: _firstName, lastName: _lastName, particularSign: _particularSign};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign} = row;
        
        if(currentSearchPersonUsage === 'person') {
          _setId(row.id);
          setValue('id', id);
          setValue('firstName', firstName);
          setValue('lastName', lastName);  
          setValue('birthDate', birthDate);   
          setValue('birthPlace', birthPlace);                    
          setValue('particularSign', particularSign); 
        } else if(currentSearchPersonUsage === 'father') {
          setValue('fatherId', id);
          setValue('fatherFirstName', firstName);
          setValue('fatherLastName', lastName);  
          setValue('fatherBirthDate', birthDate);
        } else if(currentSearchPersonUsage === 'mother') {
          setValue('motherId', id);
          setValue('motherFirstName', firstName);
          setValue('motherLastName', lastName);  
          setValue('motherBirthDate', birthDate);
        } else if(currentSearchPersonUsage === 'tutor') {
          setValue('tutorId', id);
          setValue('tutorFirstName', firstName);
          setValue('tutorLastName', lastName);  
          setValue('tutorBirthDate', birthDate);  
        }
         
                          
        setOpenPersonSearchFilter(false);
      }
  );


   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IPerson>,Error,IPerson>(
      _id>0?updatePerson:createPerson, {   
        onSuccess: (data: IResult<IPerson>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Person',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {isLoading: isLoadingBasicImport, isError: isErrorBasicImport, error: errorBasicImport,mutate: mutateBasicImport } = useMutation<IResult<boolean>,Error,IPerson[]>(
      createBasicImportPerson, {   
        onSuccess: (data: IResult<boolean>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                    
          setIsSaveLoading(false);
          //_setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          //queryClient.invalidateQueries(['Product',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IPerson>(['Person', _id], () => retrieveEntity('Person',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Person'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_TOWN, Enum_COUNTRY, Enum_DEFAULT_LANGUAGE, Enum_PERSON_IDENTIFICATION, Enum_CHRONIC_ILLNESS,
          Enum_HEIGHT_UNITY, Enum_WEIGHT_UNITY, Enum_RELIGION, Enum_ETHNICITY,Enum_SPECIFIC_NEED_PURPOSE,
              Enum_PERSON_GENDER, Enum_PERSON_CIVILITY ] ));

    const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    type ParentType = 'father' | 'mother' | 'tutor';
    const [currentParentType, setCurrentParentType] = useState<ParentType>('father');
    const handleChangeCurrentParentType = ( event: React.MouseEvent<HTMLElement>, newParentType: ParentType ) => {  
      if(isFalsy(newParentType) ) 
        return;

      setCurrentParentType(newParentType);
    }

    const handleClickOpenParent = (parentType: ParentType) => (event: any) => {
      
      //setCurrentTeacherUsage(teacherUsage);
      setOpenPersonFilter(true);      
    }

    function openFileDialog() {
      (document as any).getElementById("file-upload").click();
    }
  
    const setFile = (_event: any) => {
      let f = _event.target.files![0];
      var reader = new FileReader();
  
      reader.onload = function () {
  
          var binaryString = reader.result as string;
      
          const base64String = binaryString
                                      .replace('data:', '')
                                      .replace(/^.+,/, '');
  
          setValue("base64Picture", base64String);
          setValue("fileName", f.name);
        };
    
        reader.onerror = function () {
          console.log("File load failed");
        };
    
        reader.readAsDataURL(f);    
    };
    // const handleClickOpenPerson = async (event: any) => {
    //   setOpenPersonFilter(true);
    // }

    const debouncedNameChange = useRef(
      debounce( async () => {      
        const { id, firstName, lastName, particularSign} = getValues();
  
        if(id>0) return;
  
        if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' )) return;
  
        const count = await getPersonsSearchCount(firstName, lastName, particularSign);
  
        if(!isFalsy(refPersonSnackbarId.current)) closeSnackbar(refPersonSnackbarId.current!);
        
        if(count > 0) 
          refPersonSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
                anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                action: xActionPersonSearch('person') } );             
  
      }, 2500)
    ).current;

    const debouncedFatherNameChange = useRef(
      debounce( async () => {      
        const { fatherId, fatherFirstName, fatherLastName, fatherParticularSign } = getValues();
  
        if(fatherId>0) return;
  
        if( (isFalsy(fatherFirstName) || fatherFirstName === '') && (isFalsy(fatherLastName) || fatherLastName === '' ))
          return;
  
        const count = await getPersonsSearchCount(fatherFirstName, fatherLastName, fatherParticularSign);
  
        if(!isFalsy(refFatherSnackbarId.current)) closeSnackbar(refFatherSnackbarId.current!);
        
        if(count > 0) 
          refFatherSnackbarId.current = enqueueSnackbar( `${t('_Father')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
                anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                action: xActionPersonSearch('father') } );             
  
      }, 2500)
    ).current;

    const debouncedMotherNameChange = useRef(
      debounce( async () => {      
        const { motherId, motherFirstName, motherLastName, motherParticularSign } = getValues();
  
        if(motherId>0) return;
  
        if( (isFalsy(motherFirstName) || motherFirstName === '') && (isFalsy(motherLastName) || motherLastName === '' ))
          return;
  
        const count = await getPersonsSearchCount(motherFirstName, motherLastName, motherParticularSign);
  
        if(!isFalsy(refMotherSnackbarId.current)) closeSnackbar(refMotherSnackbarId.current!);
        
        if(count > 0) 
        refMotherSnackbarId.current = enqueueSnackbar( `${t('_Mother')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
                anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                action: xActionPersonSearch('mother') } );             
  
      }, 2500)
    ).current;

    const debouncedTutorNameChange = useRef(
      debounce( async () => {      
        const { tutorId, tutorFirstName, tutorLastName, tutorParticularSign } = getValues();
  
        if(tutorId>0) return;
  
        if( (isFalsy(tutorFirstName) || tutorFirstName === '') && (isFalsy(tutorLastName) || tutorLastName === '' ))
          return;
  
        const count = await getPersonsSearchCount(tutorFirstName, tutorLastName, tutorParticularSign);
  
        if(!isFalsy(refTutorSnackbarId.current)) closeSnackbar(refTutorSnackbarId.current!);
        
        if(count > 0) 
        refTutorSnackbarId.current = enqueueSnackbar( `${t('_Tutor')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
                anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
                action: xActionPersonSearch('tutor') } );             
  
      }, 2500)
    ).current;

    const refPersonSnackbarId = useRef<SnackbarKey>();
    const refFatherSnackbarId = useRef<SnackbarKey>();
    const refMotherSnackbarId = useRef<SnackbarKey>();
    const refTutorSnackbarId = useRef<SnackbarKey>();

    const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
      <>        
          <Button onClick={() => { 
                  // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                  setCurrentSearchPersonUsage(searchPersonUsage);                  
                  setOpenPersonSearchFilter(true);
                  closeSnackbar(snackbarId); }}>   
              <GrSearch size={24} />
          </Button>
          <Button onClick={() => { closeSnackbar(snackbarId) }}>
              <GrClose size={24} />
          </Button>
      </>
  )

  const refEnumItems = useRef<IEnumerationItem[]>([]);    
  useEffect( () => {   
      refEnumItems.current = enumItems || [];
    
  }, [enumItems]);

  useEffect( () => {     
    setCurrentSearchPersonUsage('person');         
    debouncedNameChange();      
    }, [watchFirstName, watchLastName, watchParticularSign, debouncedNameChange]);

    useEffect( () => {     
        setCurrentSearchPersonUsage('father');         
        debouncedFatherNameChange();      
    }, [watchFatherFirstName, watchFatherLastName, watchFatherParticularSign, debouncedFatherNameChange]);

    useEffect( () => {     
      setCurrentSearchPersonUsage('mother');         
      debouncedMotherNameChange();      
  }, [watchMotherFirstName, watchMotherLastName, watchMotherParticularSign, debouncedMotherNameChange]);

  useEffect( () => {     
    setCurrentSearchPersonUsage('tutor');         
    debouncedTutorNameChange();      
  }, [watchTutorFirstName, watchTutorLastName, watchTutorParticularSign, debouncedTutorNameChange]);
        
  
      useEffect( () => {        
        setCurrentFormNameAtom(t('Person'));
        setCurrentBasicTextFilterProps(basicFilterPerson);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Person',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
            const mat = isFalsy(_data.studentCode) ? '' : _data.studentCode;
            const nat = isFalsy(_data.nationalStudentCode) ? '' : `Nat : ${_data.nationalStudentCode}`;
            setCurrentFormNameAtom(`${t('Person')} - ${mat} - ${nat}`);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultPerson);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
        if(!checkEntitySaveAuthorization('Person', _id)) {
          setIsSaveLoading(false);
          return;
        }
        

          const data = getValues(); 
          if(data.firstName.trim() === '' && data.lastName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Person', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    const [personsBasicImport, setPersonsBasicImport] = useState<IPerson[]>([]);
    const [openBasicImportForm, setOpenBasicImportForm] = useState<boolean>(false);
    const basicImportData = async (event: MouseEvent<HTMLButtonElement>) => {          
      setOpenBasicImportForm(true); 
    }

    const handleBasicImportSave = (event: any) => {

      if(personsBasicImport.length === 0) {
        enqueueSnackbar( t('There is no data to import'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      if(!checkEntitySaveAuthorization('Person', _id)) {
        enqueueSnackbar( t('You can not perform this action'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      if(personsBasicImport.some(p => isFalsy(p.firstName) && isFalsy(p.lastName) ) ) {
        enqueueSnackbar( t('There are some person without name'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      // if(productsBasicImport.some(p => isFalsy(p.type) ) ) {
      //   enqueueSnackbar( t('There are some product without line of business'), { variant: 'warning',
      //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      //   return;
      // }

      const badCivilities = personsBasicImport.filter( p => !refEnumItems.current.some( e => 
            e.enumerationCode === Enum_PERSON_CIVILITY && e.code === p.civility  ) );

      if(badCivilities.length !== 0 ) {
        enqueueSnackbar( `${t('There are some person with bad Civility')} : ${badCivilities[0].firstName}` , { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        return;
      }

      mutateBasicImport(personsBasicImport);
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={7} lg={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Identity'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 0.5, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnBasicImport' onClick={basicImportData} sx={ {display:'none'}}  />


                              <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Id')} 
                                {...register('id')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                 />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="particularSign" label={t('Part. sign')} 
                                {...register('particularSign')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />    

                              { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> }                          
                                
                            </Box>                                                   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='gender' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='civility' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller control={control}
                                  name='birthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="birthPlace" label={t('Birth place')} {...register('birthPlace')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/> 
                            </Box>                            
                            <Box sx={{ mt: 0.5, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Contacts'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable1" label={`${t('Portable')} 1`} 
                                {...register('portable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="portable2" label={`${t('Portable')} 2`} 
                                {...register('portable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email1" label={`${t('Email')} 1`} 
                                {...register('email1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="email2" label={`${t('Email')} 2`} 
                                {...register('email2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <Controller name='country' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="country"
                                        label={t('Country')} inputProps={ {readOnly: false}}  
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_COUNTRY, 
                                                                  t('Country'), t('Country'))}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchCountry) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_COUNTRY, t('Country'), t('Country'),
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_COUNTRY && x.code === watchCountry)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        }>
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_COUNTRY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller name='town' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="town"
                                        label={t('Town')} inputProps={ {readOnly: false}}  
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_TOWN, 
                                                                  t('Town'), t('Town'))}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchTown) ) && <Link href="#" onClick={(event) => {
                                                   onUpdateItemClick( Enum_TOWN, t('Town'), t('Town'),
                                                          refEnumItems.current?.find(x => x.enumerationCode === Enum_TOWN && x.code === watchTown)
                                                        ); }}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        }>
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TOWN ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="addressLine1" label={`${t('Address')} 1`}
                                {...register('addressLine1')}  />  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="addressLine2" label={`${t('Address')} 2`}
                                {...register('addressLine2')}  />                                                       
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <ToggleButtonGroup size="small"
                                    color="primary"
                                    value={currentParentType} 
                                    exclusive
                                    onChange={ (event, parentType) => handleChangeCurrentParentType(event, parentType)}
                                    aria-label="Small sizes" fullWidth
                                  >                                    
                                    <ToggleButton value="father">{t('_Father')}</ToggleButton> 
                                    <ToggleButton value="mother">{t('_Mother')}</ToggleButton>
                                    <ToggleButton value="tutor">{t('_Tutor')}</ToggleButton>                                   
                              </ToggleButtonGroup>  
                              { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Person filter')} onCancel={()=> {setOpenPersonFilter(false);}} 
                                onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<IPerson> {...basicFilterParent } />
                            </FormDialog> }                                                     
                            </Box>
                            <Box sx={{ mt: 0.5, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Identity'))} ${(currentParentType==='father')? t('of father'): (currentParentType==='mother')? t('of mother'):t('of tutor') }`}
                              </Typography>                                                       
                            </Box>
                            {currentParentType==='father' && <Box sx={{ mt: 1, width: '100%' }} >
                               <TextField sx={{width:'calc(15% - 8px)'}} id="fatherId" label={t('Id')} 
                                {...register('fatherId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickOpenParent('father') }>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                ) 
                              }} />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'fatherLastName':'fatherFirstName')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'fatherFirstName':'fatherLastName')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <Controller control={control}
                                  name='fatherBirthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>}   
                            {currentParentType==='mother' && <Box sx={{ mt: 1, width: '100%' }} >
                               <TextField sx={{width:'calc(15% - 8px)'}} id="motherId" label={t('Id')} 
                                {...register('motherId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickOpenParent('mother') }>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                ) 
                              }}/>
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'motherLastName':'motherFirstName')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'motherFirstName':'motherLastName')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <Controller control={control}
                                  name='motherBirthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>}    
                            {currentParentType==='tutor' && <Box sx={{ mt: 1, width: '100%' }} >
                               <TextField sx={{width:'calc(15% - 8px)'}} id="tutorId" label={t('Id')} 
                                {...register('tutorId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickOpenParent('tutor') }>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                ) 
                              }}
                                 />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'tutorLastName':'tutorFirstName')}  
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'tutorFirstName':'tutorLastName')} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />  
                              <Controller control={control}
                                  name='tutorBirthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>}
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Contacts'))} ${(currentParentType==='father')? t('of father'): (currentParentType==='mother')? t('of mother'):t('of tutor') }`}
                              </Typography>                                                       
                            </Box>                   
                            {currentParentType==='father' && <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="fatherPortable1" label={`${t('Portable')} 1`} 
                                {...register('fatherPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="fatherPortable2" label={`${t('Portable')} 2`} 
                                {...register('fatherPortable2')}  />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fatherEmail1" label={`${t('Email')} 1`} 
                                {...register('fatherEmail1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fatherEmail2" label={`${t('Email')} 2`} 
                                {...register('fatherEmail2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    
                            </Box> }
                            {currentParentType==='mother' && <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="motherPortable1" label={`${t('Portable')} 1`} 
                                {...register('motherPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="motherPortable2" label={`${t('Portable')} 2`} 
                                {...register('motherPortable2')}  />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="motherEmail1" label={`${t('Email')} 1`} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } {...register('motherEmail1')}  />  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="motherEmail2" label={`${t('Email')} 2`} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } {...register('motherEmail2')}  />                                                    
                            </Box> }
                            {currentParentType==='tutor' && <Box sx={{ mt: 1, width: '100%' }} > 
                              <TextField sx={{width:'calc(15% - 8px)'}} id="tutorPortable1" label={`${t('Portable')} 1`} 
                                {...register('tutorPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                              <TextField sx={{width:'calc(15% - 8px)'}} id="tutorPortable2" label={`${t('Portable')} 2`} 
                                {...register('tutorPortable2')}  />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="tutorEmail1" label={`${t('Email')} 1`} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } {...register('tutorEmail1')}  />  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="tutorEmail2" label={`${t('Email')} 2`} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } {...register('tutorEmail2')}  />                                                    
                            </Box> }
                            {currentParentType==='father' && <Box sx={{ mt: 1, width: '100%' }} > 
                              <Controller name='fatherCountry' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="fatherCountry"
                                        label={t('Country')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_COUNTRY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller name='fatherTown' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="fatherTown"
                                        label={t('Town')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TOWN ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fatherAddressLine1" label={`${t('Address')} 1`}
                                {...register('fatherAddressLine1')}  />  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fatherAddressLine2" label={`${t('Address')} 2`}
                                {...register('fatherAddressLine2')}  />                                                       
                            </Box> }
                            {currentParentType==='mother' && <Box sx={{ mt: 1, width: '100%' }} > 
                              <Controller name='motherCountry' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="motherCountry"
                                        label={t('Country')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_COUNTRY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller name='motherTown' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="motherTown"
                                        label={t('Town')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TOWN ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="motherAddressLine1" label={`${t('Address')} 1`}
                                {...register('motherAddressLine1')}  />  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="motherAddressLine2" label={`${t('Address')} 2`}
                                {...register('motherAddressLine2')}  />                                                       
                            </Box> }
                            {currentParentType==='tutor' && <Box sx={{ mt: 1, width: '100%' }} > 
                              <Controller name='tutorCountry' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="tutorCountry"
                                        label={t('Country')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_COUNTRY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller name='tutorTown' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="tutorTown"
                                        label={t('Town')} inputProps={ {readOnly: false}}  >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_TOWN ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="tutorAddressLine1" label={`${t('Address')} 1`}
                                {...register('tutorAddressLine1')}  />  
                              <TextField sx={{width:'calc(35% - 8px)'}} id="tutorAddressLine2" label={`${t('Address')} 2`}
                                {...register('tutorAddressLine2')}  />                                                       
                            </Box> }                           
                            
                            
                                     
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={5} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Stack flexDirection='row'>                
                            <Box sx={{ mt: 1, width: '50%' }} >
                              <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                accept="image/*" />
                              <div key={'step.id'}>
                                <Box
                                  component="img"
                                  sx={carouselImage}
                                  //src={`data:image/png;base64,${watchBase64Picture}`}
                                  src={isFalsy(watchBase64Picture)?`/images/person.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                                  onClick={openFileDialog}
                                  //alt={step.id}
                                ></Box>
                              </div>
                              {/* <img  width='100%'
                                onClick={openFileDialog} height='100%' maxHeight='250px' /> */}
                            </Box>
                            <Box sx={{ mt: 1, width: '50%' }} >
                              <Stack flexDirection='column'>                
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='defaultLanguage' control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="defaultLanguage"
                                            label={t('Language')} inputProps={ {readOnly: false}}  >
                                            {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_DEFAULT_LANGUAGE ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                          </TextField>
                                        )}
                                    />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='nationality' control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="nationality"
                                            label={t('Nationality')} inputProps={ {readOnly: false}}  
                                            >
                                            {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_COUNTRY ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                          </TextField>
                                        )}
                                    />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller name='identificationType' control={control}                                     
                                        render={ ({field: {onChange, value}}) => (
                                          <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultLanguage"
                                            label={t('Identification type')} inputProps={ {readOnly: false}}  
                                            helperText={ 
                                              <FormHelperText>                                    
                                                { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_PERSON_IDENTIFICATION, 
                                                                      `N°-${t('Person')} : ${t('Identification type')}`, `N°-${t('Person')} : ${t('Identification type')}`)}
                                                  sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                                  {t('Add')}
                                                </Link> }
                                                { (canUpdateEnumerationItem && !isFalsy(watchIdentificationType) ) && <Link href="#" onClick={(event) => {
                                                       onUpdateItemClick( Enum_PERSON_IDENTIFICATION, `N°-${t('Person')} : ${t('Identification type')}`, `N°-${t('Person')} : ${t('Identification type')}`,
                                                              refEnumItems.current?.find(x => x.enumerationCode === Enum_PERSON_IDENTIFICATION && x.code === watchIdentificationType)
                                                            ); }}
                                                  sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                                  {t('Update')}
                                                </Link> }
                                              </FormHelperText>
                                            }>
                                            {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_PERSON_IDENTIFICATION ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                          </TextField>
                                        )}
                                    />
                                    <TextField sx={{width:'calc(50% - 8px)'}} id="identificationNumber" label={`${t('Number')}`}
                                      {...register('identificationNumber')}  />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <TextField sx={{width:'calc(100% - 8px)'}} id="identificationDelivrancePlace" label={`${t('Delivrance place')}`}
                                      {...register('identificationDelivrancePlace')}  
                                      inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                  <Controller control={control}
                                    name='identificationStartDate' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Start date')} 
                                        onChange={onChange}                     
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                      /> )}
                                  />
                                  <Controller control={control}
                                  name='identificationEndDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('End date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                    /> )}
                                />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} >
                                    
                                </Box>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                            {`${t('Biometric informations and others')}`}
                          </Typography>                                                       
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller name='bloodType' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="defaultLanguage"
                                    label={t('Blood type')} inputProps={ {readOnly: false}}  >
                                    <MenuItem value='O'>O</MenuItem>
                                    <MenuItem value='A'>A</MenuItem>
                                    <MenuItem value='B'>B</MenuItem>
                                    <MenuItem value='AB'>AB</MenuItem>
                                  </TextField>
                                )}
                            />
                          <Controller name='rhesusFactor' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="defaultLanguage"
                                  label={t('Rhesus factor')} inputProps={ {readOnly: false}}  >
                                  <MenuItem value='+'>+</MenuItem>
                                  <MenuItem value='-'>-</MenuItem>
                                </TextField>
                              )}
                          />
                          <Controller name='chronicIllnessCode' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="chronicIllnessCode"
                                  label={t('Chronic illness')} inputProps={ {readOnly: false}}  
                                  helperText={ 
                                    <FormHelperText>                                    
                                      { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_CHRONIC_ILLNESS, 
                                                            `${t('Chronic illness')}`, `${t('Chronic illness')}`)}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                        {t('Add')}
                                      </Link> }
                                      { (canUpdateEnumerationItem && !isFalsy(watchChronicIllnessCode) ) && <Link href="#" onClick={(event) => {
                                             onUpdateItemClick( Enum_CHRONIC_ILLNESS, `${t('Chronic illness')}`, `${t('Chronic illness')}`,
                                                    refEnumItems.current?.find(x => x.enumerationCode === Enum_CHRONIC_ILLNESS && x.code === watchChronicIllnessCode)
                                                  ); }}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                        {t('Update')}
                                      </Link> }
                                    </FormHelperText>
                                  }>
                                  {enumItems && enumItems.filter( e => 
                                        e.enumerationCode === Enum_CHRONIC_ILLNESS ).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller                                                   
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Height')} sx={{width:'calc(15% - 8px)'}} 
                                      allowEmptyFormatting={false} 
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`height`}
                                control={control}
                            />
                          <Controller name='heightUnity' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="heightUnity"
                                  label={t('Unity')} inputProps={ {readOnly: false}}  
                                  helperText={ 
                                    <FormHelperText>                                    
                                      { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_HEIGHT_UNITY, 
                                                            `${t('Height')} : ${t('Unity')}`, `${t('Height')} : ${t('Unity')}`)}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                        {t('Add')}
                                      </Link> }
                                      { (canUpdateEnumerationItem && !isFalsy(watchHeightUnity) ) && <Link href="#" onClick={(event) => {
                                             onUpdateItemClick( Enum_HEIGHT_UNITY, `${t('Height')} : ${t('Unity')}`, `${t('Height')} : ${t('Unity')}`,
                                                    refEnumItems.current?.find(x => x.enumerationCode === Enum_HEIGHT_UNITY && x.code === watchHeightUnity)
                                                  ); }}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                        {t('Update')}
                                      </Link> }
                                    </FormHelperText>
                                  }>
                                  {enumItems && enumItems.filter( e => 
                                        e.enumerationCode === Enum_HEIGHT_UNITY ).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                          <Controller                                                   
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Weight')} sx={{width:'calc(15% - 8px)'}} 
                                      allowEmptyFormatting={false} 
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`weight`}
                                control={control}
                            />
                          <Controller name='weightUnity' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="weightUnity"
                                  label={t('Unity')} inputProps={ {readOnly: false}}  
                                  helperText={ 
                                    <FormHelperText>                                    
                                      { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_WEIGHT_UNITY, 
                                                            `${t('Weight')} : ${t('Unity')}`, `${t('Weight')} : ${t('Unity')}`)}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                        {t('Add')}
                                      </Link> }
                                      { (canUpdateEnumerationItem && !isFalsy(watchWeightUnity) ) && <Link href="#" onClick={(event) => {
                                             onUpdateItemClick( Enum_WEIGHT_UNITY, `${t('Weight')} : ${t('Unity')}`, `${t('Weight')} : ${t('Unity')}`,
                                                    refEnumItems.current?.find(x => x.enumerationCode === Enum_WEIGHT_UNITY && x.code === watchWeightUnity)
                                                  ); }}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                        {t('Update')}
                                      </Link> }
                                    </FormHelperText>
                                  }>
                                  {enumItems && enumItems.filter( e => 
                                        e.enumerationCode === Enum_WEIGHT_UNITY ).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Has specific needs ?')}`}
                                    control={
                                    <Controller
                                        name='hasSpecificNeeds'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                          <Controller name='specificNeedPurposeCode' control={control}                                     
                            render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(60% - 8px)'}} id="specificNeedPurposeCode"
                                label={t('Purpose')} inputProps={ {readOnly: false}}  
                                helperText={ 
                                  <FormHelperText>                                    
                                    { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_SPECIFIC_NEED_PURPOSE, 
                                                          `${t('Specific needs')} : ${t('Purpose')}`, `${t('Specific needs')} : ${t('Purpose')}`)}
                                      sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                      {t('Add')}
                                    </Link> }
                                    { (canUpdateEnumerationItem && !isFalsy(watchSpecificNeedPurposeCode) ) && <Link href="#" onClick={(event) => {
                                           onUpdateItemClick( Enum_SPECIFIC_NEED_PURPOSE, `${t('Specific needs')} : ${t('Purpose')}`, `${t('Specific needs')} : ${t('Purpose')}`,
                                                  refEnumItems.current?.find(x => x.enumerationCode === Enum_SPECIFIC_NEED_PURPOSE && x.code === watchSpecificNeedPurposeCode)
                                                ); }}
                                      sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                      {t('Update')}
                                    </Link> }
                                  </FormHelperText>
                                }>
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_SPECIFIC_NEED_PURPOSE ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                            )}
                          />
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="medicalConditions" multiline={true} rows={2}
                              label={`${t('Medical conditions')}`} {...register('medicalConditions')}  
                              inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <TextField sx={{width:'calc(100% - 8px)'}} id="allergies" multiline={true} rows={2}
                              label={`${t('Allergies')}`} {...register('allergies')}  
                              inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >                          
                          <Controller name='ethnicityCode' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="ethnicityCode"
                                  label={t('Ethnicity')} inputProps={ {readOnly: false}}  
                                  helperText={ 
                                    <FormHelperText>                                    
                                      { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_ETHNICITY, 
                                                            t('Ethnicity'), t('Ethnicity'))}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                        {t('Add')}
                                      </Link> }
                                      { (canUpdateEnumerationItem && !isFalsy(watchEthnicityCode) ) && <Link href="#" onClick={(event) => {
                                             onUpdateItemClick( Enum_ETHNICITY, t('Ethnicity'), t('Ethnicity'),
                                                    refEnumItems.current?.find(x => x.enumerationCode === Enum_ETHNICITY && x.code === watchEthnicityCode)
                                                  ); }}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                        {t('Update')}
                                      </Link> }
                                    </FormHelperText>
                                  }>
                                  {enumItems && enumItems.filter( e => 
                                        e.enumerationCode === Enum_ETHNICITY ).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                          <Controller name='religionCode' control={control}                                     
                              render={ ({field: {onChange, value}}) => (
                                <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="religionCode"
                                  label={t('Religion')} inputProps={ {readOnly: false}}  
                                  helperText={ 
                                    <FormHelperText>                                    
                                      { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_RELIGION, 
                                                            t('Religion'), t('Religion'))}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                        {t('Add')}
                                      </Link> }
                                      { (canUpdateEnumerationItem && !isFalsy(watchReligionCode) ) && <Link href="#" onClick={(event) => {
                                             onUpdateItemClick( Enum_RELIGION, t('Religion'), t('Religion'),
                                                    refEnumItems.current?.find(x => x.enumerationCode === Enum_RELIGION && x.code === watchReligionCode)
                                                  ); }}
                                        sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                        {t('Update')}
                                      </Link> }
                                    </FormHelperText>
                                  }>
                                  {enumItems && enumItems.filter( e => 
                                        e.enumerationCode === Enum_RELIGION ).map( 
                                    (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                  }
                                </TextField>
                              )}
                          />
                        </Box>

                        { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }
                          { openBasicImportForm && <FormDialog open={openBasicImportForm} maxWidth='sm' height='45vh'
                                okText={t('Save')} cancelText={t('Cancel')} title={`${t('Import')} ....`} onCancel={()=> {setOpenBasicImportForm(false);}} 
                                onClose={()=> {setOpenBasicImportForm(false);}} onOk={handleBasicImportSave}  >
                                    <BasicImportForm<IPerson> 
                                      stateItems={[personsBasicImport, setPersonsBasicImport]} 
                                      columns={['type','firstName','lastName', 'birthDate' ,'civility']}
                                      defaultItem={defaultPerson} />
                              </FormDialog> }
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

