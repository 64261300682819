
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useUtils from 'library/utils';

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { makeStyles } from '@mui/material';

import { useTheme } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';



import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useSchoolYearService, { useBasicFilterSchoolYear, useBasicFilterSchoolYearClass } from 'features/setup/services/SchoolYear';
import { ISchoolYearClass, defaultSchoolYearClassTemplate,  ISchoolYearPeriod, ISchoolYearPeriodExam, defaultSchoolYearClass, ISchoolYearClassSubject, ISchoolYearClassSubjectSchedule, defaultSchoolYearClassSubject } from 'features/setup/models/SchoolYear';

import {IDashboardSchoolYear, ISchoolYear, defaultDashboardSchoolYear} from 'features/setup/models/SchoolYear';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


// import WidgetSummary from './widgets/WidgetSummary';
// import WidgetBarChart from './widgets/WidgetBarChart';
// import WidgetPieChart from './widgets/WidgetPieChart';
import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';
import { EventInput } from '@fullcalendar/core';
import { isFalsy } from 'utility-types';




export const TimeTableForm = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const {generateRandomColor} = useUtils();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  

  const {height, width} = useWindowSize();

  const { getSchoolYearClassTimeTable} = useSchoolYearService();
  
  const [schoolYearClassName, setSchoolYearClassName] = useState('');
  const [timetableEvents, setTimetableEvents] = useState<EventInput[]>([]);
  const [colors, setColors] = useState<Record<string, string>>({});

  const [weekStart, setWeekStart] = useState<Date>(new Date());
  const [weekEnd, setWeekEnd] = useState<Date>(new Date());


  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSchoolYearClass = useBasicFilterSchoolYearClass( 
    async (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {
        setIsSearchBoxShow(false);

        const {id, name} = row;
        setSchoolYearClassName(name);     
        
        const timeTable = await getSchoolYearClassTimeTable(id);

        const {timetableEvents, weekStart, weekEnd} = timeTable;
        const colorSet = new Set( timetableEvents.map( ({title, }) => title ));
        const colorMap : Record<string, string> = {};

        for (let i = 0; i < timetableEvents.length; i++) {
          const {title} = timetableEvents[i];
          if(isFalsy(title)) continue;

          if( isFalsy(colorMap[title]))
            colorMap[title] = generateRandomColor();

          // const color = generateRandomColor();
          // colorMap[`item${i + 1}`] = color;
          timetableEvents[i].backgroundColor = colorMap[title];
        }

        //const colorMap: ColorMap = {};
  
        setTimetableEvents(timetableEvents);
        setWeekStart(weekStart);
        setWeekEnd(weekEnd);
        //console.log(timeTable);
      }
  );

  

  useEffect( () => {        
    setCurrentFormName(t('Time table'));
    setCurrentBasicTextFilterProps(basicFilterSchoolYearClass);
    
  }, []);    

  useEffect(() => {
    async function _() {
             
      //const dash = await getSchoolYearDashboard(applicationSetup.currentSchoolYearId);
      //const schoolYear = schoolYears.find(s => s.id === applicationSetup.currentSchoolYearId);
      //setSchoolYearName(schoolYear?.name || '');
      
      //setDashboard(dash);     
    }
    _();
  }, []);
  
  // Custom title component for the header
  const HeaderTitle = () => {
    return <Typography variant="h6">School Timetable</Typography>;
  };

  const renderHeader = (info: any) => {
    return (
      <div>
        {info.date.toLocaleDateString(lg, { weekday: 'long' })}
      </div>
    );
  };

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                {` ${t('Time table')} ->  ${t(('Class'))} : ${schoolYearClassName}`}
              </Typography>
            </Box>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <div style={{ width: '100%' }}>
                <FullCalendar
                  plugins={[timeGridPlugin]} // {[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  locale={lg}
                  initialView="timeGridWeek"
                  // headerToolbar={{
                  //   //left: 'prev,next today',
                  //   //center: 'title',
                  //   left: '',
                  //   center: '',
                  //   //right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  //   right: ''
                  // }}
                  headerToolbar={false}
                  //height="700px"
                  initialDate={weekStart} // Set the initial start date of the week view
                  //weekends={false} // Optional: Hide weekends if desired
                  validRange={{ start: weekStart, end: weekEnd }} // Set the valid date range for the week view
                  nowIndicator={true} // Show current time indicator
                  height="auto" // Auto-adjust height to content
                  allDaySlot={false} // Hide all-day slot
                  slotMinTime="07:00:00" // Start time for the timetable (e.g., 08:00 AM)
                  slotMaxTime="17:00:00" // End time for the timetable (e.g., 06:00 PM)
                  slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    //meridiem: false,
                  }}
                  // Custom toolbar rendering function
                  
                  
                  events={timetableEvents}

                  dayHeaderContent={renderHeader}
                />
              </div>
            </Box>
            
          </Stack>
        </Grid>
      </Grid>
      
    </Box>
  )
}
