
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IClassTemplate, IClassTemplateSearch } from "../models/ClassTemplate";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createClassTemplate = async (classTemplate: IClassTemplate)  =>       
        await (await axios.post('/api/setup/classTemplate/create', classTemplate)).data;       
        
    const updateClassTemplate = async (classTemplate: IClassTemplate)  =>       
        await (await axios.post('/api/setup/classTemplate/update', classTemplate)).data; 
    
    const getClassTemplate = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/classTemplate/get-classTemplate/${id}`));
      return await data;
    }
   

    const getClassTemplates = async (criteria: IClassTemplateSearch) : Promise<IClassTemplate[]> => {

      const { studyLevel, studyLanguage, name, description} = criteria;

      const {data} = (await axios.get(`/api/setup/classTemplate/get-classTemplates?studyLevel=${studyLevel}&studyLanguage=${studyLanguage}&name=${name}&description=${description}`));
      return await data;
    }
    
      
    return {    
      createClassTemplate,
      updateClassTemplate,
      getClassTemplate,
      getClassTemplates
      
    } 
}

export default _;

export interface IFilterClassTemplateOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IClassTemplate[], React.Dispatch<React.SetStateAction<IClassTemplate[]>>],
}

const defaultFilterClassTemplateOption: IFilterClassTemplateOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterClassTemplate = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IClassTemplate) => void,
                                            filterOption?: IFilterClassTemplateOption  ) => {

  const { getClassTemplates } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterClassTemplateOption;



  const [headClassTemplateCells, setHeadClassTemplateCells]  = useState<HeadCell<IClassTemplate>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },    
    {id:'name', label : t('Name'),  display: true, type: 'string', },

    {id:'studyLevel', label : t('Study level'),  display: true, type: 'string', },
    {id:'studyLanguage', label : t('Study language'),  display: true, type: 'string', },
    
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
      {name: 'studyLevel', text: t('Study level'), value: ''},
      {name: 'studyLanguage', text: t('Study language'), value: ''},

      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredClassTemplates, ] = useState<IClassTemplate[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IClassTemplate[]> => {
    
    const studyLevel = filterElements.find( elt => elt.name === 'studyLevel')?.value || '';
    const studyLanguage = filterElements.find( elt => elt.name === 'studyLanguage')?.value || '';  
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';       

    const arr = await getClassTemplates( {studyLevel,studyLanguage, name,description} );
   
    return arr;
  }

  const objKey: keyof IClassTemplate = 'id';

  return {
    title: t('Class template'), headCells: headClassTemplateCells, objKey,
    filterElements, rows: filteredClassTemplates, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
