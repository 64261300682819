
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import usePaymentService, { useBasicFilterPayment } from './services/Payment';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IPayment, defaultPayment } from './models/Payment';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { Enum_BILLING_CATEGORY, IEnumerationItem } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

export const PaymentForm: FC<IPayment> = (props: IPayment = defaultPayment) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();
  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createPayment, updatePayment } = usePaymentService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterPayment = useBasicFilterPayment( 
    (event: React.MouseEvent<unknown>, row: IPayment) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  

  // const [openPaymentFilter, setOpenPaymentFilter] = useState(false);
  // const basicFilterPaymentParent = useBasicFilterPayment( 
  //     (event: React.MouseEvent<unknown>, row: IPayment) => {
  //         const {id, name, description} = row;

  //         setValue('parentPaymentName', name);
  //         setValue('parentPaymentId', id);
                           
  //         setOpenPaymentFilter(false);
  //     }
  // );

  
  const methods = useForm<IPayment>({defaultValues:defaultPayment});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

   
  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IPayment>,Error,IPayment>(
      _id>0?updatePayment:createPayment, {   
        onSuccess: (data: IResult<IPayment>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Payment',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IPayment>(['Payment', _id], () => retrieveEntity('Payment',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'Payment'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_BILLING_CATEGORY ] ));

      const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

      const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
        
        setValueTabCoverage(newValue);       
      };

      

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'coverage-obligatory' | 'coverage-exclusion' | 'coverage-baseAmount' | 
                'coverage-rawAmount' | 'coverage-tax' | 'coverage-defaultCapital' | 
                'accessories' | 'accessories-tax';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('accessories');

           
            

      useEffect( () => {        
        setCurrentFormNameAtom(t('Payment'));
        setCurrentBasicTextFilterProps(basicFilterPayment);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Payment',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultPayment);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Payment', _id)) {
          setIsSaveLoading(false);
             return;
        }
          
  
          const data = getValues(); 
          if(data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
            setIsSaveLoading(false);
            return;
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Payment', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <TextField sx={{width:'calc(30% - 8px)'}} id="issusDate" label={t('Date')} {...register('issueDate')} />                                                        
                            </Box>   
                            
                                                     
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="ownerName" label={t('Beneficiary')} {...register('ownerName')} />                                
                              <TextField sx={{width:'calc(50% - 8px)'}} id="bearerName" label={t('Bearer')} {...register('bearerName')} />                                
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Cancelled ?')}`}
                                    control={
                                    <Controller
                                        name='isCancelled'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <Controller name='cancellationPurpose' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="category"
                                        label={t('Purpose')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_BILLING_CATEGORY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          
                          
                            
                        </Box>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          
                        </Box>
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

