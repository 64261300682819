
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';
import BookIcon from '@mui/icons-material/Book';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useTeacherService, { useBasicFilterTeacher } from './services/Teacher';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, ToggleButtonGroup, ToggleButton } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_TEACHER_STATUS, 
    Enum_SUBJECT, Enum_SUBJECT_CATEGORY, 
    Enum_APPRECIATION_SYSTEM} from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear, useBasicFilterSchoolYearClassTemplate } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClassTemplate, ISchoolYearClass, defaultSchoolYearClassTemplate, ISchoolYearTemplateSubject, defaultSchoolYear, defaultSchoolYearTemplateSubject, ISchoolYearTemplateSubjectExamSchedule, ISchoolYearPeriod, ISchoolYearPeriodExam } from 'features/setup/models/SchoolYear';
import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';


import { IPerson } from 'features/production/models/Person';
import { useBasicFilterPerson } from 'features/production/services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import { useBasicFilterBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { ITeacher } from './models/Teacher';
import { IClassroom } from './models/Classroom';
import { useBasicFilterClassroom } from './services/Classroom';
import { getUnixTime } from 'date-fns';
import EnhancedTable from 'components/ui/EnhancedTable';


export const SchoolYearClassTemplateForm: FC<ISchoolYearClassTemplate> = (props: ISchoolYearClassTemplate = defaultSchoolYearClassTemplate) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg, schoolYears} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  //const { createTeacher, updateTeacher } = useTeacherService();

  const { createSchoolYearClassTemplate,updateSchoolYearClassTemplate, getSchoolYearClassTemplates, 
      getSchoolYearPeriods, getSchoolYearPeriodExams, getSchoolYearClasses } = useSchoolYearService();

  const { getClassTemplates } = useClassTemplateService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const refSchoolYearId_0 = useRef<number>(0);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSchoolYearClassTemplate = useBasicFilterSchoolYearClassTemplate( 
    (event: React.MouseEvent<unknown>, row: ISchoolYearClassTemplate) => {
        setIsSearchBoxShow(false);

        setValue('schoolYearId', row.schoolYearId);

        _setId(row.id);
      }, refSchoolYearId_0, false
  );

      
  const [openClassTemplateFilter, setOpenClassTemplateFilter] = useState(false);
  const basicFilterClassTemplate = useBasicFilterClassTemplate( 
      (event: React.MouseEvent<unknown>, row: IClassTemplate) => {
          const {id, name, studyLanguage} = row;

             
        setValue('classTemplateId', id);
        setValue('classTemplateName', name);
        setValue('studyLanguage', studyLanguage);                          

        setOpenClassTemplateFilter(false);
      }
  );

  const inputSchoolYear = useRef();
  const refSchoolYearId = useRef<number>(0);
  
  const [openSchoolYearClassTemplateFilter2, setOpenSchoolYearClassTemplateFilter2] = useState(false);
  const basicFilterSchoolYearClassTemplate2 = useBasicFilterSchoolYearClassTemplate( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYearClassTemplate) => {

        const {id, classTemplateId, classTemplateName, studyLanguage } = row;

        // check if there is an request with the same core key for business app
        const arrSchoolYearClassTemplates = await getSchoolYearClassTemplates(watchSchoolYearId, false, '', '');
        const findSchoolYearClassTemplate = arrSchoolYearClassTemplates.find(c => c.classTemplateId === classTemplateId);
        if( !isFalsy(findSchoolYearClassTemplate)  ) {
          enqueueSnackbar( t('This class is already configured for this school year !!!'), { variant: 'info',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            //_setId(arrSchoolYearClassTemplates[0].id);
            _setId(findSchoolYearClassTemplate!.id);
            setOpenSchoolYearClassTemplateFilter2(false);
        }
        //_setId(id);

        setValue('id', id);        
        setValue('schoolYearId', watchSchoolYearId);
        setValue('classTemplateId', classTemplateId);
        setValue('classTemplateName', classTemplateName);
        setValue('studyLanguage', studyLanguage);
                                  
        setOpenSchoolYearClassTemplateFilter2(false);
      }, refSchoolYearId, true
  );

  const [openSchoolYearClassTemplateFilter3, setOpenSchoolYearClassTemplateFilter3] = useState(false);
  const basicFilterSchoolYearClassTemplate3 = useBasicFilterSchoolYearClassTemplate( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYearClassTemplate) => {

        const {id, classTemplateId, classTemplateName, studyLanguage } = row;

        if(getValues().id === id) return;

        const schoolYearClassTemplate = await retrieveEntity('SchoolYearClassTemplate', id) as ISchoolYearClassTemplate;
                
        (refAppendSchoolYearTemplateSubjects.current??emptyFunc)( 
          schoolYearClassTemplate.schoolYearTemplateSubjects.filter(x => 
              !getValues().schoolYearTemplateSubjects.some(y => x.subject === y.subject) )
            .map( (x) => (  {...x, id: 0, schoolYearClassTemplateId : getValues().id,
                              
            }) )
        ); 
                                          
        setOpenSchoolYearClassTemplateFilter3(false);
      }, refSchoolYearId, false
  );
  
  type TeacherUsage = 'full' | 'substitute';
  const [currentTeacherUsage, setCurrentTeacherUsage] = useState<TeacherUsage>('full');

  const [openTeacherFilter, setOpenTeacherFilter] = useState(false);
  const basicFilterTeacher = useBasicFilterTeacher( 
      (event: React.MouseEvent<unknown>, row: ITeacher) => {
          const {id, firstName, lastName} = row;

          const schoolYearClass = getValues().schoolYearClasses.at(schoolYearClassIndex);
          if(isFalsy(schoolYearClass)) return;

          const fullTeacherId = (currentTeacherUsage === 'full') ? id : schoolYearClass.fullTeacherId;
          const substituteTeacherId = (currentTeacherUsage === 'substitute') ? id : schoolYearClass.substituteTeacherId;

          const fullTeacherName = (currentTeacherUsage === 'full') ? `${lastName} ${firstName}` : schoolYearClass.fullTeacherName;
          const substituteTeacherName = (currentTeacherUsage === 'substitute') ? `${lastName} ${firstName}` : schoolYearClass.substituteTeacherName;

          (refUpdateSchoolYearClass.current??emptyFunc)( schoolYearClassIndex,
               {...schoolYearClass, fullTeacherId, substituteTeacherId, fullTeacherName, substituteTeacherName });  
                           
          setOpenTeacherFilter(false);
      }
  );

  const [openClassroomFilter, setOpenClassroomFilter] = useState(false);
  const basicFilterClassroom = useBasicFilterClassroom( 
      (event: React.MouseEvent<unknown>, row: IClassroom) => {
          const {name, id} = row;
        
        const schoolYearClass = getValues().schoolYearClasses.at(schoolYearClassIndex);
        if(isFalsy(schoolYearClass)) return;

        (refUpdateSchoolYearClass.current??emptyFunc)( schoolYearClassIndex, 
          {...schoolYearClass, classroomId: id, classroomName: name });
                           
         setOpenClassroomFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<ISchoolYearClassTemplate>({defaultValues:defaultSchoolYearClassTemplate});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchSchoolYearId = watch('schoolYearId');

  

   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYearClassTemplate>,Error,ISchoolYearClassTemplate>(
    _id>0?updateSchoolYearClassTemplate:createSchoolYearClassTemplate, {   
        onSuccess: (data: IResult<ISchoolYearClassTemplate>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Class')} - ${t('Subject')} : # ${data.data.id} # -` );
          queryClient.invalidateQueries(['SchoolYearClassTemplate',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ISchoolYearClassTemplate>(['SchoolYearClassTemplate', _id], () => retrieveEntity('SchoolYearClassTemplate',_id), 
      {refetchOnWindowFocus: false ,enabled: false, } );

    
    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'SchoolYearClassTemplate'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, Enum_TEACHER_STATUS, Enum_APPRECIATION_SYSTEM,
              Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_SUBJECT, Enum_SUBJECT_CATEGORY ] ));
   

    const [classTemplates, setClassTemplates] = useState<IClassTemplate[]>([]);

    const [schoolYearPeriods, setSchoolYearPeriods] = useState<ISchoolYearPeriod[]>([]);
    const [schoolYearPeriodExams, setSchoolYearPeriodExams] = useState<ISchoolYearPeriodExam[]>([]);
        
    //const [schoolYear, setSchoolYear] = useState<ISchoolYear>(defaultSchoolYear);

    const handleClickOpenSchoolYearClassTemplate = async (event: any) => {
      if(getValues().id > 0) {
        enqueueSnackbar( t('Class can not be changed, you have to add new.'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2500 }); 
        return;
      }

      if(getValues().schoolYearId <= 0) {
        enqueueSnackbar( t('You have to select school year before continue.'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2500 }); 
        return;
      }

      setOpenSchoolYearClassTemplateFilter2(true);
      
    }



    const getInputAdornmentClassroom = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass)  => ({

      toolTip: 'Classroom',
      icon: ArrowDropDownCircleIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearClass ) => {
                
        setSchoolYearClassIndex(index);
        setOpenClassroomFilter(true);
      }  
    })
  
    const getInputAdornmentFullTeacher = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass)  => ({
  
      toolTip: 'Teacher',
      icon: ArrowDropDownCircleIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearClass ) => {
                
        setCurrentTeacherUsage('full');
        setSchoolYearClassIndex(index);
        setOpenTeacherFilter(true);
      }  
    })
  
    const getInputAdornmentSubstituteTeacher = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass)  => ({
  
      toolTip: 'Teacher',
      icon: ArrowDropDownCircleIcon,
      onClickIcon: (event: any, index: number, row: ISchoolYearClass ) => {
                
        setCurrentTeacherUsage('substitute');
        setSchoolYearClassIndex(index);
        setOpenTeacherFilter(true);
      }  
    })
  
    const getStudyTypeList = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass, 
      opts: {value: string, name: string}[]) => {        
      
      return getAsOptions(refEnumItems.current ?? [],Enum_STUDY_TYPE);
    }
  
    const cellEditableClass = (row: ISchoolYearClass, cellId: keyof ISchoolYearClass) => {
      return true;
    }
  
    const [headSchoolYearClassCells, setHeadSchoolYearClassCells]  = useState<HeadCell<ISchoolYearClass>[]>([]);
    useEffect(() => {
      setHeadSchoolYearClassCells([            
        {id:'id', label : t('Id'),  display: false, type: 'numeric', },
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 15, isEditable: cellEditableClass, },
        {id:'studyType', label : t('Study type'),  display: true, type: 'string', width: 15, isEditable: cellEditableClass,
          getOptions: getStudyTypeList},
        {id:'classroomName', label : t('Room'),  display: true, type: 'string', width: 20, isEditable: cellEditableClass, 
          getInputAdornment: getInputAdornmentClassroom},
        {id:'fullTeacherName', label : t('Full teacher'),  display: true, type: 'string', width: 25, isEditable: cellEditableClass, 
          getInputAdornment: getInputAdornmentFullTeacher},
        {id:'substituteTeacherName', label : t('Substitute teacher'),  display: true, type: 'string', width: 25, isEditable: cellEditableClass, 
          getInputAdornment: getInputAdornmentSubstituteTeacher},
      ]  )
    }, [t,i18n])
  
    const refAppendSchoolYearClasses = useRef<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateSchoolYearClass = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>> ) => void>(null);
    const refRemoveSchoolYearClass = useRef<(index: number ) => void>(null);
  
    const handleAddSchoolYearClasses = (event: any) => {
      (refAppendSchoolYearClasses.current??emptyFunc)( {
        id: 0, schoolYearId: _id, name: '', classTemplateId: 0, classTemplateName: '', classroomId: 0, classroomName: '' 
      });
    }

    const [schoolYearClassIndex, setSchoolYearClassIndex] = useState<number>(-1);
    const schoolYearClassRowActionIcon = ( schoolYearClass: ISchoolYearClass) : ActionIconTableRow<ISchoolYearClassTemplate,ISchoolYearClass> => {
    
      const res: ActionIconTableRow<ISchoolYearClassTemplate,ISchoolYearClass> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: ISchoolYearClass) => {
          
          (refRemoveSchoolYearClass.current??emptyFunc)(index);            
        }
      }
      return res;
  }


  //////
  
  const getInputAdornmentSubjectLongName = (row: ISchoolYearTemplateSubject, cellId: keyof ISchoolYearTemplateSubject)  => ({
  
    toolTip: 'Books',
    icon: BookIcon,
    onClickIcon: (event: any, index: number, row: ISchoolYearTemplateSubject ) => {

    }  
  })

  
  const getSubjectList = (row: ISchoolYearTemplateSubject, cellId: keyof ISchoolYearTemplateSubject, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_SUBJECT);
  }

  const getReportCardList = (row: ISchoolYearTemplateSubject, cellId: keyof ISchoolYearTemplateSubject, 
    opts: {value: string, name: string}[]) => {        
    
    return getAsOptions(refEnumItems.current ?? [],Enum_STUDY_LANGUAGE);
  }

  const cellEditableTemplateSubject = (row: ISchoolYearTemplateSubject, cellId: keyof ISchoolYearTemplateSubject) => {
    return true;
  }

  const [headSchoolYearTemplateSubjectCells, setHeadSchoolYearTemplateSubjectCells]  = useState<HeadCell<ISchoolYearTemplateSubject>[]>([]);
  useEffect( () => {
    setHeadSchoolYearTemplateSubjectCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },      
      {id:'subject', label : t('Subject'),  display: true, type: 'string', width: 35, isEditable: cellEditableTemplateSubject,
        getOptions: getSubjectList},
      {id:'longName', label : t('Long name'),  display: true, type: 'string', width: 60, isEditable: cellEditableTemplateSubject,
        getInputAdornment: getInputAdornmentSubjectLongName },
      {id:'reportCardOrder', label : t('Order'),  display: true, type: 'numeric', width: 5, isEditable: cellEditableTemplateSubject, },
      //{id:'shortName', label : t('Short name'),  display: true, type: 'string', width: 25, isEditable: cellEditableTemplateSubject, },
      // {id:'coefficient', label : t('Coefficient'),  display: true, type: 'numeric', width: 10, isEditable: cellEditableTemplateSubject,},
      // {id:'reportCard', label : t('Report cart'),  display: true, type: 'string', width: 20, 
      //   isEditable: cellEditableTemplateSubject, getOptions: getReportCardList},
    ]  )
  }, [t,i18n])

  const refAppendSchoolYearTemplateSubjects = useRef<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateSchoolYearTemplateSubject = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>> ) => void>(null);
  const refRemoveSchoolYearTemplateSubject = useRef<(index: number ) => void>(null);

  const handleAddSchoolYearTemplateSubjects = (event: any) => {

    const {schoolYearId, classTemplateId} = getValues();
    
    if(schoolYearId <= 0 || classTemplateId <= 0) return;

    const lastSubject = getValues().schoolYearTemplateSubjects.findLast(x => !isFalsy(x.reportCard));

    const reportCard = !isFalsy(lastSubject)? lastSubject.reportCard: 
                        (getValues().countReportCard == 1) ? getValues().studyLanguage: '';

    (refAppendSchoolYearTemplateSubjects.current??emptyFunc)( {
      id: 0, schoolYearClassTemplateId: _id, name: '', classTemplateId: 0, category: '', reportCard,
      subject: '', longName: '', shortName: '', coefficient: 1, 
      schoolYearTemplateSubjectExamSchedules: [], 
      schoolYearTemplateSubjectBooks: [],
      schoolYearTemplateSubjectPeriods: schoolYearPeriods.map( (schoolYearPeriod, idx) => ({
        schoolYearPeriodId: schoolYearPeriod.id, schoolYearPeriodName: schoolYearPeriod.name,
        schoolYearTemplateSubjectExamSchedules: []
      }))
      
    });    
  }

  const handleCopySchoolYearTemplateSubjects = (event: any) => {
    const {schoolYearId, classTemplateId} = getValues();
    if(schoolYearId <= 0 || classTemplateId <= 0) return;

    setOpenSchoolYearClassTemplateFilter3(true);
  }

  const [currentSchoolYearTemplateSubject, setCurrentSchoolYearTemplateSubject] = useState<ISchoolYearTemplateSubject>(defaultSchoolYearTemplateSubject)
  const [schoolYearTemplateSubjectIndex, setSchoolYearTemplateSubjectIndex] = useState<number>(-1);
  const schoolYearTemplateSubjectRowActionIcon = ( schoolYearClass: ISchoolYearTemplateSubject) : ActionIconTableRow<ISchoolYearClassTemplate,ISchoolYearTemplateSubject> => {
  
    const res: ActionIconTableRow<ISchoolYearClassTemplate,ISchoolYearTemplateSubject> = {
      toolTip: 'Remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ISchoolYearTemplateSubject) => {
        
        (refRemoveSchoolYearTemplateSubject.current??emptyFunc)(index);         
      }
    }
    return res;
}

const handleSchoolYearTemplateSubjectSelected = (event: React.MouseEvent<unknown>,index: number,row: ISchoolYearTemplateSubject) => {      
  schoolYearTemplateSubjectSelected(index);
}

const schoolYearTemplateSubjectSelected = (index: number) => {
  setSchoolYearTemplateSubjectIndex(index);
  const schoolYearTemplateSubject = getValues().schoolYearTemplateSubjects.at(index);
    
  if(schoolYearPeriods.length>0)
    setCurrentSchoolYearPeriodId( schoolYearPeriods[0].id);

  if(!isFalsy(schoolYearTemplateSubject))
    setCurrentSchoolYearTemplateSubject(schoolYearTemplateSubject!);
}

const onBlurReportCard = (event: any) => {
  if(getValues().countReportCard == 2) return;

  const schoolYearTemplateSubject = getValues().schoolYearTemplateSubjects.at(schoolYearTemplateSubjectIndex);
  if(isFalsy(schoolYearTemplateSubject)) return;
  
  const {reportCard} = schoolYearTemplateSubject;
  if(getValues().schoolYearTemplateSubjects.some( x => x.reportCard !== reportCard))
    enqueueSnackbar( t('You have more than one report card, you have to update before save'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 

}



const cellEditableTemplateSubjectExamSchedule = (row: ISchoolYearTemplateSubjectExamSchedule, cellId: keyof ISchoolYearTemplateSubjectExamSchedule) => {
  return true;
}

const [headSchoolYearTemplateSubjectExamScheduleCells, setHeadSchoolYearTemplateSubjectExamScheduleCells]  = useState<HeadCell<ISchoolYearTemplateSubjectExamSchedule>[]>([]);
useEffect( () => {
  setHeadSchoolYearTemplateSubjectExamScheduleCells([            
    {id:'id', label : t('Id'),  display: false, type: 'numeric', },      
    {id:'schoolYearPeriodExamName', label : t('Exam'),  display: true, type: 'string', width: 30, },
    {id:'examDate', label : t('Date'),  display: true, type: 'date', width: 20, isEditable: cellEditableTemplateSubjectExamSchedule, },
    {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 18, isEditable: cellEditableTemplateSubjectExamSchedule, },
    {id:'endTime', label : t('End'),  display: true, type: 'datetime', width: 18, isEditable: cellEditableTemplateSubjectExamSchedule,},
    {id:'maxMark', label : t('Max mark'),  display: true, type: 'numeric', width: 14, isEditable: cellEditableTemplateSubjectExamSchedule,},
  ]  )
}, [t,i18n])

const refAppendSchoolYearTemplateSubjectExamSchedules = useRef<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateSchoolYearTemplateSubjectExamSchedule = useRef<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>> ) => void>(null);
const refRemoveSchoolYearTemplateSubjectExamSchedule = useRef<(index: number ) => void>(null);

const handleAddSchoolYearTemplateSubjectExamSchedules = (schoolYearPeriodId: number) => (event: any) => {
  
  const schoolYearPeriod = schoolYearPeriods.find(x => x.id === schoolYearPeriodId);
  
  if(isFalsy(schoolYearPeriod)) return;
  
  const schoolYearTemplateSubjectPeriod = currentSchoolYearTemplateSubject.schoolYearTemplateSubjectPeriods
        .find(x => x.schoolYearPeriodId === schoolYearPeriodId);
  
  if(isFalsy(schoolYearTemplateSubjectPeriod)) return;

  const arr = schoolYearPeriodExams.filter(x => x.schoolYearPeriodId === schoolYearPeriodId &&
          !schoolYearTemplateSubjectPeriod.schoolYearTemplateSubjectExamSchedules.some(sc => sc.schoolYearPeriodExamId === x.id) );
  
  setCurrentSchoolYearPeriodExams(arr);
  setOpenSchoolYearPeriodExam(true);  
}

const [openSchoolYearPeriodExam, setOpenSchoolYearPeriodExam] = useState<boolean>(false);
const [currentSchoolYearPeriodExams, setCurrentSchoolYearPeriodExams] = useState<ISchoolYearPeriodExam[]>([]);

const handleSchoolYearPeriodExamDoubleClick = (event: React.MouseEvent<unknown>,row: ISchoolYearPeriodExam) => {  
    
  const {name, id, startDate, schoolYearPeriodId} = row;     
    
  // we allowed a subject to be scheduled more than once, meaning that we can have many marks.
  (refAppendSchoolYearTemplateSubjectExamSchedules.current??emptyFunc)( {
    id: 0, schoolYearTemplateSubjectId: currentSchoolYearTemplateSubject.id, 
    schoolYearPeriodExamId: id, schoolYearPeriodExamName: name, schoolYearPeriodId,
     
    examDate: startDate, startTime: new Date(), endTime: new Date(), maxMark: 20
  }); 
  setOpenSchoolYearPeriodExam(false);      
}

const schoolYearTemplateSubjectExamScheduleRowActionIcon = ( schoolYearClass: ISchoolYearTemplateSubjectExamSchedule) : ActionIconTableRow<ISchoolYearClassTemplate,ISchoolYearTemplateSubjectExamSchedule> => {
  
  const res: ActionIconTableRow<ISchoolYearClassTemplate,ISchoolYearTemplateSubjectExamSchedule> = {
    toolTip: 'remove',
    icon: RemoveCircleIcon,
    hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: ISchoolYearTemplateSubjectExamSchedule) => {
      
      (refRemoveSchoolYearTemplateSubjectExamSchedule.current??emptyFunc)(index);         
    }
  }
  return res;
}


const [currentSchoolYearPeriodId, setCurrentSchoolYearPeriodId] = useState<number>(0);

const handleChangeSchoolYearPeriod = ( event: React.MouseEvent<HTMLElement>, newSchoolYearPeriodId: number ) => {  
  if(isFalsy(newSchoolYearPeriodId) ) 
    return;

  setCurrentSchoolYearPeriodId(newSchoolYearPeriodId);
}

  


useEffect( () => {   
  async function _() {
    
    if(watchSchoolYearId <= 0) return;

    const schoolYear = schoolYears.find(x => x.id === watchSchoolYearId);
    if(isFalsy(schoolYear)) return;

    setValue('schoolYearStartDate', schoolYear.startDate);
    setValue('schoolYearEndDate', schoolYear.endDate);

    const periods = await getSchoolYearPeriods(watchSchoolYearId);    
    setSchoolYearPeriods([...periods]);

    const exams = await getSchoolYearPeriodExams(watchSchoolYearId);    
    setSchoolYearPeriodExams([...exams]);    
  }          

  refSchoolYearId.current = watchSchoolYearId;
  _();

  }, [watchSchoolYearId]);

  const refEnumItems = useRef<IEnumerationItem[]>();
    
    useEffect( () => {   
        refEnumItems.current = enumItems;
     
    }, [enumItems])

  
      useEffect( () => {        
        setCurrentFormNameAtom(`${t('Class')} - ${t('Subject')}`);
        setCurrentBasicTextFilterProps(basicFilterSchoolYearClassTemplate);
        //setCurrentBasicTextFilterProps(basicFilterTeacher);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('SchoolYearClassTemplate',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
          
          setCurrentFormNameAtom(`${t('Class')} - ${t('Subject')} : # ${_data.id} # ` );
            reset({..._data});

            // reset({..._data,
            //   schoolYearTemplateSubjects: _data.schoolYearTemplateSubjects.map( 
            //     (schoolYearTemplateSubject) => (
            //       {
            //         ...schoolYearTemplateSubject,
            //         schoolYearTemplateSubjectPeriods: schoolYearPeriods.map( (period) => ({
            //           schoolYearPeriodId: period.id, schoolYearPeriodName: period.name,
            //           schoolYearTemplateSubjectExamSchedules: schoolYearTemplateSubject.schoolYearTemplateSubjectExamSchedules
            //                 .filter(x => x.schoolYearPeriodId === period.id)
            //         }) )
            //       }) 
            //     ) 
            //   });
              
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        setCurrentFormNameAtom(`${t('Class')} - ${t('Subject')}`);
        reset(defaultSchoolYearClassTemplate);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {       
        
        if(!checkEntitySaveAuthorization('SchoolYearClassTemplate', _id)){
          setIsSaveLoading(false);
             return;
        }
        

          const data = getValues(); 
          if(data.schoolYearId <= 0 || data.classTemplateId <= 0) {
              enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
            }  

            
          const reportCards = data.schoolYearTemplateSubjects.map(x => x.reportCard);
          
          if( Array.from(new Set(reportCards)).length > data.countReportCard) {
            enqueueSnackbar( t('You have more than one report card, you have to update before save'), { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
              setIsSaveLoading(false);
              return;
          }
          
          mutate({...data, schoolYearTemplateSubjects: data.schoolYearTemplateSubjects.map( (x) =>
            ({ ...x, schoolYearTemplateSubjectExamSchedules: x.schoolYearTemplateSubjectPeriods.flatMap( (y) => 
              y.schoolYearTemplateSubjectExamSchedules ) }) )
          });
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('SchoolYearClassTemplate', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={12} lg={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                
                                <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputRef={inputSchoolYear} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                 
                                <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Study level')} 
                                  {...register('classTemplateName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenSchoolYearClassTemplate}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }} />
                                <Controller name='studyLanguage' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="studyLanguage"
                                        label={t('Study language')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                <FormControlLabel sx={{width:'calc(10% - 8px)'}}
                                  label={t('Active ?')}
                                  control={
                                  <Controller
                                      name='isActive'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                                                                
                                  <Controller name='countReportCard' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(10% - 8px)'}} id="countReportCard"
                                        label={t('Count report card')}  inputProps={ {readOnly: false}} focused >
                                        <MenuItem key={1} value={1}>{1}</MenuItem>
                                        <MenuItem key={1} value={2}>{2}</MenuItem>
                                      </TextField>
                                    )}
                                />
                                <Controller                                                   
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Mark')} - ${t('Report card')}`} sx={{width:'calc(10% - 8px)'}} 
                                          allowEmptyFormatting={false} 
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`reportCardMark`}
                                    control={control}
                                /> 
                                <Controller                                                   
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat    
                                          label={`${t('Default success mark')}`} sx={{width:'calc(10% - 8px)'}} 
                                          allowEmptyFormatting={false} 
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`defaultSuccessMark`}
                                    control={control}
                                />      
                                <Controller name='appreciationSystemCode' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(10% - 8px)'}} id="appreciationSystemCode"
                                        label={t('Appreciation system')} inputProps={ {readOnly: false}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_APPRECIATION_SYSTEM ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                         
                                { openSchoolYearClassTemplateFilter2 && <FormDialog open={openSchoolYearClassTemplateFilter2} maxWidth='md'
                                  okText='' cancelText='' title={`${t('Class')} ...`} onCancel={()=> {}} 
                                  onClose={()=> {setOpenSchoolYearClassTemplateFilter2(false);}} onOk={()=> {setOpenSchoolYearClassTemplateFilter2(false);}}  >
                                      <BasicTextFilterForm<ISchoolYearClassTemplate> {...basicFilterSchoolYearClassTemplate2 } />
                              </FormDialog> } 
                              { openSchoolYearClassTemplateFilter3 && <FormDialog open={openSchoolYearClassTemplateFilter3} maxWidth='md'
                                  okText='' cancelText='' title={`${t('Copy subjects from class')} ...`} onCancel={()=> {}} 
                                  onClose={()=> {setOpenSchoolYearClassTemplateFilter3(false);}} onOk={()=> {setOpenSchoolYearClassTemplateFilter2(false);}}  >
                                      <BasicTextFilterForm<ISchoolYearClassTemplate> {...basicFilterSchoolYearClassTemplate3 } />
                              </FormDialog> } 
                            </Box>   
                            
                            
                        </Stack>                        
                      </Grid>
                      {/* <Grid item xs={12} md={12} lg={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                        <Stack flexDirection='column'>                
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <ArrayFieldTableEx<ISchoolYearClassTemplate,ISchoolYearClass,'id'> 
                                  key={`Report field - IReportTableDefaultParameter ${'reportTable.name'}`}
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headSchoolYearClassCells} 
                                  rowsPathName={`schoolYearClasses`}  
                                  title={t(`Classes`)} rowActionIcon={schoolYearClassRowActionIcon}  
                                  
                                  //onRowSelected={handleRoleEntitySelected}                                                    
                                  refAppend={refAppendSchoolYearClasses as MutableRefObject<(value: Partial<FieldArray<ISchoolYear>> | Partial<FieldArray<ISchoolYear>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateSchoolYearClass as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYear>>) => void>}
                                  refRemove={refRemoveSchoolYearClass as MutableRefObject<(index: number) => void>}
                                                    //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                  toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearClasses ,icon: AddCircleIcon,  },
                                ]}
                              />   
                              { openClassroomFilter && <FormDialog open={openClassroomFilter} maxWidth='md'
                                  okText={t('OK')} cancelText={t('Cancel')} title={t('Classroom filter')} onCancel={()=> {setOpenClassroomFilter(false);}} 
                                  onClose={()=> {setOpenClassroomFilter(false);}} onOk={()=> {setOpenClassroomFilter(false);}}  >
                                      <BasicTextFilterForm<IClassroom> {...basicFilterClassroom } />
                              </FormDialog> }      
                              { openTeacherFilter && <FormDialog open={openTeacherFilter} maxWidth='md'
                                okText={''} cancelText={t('Cancel')} title={t('Teacher filter')} onCancel={()=> {setOpenTeacherFilter(false);}} 
                                onClose={()=> {setOpenTeacherFilter(false);}} onOk={()=> {}}  >
                                    <BasicTextFilterForm<ITeacher> {...basicFilterTeacher } />
                            </FormDialog> }
                          </Box>
                        </Stack>
                      </Grid>      */}
                  <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'>                
                      <Box sx={{ mt: 1, width: '100%' }} key={`subjects-${getValues().id}-${getValues().schoolYearTemplateSubjects.length}`}>
                        <ArrayFieldTableEx<ISchoolYearClassTemplate,ISchoolYearTemplateSubject,'id'> 
                            key={`Report field - ISchoolYearTemplateSubject ${'reportTable.name'}`}
                            mainObject={getValues()} fieldKey='id' 
                            headCells={headSchoolYearTemplateSubjectCells} 
                            rowsPathName={`schoolYearTemplateSubjects`}  
                            title={t(`Subjects`)} rowActionIcon={schoolYearTemplateSubjectRowActionIcon}  
                            
                            onRowSelected={handleSchoolYearTemplateSubjectSelected}                                                    
                            refAppend={refAppendSchoolYearTemplateSubjects as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>}
                            refUpdate={refUpdateSchoolYearTemplateSubject as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>>) => void>}
                            refRemove={refRemoveSchoolYearTemplateSubject as MutableRefObject<(index: number) => void>}
                                              //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                            toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearTemplateSubjects ,icon: AddCircleIcon,  },
                              { toolTip: `${t('Copy')}...`, onClickIcon: handleCopySchoolYearTemplateSubjects,icon: FolderCopyIcon,  },
                            ]}
                          />
                      </Box>
                    </Stack>
                  </Grid>      
                  <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                    <Stack flexDirection='column'> 
                      <Box sx={{ mt: 1, width: '100%' }} > 
                        { currentSchoolYearTemplateSubject && <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                          {`${t('Schedule of subject')} : ${currentSchoolYearTemplateSubject.longName}`}
                        </Typography> }                                                     
                      </Box>               
                      { schoolYearTemplateSubjectIndex>= 0 &&  <Box sx={{ mt: 1, width: '100%' }} > 
                        
                        <Controller 
                          key={`key-subject-shortName${schoolYearTemplateSubjectIndex} `}
                            
                            name={`schoolYearTemplateSubjects.${schoolYearTemplateSubjectIndex}.shortName`}
                            control={control}
                            render={({ field }) => <TextField {...field} label={t('Short name')}  sx={{width:`calc(30% - 8px)`}} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />}
                        />
                        <Controller
                          key={`key-subject-${schoolYearTemplateSubjectIndex} `}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:`calc(25% - 8px)`}}
                              id={`key-subject-${schoolYearTemplateSubjectIndex} `}                               
                              label={t('Report card')} 
                              onBlur={onBlurReportCard}
                              >
                            {enumItems && enumItems.filter( e => e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                              (e,ix) => <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem> )
                            }
                            </TextField>
                          )}
                          
                          name={`schoolYearTemplateSubjects.${schoolYearTemplateSubjectIndex}.reportCard`}
                          control={control}
                        />
                        <Controller
                          key={`key-subject-category${schoolYearTemplateSubjectIndex} `}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:`calc(25% - 8px)`}}
                              id={`key-subject-category-${schoolYearTemplateSubjectIndex} `}                               
                              label={t('Category')} 
                              //onBlur={onBlurReportCard}
                              >
                            {enumItems && enumItems.filter( e => e.enumerationCode === Enum_SUBJECT_CATEGORY ).map( 
                              (e,ix) => <MenuItem key={e.code} value={e.code}>{e.name}</MenuItem> )
                            }
                            </TextField>
                          )}
                          
                          name={`schoolYearTemplateSubjects.${schoolYearTemplateSubjectIndex}.category`}
                          control={control}
                        />
                        <Controller
                          key={`key-subject-maxMark-${schoolYearTemplateSubjectIndex} `}
                          render={({ field: {onChange, onBlur, name, value, ref} }) => {
                            return (
                              <NumberFormat      
                                sx={{width:'calc(10% - 8px)'}}
                                //decimalScale={2}
                                allowEmptyFormatting={false}
                                control={control}             
                                label={t('Max mark')}        
                                //fixedDecimalScale={true} 
                                thousandSeparator={true}
                                onValueChange={ (v) => onChange(v.floatValue) }
                                //{...field}
                                customInput={TextFieldRight}
                                defaultValue={value}
                                value={value}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`schoolYearTemplateSubjects.${schoolYearTemplateSubjectIndex}.maxMark`}
                          
                          control={control}
                        />
                        <Controller
                          key={`key-subject-coefficient-${schoolYearTemplateSubjectIndex} `}
                          render={({ field: {onChange, onBlur, name, value, ref} }) => {
                            return (
                              <NumberFormat      
                                sx={{width:'calc(10% - 8px)'}}
                                decimalScale={2}
                                allowEmptyFormatting={false}
                                control={control}             
                                label={t('Coefficient')}        
                                fixedDecimalScale={true} 
                                thousandSeparator={true}
                                onValueChange={ (v) => onChange(v.floatValue) }
                                //{...field}
                                customInput={TextFieldRight}
                                defaultValue={value}
                                value={value}
                                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                              />
                            );
                          }}
                          name={`schoolYearTemplateSubjects.${schoolYearTemplateSubjectIndex}.coefficient`}
                          
                          control={control}
                        />                        
                      </Box> }
                      {                         
                        <Fragment key={`exam schedule - `}>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <ToggleButtonGroup size="small"
                              color="primary"
                              value={currentSchoolYearPeriodId}
                              exclusive
                              onChange={ (event, newSchoolYearPeriodId) => handleChangeSchoolYearPeriod(event, newSchoolYearPeriodId)}
                              aria-label="Small sizes" fullWidth
                            >
                              { (currentSchoolYearTemplateSubject.schoolYearTemplateSubjectPeriods || []).map( 
                                ({schoolYearPeriodId, schoolYearPeriodName}) => 
                                  (<ToggleButton key={schoolYearPeriodId} value={schoolYearPeriodId}>{schoolYearPeriodName}</ToggleButton>)
                              )}
                            </ToggleButtonGroup> 
                          </Box>
                          { 
                            (currentSchoolYearTemplateSubject.schoolYearTemplateSubjectPeriods || []).map( 
                              ({schoolYearPeriodId, schoolYearPeriodName}, idxPeriod) =>
                                ( (schoolYearPeriodId === currentSchoolYearPeriodId) && <Box key={`exam schedule - ${idxPeriod}`} sx={{ mt: 4, width: '100%' }} >
                                  <ArrayFieldTableEx<ISchoolYearClassTemplate,ISchoolYearTemplateSubjectExamSchedule,'id'> 
                                      key={` ${currentSchoolYearTemplateSubject.longName} ${schoolYearPeriodId}`}
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headSchoolYearTemplateSubjectExamScheduleCells} 
                                      rowsPathName={`schoolYearTemplateSubjects.${schoolYearTemplateSubjectIndex}.schoolYearTemplateSubjectPeriods.${idxPeriod}.schoolYearTemplateSubjectExamSchedules`}  
                                      title={` ${t('Scheduling')} - ${schoolYearPeriodName}`} 
                                      rowActionIcon={schoolYearTemplateSubjectExamScheduleRowActionIcon}  
                                      
                                      //onRowSelected={handleSchoolYearClassTemplateSelected}                                                    
                                      refAppend={refAppendSchoolYearTemplateSubjectExamSchedules as MutableRefObject<(value: Partial<FieldArray<ISchoolYearClassTemplate>> | Partial<FieldArray<ISchoolYearClassTemplate>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateSchoolYearTemplateSubjectExamSchedule as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearClassTemplate>>) => void>}
                                      refRemove={refRemoveSchoolYearTemplateSubjectExamSchedule as MutableRefObject<(index: number) => void>}
                                                        //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}                                
                                      toolbarActions={[
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddSchoolYearTemplateSubjectExamSchedules(schoolYearPeriodId),icon: AddCircleIcon,  },
                                        
                                      ]}
                                    />
                                </Box>)                                      
                              )
                          }
                          { openSchoolYearPeriodExam && <FormDialog open={openSchoolYearPeriodExam} maxWidth='md'
                                  okText='' cancelText='' title={t('Exams')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenSchoolYearPeriodExam(false);}} onOk={()=> {setOpenSchoolYearPeriodExam(false);}}  >
                                    <EnhancedTable<ISchoolYearPeriodExam> rows={currentSchoolYearPeriodExams} 
                                      headCells={[            
                                        {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                        {id:'name', label : t('Name'),  display: true, type: 'string', },
                                        {id:'startDate', label : t('Start date'),  display: true, type: 'date', },
                                        {id:'endDate', label : t('End date'),  display: true, type: 'date', },
                                        {id:'gradeWeight', label : t('Grade weight'),  display: true, type: 'numeric', decimalScale: 2 },
                                        
                                      ]} 
                                      title={t(`Queries: Extraction`)} objKey='id' 
                                      stateSelected={undefined} 
                                      onRowSelected={undefined} rowCheckedMode='single'
                                      onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                      onRowDoubleClick={handleSchoolYearPeriodExamDoubleClick} 
                                      rowActionIcon={undefined}
                                      toolbarActions={[
                                        // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                      ]}
                                    />
                              </FormDialog> }
                        </Fragment> 

                        // schoolYear.schoolYearPeriods.map( (schoolYearPeriod, idxPeriod) => {

                        //   return (
                            
                        //   )
                        // })
                      }
                    </Stack>
                  </Grid>                              
                </Grid>
            </Box>
        </FormProvider> 
  )
}

