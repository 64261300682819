
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


import {ISchoolYear,defaultSchoolYear, ISchoolYearClass, IDashboardSchoolYear, defaultDashboardSchoolYear} from 'features/setup/models/SchoolYear';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBilling } from 'features/finance/models/Billing';

import { isFalsy } from 'utility-types';

export const SchoolYearFinanceForm: FC<ISchoolYear> = (props: ISchoolYear = defaultSchoolYear) => {

  const navigate = useNavigate();
  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();

  const {getSchoolYearDashboard} = useSchoolYearService();
  const [dashboard, setDashboard] = useState<IDashboardSchoolYear>(defaultDashboardSchoolYear);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<ISchoolYearClass[]>([]);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<ISchoolYearClass[]>([]);

  const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  const basicFilterSchoolYear = useBasicFilterSchoolYear( 
      async (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
          const {id, name } = row;

          const dash = await getSchoolYearDashboard(id);
          setSchoolYearName(name);

          setDashboard(dash);
          setOpenSchoolYearFilter(false);
      }
  );


  const handleClickSelectSchoolYear = (event: any) => {
    setOpenSchoolYearFilter(true);
  }

  const handleSchoolYearClassDoubleClick = (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {
    const roleEntitySchoolYearClass = roleEntities.find(re => re.entityName === 'SchoolYearClass');
    if(isFalsy(roleEntitySchoolYearClass)) return;
    
    navigate(`/schoolYearClass/${row.id}`);
  }


  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  useEffect(() => {
    async function _() {
             
      const dash = await getSchoolYearDashboard(applicationSetup.currentSchoolYearId);
      const schoolYear = schoolYears.find(s => s.id === applicationSetup.currentSchoolYearId);
      setSchoolYearName(schoolYear?.name || '');
      
      setDashboard(dash);     
    }
    _();
  }, []);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${schoolYearName} :::::`}
                </Typography>
                <Box sx={{ ...justifyCenter, ml: 1 }} >
                  {`${t('Click to change school year')}`}                  
                  <MdOutlineChangeCircle size={24} onClick={handleClickSelectSchoolYear} />
                </Box>  
              </Button>
            </Box>
            { openSchoolYearFilter && <FormDialog open={openSchoolYearFilter} maxWidth='md'
                  okText='' cancelText='' title={t('School year')} onCancel={()=> {}} 
                  onClose={()=> {setOpenSchoolYearFilter(false);}} onOk={()=> {setOpenSchoolYearFilter(false);}}  >
                      <BasicTextFilterForm<ISchoolYear> {...basicFilterSchoolYear } />
              </FormDialog> }
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.amount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount paid')} `} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.amountPaid}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Due amount')} `} paletteColor={theme.palette['warning']} 
                   iconName='IoSendSharp' total={dashboard.dueAmount}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unpaid amount')} `} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.unpaidAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Students')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.countStudents} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Up to date')} `} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.countUpToDateStudents}  />
        </Grid> 
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<ISchoolYearClass> rows={dashboard.schoolYearClasses} 
                  headCells={[            
                    {id:'id', label : t('Id'),  display: false, type: 'numeric', },
                    {id:'name', label : t('Name'),  display: true, type: 'string', width: 20 },
                    {id:'countStudents', label : '#',  display: true, type: 'string', width: 8 },
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 18},
                    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 18},
                    {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18 },
                    {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 18 },                    
                    
                  ]} 
                  title={t(`Financial situation per class`)} objKey='id' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                  onRowDoubleClick={handleSchoolYearClassDoubleClick} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBilling> rows={dashboard.schoolYearBillingCategories} 
                  headCells={[            
                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                    {id:'billingCategoryName', label : t('Name'),  display: true, type: 'string', width: 20 },
                                        
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 20},
                    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 20},
                    {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 20},
                    {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 20},                    
                    
                  ]} 
                  title={t(`Financial situation per category`)} objKey='billingCategoryName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBilling> rows={dashboard.schoolYearBillingTypes} 
                  headCells={[            
                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                    {id:'billingTypeName', label : t('Name'),  display: true, type: 'string', width: 28},
                    
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 18},
                    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 18},
                    {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                    {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 18},                    
                    
                  ]} 
                  title={t(`Financial situation per type`)} objKey='billingTypeName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>
        </Grid>

      </Grid>
      
    </Box>
  )
}
