
export interface ITellerOperation {
    id: number,
    isArticle: boolean,

    registrationId: number,
    
    amount: number,
    operationDate: Date,
    issueDate: Date,
    bearer: string,
    reference: string,
    isCancelled: boolean,
    cancellationDate: Date,
    cancellationPurpose: string,
    isCancelledAtDayClosing: boolean,
    tellerDayClosingId: number,

    tellerOperationBillings: ITellerOperationBilling[],
    tellerOperationModes: ITellerOperationMode[],


    firstName: string,
    lastName: string,
    schoolYearName: string,
    schoolYearClassName: string,
    registrationCode: string,
  }

  export interface ITellerOperationBilling {
    id: number,
    tellerOperationId: number,

    billingId: number,   
    amount: number,

    billingTypeId: number,
    parentBillingTypeId: number,

    billingTypeName: string,
    billingAmount: number,
    amountPaid: number,
    dueAmount: number,
    unpaidAmount: number,
  }

  export interface ITellerOperationMode {
    id: number,
    tellerOperationId: number,
 
    amount: number,
    reference: string,

    type: string,

    checkBankCode: string,
    checkNumber: string,
    checkDate: Date,
    operatorCode: string,
    phoneNumber: string,
    cartType: string,
    cartNumber: string,
    transferFromBankCode: string,
    transferFromToCode: string,
    transferFromAccountNumber: string,
    transferToAccountNumber: string,
    otherReference: string,
    
    paymentOperationId: number,
  }


  export const defaultTellerOperation : ITellerOperation = {
    id: 0,
    isArticle: false,
    registrationId: 0,
    amount: 0,
    operationDate: new Date(),
    issueDate: new Date(),
    bearer: '',
    reference: '',
    isCancelled: false,
    
    cancellationDate: new Date(),
    cancellationPurpose: '',

    isCancelledAtDayClosing: false,
    tellerDayClosingId: 0,

    tellerOperationBillings: [],
    tellerOperationModes: [],

    firstName: '',
    lastName: '',
    schoolYearName: '',
    schoolYearClassName: '',

    registrationCode: ''
  }

  export const defaultTellerOperationEx = {...defaultTellerOperation, isArticle: true}

  export interface ITellerOperationDashboard {
    
    amount: number,
    netAmountAtDayClosing: number,
    netAmount: number,

    cancelledAmount: number,
    cancelledAmountAtDayClosing: number,
    cancelledAmountAfterClosing: number,

    count: number,
    countNetAtDayClosing: number,
    countNet: number,

    countCancelled: number,
    countCancelledAtDayClosing: number,
    countCancelledAfterClosing: number,

    operationsByClass : IOperationDasbordItem[],
    operationsByTeller : IOperationDasbordItem[]
    operationsByBillingType : IOperationDasbordItem[]
    operationsByBillingCategory : IOperationDasbordItem[],
    operationsByMode: IOperationDasbordItem[],
  }

  export interface IOperationDasbordItem {
    id: number,
    name: string, 
    totalAmount: number, 
    count: number, 
    netAmount: number, 
    netAmountAtClosingDay: number, 
    cancelledAmount: number
  }

  export const defaultTellerOperationDashboard : ITellerOperationDashboard = {
    amount: 0,
    netAmountAtDayClosing: 0,
    netAmount: 0,

    cancelledAmount: 0,
    cancelledAmountAtDayClosing: 0,
    cancelledAmountAfterClosing: 0,

    count: 0,
    countNetAtDayClosing: 0,
    countNet: 0,

    countCancelled: 0,
    countCancelledAtDayClosing: 0,
    countCancelledAfterClosing: 0,

    operationsByClass:[],
    operationsByTeller : [],
    operationsByBillingType : [],
    operationsByBillingCategory : [],
    operationsByMode: [],
  }


  export interface ITellerOperationSearch {
    schoolYearId: number,
        
    schoolYearClassName: string,

    firstName: string,
    lastName: string,

    reference: string,
    bearer: string
  }