import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';


import { IEntity, IResult } from 'library/interface';

import useDeliveryService, { useBasicFilterDelivery } from './services/Delivery';

import {  IDelivery, 
  IDeliveryArticle, defaultDelivery } from './models/Delivery';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, FormControlLabel, IconButton, InputAdornment } from '@mui/material';
import IEnumeration, {Enum_DELIVERY_STATUS, Enum_ARTICLE_OPTION, IEnumerationItem} from 'features/configuration/models/Enumeration';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { DatePicker } from '@mui/x-date-pickers';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBillingType } from 'features/setup/models/BillingType';
import useBillingTypeService  from 'features/setup/services/BillingType';

export const DeliveryForm: FC<IDelivery> = (props: IDelivery = defaultDelivery) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createDelivery, updateDelivery } = useDeliveryService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const {getBillingTypes} = useBillingTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterDelivery = useBasicFilterDelivery( 
    
    async (event: React.MouseEvent<unknown>, row: IDelivery) => {
      const {id, reference } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          setParameterEnumeration(id, name);                                      
          setOpenEnumerationFilter(false);
      }
  );

  const setParameterEnumeration = (enumerationId: number, enumerationName: string) => {
    
    // const applicationQueryParameter = getValues().applicationQueryParameters.at(applicationQueryParameterIndex);
    // if(isFalsy(applicationQueryParameter)) return;

    // (refUpdateDeliveryArticle.current??emptyFunc)(applicationQueryParameterIndex,
    //   {...applicationQueryParameter, enumerationId, enumerationName });
  }

  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          setParameterEntity(name ?? '', description?? '');                           
          setOpenEntityFilter(false);
      }
  );
  
  const setParameterEntity = (name: string, description: string) => {
   
    // const applicationQueryParameter = getValues().applicationQueryParameters.at(applicationQueryParameterIndex);
    // if(isFalsy(applicationQueryParameter)) return;

    // (refUpdateDeliveryArticle.current??emptyFunc)(applicationQueryParameterIndex,
    //   {...applicationQueryParameter, entityName: name, entityDescription: description });
  }
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IDelivery>({defaultValues:defaultDelivery});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchBusinessApplicationId = watch('businessApplicationId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IDelivery>,Error,IDelivery>(
      _id>0?updateDelivery:createDelivery, {   
        onSuccess: (data: IResult<IDelivery>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Delivery',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IDelivery>(['Delivery', _id], () => retrieveEntity('Delivery',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Delivery'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_DELIVERY_STATUS, Enum_ARTICLE_OPTION ] ));


    const getInputAdornmentQueryParameter = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle)  => ({
 
      toolTip: `${t('Description')} ...`,
      icon: MoreVertIcon,
      onClickIcon: (event: any, index: number, row: IDeliveryArticle ) => {                
        
      }  
   })

  const cellEditableArticle = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle) => {
    return true;
  }

 const getArticleOptionList = (row: IDeliveryArticle, cellId: keyof IDeliveryArticle, 
    opts: {value: string, name: string}[]) => {        

    return getAsOptions(refEnumItems.current ?? [],Enum_ARTICLE_OPTION);
}

  const [headDeliveryArticleCells, setHeadDeliveryArticleCells]  = useState<HeadCell<IDeliveryArticle>[]>([]);
  useEffect(() => {
    setHeadDeliveryArticleCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'billingTypeName', label : t('Name'),  display: true, type: 'string', width: 45, isEditable: cellEditableArticle,},
      {id:'option', label : t('Option'),  display: true, type: 'string', width: 35, isEditable: cellEditableArticle,
        getOptions: getArticleOptionList },
      {id:'quantityDemand', label : t('Quantity'),  display: true, type: 'numeric', width: 20, isEditable: cellEditableArticle, },
    ]  )
  }, [t,i18n])

  const refAppendDeliveryArticles = useRef<(value: Partial<FieldArray<IDelivery>> | Partial<FieldArray<IDelivery>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateDeliveryArticle = useRef<(index: number,value: Partial<FieldArray<IDelivery>> ) => void>(null);
  const refRemoveDeliveryArticle = useRef<(index: number ) => void>(null);

  const [billingTypeArticles, setBillingTypeArticles] = useState<IBillingType[]>([]);
  const [openArticles, setOpenArticles] = useState<boolean>(false);
  const handleAddDeliveryArticle = (event: any) => {
    setOpenArticles(true);      
  }

  const deliveryArticleRowActionIcon = ( applicationQueryParameter: IDeliveryArticle) : ActionIconTableRow<IDelivery,IDeliveryArticle> => {
  
    const res: ActionIconTableRow<IDelivery,IDeliveryArticle> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IDeliveryArticle) => {
        
        (refRemoveDeliveryArticle.current??emptyFunc)(index);            
      }
    }
    return res;
}

const handleArticleDoubleClick = (event: React.MouseEvent<unknown>,row: IBillingType) => {    
  
  const {name, id, isArticle} = row;     
  if(!isArticle) return;
    
  (refAppendDeliveryArticles.current??emptyFunc)(
    {id:0, deliveryId:_id, billingTypeId: id, billingTypeName: name, option: '',
      quantityDemand: 0,  quantity: 0, quantityUsed: 0 });

  setOpenArticles(false);      
}

const handleClickSearchEnumeration = (event: any) => {
  setOpenEnumerationFilter(true);
}

const handleRemoveEnumeration = (event: any) => {
  setParameterEnumeration(0, '')
}

const handleClickSearchEntity = (event: any) => {
  setOpenEntityFilter(true);
}

const handleRemoveEntity = (event: any) => {
  setParameterEntity('', '')
}


const refEnumItems = useRef<IEnumerationItem[]>();    
useEffect( () => {   
    refEnumItems.current = enumItems;
  
}, [enumItems])

useEffect( () => {   
  async function _() {
    
    const arr = await getBillingTypes( {code: '', name: '', description: ''})
    setBillingTypeArticles( arr.filter(x => x.isArticle));  
  }

  _();

  }, []);

useEffect( () => {              
  setCurrentFormNameAtom(t('Query'));  
  setCurrentBasicTextFilterProps(basicFilterDelivery);
}, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    
    if(_id > 0)
      retrieveData('Delivery',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultDelivery);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {   
  if(!checkEntitySaveAuthorization('Delivery', _id)) {
    setIsSaveLoading(false);
       return;
  }

  const data = getValues(); 
  if(data.reference.trim() === '' || data.description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
    }

  if(data.deliveryArticles.some( x => x.option.trim() === '' || x.quantityDemand === 0 )) {
    enqueueSnackbar( t('All articles must have option and quantity defined'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
            setIsSaveLoading(false);
            return;
  }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('Delivery', _id);
}

const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
  openEntityPrintDrawer('Delivery', _id);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label="Id" {...register('id')} 
                                    inputProps={ {readOnly: true}} />
                                <TextField sx={{width:'calc(60% - 8px)'}} id="reference" label={t('Reference')} 
                                    {...register('reference')} />
                                <Controller control={control}
                                  name='issueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}                      
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller 
                                  name={`status`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(35% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_DELIVERY_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange} readOnly disableOpenPicker                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                              />
                              
                            </Box>
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={7} component={Paper} >
                        <Stack flexDirection='column'>                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<IDelivery,IDeliveryArticle,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headDeliveryArticleCells} rowsPathName={`deliveryArticles` }
                                title={t('_Articles')} rowActionIcon={deliveryArticleRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                                    
                                refAppend={refAppendDeliveryArticles as MutableRefObject<(value: Partial<FieldArray<IDelivery>> | Partial<FieldArray<IDelivery>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateDeliveryArticle as MutableRefObject<(index: number,value: Partial<FieldArray<IDelivery>>) => void>}
                                refRemove={refRemoveDeliveryArticle as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddDeliveryArticle ,icon: AddCircleIcon,  },
                                
                                ]}
                              />  
                               { openArticles && <FormDialog open={openArticles} maxWidth='md'
                                  okText='' cancelText='' title={t('_Articles')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenArticles(false);}} onOk={()=> {setOpenArticles(false);}}  >
                                    <EnhancedTable<IBillingType> rows={billingTypeArticles} 
                                      headCells={[            
                                        {id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                        {id:'code', label : t('Code'),  display: true, type: 'string', },
                                        {id:'name', label : t('Name'),  display: true, type: 'string', },
                                        
                                        
                                      ]} 
                                      title={t(`_Articles`)} objKey='id' 
                                      stateSelected={undefined} 
                                      onRowSelected={undefined} rowCheckedMode='single'
                                      onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                      onRowDoubleClick={handleArticleDoubleClick} 
                                      rowActionIcon={undefined}
                                      toolbarActions={[
                                        // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                      ]}
                                    />
                              </FormDialog> }                      
                            </Box>
                            
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
