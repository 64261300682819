
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {isFalsy} from 'utility-types';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import AssessmentIcon from '@mui/icons-material/Assessment';

import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tooltip } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import ArrayFieldTableEx, {ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';


import { IEdition, IEditionExtraction, IEditionExtractionReport, defaultEdition} from './models/Edition';
import useEditionService, {useBasicFilterEdition} from './services/Edition';

import useApplicationQueryService, {useBasicFilterApplicationQuery} from 'features/setup/services/ApplicationQuery';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import { IEntity, IResult } from 'library/interface';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService from 'features/configuration/services/Enumeration';

import { useBasicFilterReport } from 'features/configuration/services/Report';

import { Enum_EDITION_CATEGORY, IEnumerationItem } from 'features/configuration/models/Enumeration';

import { globalConfig } from 'config';
import { IBusinessApplication, IApplicationQuery } from 'features/setup/models/ApplicationQuery';
import { IReport } from 'features/configuration/models/Report';



type QueryType = 'prepare' | 'empty' | 'extract';

export const EditionForm: FC<IEdition> = (props: IEdition) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityAuthorizationBox, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createEdition, updateEdition } = useEditionService();

  //const {getBusinessApplications} = useApplicationQueryService();

  
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const basicFilterEdition = useBasicFilterEdition( 
    (event: React.MouseEvent<unknown>, row: IEdition) => {
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const emptyFunc = (obj: any) => {}
  const [openApplicationQueryFilter, setOpenApplicationQueryFilter] = useState(false);
    const basicFilterApplicationQuery = useBasicFilterApplicationQuery( 
      
      async (event: React.MouseEvent<unknown>, row: IApplicationQuery) => {

        const {id, name } = row;

       if(currentQueryType === 'prepare') {
        setValue('queryPrepareId', id);
        setValue('queryPrepareName', name);
       } else if(currentQueryType === 'empty') {
        setValue('queryEmptyId', id);
        setValue('queryEmptyName', name);
       } else if(currentQueryType === 'extract' && editionExtractionIndex >= 0) {
        
        const editionExtraction = getValues().editionExtractions.at(editionExtractionIndex);
        if(!isFalsy(editionExtraction)) {
          (refUpdateEditionExtraction.current??emptyFunc)(editionExtractionIndex, {...editionExtraction, 
              businessApplicationQueryId: id, businessApplicationQueryName: name});
        }
       }

        //const requestTypeBusinessApp = getValues().requestTypeBusinessApps[requestTypeBusinessAppIndex];

        // (refUpdateRequestTypeBusinessApp.current??emptyFunc)(requestTypeBusinessAppIndex,
        //   (currentQueryType === 'masterEntity') ? 
        //     {...requestTypeBusinessApp, masterEntityQueryId:id, masterEntityQueryName: name } :
        //   (currentQueryType === 'itemEntity') ? 
        //     {...requestTypeBusinessApp, itemEntityQueryId:id, itemEntityQueryName: name} : //{...requestTypeBusinessApp}
        //   (currentQueryType === 'intermediary') ? 
        //     {...requestTypeBusinessApp, intermediaryEntityQueryId:id, intermediaryEntityQueryName: name } :
        //   (currentQueryType === 'office') ? 
        //     {...requestTypeBusinessApp, officeEntityQueryId:id, officeEntityQueryName: name } :
        //   (currentQueryType === 'user') ? 
        //     {...requestTypeBusinessApp, userEntityQueryId:id, userEntityQueryName: name } : {...requestTypeBusinessApp}
        //    ); 

        
                
        setOpenApplicationQueryFilter(false);
      }
    );
  const refCurrentBusinessApplicationId = useRef(0);


  const [openReportFilter, setOpenReportFilter] = useState(false);
    const basicFilterReport = useBasicFilterReport( 
        async (event: React.MouseEvent<unknown>, row: IReport) => {
            if( isFalsy(row)) return;
            const {id, name, entityName ,description} = row;

            if(entityName !== '') {

            }
            
            if( editionExtractionIndex < 0 ) 
              return;

            const editionExtraction = getValues().editionExtractions.at(editionExtractionIndex);
            if( isFalsy(editionExtraction) ) 
              return;

            if( !editionExtraction.editionExtractionReports.some( x => x.reportId === id) )
              (refAppendEditionExtractionReports.current??emptyFunc)({ id: 0, editionExtractionId: 0,
                reportId: id, reportName: name, reportDescription: description}); 
            
            setOpenReportFilter(false);
        }
    );

  const [openExtractionReports, setOpenExtractionReports] = useState(false);

  const methods = useForm<IEdition>({defaultValues: props});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchBusinessApplicationId = watch('businessApplicationId');
  const watchQueryPrepareId = watch('queryPrepareId');
  const watchQueryEmptyId = watch('queryEmptyId');

  const [editionExtractionIndex, setEditionExtractionIndex] = useState(-1);
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IEdition>,Error,IEdition>(
      _id>0?updateEdition:createEdition, {   
        onSuccess: (data: IResult<IEdition>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Edition',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

  const [businessApplications, setBusinessApplications] = useState<IBusinessApplication[]>([]);

  const {data: _data, refetch} = useQuery<IEdition>(['Edition', _id], () => retrieveEntity('Edition',_id), 
    {refetchOnWindowFocus: false ,enabled: false } );

  const {data: enumItems} = useQuery<IEnumerationItem[]>(
    ['EnumerationItems', 'Edition'], () => 
      getEnumerationItemsByEnumerationCodes( [Enum_EDITION_CATEGORY] ));

  const [currentQueryType, setCurrentQueryType] = useState<QueryType>('prepare');
  const handleClickSearchApplicationQuery = (queryType: QueryType) => (event: any) => {
    
    //const {businessApplicationId} = getValues();

    // if(businessApplicationId <= 0) {
    //   enqueueSnackbar( t('Business application is not selected'), { variant: 'warning',
    //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
    //   return;
    // }

    setCurrentQueryType(queryType);
    setOpenApplicationQueryFilter(true);
  }

  const handleClickRemoveQuery = (queryType: QueryType) => (event: any) => {
    if(queryType === 'prepare') {
      setValue('queryPrepareId', 0);
      setValue('queryPrepareName', '');
    } if(queryType === 'empty') {
      setValue('queryEmptyId', 0);
      setValue('queryEmptyName', '');
    }
  }
        
  
  const editionExtractionRowActionIcon = ( editionExtraction: IEditionExtraction) : ActionIconTableRow<IEdition,IEditionExtraction> => {  
    const res: ActionIconTableRow<IEdition,IEditionExtraction> = {
      toolTip: 'link',
      icon: RemoveCircleIcon,
      hasAction: (index: number,row: IEditionExtraction) => true, 
      isActionExecuting: true,
      onRowClickIcon: (event : any, index: number, row: IEditionExtraction) => {        
        
        (refRemoveEditionExtraction.current??emptyFunc)(index); 
      }
    }
    return res;
  }

  const getInputAdornmentQuery = (row: IEditionExtraction, cellId: keyof IEditionExtraction)  => ({
 
    toolTip: t('Query'),
    icon: ArrowDropDownCircleIcon,
    onClickIcon: (event: any, index: number, row: IEditionExtraction ) => {
              
      setEditionExtractionIndex(index);
      
      handleClickSearchApplicationQuery('extract')(undefined);
    }  
 })


 const getInputAdornmentReports = (row: IEditionExtraction, cellId: keyof IEditionExtraction)  => ({
 
  toolTip: t('Reports'),
  icon: AssessmentIcon,
  onClickIcon: (event: any, index: number, row: IEditionExtraction ) => {
    
    if( index < 0 ) 
      return;

    const editionExtraction = getValues().editionExtractions.at(index);
    if( isFalsy(editionExtraction) ) 
      return;

    const {description} = editionExtraction;
    if(description.trim() === '') {
      enqueueSnackbar( t('Description is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      return;
    }
    
    setCurrentEditionExtractionDescription(description);
    setEditionExtractionIndex(index);
    setOpenExtractionReports(true);
  }  
})

const [currentEditionExtractionDescription, setCurrentEditionExtractionDescription] = useState<string>('');
const cellEditableEditionExtraction = (row: IEditionExtraction, cellId: keyof IEditionExtraction) => {
  return true;
}

  const [headEditionExtractionCells, setHeadEditionExtractionCells]  = useState<HeadCell<IEditionExtraction>[]>([]);
  useEffect(() => {
    setHeadEditionExtractionCells([            
      {id:'description', label : t('Description'),  display: true, type: 'string', width: 50, 
        getInputAdornment: getInputAdornmentReports, isEditable: cellEditableEditionExtraction  },
      {id:'applicationQueryName', label : t('Query'),  display: true, type: 'string', width: 50, 
        getInputAdornment: getInputAdornmentQuery },
      
    ])
  }, [t,i18n]);


  const refAppendEditionExtractions = useRef<(value: Partial<FieldArray<IEdition>> | Partial<FieldArray<IEdition>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateEditionExtraction = useRef<(index: number,value: Partial<FieldArray<IEdition>> ) => void>(null);
  const refRemoveEditionExtraction = useRef<(index: number ) => void>(null);

  const handleAddEditionExtractions = (event: any) => {
    (refAppendEditionExtractions.current??emptyFunc)({ id: 0, editionId: 0,
      description: '', businessApplicationQueryId: 0, allowByAuthorization: false,
      businessApplicationQueryName: '', editionExtractionReports: [] }); 
  }

  const editionExtractionReportRowActionIcon = ( editionExtractionReport: IEditionExtractionReport) : ActionIconTableRow<IEdition,IEditionExtractionReport> => {  
    const res: ActionIconTableRow<IEdition,IEditionExtractionReport> = {
      toolTip: 'link',
      icon: RemoveCircleIcon,
      hasAction: (index: number,row: IEditionExtractionReport) => true, 
      isActionExecuting: true,
      onRowClickIcon: (event : any, index: number, row: IEditionExtractionReport) => {        
        
        (refRemoveEditionExtractionReport.current??emptyFunc)(index); 
      }
    }
    return res;
  }

  const [headEditionExtractionReportCells, setHeadEditionExtractionReportCells]  = useState<HeadCell<IEditionExtractionReport>[]>([]);
  useEffect(() => {
    setHeadEditionExtractionReportCells([            
      {id:'reportName', label : t('Name'),  display: true, type: 'string', width: 45, },
      {id:'reportDescription', label : t('Description'),  display: true, type: 'string', width: 55,  },
      
    ])
  }, [t,i18n]);

  const refAppendEditionExtractionReports = useRef<(value: Partial<FieldArray<IEdition>> | Partial<FieldArray<IEdition>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateEditionExtractionReport = useRef<(index: number,value: Partial<FieldArray<IEdition>> ) => void>(null);
  const refRemoveEditionExtractionReport = useRef<(index: number ) => void>(null);

  const handleAddEditionExtractionReports = (event: any) => {
    
    setOpenReportFilter(true);
  }

  // useEffect( () => {        
  //   refCurrentBusinessApplicationId.current = watchBusinessApplicationId;
  // }, [watchBusinessApplicationId]);  

  
        
    useEffect( () => {        
      setCurrentFormNameAtom(t('Desktop publishing'));
      setCurrentBasicTextFilterProps(basicFilterEdition);
    }, []);     

    /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
  and the new useEffect will take place ********************/
  useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    
    if(_id > 0)
      retrieveData('Edition',_id, refetch);  
  }, [_id] );

  useEffect( () => {        
        
    if(_data && _data.id > 0) {       
        reset({..._data });
       }
      
    }, [_data, reset]);
    
  const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
    _setId(0);       
    reset(defaultEdition);    
  }
  
  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
    if(!checkEntitySaveAuthorization('Edition', _id))
        return;

      const data = getValues(); 
      if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }
      
      mutate(data);
  }

  const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
    openEntityActionDrawer('Edition', _id);
  }
  
const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}
  const authorization = async (event: MouseEvent<HTMLButtonElement>) => {          
    openEntityAuthorizationBox('Edition', _id);   
  } 

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} lg={6}  component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnAuth' onClick={authorization} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} 
                                    {...register('id')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } /> 
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} 
                                    {...register('name')} inputProps={ {readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                      label={t('Active ?')}
                                      control={
                                      <Controller
                                          name='isActive'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  /> 
                            </Box>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(70% - 8px)'}} id="description" label={t('Description')} 
                                    {...register('description')} inputProps={ {readOnly: false, autoComplete: 'new-password' } } />
                              <Controller name='category' control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="category"
                                      label={t('Category')} inputProps={ {readOnly: false}}>
                                      {enumItems && enumItems.filter( e => e.enumerationCode === Enum_EDITION_CATEGORY).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }  
                                    </TextField>
                                    )}
                                  />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              {/* <Controller name='businessApplicationId' control={control} 
                                    defaultValue={ (businessApplications).length === 1 ? (businessApplications)[0].id : 0 }
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(70% - 8px)'}} id="businessApplicationId"
                                        label={t('Business application')} inputProps={ {readOnly: false}} >
                                        {businessApplications.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> */}
                                <FormControlLabel sx={{width:'calc(100% - 8px)'}}
                                      label={t('Allowed by auth.?')}
                                      control={
                                      <Controller
                                          name='allowByAuthorization'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} key={` prepare ${getValues().queryPrepareId}`}>
                              <TextField sx={{width:'calc(50% - 8px)'}} id="queryPrepareName" label={t('Query: Prepare')} key={`queryPrepareName ${getValues().queryPrepareId}`}
                                  {...register('queryPrepareName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">   
                                      { (isFalsy(watchQueryPrepareId) ||  watchQueryPrepareId<=0) ?
                                        <IconButton color="primary" onClick={handleClickSearchApplicationQuery('prepare')}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={handleClickRemoveQuery('prepare')}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                               
                                    </InputAdornment>
                                  )
                                }} />  
                                { openApplicationQueryFilter && <FormDialog open={openApplicationQueryFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenApplicationQueryFilter(false);}} onOk={()=> {setOpenApplicationQueryFilter(false);}}  >
                                    <BasicTextFilterForm<IApplicationQuery> {...basicFilterApplicationQuery} />
                                  </FormDialog>  }       
                              <TextField sx={{width:'calc(50% - 8px)'}} id="queryEmptyName" label={t('Query: Empty')} key={`queryEmptyName ${getValues().queryEmptyId}`} 
                              {...register('queryEmptyName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">    
                                  { (isFalsy(watchQueryEmptyId) ||  watchQueryEmptyId<=0) ?
                                    <IconButton color="primary" onClick={handleClickSearchApplicationQuery('empty')}>
                                      <ArrowDropDownCircleIcon />
                                    </IconButton> : 
                                    <IconButton color="primary" onClick={() => {setValue('queryEmptyName', '');setValue('queryEmptyId', 0);}}>
                                      <RemoveCircleIcon />
                                    </IconButton>
                                  }                                                                                           
                                </InputAdornment>
                              )
                            }} />                                                                   
                            </Box>
                        </Stack>                        
                    </Grid> 
                    <Grid item xs={12} md={6} lg={6}  component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<IEdition,IEditionExtraction,'id'> 
                                  key={`EditionExtraction -`}
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headEditionExtractionCells} rowsPathName='editionExtractions' 
                                  title={t(`Queries: Extraction`)} rowActionIcon={editionExtractionRowActionIcon}  
                                  //onRowSelected={handleEnumerationItemSelected}
                                                      
                                  refAppend={refAppendEditionExtractions as MutableRefObject<(value: Partial<FieldArray<IEdition>> | Partial<FieldArray<IEdition>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateEditionExtraction as MutableRefObject<(index: number,value: Partial<FieldArray<IEdition>>) => void>}
                                  refRemove={refRemoveEditionExtraction as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}
                                  
                                  toolbarActions={[
                                      { toolTip: `${t('Print')}...`, onClickIcon: handleAddEditionExtractions ,icon: AddCircleIcon, },  
                                                            
                                  ]}
                              />
                              { openExtractionReports && <FormDialog open={openExtractionReports} maxWidth='sm'
                              okText={t('OK')} cancelText='' title={`${t('Reports')} - ${currentEditionExtractionDescription}`} onCancel={()=> {}} 
                              onClose={()=> {setOpenExtractionReports(false);}} onOk={()=> {setOpenExtractionReports(false);}}  >
                                  <Stack flexDirection='column'>
                                    <Box sx={{ mt: 1, width: '100%' }} key={`editionExtractionReports-${getValues().id}-`}>
                                      <ArrayFieldTableEx<IEdition,IEditionExtractionReport,'id'> 
                                
                                        mainObject={getValues()} fieldKey='id'   
                                        headCells={headEditionExtractionReportCells} rowsPathName={`editionExtractions.${editionExtractionIndex}.editionExtractionReports`}
                                        title={t('Reports')} rowActionIcon={editionExtractionReportRowActionIcon}  
                                        //onRowSelected={handleJobScheduleSelected}
                                                            
                                        refAppend={refAppendEditionExtractionReports as MutableRefObject<(value: Partial<FieldArray<IEdition>> | Partial<FieldArray<IEdition>>[], options?: FieldArrayMethodProps) => void>}
                                        refUpdate={refUpdateEditionExtractionReport as MutableRefObject<(index: number,value: Partial<FieldArray<IEdition>>) => void>}
                                        refRemove={refRemoveEditionExtractionReport as MutableRefObject<(index: number) => void>}

                                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                        //displayMore={undefined}
                                        toolbarActions={[      
                                          { toolTip: `${t('Add')}...`, onClickIcon: handleAddEditionExtractionReports ,icon: AddCircleIcon, },                                                                           
                                        ]}
                                      /> 
                                    </Box>
                                  </Stack>
                            </FormDialog> }
                            { openReportFilter && <FormDialog open={openReportFilter} maxWidth='md'
                                  okText={t('OK')} cancelText='' title={t('Report filter')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenReportFilter(false);}} onOk={()=> {setOpenReportFilter(false);}}  >
                                      <BasicTextFilterForm<IReport> {...basicFilterReport } />
                              </FormDialog> }
                            </Box>                   
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </FormProvider> 
  )
}

