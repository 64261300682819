
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ITellerOperation, ITellerOperationDashboard, ITellerOperationSearch } from "../models/TellerOperation";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createTellerOperation = async (tellerOperation: ITellerOperation)  =>       
        await (await axios.post('/api/finance/tellerOperation/create', tellerOperation)).data;       
        
    const updateTellerOperation = async (tellerOperation: ITellerOperation)  =>       
        await (await axios.post('/api/finance/tellerOperation/update', tellerOperation)).data; 
    
    const getTellerOperation = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/tellerOperation/get-tellerOperation/${id}`));
      return await data;
    }
   

    const getTellerOperations = async (criteria: ITellerOperationSearch, pagination?: IPagination) : Promise<ITellerOperation[]> => {
      
      const {schoolYearId, schoolYearClassName, firstName, lastName, reference, bearer} = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/tellerOperation/get-tellerOperations?schoolYearId=${schoolYearId}` +
        `&schoolYearClassName=${schoolYearClassName}&firstName=${firstName}&lastName=${lastName}&reference=${reference}&bearer=${bearer}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getTellerOperationDashboard = async (schoolYearId: number, dateFrom: Date, dateTo: Date) : Promise<ITellerOperationDashboard> => {
      
      const sDateFrom = encodeURIComponent(dateFrom.toISOString().split('T')[0]);
      const sDateTo = encodeURIComponent(dateTo.toISOString().split('T')[0]);

      const {data} = (await axios.get(`/api/finance/tellerOperation/get-tellerOperation-dashboard?schoolYearId=${schoolYearId}&sDateFrom=${sDateFrom}&sDateTo=${sDateTo}`));
      return await data;
    }
      
    return {    
      createTellerOperation,
      updateTellerOperation,
      getTellerOperation,
      getTellerOperations,

      getTellerOperationDashboard
     
    } 
}

export default _;

export interface IFilterTellerOperationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [ITellerOperation[], React.Dispatch<React.SetStateAction<ITellerOperation[]>>],
}

const defaultFilterProductOption: IFilterTellerOperationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterTellerOperation = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITellerOperation) => void,
                                            filterOption?: IFilterTellerOperationOption  ) => {

  const { getTellerOperations } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg,schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headTellerOperationCells, setHeadTellerOperationCells]  = useState<HeadCell<ITellerOperation>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 20 },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 20},


    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },
    {id:'operationDate', label : t('Date'),  display: true, type: 'date', },
    {id:'reference', label : t('Reference'),  display: true, type: 'string', },
    {id:'schoolYearName', label : t('School year'),  display: true, type: 'string', },
    {id:'schoolYearClassName', label : t('Class'),  display: true, type: 'string', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
    {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
      options: [ ...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },

    {name: 'schoolYearClassName', text: t('Class'), value: ''},

    {name: lg.includes('en')?'firstName':'lastName', text: lg.includes('en')?t('First name'):t('Last name'), value: ''},
    {name: lg.includes('en')?'lastName':'firstName', text: lg.includes('en')?t('Last name'):t('First name'), value: ''},  

    {name: 'reference', text: t('Reference'), value: ''},
    {name: 'bearer', text: t('Bearer'), value: ''},  
      
    ]);    

  const [filteredTellerOperations, ] = useState<ITellerOperation[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ITellerOperation[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const schoolYearId = toNumber(temp);

    const schoolYearClassName = filterElements.find( elt => elt.name === 'schoolYearClassName')?.value || '';
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';

    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
    const bearer = filterElements.find( elt => elt.name === 'bearer')?.value || '';
       
    const arr = await getTellerOperations( {schoolYearId, schoolYearClassName,firstName,lastName,reference,bearer }, pagination );
    
    return arr;
  }

  const objKey: keyof ITellerOperation = 'id';

  return {
    title: t('Teller operation'), headCells: headTellerOperationCells, objKey,
    filterElements, rows: filteredTellerOperations, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
