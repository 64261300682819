import { HeadCell } from 'components/ui/EnhancedTable';
import useAxios from 'library/axios'; 
import { useState } from 'react';
import {DataType, IExtensionType,  IExtensionTypeSearch, BaseType } from "../models/ExtensionType";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { useNavigate } from 'react-router-dom';


const _ = () => {

    const axios = useAxios();     

    const createExtensionType = async (extensionType: IExtensionType)  =>       
        await (await axios.post('/api/configuration/extensionType/create', extensionType)).data;       
        
    const updateExtensionType = async (extensionType: IExtensionType)  =>       
        await (await axios.post('/api/configuration/extensionType/update', extensionType)).data; 
    
    const getExtensionType = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/extensionType/get-extensionType/${id}`));
      return await data;
    }

    const getExtensionTypes = async (criteria: IExtensionTypeSearch) : Promise<IExtensionType[]> => {
    const {name, description} = criteria;

    const {data} = (await axios.get(`/api/configuration/extensionType/get-extensionTypes?name=${name}&description=${description}`));
      return await data;
    }

    const defaultValue = (dataType: DataType): any => {
      switch(dataType) {
        case 'boolean': return false;
        case 'date': return new Date();
        case 'decimal': return 0;
        case 'entity': return 0;
        case 'enumeration': return '';
        case 'float': return 0;
        case 'integer': return 0;
        case 'text': return '';
        case 'time': return new Date();

        
        default: return null;
      }
    }

    const baseType = (dataType : string | undefined) : BaseType => {

      switch(dataType) {
        case 'boolean': return 'boolean';
        case 'date': return 'date';
        case 'decimal': return 'numeric';
        case 'entity': return 'numeric';
        case 'enumeration': return 'string';
        case 'float': return 'numeric';
        case 'integer': return 'numeric';
        case 'text': return 'string';
        case 'time': return 'time';

        case 'numeric': return 'numeric';
        default: return 'string';
      }
    }

    const valueType = (value: string, dataType : DataType) : any => {

      switch(dataType) {
        case 'boolean': return Boolean(value);
        case 'date': return Date.parse( String(value) );
        case 'decimal': return Number(value);
        case 'entity': return Number(value);
        case 'enumeration': return String(value);
        case 'float': return Number(value);
        case 'integer': return Number(value);
        case 'text': return String(value);
        case 'time': return Date.parse( String(value) );
        default: return String(value);
      }
    }
      
    return {    
      createExtensionType,
      updateExtensionType,

      getExtensionType,
      getExtensionTypes,

      defaultValue,
      baseType,
      valueType      
    } 
}

export default _;


export const useBasicFilterExtensionType = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IExtensionType) => void  ) => {

  const { getExtensionTypes } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  

  const [headExtensionTypeCells, setHeadExtensionTypeCells]  = useState<HeadCell<IExtensionType>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },

  ]); 

  const [filterElements, ] = useState([       
        {name: 'name', text: t('Name'), value: ''}, 
        {name: 'description', text: t('Description'), value: ''},       
      ]);

  const [filteredExtensionTypes, ] = useState<IExtensionType[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IExtensionType[]> => {
            
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getExtensionTypes( {name, description} );
    
    return arr;
  }

  const objKey: keyof IExtensionType = 'id';

  return {
    title: t('Extension type'), headCells: headExtensionTypeCells, objKey,
    filterElements, rows: filteredExtensionTypes, 
    onFilterButtonClick, onRowDoubleClick
  }
}

