
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IMatrix, IMatrixInput, IMatrixSearch, IMatrixVector, IMatrixVectorInput } from "../models/Matrix";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';
import { string } from 'yup';
import { isFalsy } from 'utility-types';


const _ = () => {

    const axios = useAxios(); 

    const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createMatrix = async (matrix: IMatrix)  =>       
        await (await axios.post('/api/configuration/matrix/create', matrix)).data;       
        
    const updateMatrix = async (matrix: IMatrix)  =>       
        await (await axios.post('/api/configuration/matrix/update', matrix)).data; 
    
    const getMatrix = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/matrix/get-matrix/${id}`));
      return await data;
    }
   
    const getMatrixVectorText = ({matrixVectorInputs} : IMatrixVector) : string => {      
      const inputs = matrixVectorInputs.map( ({minValue, maxValue, baseType}) =>  (baseType === 'numeric' ? `{${String(minValue)},${String(maxValue)}}}` : String(minValue))  );      
      
      return `[${inputs.join(',')}]`;
    }

    const getMatrixs = async (criteria: IMatrixSearch) : Promise<IMatrix[]> => {

      const { name, description} = criteria;

      const {data} = (await axios.get(`/api/configuration/matrix/get-matrixs?name=${name}&description=${description}`));
      return await data;
    }
    

    const isInputVectorEqual = (v1: IMatrixVectorInput[], v2: IMatrixVectorInput[]) : boolean => {
      if(isFalsy(v1) || isFalsy(v2)) return false;

      if(v1.length !== v2.length) return false;

      // there is alias in v1 that doesn't exist in v2 or in other hand 1<=>2
      if(v1.some( i1 => !v2.some( i2 => i1.matrixInputAlias === i2.matrixInputAlias ) )) return false; 

      if(v2.some( i2 => !v1.some( i1 => i2.matrixInputAlias === i1.matrixInputAlias ) )) return false; 

      const countMatch = v1.reduce( (accumulator: number,i1: IMatrixVectorInput) => {
          const i2 = v2.find( i => i.matrixInputAlias === i1.matrixInputAlias )!;
          return accumulator + ( (i1.minValue===i2.minValue && i1.maxValue===i2.maxValue)?1:0)
        } , 0  );

      return  countMatch === v1.length;
    }
      
    return {    
      createMatrix,
      updateMatrix,
      getMatrix,
      getMatrixs,

      getMatrixVectorText,
      isInputVectorEqual
    } 
}

export default _;

export interface IFilterMatrixOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IMatrix[], React.Dispatch<React.SetStateAction<IMatrix[]>>],
}

const defaultFilterMatrixOption: IFilterMatrixOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterMatrix = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IMatrix) => void,
                                            filterOption?: IFilterMatrixOption  ) => {

  const { getMatrixs } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterMatrixOption;



  const [headMatrixCells, setHeadMatrixCells]  = useState<HeadCell<IMatrix>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredMatrixs, ] = useState<IMatrix[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IMatrix[]> => {
    
    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getMatrixs( { name,description} );
    
    return arr;
  }

  const objKey: keyof IMatrix = 'id';

  return {
    title: t('Matrix'), headCells: headMatrixCells, objKey,
    filterElements, rows: filteredMatrixs, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
