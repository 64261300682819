
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, ClickAwayListener, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

import { ListItemIcon } from '@mui/material';

import TextFieldRight from 'components/ui/TextFieldRight';

import { stripHtml } from "string-strip-html";

import { AiOutlineMessage, AiOutlineWhatsApp, AiOutlineFacebook, AiOutlineFileExcel, AiOutlineFileWord, AiOutlineLayout } from 'react-icons/ai';

import { HiOutlineDocumentReport } from 'react-icons/hi';

import { isFalsy } from 'utility-types';
import DocViewer, { PDFRenderer, PNGRenderer, MSDocRenderer ,DocViewerRenderers} from '@cyntler/react-doc-viewer';

import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useSmsApiService, { useBasicFilterSmsApi } from './services/SmsApi';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { ISmsApi, defaultSmsApi, ISmsApiQueryParameter, ISmsApiOperator } from './models/SmsApi';

import { globalConfig } from 'config';
import { RichWysiwygEditor } from 'components/ui/RichWysiwygEditor';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';

import useEnumerationService from 'features/configuration/services/Enumeration';
import { IEnumerationItem, Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT } from 'features/configuration/models/Enumeration';

//import * from '../../../public'

export const SmsApiForm: FC<ISmsApi> = (props: ISmsApi = defaultSmsApi) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createSmsApi, updateSmsApi } = useSmsApiService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterReport = useBasicFilterSmsApi( 
    (event: React.MouseEvent<unknown>, row: ISmsApi) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [expressionField, setExpressionField] = useState<keyof ISmsApi>('returnMessageOkExpression');      

  const emptyFunc = (obj: any) => {}
      
  const methods = useForm<ISmsApi>({defaultValues:defaultSmsApi});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
 

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISmsApi>,Error,ISmsApi>(
      _id>0?updateSmsApi:createSmsApi, {   
        onSuccess: (data: IResult<ISmsApi>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SmsApi',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      const [enumItems, setEnumItems] = useState<IEnumerationItem[]>([]);
      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'SmsApi'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_REPORTFIELD_DATA_TYPE,Enum_REPORTFIELD_DATA_FORMAT] ));

    const {data: _data, refetch} = useQuery<ISmsApi>(['SmsApi', _id], () => retrieveEntity('SmsApi',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const refAppendSmsApiQueryParameters = useRef<(value: Partial<FieldArray<ISmsApi>> | Partial<FieldArray<ISmsApi>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateSmsApiQueryParameter = useRef<(index: number,value: Partial<FieldArray<ISmsApi>> ) => void>(null);
    const refRemoveSmsApiQueryParameter = useRef<(index: number ) => void>(null);

    const handleAddSmsApiQueryParameter = (event: any) => {
      const ix = getValues().smsApiQueryParameters.length + 1;
      (refAppendSmsApiQueryParameters.current??emptyFunc)({ id:0, smsApiId:0, name: '', value: '' });
    }

    const smsApiQueryParameterRowActionIcon = ( jobSchedule: ISmsApiQueryParameter) : ActionIconTableRow<ISmsApi,ISmsApiQueryParameter> => {  
      const res: ActionIconTableRow<ISmsApi,ISmsApiQueryParameter> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: ISmsApiQueryParameter) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: ISmsApiQueryParameter) => {           
          
          (refRemoveSmsApiQueryParameter.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const cellEditableSmsApiQueryParameter = (row: ISmsApiQueryParameter, cellId: keyof ISmsApiQueryParameter) => {
      return true;
    }
    const [headSmsApiQueryParameterCells, setHeadSmsApiQueryParameterCells]  = useState<HeadCell<ISmsApiQueryParameter>[]>([]);
    useEffect(() => {
      setHeadSmsApiQueryParameterCells([            
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 65, isEditable: cellEditableSmsApiQueryParameter},
        //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
        {id:'value', label : t('Value'),  display: true, type: 'string', width: 35, isEditable: cellEditableSmsApiQueryParameter}
      ]  )
    }, [t,i18n])


    const refAppendSmsApiOperators = useRef<(value: Partial<FieldArray<ISmsApi>> | Partial<FieldArray<ISmsApi>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateSmsApiOperator = useRef<(index: number,value: Partial<FieldArray<ISmsApi>> ) => void>(null);
    const refRemoveSmsApiOperator = useRef<(index: number ) => void>(null);

    const handleAddSmsApiOperator = (event: any) => {
      const ix = getValues().smsApiOperators.length + 1;
      (refAppendSmsApiOperators.current??emptyFunc)({ id:0, smsApiId:0, initialNumber: '', priority: 0 });
    }

    const smsApiOperatorRowActionIcon = ( jobSchedule: ISmsApiOperator) : ActionIconTableRow<ISmsApi,ISmsApiOperator> => {  
      const res: ActionIconTableRow<ISmsApi,ISmsApiOperator> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: ISmsApiOperator) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: ISmsApiOperator) => {           
          
          (refRemoveSmsApiOperator.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const cellEditableSmsApiOperator = (row: ISmsApiOperator, cellId: keyof ISmsApiOperator) => {
      return true;
    }
    const [headSmsApiOperatorCells, setHeadSmsApiOperatorCells]  = useState<HeadCell<ISmsApiOperator>[]>([]);
    useEffect(() => {
      setHeadSmsApiOperatorCells([            
        {id:'initialNumber', label : t('Initial'),  display: true, type: 'string', width: 65, isEditable: cellEditableSmsApiOperator},
        //{id:'startDate', label : t('Start date'),  display: true, type: 'date', width: 25, isEditable: cellEditableJobSchedule},
        {id:'priority', label : t('Priority'),  display: true, type: 'numeric', width: 35, isEditable: cellEditableSmsApiOperator}
      ]  )
    }, [t,i18n])

       
      const handleClickOpenReturnOkExpression = (event: any) => {
        const {returnMessageOkExpression} = getValues();
        
        setCurrentEntityName('SmsApi');
        setExpressionField('returnMessageOkExpression');    
        setCurrentExpression(returnMessageOkExpression);    
        setOpenEntityExpression(true);
      }

      const handleClickOpenBalanceExpression = (event: any) => {  
        const {balanceCountExpression} = getValues();
        
        setCurrentEntityName('SmsApi');
        setExpressionField('balanceCountExpression');  
        setCurrentExpression(balanceCountExpression);           
        setOpenEntityExpression(true);
      }      

      const handleClickOkExpression = async () => {

        const checkExpression = await checkEntityExpressionSyntax('SmsApi', currentExpression);
        if(!checkExpression){
          enqueueSnackbar( t('Expression evaluation error'), { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        const {syntaxOk, syntaxError, returnType} = checkExpression;
        if(!syntaxOk) {
          enqueueSnackbar( syntaxError , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        if(expressionField === 'returnMessageOkExpression' && !returnType.includes('bool') ) {
          enqueueSnackbar( t('The result of expression must be boolean') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        if(expressionField === 'balanceCountExpression' && !returnType.includes('numeric') ) {
          enqueueSnackbar( t('The result of expression must be numeric') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
        }

        setValue(expressionField, currentExpression);
        setOpenEntityExpression(false);
      }
   


      useEffect( () => {        
        setCurrentFormNameAtom(t('SmsApi'));
        setCurrentBasicTextFilterProps(basicFilterReport);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('SmsApi',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {
         
        if(_data && _data.id > 0) {
            reset({..._data });
          }
          
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);       
        reset(defaultSmsApi);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
        if(!checkEntitySaveAuthorization('SmsApi', _id)) {
          setIsSaveLoading(false);
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
                
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('SmsApi', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

    

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={1} columnSpacing={0.2}>
                    <Grid item xs={12} md={6} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(15% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(65% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                      label={t('Active ?')}
                                      control={
                                      <Controller
                                          name='isActive'
                                          control={control}
                                          render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                      />} 
                                  />  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />            
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(100% - 8px)'}} id="urlSendMessageApi" label={t('Url message (send)')} {...register('urlSendMessageApi')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                           </Box>
                           <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(100% - 8px)'}} id="urlCheckBalanceApi" label={t('Url balance (check)')} {...register('urlCheckBalanceApi')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                           </Box>
                           <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="returnMessageExpressionOk" multiline={true} rows={3}
                                label={t('Expression message OK')} {...register('returnMessageOkExpression')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenReturnOkExpression}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }} />
                                { openEntityExpression && <FormDialog open={openEntityExpression} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityExpression(false);}} onOk={handleClickOkExpression}  >
                                    <EntityExpression entityName={currentEntityName} properties={[]} 
                                      expression={currentExpression} setExpression={setCurrentExpression} />
                                  </FormDialog>  } 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="balanceCountExpression" multiline={true} rows={3}
                                label={t('Balance count expression')} {...register('balanceCountExpression')}
                                  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenBalanceExpression}>
                                          <DeveloperModeIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  )
                                }} />                                
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(50% - 8px)'}} id="recipientName" label={t('Recipient name')} {...register('recipientName')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="messageName" label={t('Message name')} {...register('messageName')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                           </Box>
                           <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(50% - 8px)'}} id="phoneNumberPrefix" label={t('Prefix number')} {...register('phoneNumberPrefix')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Number lentgh')} sx={{width:'calc(50% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}

                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='phoneNumberLength'
                                control={control}
                              />
                           </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <ArrayFieldTableEx<ISmsApi,ISmsApiQueryParameter,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headSmsApiQueryParameterCells} rowsPathName='smsApiQueryParameters' 
                                title={t('Query parameters')} rowActionIcon={smsApiQueryParameterRowActionIcon}  
                                //onRowSelected={handleJobScheduleSelected}
                                                    
                                refAppend={refAppendSmsApiQueryParameters as MutableRefObject<(value: Partial<FieldArray<ISmsApi>> | Partial<FieldArray<ISmsApi>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateSmsApiQueryParameter as MutableRefObject<(index: number,value: Partial<FieldArray<ISmsApi>>) => void>}
                                refRemove={refRemoveSmsApiQueryParameter as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                { toolTip: `${t('Add')}...`, onClickIcon: handleAddSmsApiQueryParameter ,icon: AddCircleIcon,  },
                                
                                ]}
                              /> 
                           </Box>
                        </Stack>                        
                    </Grid>     
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                              <ArrayFieldTableEx<ISmsApi,ISmsApiOperator,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headSmsApiOperatorCells} rowsPathName='smsApiOperators' 
                                  title={t('Operators')} rowActionIcon={smsApiOperatorRowActionIcon}  
                                  //onRowSelected={handleJobScheduleSelected}
                                                      
                                  refAppend={refAppendSmsApiOperators as MutableRefObject<(value: Partial<FieldArray<ISmsApi>> | Partial<FieldArray<ISmsApi>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateSmsApiOperator as MutableRefObject<(index: number,value: Partial<FieldArray<ISmsApi>>) => void>}
                                  refRemove={refRemoveSmsApiOperator as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleAddSmsApiOperator ,icon: AddCircleIcon,  },
                                  
                                  ]}
                                /> 
                           </Box>
                        </Stack>                        
                    </Grid>
                       
                    
                                     
                </Grid>
            </Box>
        </FormProvider> 
  )
}

