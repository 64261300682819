
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { useTheme } from '@mui/material/styles';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MoreIcon from '@mui/icons-material/More';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearIcon from '@mui/icons-material/Clear';

import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import {ThreeDots, Watch} from "react-loader-spinner";


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useAdmissionService, { useBasicFilterAdmission } from './services/Admission';


import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Slide, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IAdmission, defaultAdmission } from './models/Admission';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { IEnumerationItem, Enum_REGISTRATION_STATUS, Enum_STUDY_TYPE, Enum_SCHOOL_MATERIAL,
    Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_ADMISSION_MODE } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';


import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';
import { ISchoolYear, ISchoolYearClass, ISchoolYearClassTemplate } from 'features/setup/models/SchoolYear';

import useBusDestinationService, { useBasicFilterBusDestination } from 'features/setup/services/BusDestination';
import { IBusDestination } from 'features/setup/models/BusDestination';

import { DatePicker } from '@mui/x-date-pickers';
import { IClassTemplate } from 'features/setup/models/ClassTemplate';
import useClassTemplateService, { useBasicFilterClassTemplate } from 'features/setup/services/ClassTemplate';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBilling, defaultBilling } from 'features/finance/models/Billing';
import { IPerson } from './models/Person';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from './services/Person';
import { IBillingType } from 'features/setup/models/BillingType';
import useBillingTypeService, { useBasicFilterBillingType, useBasicFilterArticleBillingType, useBasicFilterTransportBillingType } from 'features/setup/services/BillingType';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { debounce } from 'lodash';
import { GrClose, GrSearch, GrSearchAdvanced } from 'react-icons/gr';
import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';

import { Colors } from 'themes/theme';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ITellerOperation } from 'features/finance/models/TellerOperation';
import { Print } from '@mui/icons-material';


export const AdmissionForm: FC<IAdmission> = (props: IAdmission = defaultAdmission) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const theme = useTheme();

  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes,getExtensionEnumerationItemsBySchoolYear, getAsOptions } = useEnumerationService();
  
  const { createAdmission, updateAdmission } = useAdmissionService();

  const { getSchoolYearClasses, getSchoolYearClassTemplates } = useSchoolYearService();

  const { getPersons, getPersonsSearchCount, getParentPortables } = usePersonService();

  const {getAvailableArticleBillingTypes} = useBillingTypeService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);
  //const refSchoolYears = useRef<ISchoolYear[]>();

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterAdmission = useBasicFilterAdmission( 
    (event: React.MouseEvent<unknown>, row: IAdmission) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign, gender, civility} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  
        setValue('gender', gender);  
        setValue('civility', civility);  
                          
        setOpenPersonFilter(false);
      }
  );

  
  const emptyFunc = (obj: any) => {}
  
  const methods = useForm<IAdmission>({defaultValues:defaultAdmission});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  const watchSchoolYearId = watch('schoolYearId');
  const watchStudyType = watch('studyType');
  const watchStudyLanguage = watch('studyLanguage');

  const watchSchoolYearClassTemplateId = watch('schoolYearClassTemplateId');
  
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchParticularSign = watch('particularSign');
  const watchPersonId = watch('personId');


   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
   

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const {firstName, lastName, particularSign} = getValues();
        return {firstName, lastName, particularSign};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, firstName, lastName, birthDate, birthPlace, particularSign, civility, gender, studentCode} = row;
        
        setValue('personId', id);
        setValue('firstName', firstName);
        setValue('lastName', lastName);  
        setValue('birthDate', birthDate);   
        setValue('birthPlace', birthPlace);                    
        setValue('particularSign', particularSign);  

        setValue('civility', civility);
        setValue('gender', gender);
                          
        setOpenPersonSearchFilter(false);
      }
  );

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IAdmission>,Error,IAdmission>(
      _id>0?updateAdmission:createAdmission, {   
        onSuccess: (data: IResult<IAdmission>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Admission',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
          
        }
      });

      //// Mutation for actions.....
      
    const {data: _data, refetch} = useQuery<IAdmission>(['Admission', _id], () => retrieveEntity('Admission',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: extensionEnumerationItems } = useQuery<IEnumerationItem[]>(['SchoolYear','EnumerationItem', watchSchoolYearId], () =>
      getExtensionEnumerationItemsBySchoolYear(watchSchoolYearId) );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Admission'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_STUDY_LEVEL, Enum_STUDY_LANGUAGE, Enum_REGISTRATION_STATUS, 
              Enum_STUDY_TYPE, Enum_PERSON_GENDER, Enum_PERSON_CIVILITY, Enum_ADMISSION_MODE ] ));

    //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

    
    const [schoolYearClassTemplates, setSchoolYearClassTemplates] = useState<ISchoolYearClassTemplate[]>([]);
    const [classes, setClasses] = useState<ISchoolYearClass[]>([]);

    
  const handleClickOpenPerson = async (event: any) => {
    setOpenPersonFilter(true);
  }

  const handleClickRemovePerson = async (event: any) => {
    setValue('personId', 0);
    setValue('firstName', '');
    setValue('lastName', '');
    setValue('birthPlace', '');
    setValue('civility', '');
    setValue('gender', '');
  }

  const [openParentPortables, setOpenParentPortables] = useState<boolean>(false);
  const [currentParentPortableIdx, setCurrentParentPortableIdx] = useState<number>(0);
  const [parentPortables, setParentPortables] = useState<IPerson[]>([]);
  const handleClickOpenParentPortables = async (portableIdx: number) => {

    const {personId} = getValues();
    if(personId <= 0) return;

    const prtPortables = await getParentPortables(personId);
    setParentPortables(prtPortables);
    setCurrentParentPortableIdx(portableIdx);
    setOpenParentPortables(true);
  }


  const handlePersonPortableDoubleClick = (event: React.MouseEvent<unknown>,row: IPerson) => {  

    setOpenParentPortables(false);
    if(currentParentPortableIdx === 1)
      setValue('portable1', row.portable);
    else if(currentParentPortableIdx === 2)
    setValue('portable2', row.portable);
  }
  
  
  const debouncedNameChange = useRef(
    debounce( async () => {      
      const {personId, firstName, lastName, particularSign} = getValues();

      if(personId>0) return;

      if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' ))
        return;

      const count = await getPersonsSearchCount(firstName, lastName, particularSign);

      if(!isFalsy(refPersonSnackbarId.current)) closeSnackbar(refPersonSnackbarId.current!);
      
      if(count > 0) 
        refPersonSnackbarId.current = enqueueSnackbar( `${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch(firstName,lastName,particularSign) } );             

    }, 2500)
  ).current;

  

  const refPersonSnackbarId = useRef<SnackbarKey>();
  const xActionPersonSearch = (firstName: string, lastName: string, particularSign:string): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  useEffect( () => {
      debouncedNameChange();    
  }, [watchFirstName, watchLastName, watchParticularSign, debouncedNameChange]); 

  useEffect( () => {              
    async function _() {
      const arr = await getSchoolYearClassTemplates(watchSchoolYearId, false, '', '');

      setSchoolYearClassTemplates( arr.filter( x => x.studyLanguage === watchStudyLanguage) );
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchStudyLanguage]); 

  useEffect( () => {              
    async function _() {
      // const schoolYear = (schoolYears || []).find(sy => sy.id === watchSchoolYearId);
      // const year = isFalsy(schoolYear) ? 0 : schoolYear.year;
      if(watchSchoolYearId > 0 && watchSchoolYearClassTemplateId > 0) {
        const arr = await getSchoolYearClasses({
            schoolYearId: watchSchoolYearId, 
            name: '' ,
            studyType: watchStudyType, 
            studyLanguage:watchStudyLanguage,
            studyLevel: '' });
        
        setClasses(arr.filter(x => x.schoolYearClassTemplateId === watchSchoolYearClassTemplateId && x.isActive) );  
      }    
    }

    _();
  }, [watchSchoolYearId, watchStudyType, watchSchoolYearClassTemplateId, watchStudyLanguage  ]); 


  const [schoolYearClassTemplateCountStudents, setSchoolYearClassTemplateCountStudents] = useState<number>(0);
  

  const refEnumItems = useRef<IEnumerationItem[]>();    
    useEffect( () => {   
        refEnumItems.current = enumItems;
        const studyTypes = (enumItems || []).filter(x => x.enumerationCode === Enum_STUDY_TYPE);
        if(getValues().status === '95' && studyTypes.length === 1)
          setValue('studyType', studyTypes[0].code);
     
    }, [enumItems]);


  useEffect( () => {        
    if(getValues().schoolYearId <= 0)
      setValue('schoolYearId', applicationSetup.currentSchoolYearId);
  }, []);


      useEffect( () => {        
        setCurrentFormNameAtom(t('Admission'));
        setCurrentBasicTextFilterProps(basicFilterAdmission);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Admission',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset({..._data});
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultAdmission, schoolYearId: applicationSetup.currentSchoolYearId});    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Admission', _id)){
          setIsSaveLoading(false);
          return;
        }
        
        const data = getValues(); 
        if(data.firstName.trim() === '' && data.lastName.trim() === '') {
            enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }

              
        if(isFalsy(data.studyType)) {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

         mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Admission', _id);
      }
      
      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('Admission', _id);
      }

    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {    
       queryClient.invalidateQueries(['Admission',_id]);        
      await retrieveData('Admission',_id, refetch);        
      reset(_data);               
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('School year'))} - ${t('Study type')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                                                
                                <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='studyType' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="schoolYearId"
                                        label={t('Study type')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_TYPE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />      

                                <Controller name='studyLanguage' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="studyLanguage"
                                        label={t('Study language')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_STUDY_LANGUAGE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                                                        
                                
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap key={` student code :`}
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Student'))} - ${t('Information')} ::`}
                              </Typography>                                                       
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().personId}`}>
                              <TextField sx={{width:'calc(15% - 8px)'}} id="personId" label={t('Student Id')} 
                                {...register('personId')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                    { (isFalsy(watchPersonId) ||  watchPersonId<=0) ?
                                        <IconButton color="primary" onClick={handleClickOpenPerson}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={handleClickRemovePerson}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                                                                     
                                  </InputAdornment>
                                ) 
                              }} />
                              <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                {...register(lg.startsWith('fr')?'lastName':'firstName')} inputProps={ {readOnly: (watchPersonId>0) , style: {textTransform: 'none'}}}/>   
                              <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                {...register(lg.startsWith('fr')?'firstName':'lastName')} inputProps={ {readOnly: (watchPersonId>0) ,style: {textTransform: 'none'}}} />  
                              <TextField sx={{width:'calc(15% - 8px)'}} id="particularSign" label={t('Part. sign')} 
                                {...register('particularSign')}  />                              
                                { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                              </FormDialog> }
                              { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> }
                            </Box>                                                   
                            <Box sx={{ mt: 0.25, width: '100%' }} >
                              <Controller name='gender' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="gender"
                                        label={t('Gender')} inputProps={ {readOnly: (watchPersonId>0) }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_GENDER ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                                <Controller name='civility' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="civility"
                                        label={t('Civility')} inputProps={ {readOnly: (watchPersonId>0) }} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PERSON_CIVILITY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                /> 
                              <Controller control={control}
                                  name='birthDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} 
                                      readOnly={(watchPersonId>0)}
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    /> )}
                                />
                              <TextField sx={{width:'calc(50% - 8px)'}} id="birthPlace" label={t('Birth place')} 
                                  {...register('birthPlace')}  inputProps={ {readOnly: (watchPersonId>0) }}/> 
                            </Box>
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Class'))} `}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                              
                              
                                <Controller name='schoolYearClassTemplateId' control={control} 
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="schoolYearClassId"
                                        label={t('Class')} inputProps={ {readOnly: false}} focused
                                        helperText={`# ${t('Student')}(s) : ${schoolYearClassTemplateCountStudents} `}
                                        >
                                        {schoolYearClassTemplates && schoolYearClassTemplates.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.classTemplateName}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />    

                                <Controller name='admissionMode' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="admissionMode"
                                        label={t('Admission mode')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_ADMISSION_MODE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                             

                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                              <Controller name='status' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                        label={t('Status')} inputProps={ {readOnly: true}} >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_REGISTRATION_STATUS ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Status date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(18% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(18% - 8px)'}} />}
                                    /> )}
                                /> 
                                <Controller control={control}
                                  name='validationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Validation date')} 
                                      onChange={onChange} disableOpenPicker readOnly                    
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(17% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(17% - 8px)'}} />}
                                    /> )}
                                />
                            </Box>
                                                        
                            <Box sx={{ mt: 0.25, width: '100%' }} > 
                              <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                    sx={{...typographyGroupBoxStyling}}>
                                {`${t(('Parent'))} - ${t('_Tutor')}`}
                              </Typography>                                                       
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >                              
                              <TextField sx={{width:'calc(34% - 8px)'}} id="fatherName" label={`${t('_Father')}`} 
                                {...register('fatherName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(33% - 8px)'}} id="motherName" label={`${t('_Mother')}`} 
                                {...register('motherName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <TextField sx={{width:'calc(33% - 8px)'}} id="tutorName" label={`${t('_Tutor')}`} 
                                {...register('tutorName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                        </Stack>                        
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                        <Stack flexDirection='column'>       
                                                        
                        </Stack>
                      </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

