import { IEnumerationItem } from "features/configuration/models/Enumeration"
import { BaseType, DataType } from "features/configuration/models/ExtensionType"


export interface IProduct {
    id: number,
    code: string,
    isActive: boolean,
    name: string,
    description: string,

    productTypeId: number,

    accessoriesTarificationId: number,
    accessoriesTarificationName: string,

    accessoriesTaxTarificationId: number,
    accessoriesTaxTarificationName: string,

    accessoriesExpression: string,
    accessoriesTaxExpression: string,


    currentExtensionAlias: string,    
    currentExtensionDescription: string,
    currentExtensionTypeId: number,
    currentExtensionTypeName: string,

    currentExtensionTypeType: DataType,
    currentExtensionTypeBaseType: BaseType,
    
    productCoverages: IProductCoverage[],  

    productExtensions: IProductExtension[]  
  }

  export interface IProductCoverage {
    id: number,
    productId: number,
    coverageId: number,

    coverageCode: string,
    coverageName: string,
    coverageDescription: string,
    
    obligatoryExpression: string,
    exclusionExpression: string,
    
    baseAmountExpression: string,
    rawAmountExpression: string,
    taxExpression: string,
    defaultCapitalExpression: string,

    amountTarificationId: number,
    amountTarificationName: string,
    taxTarificationId: number,
    taxTarificationName: string,

    isReductionAllowed: boolean,
    isPolicyReductionApply: boolean,

    maxReductiontRate:	number,
    maxBonusRate:	number,
    maxOtherRate:	number,
    
    maxLoadedRate:	number,   

    productCoverageExtensions: IProductCoverageExtension[]
  }

  export interface IProductExtension {
    id: number,
    productId: number,
    alias: string,
    description: string,

    extensionTypeId: number,
    extensionTypeName: string,
  
    extensionEnumerationItems: IEnumerationItem[],

    type: DataType,
    baseType: BaseType
  }

  export interface IProductCoverageExtension {
    id: number,
    productCoverageId: number,
    alias: string,
    description: string,

    extensionTypeId: number,
    extensionTypeName: string,
  
    extensionEnumerationItems: IEnumerationItem[],

    type: DataType,
    baseType: BaseType
  }

  export const defaultProduct : IProduct = {
    id: 0,
    code: '',
    isActive: true,
    name: '',
    description: '',

    productTypeId: 0,

    accessoriesTarificationId: 0,
    accessoriesTarificationName: '',

    accessoriesTaxTarificationId: 0,
    accessoriesTaxTarificationName: '',

    accessoriesExpression: '0.0',
    accessoriesTaxExpression: '0.0',
    
    currentExtensionAlias: '',    
    currentExtensionDescription: '',
    currentExtensionTypeId: 0,
    currentExtensionTypeName: '',

    currentExtensionTypeType: 'text',
    currentExtensionTypeBaseType: 'string',
    
    productCoverages: [],

    productExtensions: []
  }

  export const defaultProductCoverage : IProductCoverage = {
    id: 0,
    productId: 0,
    coverageId: 0,

    coverageCode: '',
    coverageName: '',
    coverageDescription: '',
    obligatoryExpression: 'false',
    exclusionExpression: 'false',

    baseAmountExpression: '0.0',
    rawAmountExpression: '0.0',
    taxExpression: '0.0',
    defaultCapitalExpression: '0.0',

    amountTarificationId: 0,
    amountTarificationName: '',
    taxTarificationId: 0,
    taxTarificationName: '',

    isReductionAllowed: false,
    isPolicyReductionApply: false,

    maxReductiontRate: 0,
    maxBonusRate: 0,
    maxOtherRate: 0,
    
    maxLoadedRate: 0,

    productCoverageExtensions: []
  }

  export interface IProductSearch {
    
    code: string,
    name: string,
    description: string
  }