import { IBilling } from "features/finance/models/Billing";
import { DataType } from "features/configuration/models/ExtensionType";
import { ITellerOperation } from "features/finance/models/TellerOperation";


export interface IAdmission {
    id: number,
    schoolYearClassTemplateId: number,
    admissionMode: string,
    personId: number,
    admissionCode: string,
    status: string,
    statusDate: Date,
    effectiveDate: Date,
    validationDate: Date,
    
    portable1: string,
    portable2: string,
    email1: string,
    email2: string,
    
    firstName: string,
    lastName: string,
    particularSign: string,
    birthDate: Date,
    birthPlace: string,
    civility: string,
    gender: string,

    defaultLanguage: string,
    nationality: string,
    identificationType: string,
    identificationNumber: string,
    identificationDelivrancePlace: string,
    identificationStartDate: Date,
    identificationEndDate: Date,

    schoolYearClassTemplateName: string,
    schoolYearName: string,

    schoolYearId: number,
    studyType: string,

    studyLanguage: string,    
    classTemplateId: number,

    fatherName: string,
    motherName: string,
    tutorName: string,
  }

  
  export const defaultAdmission : IAdmission = {
    id: 0,
    schoolYearClassTemplateId: 0,
    admissionMode: '',
    personId: 0,
    admissionCode: '',
    status: '95',
    statusDate: new Date(),
    effectiveDate: new Date(),
    validationDate: new Date(),
    
    portable1: '',
    portable2: '',
    email1: '',
    email2: '',


    firstName: '',
    lastName: '',
    particularSign: '',
    birthDate: new Date(),
    birthPlace: '',
    civility: '',
    gender: '',

    defaultLanguage: '',
    nationality: '',
    identificationType: '',
    identificationNumber: '',
    identificationDelivrancePlace: '',
    identificationStartDate: new Date(),
    identificationEndDate: new Date(),

    schoolYearClassTemplateName: '',
    schoolYearName: '',

    schoolYearId: 0,
    studyType: '',

    studyLanguage: '',
    classTemplateId: 0,

    fatherName: '',
    motherName: '',
    tutorName: '',
  }

  

  export interface IAdmissionSearch {
    schoolYearId: number,
    
    studyLanguage: string,

    schoolYearClassTemplateName: string,

    firstName: string,
    lastName: string,
        
  }