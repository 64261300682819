
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ISchoolYearBus, ISchoolYearBusSearch } from "../models/SchoolYearBus";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
import { toNumber } from 'lodash';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createSchoolYearBus = async (classroom: ISchoolYearBus)  =>       
        await (await axios.post('/api/setup/schoolYearBus/create', classroom)).data;       
        
    const updateSchoolYearBus = async (classroom: ISchoolYearBus)  =>       
        await (await axios.post('/api/setup/schoolYearBus/update', classroom)).data; 
    
    const getSchoolYearBus = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/classroom/get-schoolYear-bus/${id}`));
      return await data;
    }
   

    const getSchoolYearBuses = async (criteria: ISchoolYearBusSearch) : Promise<ISchoolYearBus[]> => {

      const { schoolYearId, name,description,licensePlate} = criteria;

      const {data} = (await axios.get(`/api/setup/schoolYearBus/get-schoolYear-buses?schoolYearId=${schoolYearId}&licensePlate=${licensePlate}&name=${name}&description=${description}`));
      return await data;
    }
    
      
    return {    
      createSchoolYearBus,
      updateSchoolYearBus,
      getSchoolYearBus,
      getSchoolYearBuses
      
    } 
}

export default _;

export interface IFilterSchoolYearBusOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ISchoolYearBus[], React.Dispatch<React.SetStateAction<ISchoolYearBus[]>>],
}

const defaultFilterSchoolYearBusOption: IFilterSchoolYearBusOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterSchoolYearBus = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ISchoolYearBus) => void,
                                            filterOption?: IFilterSchoolYearBusOption  ) => {

  const { getSchoolYearBuses } = _();

  const { t, i18n } = useTranslation();   
  const {schoolYears, applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterSchoolYearBusOption;



  const [headSchoolYearBusCells, setHeadSchoolYearBusCells]  = useState<HeadCell<ISchoolYearBus>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },    
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 35},
    {id:'description', label : t('Name'),  display: true, type: 'string', width: 35},

    {id:'licensePlate', label : t('Immat.'),  display: true, type: 'string', },
    {id:'energyType', label : t('Energy'),  display: true, type: 'string', },
    {id:'isActive', label : t('Active ?'),  display: true, type: 'boolean', },
    {id:'mainDriverFirstName', label : t('First name'),  display: true, type: 'string', },
    {id:'mainDriverLastName', label : t('Last name'),  display: true, type: 'numeric', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [         
      
      {name: 'schoolYearId', text: t('School year'), value: String(applicationSetup.currentSchoolYearId), dataType: 'enumeration', 
        options: //? [{name: t('Selected year'), value: String(schoolYearId)}]: 
          [...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
      },
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      {name: 'licensePlate', text: t('License plate'), value: ''},
    ]);    

  const [filteredSchoolYearBuss, ] = useState<ISchoolYearBus[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ISchoolYearBus[]> => {
    
    const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '0';
    const schoolYearId = toNumber(temp);

    const licensePlate = filterElements.find( elt => elt.name === 'licensePlate')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';       

    const arr = await getSchoolYearBuses( { schoolYearId, name,description,licensePlate} );
   
    return arr;
  }

  const objKey: keyof ISchoolYearBus = 'id';

  return {
    title: t('School bus'), headCells: headSchoolYearBusCells, objKey,
    filterElements, rows: filteredSchoolYearBuss, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
