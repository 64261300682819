


const _ = () => {

    const generateRandomColor = () : string => {
        const r = Math.floor(Math.random() * 256); // Random value for red (0 to 255)
        const g = Math.floor(Math.random() * 256); // Random value for green (0 to 255)
        const b = Math.floor(Math.random() * 256); // Random value for blue (0 to 255)
        return `rgb(${r},${g},${b})`; // Return the color as an RGB string
      };

    //const formatAmount = (num: )  new Intl.NumberFormat('fr-FR').format(total)
        
    const filterDistinctObjects = <T>(array: T[], prop1: keyof T, prop2: keyof T): T[] => {
        const seen = new Set<string>();
        return array.filter(item => {
          const key = `${item[prop1]}|${item[prop2]}`;
          if (seen.has(key)) {
            return false;
          }
          seen.add(key);
          return true;
        });
      };

    return {
        generateRandomColor,

        filterDistinctObjects
    }

}

export default _;