import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { DatePicker } from '@mui/x-date-pickers';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DateRangeIcon from '@mui/icons-material/DateRangeOutlined';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useSchoolYearBusService, { useBasicFilterSchoolYearBus } from './services/SchoolYearBus';
import useBusDestinationService, { useBasicFilterBusDestination } from './services/BusDestination';

import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, FormHelperText, IconButton, InputAdornment, Link, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ISchoolYearBus, defaultSchoolYearBus, ISchoolYearBusDestination } from './models/SchoolYearBus';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';


import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';
import useBillingService from 'features/finance/services/Billing';

import { startOfMonth, endOfMonth, format } from 'date-fns';


import IEnumeration, { Enum_ENERGY_TYPE, IEnumerationItem } from 'features/configuration/models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IBusDestination } from './models/BusDestination';
import { carouselImage } from 'themes/commonStyles';
import { ITeacher } from './models/Teacher';
import { useBasicFilterTeacher } from './services/Teacher';
import { isFalsy } from 'utility-types';
import { IBilling } from 'features/finance/models/Billing';
import EnhancedTable from 'components/ui/EnhancedTable';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const SchoolYearBusForm: FC<ISchoolYearBus> = (props: ISchoolYearBus = defaultSchoolYearBus) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();
  const {getBillingsBySchoolYearBus } = useBillingService();

  const { createSchoolYearBus, updateSchoolYearBus } = useSchoolYearBusService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);
  
  const {language: lg, schoolYears, applicationSetup, roleEntities} = useRecoilValue(currentUserSessionAtom);
  

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterSchoolYearBus = useBasicFilterSchoolYearBus( 
    (event: React.MouseEvent<unknown>, row: ISchoolYearBus) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  const emptyFunc = (obj: any) => {}
  
  const [openBusDestinationFilter, setOpenBusDestinationFilter] = useState(false);
  const basicFilterBusDestination = useBasicFilterBusDestination( 
      (event: React.MouseEvent<unknown>, row: IBusDestination) => {
          const {id ,name, description, destinationOption} = row;

          if(getValues().schoolYearBusDestinations.some( x => x.busDestinationId === id)) return;       
            
                (refAppendSchoolYearBusDestinations.current??emptyFunc)({id: 0, busDestinationId: id, schoolYearBusId: _id,
                  isDeparture: true, isArrival: true, busDestinationName: name, busDestinationOption: destinationOption });

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenBusDestinationFilter(false);
      }
  );

  type TeacherUsage = 'main' | 'first' | 'second';
  const [currentTeacherUsage, setCurrentTeacherUsage] = useState<TeacherUsage>('main');

  const [openTeacherFilter, setOpenTeacherFilter] = useState(false);
  const basicFilterTeacher = useBasicFilterTeacher( 
      (event: React.MouseEvent<unknown>, row: ITeacher) => {
          const {id, firstName, lastName} = row;
          
          if(currentTeacherUsage === 'main'){
            setValue('mainDriverId', id);
            setValue('mainDriverName', `${lastName} ${firstName}`);
          } else if(currentTeacherUsage === 'first') {
            setValue('firstSubstituteDriverId', id);
            setValue('firstSubstituteDriverName', `${lastName} ${firstName}`);
          } else if(currentTeacherUsage === 'second') {
            setValue('secondSubstituteDriverId', id);
            setValue('secondSubstituteDriverName', `${lastName} ${firstName}`);
          }
                           
          setOpenTeacherFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<ISchoolYearBus>({defaultValues:defaultSchoolYearBus});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchBase64Picture = watch('base64Picture');
  const watchEnergyType = watch('energyType');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ISchoolYearBus>,Error,ISchoolYearBus>(
      _id>0?updateSchoolYearBus:createSchoolYearBus, {   
        onSuccess: (data: IResult<ISchoolYearBus>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['SchoolYearBus',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ISchoolYearBus>(['SchoolYearBus', _id], () => retrieveEntity('SchoolYearBus',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'BusDestination'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_ENERGY_TYPE ] ));

      const getSchoolYearBusDestinationType = (row: ISchoolYearBusDestination, cellId: keyof ISchoolYearBusDestination, 
        opts: {value: string, name: string}[]) => {        
        
        return [{value: 'base', name: `${t('Base')} (${t('User')})` }, {value: 'expression', name: `${t('Expression')}` }];
      }

      const cellEditableSchoolYearBusDestination = (row: ISchoolYearBusDestination, cellId: keyof ISchoolYearBusDestination) => {
        return true;
      }

    const [headSchoolYearBusDestinationCells, setHeadSchoolYearBusDestinationCells]  = useState<HeadCell<ISchoolYearBusDestination>[]>([      
        //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'busDestinationName', label : t('Destination'),  display: true, type: 'string', width: 60,  },
        {id:'isArrival', label : t('Arrival ?'),  display: true, type: 'boolean', width: 20 },
        {id:'isDeparture', label : t('Departure ?'),  display: true, type: 'boolean', width: 20 },
        
      ]);

    const refAppendSchoolYearBusDestinations = useRef<(value: Partial<FieldArray<ISchoolYearBus>> | Partial<FieldArray<ISchoolYearBus>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateSchoolYearBusDestination = useRef<(index: number,value: Partial<FieldArray<ISchoolYearBus>> ) => void>(null);
    const refRemoveSchoolYearBusDestination = useRef<(index: number ) => void>(null);
      
    const handleAddUsers = (event: any) => {
      setOpenBusDestinationFilter(true);
    }   

    const handleOkFilterUser = () => {
      setOpenBusDestinationFilter(false);
    }

    const groupUserRowActionIcon = ( tarificationMatrix: ISchoolYearBusDestination) : ActionIconTableRow<ISchoolYearBus,ISchoolYearBusDestination> => {
  
      const res: ActionIconTableRow<ISchoolYearBus,ISchoolYearBusDestination> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: ISchoolYearBusDestination) => {
          
           (refRemoveSchoolYearBusDestination.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const handleBusDestinationDoubleClick = (event: React.MouseEvent<unknown>,index: number,row: ISchoolYearBusDestination) => {      
    const roleEntityBusDestination = roleEntities.find(re => re.entityName === 'BusDestination');
    if(isFalsy(roleEntityBusDestination)) return;

    const schoolYearBusDestination = getValues().schoolYearBusDestinations.at(index);
    if(isFalsy(schoolYearBusDestination)) return;
    
    navigate(`/busDestination/${schoolYearBusDestination.busDestinationId}`);
  }

  const handleClickOpenTeacher = (teacherUsage: TeacherUsage) => (event: any) => {
      
    setCurrentTeacherUsage(teacherUsage);
    setOpenTeacherFilter(true);      
  }

  const handleBillingDoubleClick = (event: React.MouseEvent<unknown>,row: IBilling) => {      
    const roleEntityRegistration = roleEntities.find(re => re.entityName === 'Registration');
    if(isFalsy(roleEntityRegistration)) return;
    
    navigate(`/registration/${row.registrationId}`);
  }


  function openFileDialog() {
    (document as any).getElementById("file-upload").click();
  }

  const setFile = (_event: any) => {
    let f = _event.target.files![0];
    var reader = new FileReader();

    reader.onload = function () {

        var binaryString = reader.result as string;
    
        const base64String = binaryString
                                    .replace('data:', '')
                                    .replace(/^.+,/, '');

        setValue("base64Picture", base64String);        
      };
  
      reader.onerror = function () {
        console.log("File load failed");
      };
  
      reader.readAsDataURL(f);    
  };

  const handleChangeDateRange = (event: any) => {

    if(getValues().id <= 0) return;

    setValue('startDate', startDate);
    setValue('endDate', endDate);

    setOpenDateRangeDialog(true);
  }

  const [openDateRangeDialog, setOpenDateRangeDialog] = useState<boolean>(false);
  const [billings, setBillings] = useState<IBilling[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

  useEffect( () => {
  
    async function _() {    
      if(_id>0) {

        //const startDate = new Date();
        //startDate.setDate(1); // Set to the 1st day of the current month
        
        //const formattedDate = startDate.toISOString().split('T')[0]; // Get "YYYY-MM-DD" format
        //const encodedString = encodeURIComponent(formattedDate);
        //endOfMonth(new Date()

        
        const arr = await getBillingsBySchoolYearBus(_id, startDate, endDate );
        setBillings(arr);
      }
    }
    
    _();
  }, [_id, startDate, endDate]);
  
    useEffect( () => {        
      if(getValues().schoolYearId <= 0)
        setValue('schoolYearId', applicationSetup.currentSchoolYearId);
    }, []);


      useEffect( () => {        
        setCurrentFormNameAtom(t('School bus'));
        setCurrentBasicTextFilterProps(basicFilterSchoolYearBus);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0) {
              retrieveData('SchoolYearBus',_id, refetch);  
              const start = startOfMonth(new Date());
              //startDate.setDate(1);
              const end = endOfMonth(new Date());

              setStartDate(start);
              setEndDate(end);
            }
              
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultSchoolYearBus, schoolYearId: applicationSetup.currentSchoolYearId});    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {   
        if(!checkEntitySaveAuthorization('SchoolYearBus', _id)){
          setIsSaveLoading(false);
             return;
        }
       

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('SchoolYearBus', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(5% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <Controller name='schoolYearId' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="schoolYearId"
                                        label={t('School year')} inputProps={ {readOnly: false}} focused >
                                        {schoolYears && schoolYears.map( 
                                          (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />
                                
                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                                <FormControlLabel sx={{width:'calc(15% - 8px)'}}
                                  label={t('Active ?')}
                                  control={
                                  <Controller
                                      name='isActive'
                                      control={control}
                                      render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                  />} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                
                                <TextField sx={{width:'calc(45% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('Potency')} sx={{width:'calc(10% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='fiscalPotency'
                                  control={control}
                                />
                                <Controller name='energyType' control={control} 
                              
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="destinationOption"
                                      label={t('Energy')} inputProps={ {readOnly: false}} 
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_ENERGY_TYPE, 
                                                                t('Energy type'), t('Energy type'))}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchEnergyType) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_ENERGY_TYPE, t('Energy type'), t('Energy type'),
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_ENERGY_TYPE && x.code === watchEnergyType)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      } >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_ENERGY_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                                />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat              
                                          disabled={false}
                                          label={t('Capacity')} sx={{width:'calc(10% - 8px)'}} //disabled={true}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}

                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name='capacity'
                                    control={control}
                                  />
                                <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                    return (
                                      <NumberFormat              
                                        disabled={false}
                                        label={t('Max capacity')} sx={{width:'calc(15% - 8px)'}} //disabled={true}
                                        allowEmptyFormatting={false}
                                        control={control}    
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        onValueChange={ (v) => onChange(v.floatValue) }
                                        defaultValue={value}
                                        value={value}

                                        customInput={TextFieldRight}                            
                                      />
                                    );
                                  }}
                                  name='maxCapacity'
                                  control={control}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Stack flexDirection='row'>
                                <Box sx={{ mt: 0.25, width: '50%' }} >
                                  <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                    accept="image/*" />
                                  <div key={'step.id'}>
                                    <Box
                                      component="img"
                                      sx={carouselImage}
                                      src={isFalsy(watchBase64Picture)?`/images/schoolBus.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                                      onClick={openFileDialog}
                                      //alt={step.id}
                                    ></Box>
                                  </div>                                
                                </Box>
                                <Box sx={{ mt: 1, width: '50%' }} >
                                  <Stack flexDirection='column'>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(50% - 8px)'}} id="licensePlate" label={t('License plate')} 
                                          {...register('licensePlate')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                      <TextField sx={{width:'calc(50% - 8px)'}} id="registrationNumber" label={t('Chassis number')} 
                                          {...register('registrationNumber')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                    </Box>
                                    
                                    
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="mainDriverName" label={t('Main driver')} 
                                          {...register('mainDriverName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={handleClickOpenTeacher('main') }>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                              </InputAdornment>
                                            ) 
                                          }} 
                                        />   
                                        { openTeacherFilter && <FormDialog open={openTeacherFilter} maxWidth='md'
                                              okText={''} cancelText={t('Cancel')} title={t('Driver filter')} onCancel={()=> {setOpenTeacherFilter(false);}} 
                                              onClose={()=> {setOpenTeacherFilter(false);}} onOk={()=> {}}  >
                                                  <BasicTextFilterForm<ITeacher> {...basicFilterTeacher } />
                                          </FormDialog> }                                   
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="firstSubstituteDriverName" label={`${t('Substitute driver')}(1)`} 
                                          {...register('firstSubstituteDriverName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={handleClickOpenTeacher('first') }>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                              </InputAdornment>
                                            ) 
                                          }} 
                                        />                                      
                                    </Box>
                                    <Box sx={{ mt: 1, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="secondSubstituteDriverName" label={`${t('Substitute driver')}(2)`} 
                                          {...register('secondSubstituteDriverName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                              readOnly: true,
                                              endAdornment: (
                                                <InputAdornment position="end">                                            
                                                  <IconButton color="primary" onClick={handleClickOpenTeacher('second') }>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton>                                                                                               
                                              </InputAdornment>
                                            ) 
                                          }} 
                                        />                                      
                                    </Box>
                                  </Stack>
                                </Box>
                                 
                              </Stack>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<ISchoolYearBus,ISchoolYearBusDestination,'id'> 
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headSchoolYearBusDestinationCells} rowsPathName='schoolYearBusDestinations' 
                                      title={t('Destinations')} rowActionIcon={groupUserRowActionIcon}  
                                      //onRowSelected={handleRoleEntitySelected}
                                      onRowDoubleClick={handleBusDestinationDoubleClick}                    
                                      refAppend={refAppendSchoolYearBusDestinations as MutableRefObject<(value: Partial<FieldArray<ISchoolYearBus>> | Partial<FieldArray<ISchoolYearBus>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateSchoolYearBusDestination as MutableRefObject<(index: number,value: Partial<FieldArray<ISchoolYearBus>>) => void>}
                                      refRemove={refRemoveSchoolYearBusDestination as MutableRefObject<(index: number) => void>}

                                      //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                      //displayMore={undefined}
                                      toolbarActions={[
                                      { toolTip: `${t('Add')}...`, onClickIcon: handleAddUsers ,icon: AddCircleIcon,  },
                                      
                                      ]}
                                      canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                  { openBusDestinationFilter && <FormDialog open={openBusDestinationFilter} maxWidth='md'
                                      okText='' cancelText='' title={t('Destionation filter')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenBusDestinationFilter(false);}} onOk={handleOkFilterUser}  >
                                          <BasicTextFilterForm<IBusDestination> {...basicFilterBusDestination } />
                                  </FormDialog> }
                            </Box>
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<IBilling> rows={billings} 
                                headCells={[            
                                  //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                  {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 20 },
                                  {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 20 },

                                  {id:'schoolYearClassName', label : t('Class'),  display: true, type: 'string', width: 10 },
                                  
                                  {id:'isArrival', label : t('Arrival ?'),  display: true, type: 'boolean', width: 10},
                                  {id:'isDeparture', label : t('Departure ?'),  display: true, type: 'boolean', width: 10},

                                  {id:'billingTypeName', label : t('Type'),  display: true, type: 'string', width: 15},
                                  {id:'busDestinationName', label : t('Destination'),  display: true, type: 'string', width: 15},
                                ]} 
                                title={`${t('Students in bus')} : [${format(startDate, lg.includes('en')?'MM/dd/yyyy':'dd/MM/yyyy' )} - ${format(endDate, lg.includes('en')?'MM/dd/yyyy':'dd/MM/yyyy' )}]`} 
                                objKey='id' 
                                stateSelected={undefined} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='desc' orderBy='schoolYearName'
                                onRowDoubleClick={handleBillingDoubleClick} 
                                rowActionIcon={undefined}
                                toolbarActions={[
                                  { toolTip: `${t('Add')}...`, onClickIcon: handleChangeDateRange ,icon: DateRangeIcon,  },                      
                                ]}
                              />
                              { openDateRangeDialog &&                                     
                                  <FormDialog open={openDateRangeDialog} maxWidth='sm' height='35vh'
                                    okText={t('OK')} cancelText='' title={` Date - [ ------]`} onCancel={()=> {}} 
                                    onClose={()=> {setOpenDateRangeDialog(false);}} onOk={()=> {setStartDate(getValues().startDate); setEndDate(getValues().endDate); setOpenDateRangeDialog(false);}}  >
                                        <Stack flexDirection='column' sx={{ pt:0.25, pb: 0.25 }} key={` param ${getValues().name}`} >
                                          <Box sx={{ mt: 1, width: '100%' }}>
                                          <Controller control={control}
                                              name='startDate' 
                                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <DatePicker label={t('Start date')} 
                                                  onChange={onChange}                     
                                                  value={new Date(value)}
                                                  slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                                /> )}
                                            />
                                            <Controller control={control}
                                              name='endDate' 
                                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <DatePicker label={t('End date')} 
                                                  onChange={onChange}                     
                                                  value={new Date(value)}
                                                  slotProps={{ textField: { sx: {width:'calc(50% - 8px)'}  }} }
                                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(50% - 8px)'}} />}
                                                /> )}
                                            />
                                          </Box>                                          
                                        </Stack>
                                    </FormDialog> }
                            </Box>   
                            { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }              
                        </Stack>
                    </Grid>                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

