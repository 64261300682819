

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {IDelivery, IDeliverySearch } from "../models/Delivery";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';


const _ = () => {

    const axios = useAxios(); 

    const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createDelivery = async (delivery: IDelivery)  =>       
        await (await axios.post('/api/configuration/delivery/create', delivery)).data;       
        
    const updateDelivery = async (delivery: IDelivery)  =>       
        await (await axios.post('/api/configuration/delivery/update', delivery)).data; 
    
    const getDelivery = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/delivery/get-delivery/${id}`));
      return await data;
    }
   
    const getDeliveries = async (criteria: IDeliverySearch, pagination?: IPagination) : Promise<IDelivery[]> => {

      const {reference, description } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/configuration/delivery/get-deliveries?reference=${reference}&description=${description}` +
          `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
          
      return await data;
    }

    
      
    return {    
      createDelivery,
      updateDelivery,
      getDelivery,
      getDeliveries,
      
    } 
}

export default _;

export interface IFilterDeliveryOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IDelivery[], React.Dispatch<React.SetStateAction<IDelivery[]>>],
}

const defaultFilterDeliveryOption: IFilterDeliveryOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterDelivery = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IDelivery) => void,
                    filterOption?: IFilterDeliveryOption  ) => {

  const { getDeliveries } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterDeliveryOption;

  //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

  const [headDeliveryCells, setHeadDeliveryCells]  = useState<HeadCell<IDelivery>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    
    {id:'reference', label : t('Reference'),  display: true, type: 'string', width: 20 },
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 20},

    {id:'issueDate', label : t('Date'),  display: true, type: 'date', width: 10},

  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
       
    {name: 'reference', text: t('Reference'), value: ''},
    {name: 'description', text: t('Description'), value: ''},
           
  ]);
  
  const [filteredDeliveries, ] = useState<IDelivery[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IDelivery[]> => {
    
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getDeliveries( {reference, description}, pagination );
   
    return arr;
  }

  const objKey: keyof IDelivery = 'id';

  return {
    title: `${t('Delivery')}(s)`, headCells: headDeliveryCells, objKey,
    filterElements, rows: filteredDeliveries, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
