

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {ITeacher, ITeacherSearch } from "../models/Teacher";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';
import { EventInput } from '@fullcalendar/core';


const _ = () => {

    const axios = useAxios(); 

    const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createTeacher = async (teacher: ITeacher)  =>       
        await (await axios.post('/api/setup/teacher/create', teacher)).data;       
        
    const updateTeacher = async (teacher: ITeacher)  =>       
        await (await axios.post('/api/setup/teacher/update', teacher)).data; 
    
    const getTeacher = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/teacher/get-teacher/${id}`));
      return await data;
    }
   
    const getTeachers = async (criteria: ITeacherSearch) : Promise<ITeacher[]> => {

      const {firstName, lastName } = criteria;
      const {data} = (await axios.get(`/api/setup/teacher/get-teachers?firstName=${firstName}&lastName=${lastName}`));
      return await data;
    }

    const getTeachersBySubjectExamSchedule = async (schoolYearClassId: number, schoolYearTemplateSubjectExamScheduleId: number) : Promise<ITeacher[]> => {

      const {data} = (await axios.get(`/api/setup/teacher/get-teachers-by-subject-exam-schedule?schoolYearClassId=${schoolYearClassId}&schoolYearTemplateSubjectExamScheduleId=${schoolYearTemplateSubjectExamScheduleId}`));
      return await data;
    }

    const getActiveTeacherTimeTable = async (teacherId: number) : 
        Promise<{weekStart: Date, weekEnd: Date, timetableEvents: EventInput[]}> => {
      const {data} = (await axios.get(`/api/setup/teacher/get-active-teacher-time-table?teacherId=${teacherId}`));
      return await data;
    }
      
      
    return {    
      createTeacher,
      updateTeacher,
      getTeacher,
      getTeachers,

      getTeachersBySubjectExamSchedule,

      getActiveTeacherTimeTable
    } 
}

export default _;

export interface IFilterTeacherOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [ITeacher[], React.Dispatch<React.SetStateAction<ITeacher[]>>],
}

const defaultFilterTeacherOption: IFilterTeacherOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterTeacher = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITeacher) => void,
                    filterOption?: IFilterTeacherOption  ) => {

  const { getTeachers } = _();

  const { t, i18n } = useTranslation();   

  const {schoolYears} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterTeacherOption;

  //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

  const [headTeacherCells, setHeadTeacherCells]  = useState<HeadCell<ITeacher>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    
    {id:'firstName', label : t('First name'),  display: true, type: 'string', width: 20 },
    {id:'lastName', label : t('Last name'),  display: true, type: 'string', width: 20},

    {id:'birthDate', label : t('Birth date'),  display: true, type: 'date', width: 10},

    {id:'registrationNumber', label : t('Regis. Number'),  display: true, type: 'string', width: 10},
    {id:'hiringDate', label : t('Hiring date'),  display: true, type: 'string', width: 10},
    {id:'status', label : t('Status'),  display: true, type: 'string', width: 15},
    {id:'statusDate', label : t('Status date'),  display: true, type: 'date', width: 10},
    
    
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
        
    {name: 'firstName', text: t('First name'), value: ''},
    {name: 'lastName', text: t('Last name'), value: ''},            
  ]);
  
  const [filteredTeachers, ] = useState<ITeacher[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ITeacher[]> => {
        
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';  
    
    const arr = await getTeachers( {firstName,lastName} );
   
    return arr;
  }

  const objKey: keyof ITeacher = 'id';

  return {
    title: t('Teacher'), headCells: headTeacherCells, objKey,
    filterElements, rows: filteredTeachers, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
